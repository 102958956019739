import React, { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
} from '@mui/material'
import { DialogTitle } from '../../../components/titles'
import theme from '../../../utils/theme'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { DataTable } from '../../../components/tables'
import { useSelector } from '../../../state/store'
import { Text } from '@components/texts'
import { Button } from '@components/buttons'

import {
  deleteAmbulanceCompany,
  findAmbulanceCompanies,
} from '../../../state/thunks/ambulanceThunk'
import { useAppDispatch } from '../../../hooks/reduxHooks'
import { AmbulanceForm } from './AmbulanceForm'

const columns: GridColumns = [
  {
    field: 'name',
    headerName: 'Nom',
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Adresse',
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'Ville',
    flex: 1,
  },
  {
    field: 'zipCode',
    headerName: 'Code postal',
    flex: 1,
  },
  {
    field: 'contact',
    headerName: 'Téléphone',
    flex: 1,
  },

  {
    field: 'email',
    headerName: 'Email de contact',
    flex: 1,
  },
  { field: 'siret', headerName: 'Siret', flex: 1 },
  // {
  //   field: 'sites',
  //   headerName: 'Sites',
  //   flex: 1,
  //   renderCell: ({ row }) =>
  //     row.sites.map((s) => <span key={s.id}>{s.label}</span>),
  // },
]

const AmbulanceCompanies: React.FC = () => {
  const dispatch = useAppDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const ambulances = useSelector((state) => state.ambulance.data)

  useEffect(() => {
    setIsLoading(false)
    dispatch(findAmbulanceCompanies())
      .unwrap()
      .then(() => {
        setIsLoading(false)
      })
  }, [])

  const onRefresh = useCallback(() => {
    dispatch(findAmbulanceCompanies())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }

  const _onDeleteConfirm = () => {
    dispatch(deleteAmbulanceCompany(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '95vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{ width: '100%', borderRadius: '0px 25px 25px 25px' }}
          >
            <DataTable
              columns={columns}
              rows={ambulances}
              detailPanelHeight={600}
              loading={isLoading}
              getRowId={(row) => row.id}
              toolBarProps={{
                title: 'ambulance',
                icon: 'fas fa-ambulance',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one ambulance')
                },
              }}
              form={(props) => (
                <AmbulanceForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addAmbulance"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <AmbulanceForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteAmbulance"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default AmbulanceCompanies
