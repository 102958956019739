import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientFormSchema } from '../../utils/schemas'
import {
  FormHelperText,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Switch,
} from '@mui/material'
import { CreatePatientDTO } from '../../services/dtos'
import AsyncAddressAutocomplete from '@components/AddressSearchAutocomplete'
import FullAddressComponent from './FullAddressComponent'
import InputField, { useInputFieldStyles } from '../inputs/InputField'
import { DateInputField } from '../inputs/DateInputField'
import { RppsAutocompleteByNameInput } from '../RppsAutocompleteByNameInput'
import { FormattedMessage } from 'react-intl'
import { SexEnum } from '../../services/api'
import moment from 'moment'
import { SSNInput } from '../inputs/SSNInput'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PatientFormRef {
  setValues: (values: CreatePatientDTO) => void
  validate: () => Promise<boolean>
  getValues: () => Promise<CreatePatientDTO | null>
  reset: () => void
}

type Props = {
  onForeignerChange?: (isForeigner: boolean) => void
}

const PatientFormV2 = forwardRef<PatientFormRef, Props>(
  ({ onForeignerChange }, ref) => {
    const inputClasses = useInputFieldStyles()

    const {
      setValue,
      watch,
      control,
      register,
      getValues,
      trigger,
      formState: { errors },
      reset,
    } = useForm<CreatePatientDTO>({
      resolver: yupResolver(patientFormSchema),
      defaultValues: {
        sex: SexEnum.M,
        isForeigner: false,
      },
    })

    const [isManualAddressVisible, setIsManualAddressVisible] = useState(false)
    const [isManualBirthAddressVisible, setIsManualBirthAddressVisible] =
      useState(false)

    const address = watch('address')
    const placeOfBirth = watch('placeOfBirth')
    const familyDoctor = watch('familyDoctor')
    const phoneNumber = watch('phoneNumber')
    const birthdate = watch('birthDate')
    const birthDateStr = watch('birthDateStr')
    const sex = watch('sex')
    const ssn = watch('ssn')
    const isForeigner = watch('isForeigner')

    const [phoneValue, setPhoneValue] = useState('')
    const [ssnValue, setSSNValue] = useState('')

    useImperativeHandle(ref, () => ({
      setValues: (values: CreatePatientDTO) => {
        if (values.birthDate) {
          const bday = moment(values.birthDate, 'YYYYMMDD')
          bday.add(4, 'hour')
          if (!bday.isValid()) {
            const bday = values.birthDate as any as string
            setValue(
              'birthDateStr',
              `${bday.substring(bday.length - 2, bday.length)}-${bday.substring(
                4,
                6,
              )}-${bday.substring(0, 4)}`,
            )
          } else {
            setValue('birthDate', bday as any)
          }
        }

        if (values.birthDateStr) {
          setValue('birthDateStr', values.birthDateStr)
        }
        setValue('address', values.address)
        setValue('placeOfBirth', values.placeOfBirth)
        setValue('familyDoctor', values.familyDoctor)
        setValue('sex', values.sex)
        setValue('lastName', values.lastName)
        setValue('motherMaidenName', values.motherMaidenName)
        setValue('firstName', values.firstName)
        setValue('phoneNumber', values.phoneNumber)
        setValue('email', values.email)
        setValue('weight', values.weight)
        setValue('height', values.height)
        setValue('ssn', values.ssn)
        setValue('isForeigner', values.isForeigner)
        setValue('identifier', values.identifier)
      },
      validate: () => {
        return trigger()
      },
      getValues: async () => {
        const isValid = await trigger()
        const values = getValues()
        return isValid ? values : null
      },
      reset: () => {
        reset({
          address: '',
          placeOfBirth: '',
          familyDoctor: null,
          sex: SexEnum.M,
          ssn: '',
          phoneNumber: '',
          email: '',
          weight: 0,
          height: 0,
          birthDate: null,
          birthDateStr: null,
          lastName: '',
          motherMaidenName: '',
          firstName: '',
          isForeigner: false,
        })
        setPhoneValue('')
        setSSNValue('')
      },
    }))

    useEffect(() => {
      if (birthdate) {
        setValue('birthDateStr', moment(birthdate).format('DD-MM-YYYY'))
      }
    }, [birthdate])

    useEffect(() => {
      onForeignerChange?.(isForeigner ?? false)
    }, [isForeigner, onForeignerChange])

    const formatPhoneNumber = (phoneNumber: string) => {
      if (!phoneNumber) return ''
      let cleaned = phoneNumber.replace(/[^\d\s+.-]/g, '')

      if (cleaned.match(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/)) {
        if (cleaned.startsWith('+33')) {
          cleaned = cleaned.replace(
            /^\+33\s*(\d)(\d{2})(\d{2})(\d{2})(\d{2}).*/,
            '$1 $2 $3 $4 $5',
          )
          cleaned = '0' + cleaned
        } else if (cleaned.startsWith('0033')) {
          cleaned = cleaned.replace(
            /^0033\s*(\d)(\d{2})(\d{2})(\d{2})(\d{2}).*/,
            '$1 $2 $3 $4 $5',
          )
          cleaned = '0' + cleaned
        } else {
          cleaned = cleaned.replace(
            /^0\s*(\d)(\d{2})(\d{2})(\d{2})(\d{2}).*/,
            '0$1 $2 $3 $4 $5',
          )
        }
        return cleaned
      }

      return cleaned
    }

    return (
      <Box sx={{ p: 3 }}>
        <form>
          <Box mb={4}>
            <Typography
              variant="h6"
              sx={{
                mb: 3,
                color: 'grey.800',
                fontWeight: 600,
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: 40,
                  height: 3,
                  bgcolor: 'primary.main',
                  borderRadius: 1,
                },
              }}
            >
              Informations personnelles
            </Typography>

            <Grid
              item
              xs={12}
              sm={3}
              sx={{ display: 'flex', flexDirection: 'row', pb: 2 }}
            >
              <div className="mr-4">
                <RadioGroup
                  row
                  aria-labelledby="sexe"
                  defaultValue={'M'}
                  value={sex}
                  onChange={(e) => setValue('sex', e.target.value as SexEnum)}
                >
                  <FormControlLabel
                    style={{ color: '#48bef9', fontWeight: 'bold' }}
                    value="M"
                    control={
                      <Radio
                        value="M"
                        sx={{
                          color: '#48bef9',
                          '&.Mui-checked': {
                            color: '#48bef9',
                          },
                        }}
                      />
                    }
                    label="Mr"
                  />
                  <FormControlLabel
                    value="F"
                    style={{ color: '#f48dde', fontWeight: 'bold' }}
                    control={
                      <Radio
                        value="F"
                        sx={{
                          color: '#f48dde',
                          '&.Mui-checked': {
                            color: '#f48dde',
                          },
                        }}
                      />
                    }
                    label="Mme"
                  />
                </RadioGroup>
                {errors.sex && (
                  <FormHelperText error>
                    {(errors.sex as any)?.message}
                  </FormHelperText>
                )}
                <Grid item xs={2}></Grid>
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={isForeigner}
                    onChange={(e) => {
                      setValue('isForeigner', e.target.checked)
                      if (e.target.checked) {
                        setValue('ssn', '')
                      }
                    }}
                    color="primary"
                  />
                }
                labelPlacement="start"
                label="Patient étranger"
              />
            </Grid>

            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={3}>
                <InputField
                  control={control}
                  name="lastName"
                  label="Nom"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputField
                  control={control}
                  name="motherMaidenName"
                  label="Nom de jeune fille"
                  error={!!errors.motherMaidenName}
                  helperText={errors.motherMaidenName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputField
                  control={control}
                  name="firstName"
                  label="Prénom"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                {birthDateStr && !birthdate ? (
                  <div>
                    <div className="border border-gray-300 rounded-lg px-3 py-2 relative bg-gray-50">
                      <span className="absolute -top-3 left-3 bg-white px-1 text-sm text-gray-600">
                        Date de naissance
                      </span>
                      <div className="flex items-center justify-between">
                        <span className="text-gray-800">{birthDateStr}</span>
                        <Button
                          onClick={() => setValue('birthDateStr', undefined)}
                        >
                          Modifier
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <DateInputField
                    control={control}
                    name="birthDate"
                    label="Date de naissance"
                    error={!!errors.birthDate}
                    errorMessage={errors.birthDate?.message}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={phoneValue || phoneNumber}
                  name="phoneNumber"
                  className={inputClasses.textField}
                  type="tel"
                  onChange={(e) => {
                    const formatted = formatPhoneNumber(e.target.value)
                    setPhoneValue(formatted)
                    setValue('phoneNumber', formatted)
                  }}
                  label="Téléphone"
                  error={!!errors.phoneNumber}
                />
                {errors.phoneNumber && (
                  <FormHelperText error>
                    {(errors.phoneNumber as any)?.message}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputField
                  control={control}
                  name="email"
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2.5}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isManualAddressVisible}
                      onChange={(e) =>
                        setIsManualAddressVisible(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label={
                    isManualAddressVisible
                      ? 'Retour à la recherche'
                      : 'Vous ne trouvez pas la bonne adresse ?'
                  }
                />
                {isManualAddressVisible ? (
                  <FullAddressComponent
                    onChange={(v) => setValue('address', v.address)}
                    value={{
                      address: address || '',
                      address2: '',
                      zipCode: '',
                      city: '',
                      country: '',
                    }}
                  />
                ) : (
                  <AsyncAddressAutocomplete
                    onChange={(v) => setValue('address', v)}
                    value={address || ''}
                    defaultSearchText={address || ''}
                  />
                )}
                {errors.address && (
                  <FormHelperText error>
                    {errors.address?.message}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isManualBirthAddressVisible}
                      onChange={(e) =>
                        setIsManualBirthAddressVisible(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label={
                    isManualBirthAddressVisible
                      ? 'Retour à la recherche'
                      : 'Vous ne trouvez pas la bonne adresse ?'
                  }
                />
                {isManualBirthAddressVisible ? (
                  <FullAddressComponent
                    label="Adresse de naissance"
                    onChange={(v) => setValue('placeOfBirth', v.address)}
                    value={{
                      address: placeOfBirth || '',
                      address2: '',
                      zipCode: '',
                      city: '',
                      country: '',
                    }}
                  />
                ) : (
                  <AsyncAddressAutocomplete
                    label="Adresse de naissance"
                    onChange={(v) => setValue('placeOfBirth', v)}
                    value={placeOfBirth || ''}
                    defaultSearchText={placeOfBirth || ''}
                  />
                )}
                {errors.placeOfBirth && (
                  <FormHelperText error>
                    {errors.placeOfBirth?.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography
              variant="h6"
              sx={{
                mb: 3,
                color: 'grey.800',
                fontWeight: 600,
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: 40,
                  height: 3,
                  bgcolor: 'primary.main',
                  borderRadius: 1,
                },
              }}
            >
              Informations médicales
            </Typography>

            <Grid container spacing={2.5}>
              <Grid item sm={2}>
                <InputField
                  control={control}
                  name="weight"
                  label="Poids (kg)"
                  type="number"
                  error={!!errors.weight}
                  helperText={errors.weight?.message}
                />
              </Grid>
              <Grid item sm={2}>
                <InputField
                  control={control}
                  name="height"
                  label="Taille (cm)"
                  type="number"
                  error={!!errors.height}
                  helperText={errors.height?.message}
                />
              </Grid>
              <Grid item sm={2}>
                <SSNInput
                  name="ssn"
                  birthDate={birthdate ?? undefined}
                  birthPlace={placeOfBirth ?? undefined}
                  sex={sex as SexEnum}
                  value={ssnValue || ssn || undefined}
                  isForeigner={isForeigner}
                  onChange={(value) => {
                    setSSNValue(value)
                    setValue('ssn', value)
                  }}
                  errorMessage={errors.ssn?.message}
                />
              </Grid>
            </Grid>
            {!isForeigner && (
              <Grid container spacing={2.5} mt={2}>
                <Grid item xs={6}>
                  <RppsAutocompleteByNameInput
                    {...register('familyDoctor')}
                    label={
                      <FormattedMessage
                        id="familyDoctor"
                        defaultMessage="Médecin traitant"
                      />
                    }
                    onChange={(value) => setValue('familyDoctor', value as any)}
                    value={familyDoctor || null}
                  />
                  {errors?.familyDoctor && (
                    <FormHelperText error>
                      {(errors.familyDoctor as any)?.message}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </form>
      </Box>
    )
  },
)

export default PatientFormV2
