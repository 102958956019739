import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateLotPaymentDto,
  LotDto,
  OrganismPaymentDto,
  UpdateLotPaymentDto,
} from '@services/dtos'
import { PaginatedDto } from '@/services/extendedType'
import { PaginatedQuery } from '@/services/api'
import { LotService } from '@services/lotService'

export enum LotStatus {
  PENDING = 'PENDING',
  PAYED = 'PAYED',
}

export interface LotsFilters extends PaginatedQuery {
  from?: string
  to?: string
  numero?: string
  status?: LotStatus
}

export interface LotState {
  lots: PaginatedDto<LotDto>
  filters: LotsFilters
  loading: boolean
}

const initialState: LotState = {
  lots: {
    datas: [],
    totalCount: 0,
  },
  filters: {
    limit: 20,
    page: 0,
  },
  loading: false,
}

export const getLots = createAsyncThunk<PaginatedDto<LotDto>, void>(
  'lots/find',
  async (_, { getState }) => {
    const { filters } = (getState() as { lot: LotState }).lot
    return LotService.getAll(filters)
  },
)

export const createLotPayment = createAsyncThunk<
  OrganismPaymentDto,
  { id: number; dto: CreateLotPaymentDto }
>('lots/create/payment', async ({ id, dto }) =>
  LotService.createPayment(id, dto),
)

export const updateLotPayment = createAsyncThunk<
  OrganismPaymentDto,
  { id: number; dto: UpdateLotPaymentDto }
>('lots/update/payment', async ({ id, dto }) =>
  LotService.updatePayment(id, dto),
)

export const removeLotPayment = createAsyncThunk<void, number>(
  'lots/remove/payment',
  async (id) => LotService.removePayment(id),
)

export const lotReducer = createSlice({
  name: 'lots',
  initialState,
  reducers: {
    setLotFilters: (state, action: PayloadAction<LotsFilters>) => {
      state.filters = {
        ...state.filters,
        page: 0,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLots.pending, (state) => {
        state.loading = true
      })
      .addCase(getLots.rejected, (state) => {
        state.loading = false
      })
      .addCase(getLots.fulfilled, (state, action) => {
        state.lots = action.payload
        state.loading = false
      })
  },
})

export default lotReducer.reducer

export const { setLotFilters } = lotReducer.actions
