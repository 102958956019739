import { InvoiceEntity, InvoiceDto } from '@services/dtos'
import { Typography } from '@mui/material'
import moment from 'moment'
import { forwardRef } from 'react'
import { DunningNoticeNextLevel } from '@utils/constants'
import { useAppSelector } from '@hooks/reduxHooks'

interface DunningNoticeTemplateProps {
  invoice: InvoiceDto
}

const DunningNoticePdfTemplate = forwardRef<
  HTMLDivElement,
  DunningNoticeTemplateProps
>(({ invoice }, ref) => {
  const { dunningNoticeConfig } = useAppSelector(({ invoice }) => ({
    dunningNoticeConfig: invoice.dunningNoticeConfig,
  }))

  const { patient } = invoice
  const { firstName, lastName, address, email, phoneNumber, mobileNumber } =
    patient
  const infos = {
    ...((firstName || lastName) && {
      Nom: `${firstName}${firstName && lastName && ' '}${lastName}`,
    }),
    ...(address && {
      Adresse: address,
    }),
    ...(email && {
      Email: email,
    }),
    ...((phoneNumber || mobileNumber) && {
      Téléphone: phoneNumber ?? mobileNumber,
    }),
  }

  const missingInfoPlaceholder = '???'
  const billingCompanyInfos = {
    Société: invoice.billing_companies?.label ?? missingInfoPlaceholder,
    Adresse: invoice.billing_companies?.address ?? missingInfoPlaceholder,
    Pays: invoice.billing_companies?.country ?? missingInfoPlaceholder,
    'Numéro d’entreprise':
      invoice.billing_companies?.registrationNumber ?? missingInfoPlaceholder,
    'Numéro FINESS':
      invoice.billing_companies?.finessNumber ?? missingInfoPlaceholder,
    'Numéro de TVA':
      invoice.billing_companies?.taxNumber ?? missingInfoPlaceholder,
  }

  const content = dunningNoticeConfig.find(
    ({ level }) => level === invoice.nextDunningNoticeLevelPatient,
  )?.content
  if (!content) return null

  const amountDue = invoice.patient_total - invoice.patient_payed

  const generateInfosBloc = (infos: Record<string, string>) => (
    <div className="flex flex-col mt-2 space-y-2">
      {Object.entries(infos).map(([infoKey, info], index) => (
        <div key={index} className="flex">
          <div className="w-32">
            <Typography fontSize={12} color="text.secondary">
              {infoKey}
            </Typography>
          </div>
          <div className="flex-1">
            <Typography
              fontSize={12}
              sx={{
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                overflowWrap: 'anywhere',
              }}
            >
              {info}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        left: '-1000rem',
        width: 794,
        height: 1123,
        paddingBottom: 16,
      }}
    >
      <div className="flex justify-between">
        <div>
          <div className="mb-2 pl-2">
            <Typography color="text.primary" variant="h4" fontSize={38}>
              Relance facture N°{invoice.id}
            </Typography>
          </div>
          <div className="mb-24 pl-2 flex gap-x-3">
            <Typography color="text.secondary" variant="body1" fontSize={20}>
              {moment(invoice.date, 'YYYY-MM-DD').format('DD MMMM YYYY')}
            </Typography>
          </div>
        </div>
        <div className="flex justify-center mb-4">
          <img src="https://placehold.co/200x200" className={''} alt="" />
        </div>
      </div>
      <div className="flex w-full mb-8">
        <div className="w-1/2 p-4 mb-4">
          <Typography variant="h6" fontSize={18}>
            Emetteur
          </Typography>
          {generateInfosBloc(billingCompanyInfos)}
        </div>

        <div className="w-1/2 p-4 mb-4">
          <Typography variant="h6" fontSize={18}>
            Destinataire
          </Typography>
          {generateInfosBloc(infos)}
        </div>
      </div>
      <Typography variant="body1" fontSize={16}>
        {content}
      </Typography>
      <Typography variant="body1" fontSize={16}>
        Reste à payer : {amountDue} €
      </Typography>
    </div>
  )
})

export default DunningNoticePdfTemplate
