import { ExamDto } from '@/services/api'
import {
  DocumentConfigDto,
  QuoteDto,
  QuotePrecotationDto,
} from '@/services/dtos'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { forwardRef } from 'react'

interface QuoteTemplateProps {
  quote: QuoteDto
  excessAmount?: number | null
  documentConfig?: DocumentConfigDto | null
}

const QuotePdfTemplate = forwardRef<HTMLDivElement, QuoteTemplateProps>(
  ({ quote, excessAmount, documentConfig }, ref) => {
    const { patient } = quote
    const { firstName, lastName, address, email, phoneNumber, mobileNumber } =
      patient
    const infos = {
      ...((firstName || lastName) && {
        Nom: `${firstName}${firstName && lastName && ' '}${lastName}`,
      }),
      ...(address && {
        Adresse: address,
      }),
      ...(email && {
        Email: email,
      }),
      ...((phoneNumber || mobileNumber) && {
        Téléphone: phoneNumber ?? mobileNumber,
      }),
    }

    const missingInfoPlaceholder = '???'
    const billingCompanyInfos = {
      Société: quote.billingCompany?.label ?? missingInfoPlaceholder,
      Adresse: quote.billingCompany?.address ?? missingInfoPlaceholder,
      Pays: quote.billingCompany?.country ?? missingInfoPlaceholder,
      'Numéro d’entreprise':
        quote.billingCompany?.registrationNumber ?? missingInfoPlaceholder,
      'Numéro FINESS':
        quote.billingCompany?.finessNumber ?? missingInfoPlaceholder,
      'Numéro de TVA':
        quote.billingCompany?.taxNumber ?? missingInfoPlaceholder,
    }

    const generateInfosBloc = (infos: Record<string, string>) => (
      <div className="flex flex-col mt-2 space-y-2">
        {Object.entries(infos).map(([infoKey, info], index) => (
          <div key={index} className="flex">
            <div className="w-32">
              <Typography fontSize={12} color="text.secondary">
                {infoKey}
              </Typography>
            </div>
            <div className="flex-1">
              <Typography
                fontSize={12}
                sx={{
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  overflowWrap: 'anywhere',
                }}
              >
                {info}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    )

    type ExamWithQuotePrecotationsDto = {
      exam: Pick<ExamDto, 'id' | 'code' | 'label'>
      quotePrecotations: Omit<QuotePrecotationDto, 'exam'>[]
    }[]
    const transformQuotePrecotationToExamWithQuotePrecotations =
      (): ExamWithQuotePrecotationsDto => {
        const groupedByExam: Record<string, QuotePrecotationDto[]> = {}

        quote.quotePrecotations.forEach((quote) => {
          const examId = quote.exam.id.toString()
          if (!groupedByExam[examId]) {
            groupedByExam[examId] = []
          }
          groupedByExam[examId].push(quote)
        })

        return Object.keys(groupedByExam).map((examId) => {
          const exam = groupedByExam[examId][0].exam
          const quotePrecotations = groupedByExam[examId].map((quote) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { exam, ...rest } = quote
            return rest
          })

          return {
            exam: {
              id: exam.id,
              code: exam.code,
              label: exam.label,
            },
            quotePrecotations,
          }
        })
      }

    const transformedData =
      transformQuotePrecotationToExamWithQuotePrecotations()

    const rows = [
      ...transformedData.map(({ exam, quotePrecotations }) => ({
        key: exam.id,
        description: exam.label,
        details: quotePrecotations.map(
          ({ cotation_type, cotation, prix_unitaire }) =>
            `${cotation_type} ${cotation} ${prix_unitaire} €`,
        ),
        amount: quotePrecotations?.reduce(
          (accumulateur, precotation) =>
            accumulateur + precotation.prix_unitaire,
          0,
        ),
      })),
      ...(excessAmount !== undefined
        ? [
            {
              key: 'excessAmount',
              description: 'Dépassement',
              details: null,
              amount: excessAmount === null ? '???' : excessAmount,
            },
          ]
        : []),
    ]

    const total =
      quote.quotePrecotations?.reduce(
        (accumulateur, precotation) => accumulateur + precotation.prix_unitaire,
        0,
      ) + (excessAmount ?? 0)

    return (
      <div
        ref={ref}
        style={{
          position: 'fixed',
          left: '-1000rem',
          width: 794,
          height: 1123,
          paddingBottom: 16,
        }}
      >
        <div className="flex justify-between">
          <div>
            <div className="mb-2 pl-2">
              <Typography color="text.primary" variant="h4" fontSize={38}>
                Devis N°{quote.id} {!quote.isLocked && '(PROVISOIRE)'}
              </Typography>
            </div>
            <div className="mb-24 pl-2 flex gap-x-3">
              <Typography color="text.secondary" variant="body1" fontSize={20}>
                {moment(quote.createdAt, 'YYYY-MM-DD').format('DD MMMM YYYY')}
              </Typography>
            </div>
          </div>
          {documentConfig?.headerImageUrl && (
            <div className="flex justify-center mb-4">
              <img src={documentConfig?.headerImageUrl} className={''} alt="" />
            </div>
          )}
        </div>
        <div className="flex w-full mb-8">
          <div className="w-1/2 p-4 mb-4">
            <Typography variant="h6" fontSize={18}>
              Emetteur
            </Typography>
            {generateInfosBloc(billingCompanyInfos)}
          </div>

          <div className="w-1/2 p-4 mb-4">
            <Typography variant="h6" fontSize={18}>
              Destinataire
            </Typography>
            {generateInfosBloc(infos)}
          </div>
        </div>

        {documentConfig?.headerText && (
          <div className="mb-6">
            <Typography fontSize={14}>{documentConfig.headerText}</Typography>
          </div>
        )}

        <div className="mb-4">
          <Typography variant="h6" fontSize={18}>
            Détail
          </Typography>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: '#3A0CA1',
                    fontSize: 14,
                    color: 'white',
                    width: 'auto',
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: '#3A0CA1',
                    fontSize: 14,
                    color: 'white',
                    width: 150,
                    textAlign: 'right',
                  }}
                >
                  Montant
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({ key, amount, description, details }) => (
                <TableRow key={key}>
                  <TableCell sx={{ fontSize: 14, paddingBottom: '2rem' }}>
                    {description}
                    {details &&
                      details.length > 0 &&
                      details.map((detail, index) => (
                        <div key={`details${index}`}>- {detail}</div>
                      ))}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 14,
                      paddingBottom: '2rem',
                      textAlign: 'right',
                    }}
                  >
                    {amount} €
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-end mt-12 mr-2">
          <div>
            <Typography variant="h6" fontSize={14}>
              Total
            </Typography>
          </div>
          <div className="ml-12">
            <Typography fontSize={14}>{total} €</Typography>
          </div>
        </div>

        {documentConfig?.footerText && (
          <div className="mt-6">
            <Typography fontSize={14}>{documentConfig.footerText}</Typography>
          </div>
        )}
        {documentConfig?.footerImageUrl && (
          <div className="mt-2">
            <img src={documentConfig?.footerImageUrl} className={''} alt="" />
          </div>
        )}
      </div>
    )
  },
)

export default QuotePdfTemplate
