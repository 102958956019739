import { useEffect, useRef, useState } from 'react'
import ManagePatientComponent, {
  ManagepatientComponentRef,
} from '../../../../components/forms/ManagePatientComponent'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import { Button, Dialog } from '@mui/material'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import { UpTransition } from '../../../../components/animations'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { updatePatient } from '../../../../state/thunks/authThunk'
import { enqueueSnackbar } from 'notistack'
import {
  doCreateCoverege,
  doUpdateCouverture,
} from '../../../../state/reducers/patientsReducer'
import { CouvertureDto } from '../../../../common/interfaces'
import { doUpdateOrder } from '../../../../state/reducers/orderReducer'
import { doGetCouverture } from '../../../../state/thunks/patientsThunk'
import { Shield } from 'lucide-react'
import { Spinner } from '../../../../components/loadings'
import { UpdatePatientDto } from '../../../../services/api'
import moment from 'moment'

type Props = {
  isOpen?: boolean
  onClose?: () => void
  onRegistered?: () => void
  mode?: 'modal' | 'div'
}

const RegisterPatientForm = ({
  isOpen,
  onClose,
  onRegistered,
  mode = 'modal',
}: Props) => {
  const dispatch = useAppDispatch()
  const order = useAppSelector((state) => state.order.selectedOrder)

  const [isSaving, setIsSaving] = useState(false)
  const [isFetchingCoverage, setIsFetchingCoverage] = useState(false)
  const managePatientRef = useRef<ManagepatientComponentRef>(null)

  useEffect(() => {
    if (isOpen) {
      const q = {
        idVisit: order?.visitId,
        idPatient: order?.patient.id,
      }
      dispatch(doGetCouverture(q))
        .unwrap()
        .then((res) => {
          const assignCouverture = (dto: CouvertureDto) => {
            managePatientRef.current?.getCouvertureFormRef()?.setValues(dto)
            if (dto.idVisit === order?.visitId) {
              managePatientRef.current?.setIsCoverageSet(true)
            }
          }
          if (res) {
            assignCouverture(res)
          } else {
            dispatch(doGetCouverture({ idPatient: q.idPatient }))
              .unwrap()
              .then((res) => {
                if (res) {
                  assignCouverture(res)
                }
              })
          }
        })
    }
  }, [isOpen, order])

  const handleUpdatePatient = async () => {
    if (!order?.patient.id) {
      return
    }
    const patientDto = await managePatientRef.current
      ?.getPatientFormRef()
      ?.getValues()

    if (!patientDto) {
      return
    }
    const cb = () => {
      enqueueSnackbar('Patient mis à jour avec succès', {
        variant: 'success',
      })
    }

    setIsSaving(true)
    try {
      const res = await dispatch(
        updatePatient({
          id: order?.patient.id,
          body: patientDto as UpdatePatientDto,
        }),
      ).unwrap()
      if (res.birthDate) {
        const coverageRef = managePatientRef.current?.getCouvertureFormRef()
        if (coverageRef) {
          const values = await coverageRef.getValues()
          if (values) {
            if (!values.beneficaireDateNaissance) {
              const m = moment(res.birthDate).format('YYYYMMDD')
              coverageRef.setValues({
                ...values,
                beneficaireDateNaissance: m,
              })
            }
          }
        }
      }
      cb()
      return res
    } catch (e) {
      console.error(e)
    } finally {
      setIsSaving(false)
    }
  }

  const updateCouverture = async () => {
    if (!order) {
      return
    }
    const cb = () => {
      enqueueSnackbar('Couverture mise à jour avec succès', {
        variant: 'success',
      })
    }

    const cv = await managePatientRef.current
      ?.getCouvertureFormRef()
      ?.getValues()

    if (!cv) {
      return
    }
    cv.idPatient = order.patient.id

    setIsSaving(true)
    try {
      if (cv.idVisit !== order.visitId) {
        const { id: _, ...dto } = cv
        if (!order.visitId) {
          return
        }
        dto.idVisit = order.visitId
        const couverture = await dispatch(
          doCreateCoverege({
            patientId: order.patient.id,
            dto: dto as CouvertureDto,
          }),
        ).unwrap()
        managePatientRef.current?.getCouvertureFormRef()?.setValues(couverture)
        managePatientRef.current?.setIsCoverageSet(true)
        cb()
      } else {
        await dispatch(
          doUpdateCouverture({
            id: cv.id,
            dto: cv as CouvertureDto,
          }),
        ).unwrap()
        cb()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  const handleRegisterPatient = async () => {
    if (!order?.id) {
      return
    }
    try {
      setIsSaving(true)
      const updatePatientRes = await handleUpdatePatient()
      if (!updatePatientRes) {
        return
      }
      try {
        await updateCouverture()
      } catch (e) {
        enqueueSnackbar('Erreur lors de la mise à jour de la couverture', {
          variant: 'error',
        })
      }
      setIsSaving(true)
      const now = moment()

      await dispatch(
        doUpdateOrder({
          id: order.id,
          dto: {
            registerDate: now.format('DD-MM-YYYY'),
            registerTime: now.format('HH:mm'),
          },
        }),
      ).unwrap()

      onRegistered?.()
    } catch (e) {
      console.error(e)
    } finally {
      setIsSaving(false)
    }
  }

  const content = (
    <>
      <div className="flex justify-between items-center bg-white p-4">
        <h4 className="text-xl font-bold">Accueillir le patient</h4>
        <div className={`flex gap-2 ${mode === 'div' ? 'pr-20' : ''}`}>
          {!order?.registeredAt && (
            <Button
              onClick={handleRegisterPatient}
              variant="contained"
              className="!bg-[#3A0CA1] hover:bg-[#3A0C91]"
              startIcon={
                isSaving ? (
                  <Spinner sx={{ color: 'white' }} size={14} />
                ) : (
                  <MeetingRoomIcon />
                )
              }
              size="small"
              disabled={isSaving || isFetchingCoverage}
            >
              Mettre à jour et accueillir
            </Button>
          )}
          <Button
            onClick={handleUpdatePatient}
            variant="outlined"
            startIcon={
              isSaving ? (
                <Spinner sx={{ color: 'gray' }} size={14} />
              ) : (
                <EditIcon />
              )
            }
            size="small"
            disabled={isSaving}
          >
            Mettre à jour
          </Button>
          <Button
            onClick={updateCouverture}
            variant="outlined"
            size="small"
            startIcon={
              isSaving ? (
                <Spinner sx={{ color: 'gray' }} size={14} />
              ) : (
                <Shield size={14} />
              )
            }
            disabled={isSaving || isFetchingCoverage}
          >
            Sauvegarder couverture
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            startIcon={<CloseIcon />}
            size="small"
            disabled={isSaving}
          >
            Fermer
          </Button>
        </div>
      </div>
      <div className="bg-white min-h-[80vh] pr-[140px] p-6">
        {!!order && (
          <ManagePatientComponent
            patientId={order?.patient.id}
            visitId={order?.visitId}
            ref={managePatientRef}
            onFetchingCoverageUpdated={setIsFetchingCoverage}
          />
        )}
      </div>
    </>
  )

  if (mode === 'div') {
    return content
  }

  return (
    <Dialog
      open={!!isOpen}
      onClose={onClose}
      fullScreen
      fullWidth
      TransitionComponent={UpTransition}
      aria-labelledby="form-dialog-title2"
      PaperProps={{
        sx: {
          borderRadius: 5,
        },
      }}
    >
      {content}
    </Dialog>
  )
}

export default RegisterPatientForm
