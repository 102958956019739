import { Container, Grid, Tooltip } from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { ChequeDto } from '@services/dtos'
import moment from 'moment'
import ReportIcon from '@mui/icons-material/Report'
import UndoIcon from '@mui/icons-material/Undo'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import { useState } from 'react'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { updateCheque } from '@state/reducers/chequeDepositReducer'
import { useAppDispatch } from '@hooks/reduxHooks'

interface ChequesTableProps {
  cheques: ChequeDto[]
  chequeDepositId: number
}

export default function ChequesTableProps({
  cheques,
  chequeDepositId,
}: ChequesTableProps) {
  const [unpaidActionChequeForm, setUnpaidActionChequeForm] =
    useState<ChequeDto | null>(null)

  const dispatch = useAppDispatch()

  const actionBtnObj = [
    {
      id: 1,
      btnName: (row: ChequeDto) =>
        row.unpaid ? 'Annuler l\'impayé' : 'Passer en impayé',
      icon: (row: ChequeDto) =>
        row.unpaid ? (
          <UndoIcon color="primary" />
        ) : (
          <ReportIcon color="primary" />
        ),
      action: (row: ChequeDto) => setUnpaidActionChequeForm(row),
      actionKey: 'unpaidActionCheque',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      sortable: false,
      renderCell: (params) => {
        const cheque = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              acc.push(
                <Tooltip
                  key={cheque.id + '-' + el.btnName(cheque)}
                  title={el.btnName(cheque)}
                >
                  <div
                    className="cursor-pointer p-1 hover:bg-gray-200 rounded"
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(cheque)
                    }}
                  >
                    {el.icon(cheque)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      valueGetter: ({ row }) =>
        moment(row.patientPayment.createdAt).format('DD/MM/YYYY'),
    },
    {
      field: 'chequeNumber',
      headerName: 'N° de chèque',
      width: 130,
      valueGetter: ({ row }) => row.chequeNumber,
    },
    {
      field: 'invoiceId',
      headerName: 'N° facture',
      width: 130,
      valueGetter: ({ row }) => row.patientPayment.invoiceId,
    },
    {
      field: 'drawerName',
      headerName: 'Tireur',
      width: 140,
      valueGetter: ({ row }) => row.drawerName,
    },
    {
      field: 'minimumRequestedDepositDate',
      headerName: 'Date dépôt minimale demandée',
      width: 145,
      valueGetter: ({ row }) =>
        row.minimumRequestedDepositDate
          ? moment(row.minimumRequestedDepositDate).format('DD/MM/YYYY')
          : '',
    },
    {
      field: 'maximumDepositDate',
      headerName: 'Date dépôt maximale',
      width: 145,
      valueGetter: ({ row }) =>
        moment(row.maximumDepositDate).format('DD/MM/YYYY'),
    },
    {
      field: 'bankEstablishment',
      headerName: 'Banque',
      width: 140,
      valueGetter: ({ row }) => row.bankEstablishment.label,
    },
    {
      field: 'amount',
      headerName: 'Montant',
      width: 120,
      valueGetter: ({ row }) => `${row.patientPayment.amount}€`,
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) =>
        row.updatedBy ? moment(row.updatedAt).format('DD/MM/YYYY') : '',
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : '',
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 300px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={cheques}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        title="Impayé"
        message={`Voulez-vous vraiment ${
          unpaidActionChequeForm?.unpaid
            ? 'retirer l\'impayé'
            : 'passer en impayé'
        } ce chèque ?`}
        open={!!unpaidActionChequeForm}
        onConfirm={() => {
          if (unpaidActionChequeForm) {
            dispatch(
              updateCheque({
                id: chequeDepositId,
                chequeId: unpaidActionChequeForm.id,
                dto: { unpaid: !unpaidActionChequeForm.unpaid },
              }),
            )
              .unwrap()
              .then(() => {
                dispatch(
                  enqueueSnackbar({
                    message: 'Le chèque a été modifié avec succès',
                    options: { variant: 'success' },
                  }),
                )
              })
              .finally(() => setUnpaidActionChequeForm(null))
          }
        }}
        onCancel={() => {
          setUnpaidActionChequeForm(null)
        }}
      />
    </Container>
  )
}
