import React, { useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Paper,
  Button,
} from '@mui/material'
import {
  Person,
  Phone,
  Email,
  LocationOn,
  Cake,
  LocalHospital,
  VerifiedUser,
} from '@mui/icons-material'
import { SexEnum } from '@services/api'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { fetchOnePatientById } from '@state/thunks/patientsThunk'

type Props = {
  patientId: number
}
export const PatientDisplay: React.FC<Props> = ({ patientId }) => {
  const dispatch = useAppDispatch()

  const patient = useAppSelector(({ patients }) => patients.currentPatientInfo)

  const isInsValidated = true

  const formatValue = (value: any): string => {
    if (value === undefined || value === null || value === '')
      return 'Non renseigné'
    return value.toString()
  }

  useEffect(() => {
    dispatch(fetchOnePatientById({ id: patientId }))
  }, [patientId])

  return patient ? (
    <Card sx={{ width: '100%', mb: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Person
              sx={{
                mr: 1,
                color: patient.sex === SexEnum.M ? '#48bef9' : '#f48dde',
              }}
            />
            <Typography variant="h5">
              {formatValue(patient.lastName)} {formatValue(patient.firstName)}
            </Typography>
            {patient.motherMaidenName && (
              <Typography
                variant="body2"
                sx={{ ml: 1, color: 'text.secondary' }}
              >
                (née {patient.motherMaidenName})
              </Typography>
            )}
          </Box>
          <div className="border border-1 border-green-600 bg-green-400 flex flex-row items-center px-4 py-2 rounded-2xl">
            <VerifiedUser style={{ color: 'white', fontSize: '20px' }} />
            <span className="ml-2 text-sm text-white font-bold">
              {isInsValidated ? 'INSi Validé' : 'INSi Non Validé'}
            </span>
          </div>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Informations Personnelles
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Cake fontSize="small" />
                    <Typography>
                      Né(e) le {patient.birthDateStr || 'Non renseigné'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationOn fontSize="small" />
                    <Typography>
                      à {formatValue(patient.placeOfBirth)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Email fontSize="small" />
                    <Typography>{formatValue(patient.email)}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Phone fontSize="small" />
                    <Typography>{formatValue(patient.phoneNumber)}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationOn fontSize="small" />
                    <Typography>{formatValue(patient.address)}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Informations Médicales
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Poids
                  </Typography>
                  <Typography>
                    {patient.weight ? `${patient.weight} kg` : 'Non renseigné'}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    Taille
                  </Typography>
                  <Typography>
                    {patient.height ? `${patient.height} cm` : 'Non renseigné'}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    <LocalHospital fontSize="small" />
                    <Typography>
                      Médecin traitant:{' '}
                      {patient.familyDoctor
                        ? `Dr ${patient.familyDoctor.lastName} ${patient.familyDoctor.firstName}`
                        : 'Non renseigné'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Informations Administratives
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Numéro de sécurité sociale
                  </Typography>
                  <Typography>{formatValue(patient.ssn)}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    INS
                  </Typography>
                  <Typography>{formatValue(patient.ins)}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    OID
                  </Typography>
                  <Typography>{formatValue(patient.iod)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {patient.comments && (
            <Grid item xs={12}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Commentaires
                </Typography>
                <Typography>{patient.comments}</Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <></>
  )
}
