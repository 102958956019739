import { Member, RppsDto, UpdateMedicalOrderDto } from '@services/api'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MedicalOrderSchema } from '../../utils/schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  MenuItem,
  Chip,
  Button,
  LinearProgress,
  CircularProgress,
  Autocomplete,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { findAll } from '@state/thunks/siteThunk'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import moment from 'moment'
import { doFindMembers } from '@state/thunks/memberThunk'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { OrderDto, UpdateOrderDto } from '@services/dtos'
import { doUpdateOrder } from '@state/reducers/orderReducer'
import { capitalize, upperCase } from 'lodash'
import { updatePatient } from '../../state/thunks/authThunk'
import { useInputFieldStyles } from '../inputs/InputField'
import {
  useAutocompleteStyles,
  useSelectFieldStyles,
} from '../inputs/SelectField'

type Props = {
  order: OrderDto
  editByDefault?: boolean
}
export const MedicalOrderForm: React.FC<Props> = ({ order, editByDefault }) => {
  const dispatch = useAppDispatch()

  // ------------------------- styles ------------------------------
  const inputFieldStyles = useInputFieldStyles()
  const selectFieldStyles = useSelectFieldStyles()
  const autocompleteStyles = useAutocompleteStyles()

  // ------------------------- state ------------------------------
  const { sites, members, currentUser } = useAppSelector(
    ({ site, member, auth }) => ({
      sites: site.sites,
      members: member.members,
      currentUser: auth.user as Member,
    }),
  )

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateMedicalOrderDto>({
    resolver: yupResolver(MedicalOrderSchema),
    defaultValues: {
      siteId: order.site?.id,
      attendingDoctor: order.attendingDoctor,
      referringDoctor: order.referringDoctor,
      consultingDoctor: order.consultingDoctor,
      secretaryId: order.secretary?.id || currentUser?.id,
      registerDate: order.registeredAt
        ? (moment(order.registeredAt).format('DD-MM-YYYY') as any)
        : undefined,
      registerTime: order.registeredAt
        ? (moment(order.registeredAt).format('HH:mm') as any)
        : undefined,
      comments: order.comments,
      isEmergency: order.isEmergency,
      isHoliday: order.isHoliday,
      isNightShift: order.isNightShift,
    },
  })

  const attendingDoctor = watch('attendingDoctor')
  const referringDoctor = watch('referringDoctor')
  const consultingDoctor = watch('consultingDoctor')
  const registerDate = watch('registerDate')
  const registerTime = watch('registerTime')
  const isEmergency = watch('isEmergency')
  const isNightShift = watch('isNightShift')
  const isHoliday = watch('isHoliday')
  const secretaryId = watch('secretaryId')

  const [isEditing, setisEditing] = useState(editByDefault)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [familyDoctor, setFamilyDoctor] = useState<RppsDto | null>(
    order.patient.familyDoctor
      ? ({
          rppsNumber: order.patient.familyDoctor.rppsNumber,
          firstName: order.patient.familyDoctor.firstName,
          lastName: order.patient.familyDoctor.lastName,
          skillLabel: order.patient.familyDoctor.skill,
          profession: order.patient.familyDoctor.profession,
          category: order.patient.familyDoctor.category,
        } as RppsDto)
      : null,
  )

  // ------------------------- useEffects -------------------------
  useEffect(() => {
    dispatch(findAll())
  }, [])

  useEffect(() => {
    dispatch(doFindMembers({ limit: 400 }))
  }, [])

  useEffect(() => {
    if (currentUser && !secretaryId) {
      setValue('secretaryId', currentUser.id)
    }
  }, [currentUser, secretaryId])

  // ------------------------- Functions ----------------
  const onSubmit = (data: UpdateMedicalOrderDto) => {
    setIsSubmitting(true)
    const dto: UpdateOrderDto = {
      ...data,
      registerDate: registerDate || null,
      registerTime: registerTime || null,
    }

    if (
      (familyDoctor &&
        order.patient?.familyDoctor?.rppsNumber !== familyDoctor?.rppsNumber) ||
      order.patient?.familyDoctor?.id
    ) {
      dto.familyDoctor = familyDoctor
    }
    dispatch(
      doUpdateOrder({
        id: order.id,
        dto,
      }),
    ).then(() => {
      setIsSubmitting(false)
      setisEditing(false)
      dispatch(
        enqueueSnackbar({
          message: 'Mise à jour effectuée',
          options: {
            variant: 'success',
          },
        }),
      )
    })
  }

  const handleAttendingDoctorChange = (id: number) => {
    const member = members.datas.find((m) => m.id === id)
    if (member) {
      setValue('attendingDoctor', member)
      setValue('attendingDoctorId', id)
    }
  }

  const handleConsultingDoctorChange = (id: number) => {
    const member = members.datas.find((m) => m.id === id)
    if (member) {
      setValue('consultingDoctor', member)
      setValue('consultingDoctorId', id)
    }
  }
  console.log(registerDate, registerTime)

  return (
    <div>
      <div className="flex flex-row justify-end align-center gap-2 translate-y-[-46px] translate-x-[-8px]">
        {isEditing ? (
          <>
            <Button
              size="small"
              style={{ color: 'white', fontWeight: 'bold', padding: '2px 4px' }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              className="flex flex-row items-center gap-1"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size="12px" />
              ) : (
                <i className="fas fa-save"></i>
              )}
              <span>Enregistrer</span>
            </Button>
            <Button
              size="small"
              style={{
                fontWeight: 'bold',
                padding: '2px 4px',
              }}
              variant="outlined"
              onClick={() => {
                setisEditing(false)
              }}
              disabled={isSubmitting}
              className="flex flex-row items-center gap-1"
            >
              <i className="fas fa-times"></i>
              <span className="">Annuler</span>
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              setisEditing(true)
            }}
            size="small"
            style={{
              color: 'white',
              fontWeight: 'bold',
              padding: '2px 8px',
            }}
            variant="contained"
          >
            <i className="fas fa-edit mr-2"></i>
            Modifier
          </Button>
        )}
      </div>
      {isEditing ? (
        <>
          {isSubmitting && (
            <LinearProgress className="w-full translate-y-[-40px]" />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
              <Grid item xs={3}>
                <FormControl
                  className={selectFieldStyles.formControl}
                  fullWidth
                >
                  <InputLabel id="site-label" size="small">
                    Site
                  </InputLabel>
                  <Select
                    disabled={!isEditing}
                    size="small"
                    fullWidth
                    labelId="site-label"
                    label="Site"
                    error={!!errors.siteId}
                    {...register('siteId')}
                    defaultValue={order.siteId}
                    className={selectFieldStyles.select}
                  >
                    {sites.datas.map((site) => (
                      <MenuItem
                        key={site.id}
                        value={site.id}
                        className={selectFieldStyles.menuItem}
                      >
                        {site.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  className={selectFieldStyles.formControl}
                  fullWidth
                >
                  <InputLabel id="uf-label" size="small">
                    UF
                  </InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    labelId="uf-label"
                    label="UF"
                    defaultValue={[]}
                    multiple
                    className={selectFieldStyles.select}
                  ></Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  className={selectFieldStyles.formControl}
                  fullWidth
                >
                  <InputLabel id="uf-loc-label" size="small">
                    UF Localisation
                  </InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    labelId="uf-loc-label"
                    label="UF Localisation"
                    defaultValue={[]}
                    multiple
                    className={selectFieldStyles.select}
                  ></Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  className={autocompleteStyles.formControl}
                >
                  <Autocomplete
                    options={members.datas.map((member) => ({
                      label: `${member.firstName} ${member.lastName}`,
                      value: member.id,
                    }))}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, v) => {
                      if (v) {
                        handleAttendingDoctorChange(v.value)
                      } else {
                        setValue('attendingDoctor', null)
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid lightgray',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Practicien" />
                    )}
                    defaultValue={
                      attendingDoctor
                        ? {
                            value: attendingDoctor.id,
                            label: `${attendingDoctor.firstName} ${attendingDoctor.lastName}`,
                          }
                        : undefined
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  className={autocompleteStyles.formControl}
                >
                  <Autocomplete
                    options={members.datas.map((member) => ({
                      label: `${member.firstName} ${member.lastName}`,
                      value: member.id,
                    }))}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, v) => {
                      if (v) {
                        handleConsultingDoctorChange(v.value)
                      } else {
                        setValue('consultingDoctor', null)
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid lightgray',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Médecin Consultant"
                      />
                    )}
                    defaultValue={
                      consultingDoctor
                        ? {
                            value: consultingDoctor.id,
                            label: `${consultingDoctor.firstName} ${consultingDoctor.lastName}`,
                          }
                        : undefined
                    }
                  />
                </FormControl>
              </Grid>
              {!order.patient.isForeigner && (
                <Grid item xs={3} className="relative">
                  <div className="flex flex-col gap-1">
                    <RppsAutocompleteByNameInput
                      onChange={(v) => setValue('referringDoctor', v)}
                      value={referringDoctor || null}
                      label="Médecin Prescripteur"
                      size="small"
                    />

                    {order.patient.familyDoctor && (
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(ev, checked) => {
                              if (checked) {
                                setValue('referringDoctor', {
                                  rppsNumber:
                                    order.patient.familyDoctor.rppsNumber,
                                  firstName:
                                    order.patient.familyDoctor.firstName,
                                  lastName: order.patient.familyDoctor.lastName,
                                } as RppsDto)
                              } else {
                                setValue('referringDoctor', null)
                              }
                            }}
                            defaultChecked={
                              order.patient.familyDoctor?.rppsNumber ===
                              referringDoctor?.rppsNumber
                            }
                            size="small"
                          />
                        }
                        label="Médecin préscripteur est le médecin traitant"
                        className="text-xs"
                        sx={{ marginBottom: '-28px' }}
                      />
                    )}
                  </div>
                </Grid>
              )}
              <Grid item xs={3}>
                <RppsAutocompleteByNameInput
                  onChange={(v) => setFamilyDoctor(v)}
                  value={familyDoctor || null}
                  label="Médecin Traitant"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}></Grid>

            <Grid container spacing={1} gap={1} mt={4}>
              <Grid item xs={4} className="flex flex-row items-center gap-x-2">
                <DatePicker
                  value={moment(registerDate, 'DD-MM-YYYY') as any}
                  onChange={(v) => {
                    setValue('registerDate', v?.format('DD-MM-YYYY'))
                  }}
                  label="Accueil"
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                    },
                  }}
                />
                <span className="mx-1">a</span>
                <TimePicker
                  value={moment(registerTime, 'HH:mm') as any}
                  onChange={(v) => setValue('registerTime', v?.format('HH:mm'))}
                  slotProps={{
                    textField: {
                      size: 'small',
                      style: { width: '280px' },
                    },
                  }}
                />

                <Button
                  onClick={() => {
                    setValue('registerDate', moment().format('DD-MM-YYYY'))
                    setValue('registerTime', moment().format('HH:mm'))
                  }}
                  variant="contained"
                  size="small"
                  className="mx-2 w-36"
                  sx={{}}
                >
                  Maintenant
                </Button>
              </Grid>
              <Grid
                item
                xs={2}
                className="flex flex-row items-center self-center flex-wrap h-13"
              >
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isEmergency', ev.target.checked)
                      }
                      defaultChecked={isEmergency}
                      size="small"
                    />
                  }
                  label="Urgent"
                />
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isEmergency', ev.target.checked)
                      }
                      size="small"
                    />
                  }
                  label="Astreinte"
                />
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isNightShift', ev.target.checked)
                      }
                      defaultChecked={isNightShift}
                      size="small"
                    />
                  }
                  defaultChecked={isNightShift}
                  label="Nuit"
                />
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isHoliday', ev.target.checked)
                      }
                      defaultChecked={isHoliday}
                      size="small"
                    />
                  }
                  label="Dimanche / J.férié"
                />
              </Grid>
              <Grid xs={5} item>
                <TextField
                  {...register('comments')}
                  multiline
                  fullWidth
                  rows={3}
                  label="Commentaires"
                  className={inputFieldStyles.textField}
                />
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <Grid container>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">Site</h4>
            <p className="text-gray-600 text-xs leader-tight">
              {order.site?.label}
            </p>
          </Grid>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">Practicien</h4>
            <p className="text-gray-600 text-xs leader-tight">
              {attendingDoctor
                ? `${capitalize(attendingDoctor.firstName)} ${upperCase(
                    attendingDoctor.lastName,
                  )}`
                : '-'}
            </p>
          </Grid>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">
              Médecin Prescripteur
            </h4>
            <p className="text-gray-600 text-xs leader-tight">
              {referringDoctor ? (
                <>
                  <span className="capitalize">
                    {referringDoctor.firstName}
                  </span>{' '}
                  <span className="uppercase">{referringDoctor.lastName}</span>{' '}
                  {!!referringDoctor.skillLabel && referringDoctor.skillLabel}
                </>
              ) : (
                '-'
              )}
            </p>
          </Grid>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">
              Médecin Consultant
            </h4>
            <p className="text-gray-600 text-xs leader-tight">
              {consultingDoctor && (
                <>
                  <span className="capitalize">
                    {consultingDoctor.firstName}
                  </span>{' '}
                  <span className="uppercase">{consultingDoctor.lastName}</span>
                </>
              )}
            </p>
          </Grid>
          <Grid container className="mt-4">
            <Grid item xs={3}>
              <h4 className="text-sm font-bold leader-tight">Secrétaire</h4>
              <p className="text-gray-600 text-xs leader-tight">
                {order.secretary
                  ? `${capitalize(order.secretary.firstName)} ${upperCase(
                      order.secretary.lastName,
                    )}`
                  : '-'}
              </p>
            </Grid>
            <Grid item xs={3}>
              <h4 className="text-sm font-bold leader-tight">
                Médecin Traitant
              </h4>
              <p className="text-gray-600 text-xs leader-tight">
                {order.patient.familyDoctor
                  ? `${capitalize(
                      order.patient.familyDoctor.firstName,
                    )} ${upperCase(order.patient.familyDoctor.lastName)}`
                  : '-'}
              </p>
            </Grid>
            <Grid item xs={3}>
              <h4 className="text-sm font-bold leader-tight">
                Acceuil Date et Heure
              </h4>
              {order.registeredAt && (
                <p className="text-gray-600 text-xs leader-tight">
                  {moment(order.registeredAt).format('DD/MM/YYYY [a] HH:mm')}
                </p>
              )}
            </Grid>
            <Grid item xs={6}>
              {order.isEmergency && (
                <Chip
                  label={
                    <>
                      <i className="fas fa-ambulance"></i> Urgent
                    </>
                  }
                  style={{
                    backgroundColor: '#c44569',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                />
              )}
              {order.isNightShift && (
                <Chip
                  label="Nuit"
                  style={{
                    backgroundColor: '#30336b',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                />
              )}
              {order.isHoliday && (
                <Chip
                  label="Dimanche / J. férié"
                  style={{
                    backgroundColor: '#f0932b',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                  className="ml-1"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default MedicalOrderForm
