import { CCAMDto, NGAPDto, PrecotationDto } from '@services/dtos'
import {
  Description as DocumentIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  InsertDriveFile as FileIcon,
} from '@mui/icons-material'
import moment from 'moment'
import * as uid from 'uid'

export const getFileIcon = (mimeType: string) => {
  if (mimeType.includes('pdf')) return <PdfIcon />
  if (mimeType.includes('image')) return <ImageIcon />
  if (mimeType.includes('text')) return <DocumentIcon />
  return <FileIcon />
}

export const getFileTypeLabel = (mimeType: string) => {
  if (mimeType.includes('pdf')) return 'PDF'
  if (mimeType.includes('image')) return 'Image'
  if (mimeType.includes('text')) return 'Document'
  return 'File'
}

export const ccamsToPrecotations = (ccams: CCAMDto[]): PrecotationDto[] =>
  ccams.map(({ code, prixNonOptam, modificateurs }) => ({
    cotation: code,
    cotation_type: 'CCAM',
    prix_unitaire: prixNonOptam,
    identifier: uid.uid(10).toString(),
    modificateur: modificateurs,
    date_execution: moment().format('YYYY-MM-DD'),
  }))

export const ngapsToPrecotations = (ngaps: NGAPDto[]): PrecotationDto[] =>
  ngaps.map(({ code, prix }) => ({
    cotation: code,
    cotation_type: 'NGAP',
    prix_unitaire: prix,
    identifier: uid.uid(10).toString(),
    modificateur: '',
    date_execution: moment().format('YYYY-MM-DD'),
  }))
