import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChequeDepositDto,
  CreateChequeDepositDto,
  UpdateChequeDepositDto,
  UpdateChequeDto,
} from '@services/dtos'
import { PaginatedQuery } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { ChequeDepositService } from '@services/chequeDepositService'

export interface ChequeDepositsFilters extends PaginatedQuery {
  from?: string
  to?: string
}

export interface ChequeDepositState {
  chequeDeposits: PaginatedDto<ChequeDepositDto>
  filters: ChequeDepositsFilters
  loading: boolean
}

const initialState: ChequeDepositState = {
  chequeDeposits: {
    datas: [],
    totalCount: 0,
  },
  filters: {
    limit: 20,
    page: 0,
  },
  loading: false,
}

export const getChequeDeposits = createAsyncThunk<
  PaginatedDto<ChequeDepositDto>,
  void
>('cheque-deposits/find', async (_, { getState }) => {
  const { filters } = (getState() as { chequeDeposit: ChequeDepositState })
    .chequeDeposit
  return ChequeDepositService.getAll(filters)
})

export const removeChequeDeposit = createAsyncThunk<void, number>(
  'cheque-deposits/remove',
  async (id) => ChequeDepositService.remove(id),
)

export const createChequeDeposit = createAsyncThunk<
  ChequeDepositDto,
  CreateChequeDepositDto
>('cheque-deposits/create', async (dto) => ChequeDepositService.create(dto))

export const updateChequeDeposit = createAsyncThunk<
  ChequeDepositDto,
  { id: number; dto: UpdateChequeDepositDto }
>('cheque-deposits/update', async ({ id, dto }) =>
  ChequeDepositService.update(id, dto),
)

export const updateCheque = createAsyncThunk<
  ChequeDepositDto,
  { id: number; chequeId: number; dto: UpdateChequeDto }
>('cheque-deposits/updateCheque', async ({ id, chequeId, dto }) =>
  ChequeDepositService.updateCheque(id, chequeId, dto),
)

export const chequeDepositReducer = createSlice({
  name: 'cheque-deposits',
  initialState,
  reducers: {
    setChequeDepositFilters: (
      state,
      action: PayloadAction<ChequeDepositsFilters>,
    ) => {
      state.filters = {
        ...state.filters,
        page: 0,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChequeDeposits.pending, (state) => {
        state.loading = true
      })
      .addCase(getChequeDeposits.rejected, (state) => {
        state.loading = false
      })
      .addCase(getChequeDeposits.fulfilled, (state, action) => {
        state.chequeDeposits = action.payload
        state.loading = false
      })
      .addCase(updateChequeDeposit.fulfilled, (state, action) => {
        state.chequeDeposits.datas = state.chequeDeposits.datas.map(
          (chequeDeposit) =>
            chequeDeposit.id === action.payload.id
              ? action.payload
              : chequeDeposit,
        )
      })
      .addCase(updateCheque.fulfilled, (state, action) => {
        state.chequeDeposits.datas = state.chequeDeposits.datas.map(
          (chequeDeposit) =>
            chequeDeposit.id === action.payload.id
              ? action.payload
              : chequeDeposit,
        )
      })
  },
})

export default chequeDepositReducer.reducer

export const { setChequeDepositFilters } = chequeDepositReducer.actions
