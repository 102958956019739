import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { dunningNoticeConfigSchema } from '@utils/schemas'
import {
  DunningNoticeLevel,
  UpdateDunningNoticeConfigDto,
} from '@services/dtos'
import {
  getDunningNoticeConfig,
  getInvoices,
  saveDunningNoticeConfig,
} from '@state/reducers/invoiceReducer'

type Props = {
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1 mb-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const DunningNoticeConfigForm: React.FC<Props> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdateDunningNoticeConfigDto>({
    resolver: yupResolver(dunningNoticeConfigSchema),
  })

  const dispatch = useAppDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { dunningNoticeConfig } = useAppSelector(({ invoice }) => ({
    dunningNoticeConfig: invoice.dunningNoticeConfig,
  }))

  useEffect(() => {
    dispatch(getDunningNoticeConfig())
  }, [])

  useEffect(() => {
    if (dunningNoticeConfig) {
      dunningNoticeConfig.forEach(({ level, days, content }) => {
        switch (level) {
          case DunningNoticeLevel.LEVEL_1:
            setValue('daysLevel1', days)
            setValue('content1', content)
            break
          case DunningNoticeLevel.LEVEL_2:
            setValue('daysLevel2', days)
            setValue('content2', content)
            break
          case DunningNoticeLevel.LEVEL_3:
            setValue('daysLevel3', days)
            setValue('content3', content)
            break
          default:
            break
        }
      })
    }
  }, [dunningNoticeConfig])

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: UpdateDunningNoticeConfigDto) => {
    setIsSubmitting(true)

    dispatch(saveDunningNoticeConfig({ dto: data }))
      .unwrap()
      .then(async () => {
        cb('La configuration des relances a été enregistrée avec succès')
        dispatch(getInvoices())
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Configuration</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Sauvegarder
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Relances
            </legend>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <fieldset className="border border-gray-200 p-4 rounded-lg">
                <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                  Niveau 1
                </legend>

                <FormField
                  label="Nombre de jours en attente de réglement"
                  error={errors.daysLevel1?.message}
                >
                  <input
                    type="number"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le nombre de jours"
                    {...register('daysLevel1')}
                  />
                </FormField>

                <FormField
                  label="Contenu (cas patient)"
                  error={errors.content1?.message}
                >
                  <textarea
                    rows={10}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le contenu"
                    {...register('content1')}
                  />
                </FormField>
              </fieldset>
              <div>
                <fieldset className="border border-gray-200 p-4 rounded-lg">
                  <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                    Niveau 2
                  </legend>

                  <FormField
                    label="Nombre de jours en attente de réglement"
                    error={errors.daysLevel2?.message}
                  >
                    <input
                      type="number"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Entrez le nombre de jours"
                      {...register('daysLevel2')}
                    />
                  </FormField>

                  <FormField
                    label="Contenu (cas patient)"
                    error={errors.content2?.message}
                  >
                    <textarea
                      rows={10}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Entrez le contenu"
                      {...register('content2')}
                    />
                  </FormField>
                </fieldset>
              </div>
              <fieldset className="border border-gray-200 p-4 rounded-lg">
                <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                  Niveau 3
                </legend>

                <FormField
                  label="Nombre de jours en attente de réglement"
                  error={errors.daysLevel3?.message}
                >
                  <input
                    type="number"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le nombre de jours"
                    {...register('daysLevel3')}
                  />
                </FormField>

                <FormField
                  label="Contenu (cas patient)"
                  error={errors.content3?.message}
                >
                  <textarea
                    rows={10}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le contenu"
                    {...register('content3')}
                  />
                </FormField>
              </fieldset>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default DunningNoticeConfigForm
