import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { doGetOrderHistory } from '@state/reducers/orderReducer'
import { FindOrdersQuery, OrderDto } from '@services/dtos'
import {
  Divider,
  Popover,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  Box,
  Stack,
  capitalize,
  CircularProgress,
} from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import DateFilterComponent, { DateRange } from './DateFilterComponent'

type Props = {
  order: OrderDto
}

const RenderOrderDetails: React.FC<{ order: OrderDto }> = ({ order }) => {
  return (
    <Paper sx={{ width: 500, p: 2 }}>
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Date de l&apos;examen</Typography>
          <Typography variant="body2" color="text.secondary">
            {moment(order.plannedDate).format('DD-MM-YYYY HH:mm')}
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Acceuilli le</Typography>
          <Typography variant="body2" color="text.secondary">
            {moment(order.registeredAt).format('DD-MM-YYYY HH:mm')}
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Prescripteur</Typography>
          <Typography variant="body2" color="text.secondary">
            {order.referringDoctor?.firstName} {order.referringDoctor?.lastName}
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Code</Typography>
          <Typography variant="body2" color="text.secondary">
            {order.exam?.code}
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Libellé</Typography>
          <Typography variant="body2" color="text.secondary">
            {order.exam?.label}
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Site</Typography>
          <Typography variant="body2" color="text.secondary">
            {order.site?.label}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  )
}

export const OrderHistoryComponent: React.FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch()
  const orders = useAppSelector(({ order }) => order.orderHistory)
  const [isFetching, setIsFetching] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange | null>(null)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedOrder, setSelectedOrder] = useState<OrderDto | null>(null)
  const [page, setPage] = useState(0)
  const itemsPerPage = 5

  const [query, setQuery] = useState<FindOrdersQuery>({
    patientId: order.patient.id,
    page,
    limit: itemsPerPage,
  })

  const handleMouseEnter = (
    order: OrderDto,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setSelectedOrder(order)
    setAnchorEl(event.currentTarget)
  }
  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  const handleDateRangeChange = (newDateRange: DateRange) => {
    setDateRange(newDateRange)
    setQuery((prev) => ({
      ...prev,
      startDate: newDateRange.startDate.toISOString(),
      endDate: newDateRange.endDate.toISOString(),
      page: 0,
    }))
    setPage(0)
  }

  useEffect(() => {
    setIsFetching(true)
    dispatch(doGetOrderHistory(query)).finally(() => setIsFetching(false))
  }, [query, dispatch])

  useEffect(() => {
    setQuery((prev) => ({
      ...prev,
      page,
    }))
  }, [page])

  const open = Boolean(anchorEl)
  const pageCount = Math.floor(orders.totalCount / itemsPerPage)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        bgcolor: 'background.default',
        position: 'relative',
      }}
    >
      <Box sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
        <DateFilterComponent
          dateRange={null}
          onDateRangeChange={handleDateRangeChange}
          disabled={isFetching}
        />
      </Box>
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: 1,
            borderColor: 'divider',
            p: 1,
          }}
        >
          <IconButton
            size="small"
            onClick={() => setPage((p) => Math.max(1, p - 1))}
            disabled={page === 1}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            {page} / {pageCount}
          </Typography>
          <IconButton
            size="small"
            onClick={() => setPage((p) => Math.min(pageCount, p + 1))}
            disabled={page === pageCount}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      {isFetching && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          filter: isFetching ? 'blur(2px)' : 'none',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <List
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            py: 0,
            height: 340,
          }}
        >
          {orders.datas.map((orderItem) => (
            <ListItem
              key={orderItem.id}
              onMouseEnter={(e) => handleMouseEnter(orderItem, e)}
              onMouseLeave={handleMouseLeave}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              <ListItemText
                primary={moment(orderItem.plannedDate).format('DD-MM-YYYY')}
                secondary={orderItem.medicalOrders
                  .map((mo) => mo.exam?.code)
                  .join(', ')}
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'text.primary',
                }}
                secondaryTypographyProps={{
                  variant: 'caption',
                }}
              />
              <Stack alignItems="flex-end">
                <Typography variant="caption" color="text.secondary">
                  {orderItem.site?.label}
                </Typography>
                {orderItem.referringDoctor && (
                  <Typography variant="caption" color="text.secondary">
                    Dr{' '}
                    {capitalize(
                      orderItem.referringDoctor?.firstName?.[0] || '',
                    )}
                    .{capitalize(orderItem.referringDoctor?.lastName || '')}
                  </Typography>
                )}
              </Stack>
            </ListItem>
          ))}
        </List>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{ pointerEvents: 'none' }}
      >
        {selectedOrder && <RenderOrderDetails order={selectedOrder} />}
      </Popover>
    </Box>
  )
}

export default OrderHistoryComponent
