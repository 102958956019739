import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from '@mui/material'
import Webcam from 'react-webcam'
import { Html5Qrcode } from 'html5-qrcode'
import StorageService from '../services/storage'
import CheckIcon from '@mui/icons-material/Check'

interface QRCodeReaderProps {
  open: boolean
  onClose: () => void
  onQRCodeDetected: (qrCode: string) => void
}

const QRCodeReaderComponent: React.FC<QRCodeReaderProps> = ({
  open,
  onClose,
  onQRCodeDetected,
}) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
  const [scanning, setScanning] = useState<boolean>(false)
  const [showDeviceSelector, setShowDeviceSelector] = useState<boolean>(true)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [permissionError, setPermissionError] = useState<boolean>(false)
  const qrReaderRef = useRef<Html5Qrcode | null>(null)
  const scannerContainerId = 'qr-reader'

  // Load available camera devices
  useEffect(() => {
    const getDevices = async () => {
      try {
        setPermissionError(false)

        // First try to get user media to trigger permission prompt
        await navigator.mediaDevices.getUserMedia({ video: true })

        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput',
        )

        if (videoDevices.length === 0) {
          setPermissionError(true)
          return
        }

        setDevices(videoDevices)

        // Check if we have a saved device ID
        const savedDeviceId = StorageService.getQrCodeReaderDeviceId()
        if (
          savedDeviceId &&
          videoDevices.some((device) => device.deviceId === savedDeviceId)
        ) {
          setSelectedDeviceId(savedDeviceId)
          setShowDeviceSelector(false)
        } else if (videoDevices.length === 1) {
          // If there's only one camera, select it automatically
          setSelectedDeviceId(videoDevices[0].deviceId)
          StorageService.setQrCodeReaderDeviceId(videoDevices[0].deviceId)
          setShowDeviceSelector(false)
        }
      } catch (error) {
        console.error('Error accessing camera devices:', error)
        setPermissionError(true)
      }
    }

    if (open) {
      getDevices()
    }

    return () => {
      // Make sure to clean up when component unmounts
      if (qrReaderRef.current && qrReaderRef.current.isScanning) {
        try {
          qrReaderRef.current
            .stop()
            .catch((err) =>
              console.error('Error stopping scanner on unmount:', err),
            )
        } catch (error) {
          console.error('Error in cleanup function:', error)
        }
      }
    }
  }, [open])

  // Start QR code scanner when device is selected
  useEffect(() => {
    let mounted = true

    if (selectedDeviceId && !showDeviceSelector && mounted) {
      // Small delay to ensure DOM is ready
      const timerId = setTimeout(() => {
        if (mounted) {
          startScanner()
        }
      }, 100)

      return () => {
        mounted = false
        clearTimeout(timerId)
        if (qrReaderRef.current && qrReaderRef.current.isScanning) {
          try {
            qrReaderRef.current
              .stop()
              .catch((err) =>
                console.error('Error stopping scanner on effect cleanup:', err),
              )
          } catch (error) {
            console.error('Error in effect cleanup:', error)
          }
        }
      }
    }

    return () => {
      mounted = false
    }
  }, [selectedDeviceId, showDeviceSelector])

  const stopScanner = () => {
    if (qrReaderRef.current && qrReaderRef.current.isScanning) {
      try {
        setScanning(false)
        qrReaderRef.current
          .stop()
          .catch((error) => console.error('Error stopping scanner:', error))
      } catch (error) {
        console.error('Error in stopScanner:', error)
      }
    }
  }

  const handleQRCodeDetected = (decodedText: string) => {
    stopScanner()
    setShowSuccess(true)

    setTimeout(() => {
      onQRCodeDetected(decodedText)
    }, 500)
  }

  const startScanner = async () => {
    if (!selectedDeviceId) return

    try {
      setScanning(true)

      if (!qrReaderRef.current) {
        qrReaderRef.current = new Html5Qrcode(scannerContainerId)
      }

      await qrReaderRef.current.start(
        { deviceId: selectedDeviceId },
        {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          aspectRatio: 1,
        },
        handleQRCodeDetected,
        (errorMessage) => {
          // Ignore errors during scanning
        },
      )
    } catch (error) {
      console.error('Error starting QR scanner:', error)
      setScanning(false)
      setPermissionError(true)
    }
  }

  const handleDeviceChange = (event: SelectChangeEvent) => {
    const deviceId = event.target.value
    setSelectedDeviceId(deviceId)
    StorageService.setQrCodeReaderDeviceId(deviceId)
  }

  const handleConfirmDevice = () => {
    if (selectedDeviceId) {
      setShowDeviceSelector(false)
    }
  }

  const handleChangeDevice = () => {
    stopScanner()
    setTimeout(() => {
      setShowDeviceSelector(true)
    }, 0)
  }

  const handleClose = () => {
    stopScanner()
    setTimeout(() => {
      onClose()
    }, 0)
  }

  const handleRequestPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })

      setPermissionError(false)

      stream.getTracks().forEach((track) => track.stop())

      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput',
      )

      if (videoDevices.length === 0) {
        setPermissionError(true)
        return
      }

      setDevices(videoDevices)

      if (videoDevices.length === 1) {
        setSelectedDeviceId(videoDevices[0].deviceId)
        StorageService.setQrCodeReaderDeviceId(videoDevices[0].deviceId)
        setShowDeviceSelector(false)
      } else {
        // If multiple cameras, show the selector
        setShowDeviceSelector(true)
      }
    } catch (error) {
      console.error('Error requesting camera permission:', error)
      setPermissionError(true)
    }
  }

  return (
    <>
      <DialogTitle>
        {showSuccess
          ? 'Succès'
          : showDeviceSelector
          ? 'Sélectionner une caméra'
          : 'Scanner le code QR'}
      </DialogTitle>
      <DialogContent>
        {permissionError ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 300,
              gap: 2,
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              L&apos;accès à la caméra est nécessaire pour scanner les codes QR
            </Alert>
            <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
              Veuillez autoriser l&apos;accès à la caméra dans les paramètres de
              votre navigateur pour utiliser cette fonctionnalité.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRequestPermission}
            >
              Demander l&apos;accès à la caméra
            </Button>
          </Box>
        ) : showSuccess ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 300,
            }}
          >
            <CheckIcon
              sx={{
                fontSize: 120,
                color: '#000000',
                animation: 'checkmarkAnimation 0.3s ease-out forwards',
                '@keyframes checkmarkAnimation': {
                  '0%': {
                    opacity: 0,
                    transform: 'scale(0.6)',
                  },
                  '50%': {
                    opacity: 1,
                    transform: 'scale(1.1)',
                  },
                  '100%': {
                    opacity: 1,
                    transform: 'scale(1)',
                  },
                },
              }}
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Succès
            </Typography>
          </Box>
        ) : showDeviceSelector ? (
          <FormControl fullWidth margin="normal">
            <InputLabel id="camera-select-label">Caméra</InputLabel>
            <Select
              labelId="camera-select-label"
              value={selectedDeviceId || ''}
              onChange={handleDeviceChange}
              label="Caméra"
            >
              {devices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Caméra ${devices.indexOf(device) + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              id={scannerContainerId}
              sx={{
                width: '100%',
                height: 350,
                position: 'relative',
                '& video': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                },
                '& canvas': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                },
                '& .qr-region': {
                  border: '2px solid white',
                  boxShadow: '0 0 0 2000px rgba(0, 0, 0, 0.3)',
                },
              }}
            />
            {scanning && (
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                Positionnez le code QR dans le cadre pour le scanner
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!showSuccess && (
          <>
            <Button onClick={handleClose}>Annuler</Button>
            {showDeviceSelector && !permissionError ? (
              <Button
                onClick={handleConfirmDevice}
                variant="contained"
                disabled={!selectedDeviceId}
              >
                Confirmer
              </Button>
            ) : !permissionError ? (
              <Button onClick={handleChangeDevice}>Changer de caméra</Button>
            ) : null}
          </>
        )}
      </DialogActions>
    </>
  )
}

export default QRCodeReaderComponent
