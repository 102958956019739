import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FindOrdersQuery, OrderDto, UpdateOrderDto } from '@services/dtos'
import { PaginatedDto } from '@services/extendedType'
import { OrderService } from '@services/OrderService'
import moment from 'moment'
import { OrderStatusEnum } from '../../common/interfaces'
import { updatePatient } from '../thunks/authThunk'
import { doGetOrderTransports } from './ambulanceReducer'
import {
  createPatientTransport,
  updatePatientTransport,
} from '../thunks/ambulanceThunk'

interface OrderFilter {
  status?: OrderStatusEnum
  search?: string
  page?: number
  limit?: number
  total?: number
  startDate?: number
  endDate?: number
  siteId?: number
}

export interface OrderState {
  orders: OrderDto[]
  filters: OrderFilter
  selectedOrder: OrderDto | null
  orderHistory: PaginatedDto<OrderDto>
}

const m = moment()
const initialState: OrderState = {
  orders: [],
  filters: {
    status: OrderStatusEnum.pendingRegistration,
    limit: 50,
    page: 0,
    total: 0,
    startDate: m.startOf('day').toDate().getTime(),
    endDate: m.endOf('day').toDate().getTime(),
  },
  selectedOrder: null,
  orderHistory: {
    datas: [],
    totalCount: 0,
  },
}

export const doFindOrders = createAsyncThunk<PaginatedDto<OrderDto>, void>(
  'order/findOrders',
  async (_, { getState }) => {
    const { filters } = (getState() as { order: OrderState }).order
    const query: FindOrdersQuery = {
      page: filters.page,
      limit: filters.limit,
    }
    if (filters.search) {
      query.search = filters.search
      return OrderService.get(query)
    }
    if (filters.siteId) {
      query.siteId = filters.siteId
    }

    query.status = filters.status
    query.startDate = moment(filters.startDate).toISOString()
    query.endDate = moment(filters.endDate).toISOString()

    return OrderService.get(query)
  },
)

export const doGetOrder = createAsyncThunk<OrderDto, number>(
  'order/getOrder',
  async (id) => {
    return OrderService.getById(id)
  },
)

export const doAddMedicalOrder = createAsyncThunk<
  OrderDto,
  { orderId: number; examId: number }
>('order/addMedicalOrder', async ({ orderId, examId }) => {
  return OrderService.addMedicalOrder(orderId, examId)
})

export const doRemoveMedicalOrder = createAsyncThunk<
  OrderDto,
  { orderId: number; medicalOrderId: number }
>('order/removeMedicalOrder', async ({ orderId, medicalOrderId }) => {
  return OrderService.removeMedicalOrder(orderId, medicalOrderId)
})

export const doUpdateOrder = createAsyncThunk<
  OrderDto,
  { id: number; dto: UpdateOrderDto }
>('order/updateOrder', async ({ id, dto }) => {
  return OrderService.update(id, dto)
})

export const doCreateChildOrder = createAsyncThunk<OrderDto, number>(
  'order/createChildOrder',
  async (orderId) => {
    return OrderService.createChildOrder(orderId)
  },
)

export const doFindChildOrders = createAsyncThunk<OrderDto[], number>(
  'order/findChildOrders',
  async (parentId) => {
    return OrderService.findChildOrders(parentId)
  },
)

export const doRemoveOrder = createAsyncThunk<void, number>(
  'order/removeOrder',
  async (id) => {
    return OrderService.removeOrder(id)
  },
)

export const doGetOrderHistory = createAsyncThunk<
  PaginatedDto<OrderDto>,
  FindOrdersQuery
>('order/getOrderHistory', async (query) => {
  return OrderService.getOrderHistory(query)
})

export const doCreateEmptyOrder = createAsyncThunk<OrderDto, number>(
  'order/createEmptyOrder',
  async (patientId) => {
    return OrderService.createEmptyOrder(patientId)
  },
)

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderFilters: (state, action: PayloadAction<OrderFilter>) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setSelectedOrder: (state, action: PayloadAction<OrderDto | null>) => {
      console.log('setSelectedOrder', action.payload)
      state.selectedOrder = action.payload
    },
    setChildren: (state, action: PayloadAction<OrderDto[]>) => {
      if (state.selectedOrder) {
        state.selectedOrder.children = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doFindOrders.fulfilled, (state, action) => {
      state.orders = action.payload.datas
      state.filters.total = action.payload.totalCount
    })
    builder.addCase(doAddMedicalOrder.fulfilled, (state, action) => {
      if (!state.selectedOrder) {
        return
      }
      if (state.selectedOrder?.id === action.payload.id) {
        state.selectedOrder = action.payload
      }
      const index = state.orders.findIndex(
        (order) => order.id === action.payload.id,
      )
      if (index !== -1) {
        state.orders[index] = action.payload
      }
    })
    builder.addCase(doRemoveMedicalOrder.fulfilled, (state, action) => {
      if (!state.selectedOrder) {
        return
      }
      if (state.selectedOrder.id === action.payload.id) {
        state.selectedOrder = action.payload
      }
      const index = state.orders.findIndex(
        (order) => order.id === action.payload.id,
      )
      if (index !== -1) {
        state.orders[index] = action.payload
      }
    })
    builder.addCase(doUpdateOrder.fulfilled, (state, { payload }) => {
      state.selectedOrder = payload
      const index = state.orders.findIndex((order) => order.id === payload.id)
      if (index !== -1) {
        state.orders[index] = payload
      }
    })
    builder.addCase(doFindChildOrders.fulfilled, (state, { payload }) => {
      if (state.selectedOrder) {
        state.selectedOrder.children = payload
      }
    })
    builder.addCase(doCreateChildOrder.fulfilled, (state, { payload }) => {
      if (state.selectedOrder) {
        state.selectedOrder.children.push(payload)
      }
    })
    builder.addCase(doRemoveOrder.fulfilled, (state, { meta }) => {
      state.orders = state.orders.filter((order) => order.id !== meta.arg)
      if (state.selectedOrder) {
        state.selectedOrder.children = state.selectedOrder.children.filter(
          (order) => order.id !== meta.arg,
        )
      }
    })
    builder.addCase(doGetOrderHistory.fulfilled, (state, { payload }) => {
      state.orderHistory = payload
    })
    builder.addCase(doCreateEmptyOrder.fulfilled, (state, { payload }) => {
      state.selectedOrder = payload
    })
    builder.addCase(updatePatient.fulfilled, (state, { payload }) => {
      if (state.selectedOrder) {
        state.selectedOrder.patient = payload
      }
      state.orders.forEach((order) => {
        if (order.patient.id === payload.id) {
          order.patient = payload
        }
      })
    })
    builder.addCase(doGetOrderTransports.fulfilled, (state, action) => {
      state.orders.forEach((order) => {
        if (action.payload[order.id]) {
          order.patientTransport = action.payload[order.id]
        }
      })
      if (state.selectedOrder) {
        state.selectedOrder.patientTransport =
          action.payload[state.selectedOrder.id]
      }
    })

    builder.addCase(updatePatientTransport.fulfilled, (state, action) => {
      state.orders = state.orders.map((o) => {
        if (o.patientTransport?.id === action.payload.id) {
          return { ...o, patientTransport: action.payload }
        }
        return o
      })
      if (state.selectedOrder) {
        state.selectedOrder.patientTransport = action.payload
      }
    })
    builder.addCase(createPatientTransport.fulfilled, (state, action) => {
      if (state.selectedOrder) {
        state.selectedOrder.patientTransport = action.payload.patientTransport
      }
      state.orders.forEach((order) => {
        if (order.id === action.payload.id) {
          order.patientTransport = action.payload.patientTransport
        }
      })
    })
  },
})

export default orderSlice.reducer

export const { setOrderFilters, setSelectedOrder, setChildren } =
  orderSlice.actions
