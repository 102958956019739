import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  getPaymentModes,
  removePaymentMode,
  updatePaymentMode,
} from '@state/reducers/paymentModeReducer'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { UpTransition } from '@components/animations'
import PaymentModeForm from '@components/forms/PaymentModeForm'
import { PaymentModeDto } from '@/services/dtos'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ToggleOn as ToggleOnIcon,
  ToggleOffOutlined as ToggleOffOutlinedIcon,
} from '@mui/icons-material'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { PaymentModeTypeLabels } from '@utils/constants'

export default function PaymentModesTable() {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [editingPaymentMode, setEditingPaymentMode] =
    useState<PaymentModeDto | null>(null)
  const [isPaymentFormOpen, setIsPaymentModeFormOpen] = useState(false)
  const [deletingPaymentForm, setDeletingPaymentForm] =
    useState<PaymentModeDto | null>(null)
  const [togglingPaymentMode, setTogglingPaymentMode] =
    useState<PaymentModeDto | null>(null)
  const { paymentModes } = useAppSelector((state) => ({
    paymentModes: state.paymentMode.paymentModes,
  }))

  useEffect(() => {
    setIsLoading(true)
    dispatch(getPaymentModes({ includeInactive: true })).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const actionBtnObj = [
    {
      id: 1,
      btnName: () => 'Modifier',
      icon: () => <EditIcon color="primary" />,
      action: (row: PaymentModeDto) => {
        if (row.isDefaultValue) return

        setEditingPaymentMode(row)
        setIsPaymentModeFormOpen(true)
      },
      actionKey: 'editPaymentMode',
    },
    {
      id: 2,
      btnName: (row: PaymentModeDto) =>
        row.isActive ? 'Désactiver' : 'Activer',
      icon: (row: PaymentModeDto) =>
        row.isActive ? (
          <ToggleOnIcon fontSize="small" color="primary" />
        ) : (
          <ToggleOffOutlinedIcon fontSize="small" color="primary" />
        ),
      action: (row: PaymentModeDto) => setTogglingPaymentMode(row),
      actionKey: 'togglePaymentMode',
    },
    {
      id: 3,
      btnName: () => 'Supprimer',
      icon: () => <DeleteIcon color="primary" />,
      action: (row: PaymentModeDto) =>
        !row.isDefaultValue && setDeletingPaymentForm(row),
      actionKey: 'deletePaymentMode',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const paymentMode = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              const actionDisabled =
                paymentMode.isDefaultValue &&
                el.actionKey !== 'togglePaymentMode'
              acc.push(
                <Tooltip
                  key={paymentMode.id + '-' + el.btnName(paymentMode)}
                  title={
                    actionDisabled
                      ? 'Valeur par défaut immuable'
                      : el.btnName(paymentMode)
                  }
                >
                  <div
                    className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                      actionDisabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(paymentMode)
                    }}
                  >
                    {el.icon(paymentMode)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 80,
    },
    {
      field: 'label',
      headerName: 'Libellé',
      width: 200,
    },
    {
      field: 'accountingSectionCode',
      headerName: 'Code section comptable',
      width: 200,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      valueGetter: ({ row }) => PaymentModeTypeLabels[row.type],
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 65px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                pagination
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <div className="ml-2 mt-2">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsPaymentModeFormOpen(true)}
                        >
                          <i className="fas fa-plus"></i>{' '}
                          <span className="ml-2">Ajouter</span>
                        </Button>
                      </div>
                    </GridToolbarContainer>
                  ),
                }}
                columns={columns}
                rows={paymentModes}
                rowCount={paymentModes.length}
                disableSelectionOnClick
                disableMultipleSelection
                loading={isLoading}
                getRowClassName={(params) =>
                  params.row.isActive ? '' : 'text-gray-400'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={isPaymentFormOpen}
        onClose={() => setIsPaymentModeFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isPaymentFormOpen && (
            <PaymentModeForm
              onClose={() => {
                setIsPaymentModeFormOpen(false)
                setEditingPaymentMode(null)
              }}
              editingPaymentMode={editingPaymentMode}
            />
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title={
          deletingPaymentForm
            ? 'Suppression'
            : togglingPaymentMode?.isActive
            ? 'Désactivation'
            : 'Activation'
        }
        message={
          deletingPaymentForm
            ? 'Voulez-vous vraiment supprimer ce mode de paiement ?'
            : `Voulez-vous vraiment ${
                togglingPaymentMode?.isActive ? 'désactiver' : 'activer'
              } ce mode de paiement ?`
        }
        open={deletingPaymentForm !== null || togglingPaymentMode !== null}
        onConfirm={() => {
          if (deletingPaymentForm) {
            dispatch(removePaymentMode(deletingPaymentForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'Le mode de paiement a été supprimé avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setDeletingPaymentForm(null))
          } else if (togglingPaymentMode) {
            dispatch(
              updatePaymentMode({
                id: togglingPaymentMode.id,
                dto: { isActive: !togglingPaymentMode?.isActive },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: `Le mode de paiement a été ${
                      togglingPaymentMode.isActive ? 'désactivé' : 'activé'
                    } avec succès`,
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setTogglingPaymentMode(null))
          }
        }}
        onCancel={() => {
          setDeletingPaymentForm(null)
          setTogglingPaymentMode(null)
        }}
      />
    </Container>
  )
}
