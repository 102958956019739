import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { CreatePaymentModeDto, PaymentModeDto } from '@services/dtos'
import { paymentModeSchema } from '@utils/schemas'
import { useAppDispatch } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Grid, Tooltip } from '@mui/material'
import {
  createPaymentMode,
  updatePaymentMode,
} from '@state/reducers/paymentModeReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { PaymentModeTypeLabels } from '@utils/constants'

type Props = {
  editingPaymentMode?: PaymentModeDto | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const PaymentModeForm: React.FC<Props> = ({ editingPaymentMode, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreatePaymentModeDto>({
    defaultValues: editingPaymentMode
      ? (({ code, label, type, accountingSectionCode }) => ({
          code,
          label,
          type,
          accountingSectionCode,
        }))(editingPaymentMode)
      : undefined,
    resolver: yupResolver(paymentModeSchema),
  })

  const dispatch = useAppDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreatePaymentModeDto) => {
    setIsSubmitting(true)

    if (editingPaymentMode) {
      dispatch(updatePaymentMode({ id: editingPaymentMode.id, dto: data }))
        .unwrap()
        .then(async () => cb('Le mode de paiement a été modifié avec succès'))
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(createPaymentMode(data))
        .unwrap()
        .then(async () => cb('Le mode de paiement a été créé avec succès'))
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Configuration</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingPaymentMode ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Mode de paiement
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Identifiant court"
                  error={errors.code?.message}
                >
                  <input
                    type="text"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le code"
                    {...register('code')}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField label="Libellé" error={errors.label?.message}>
                  <input
                    type="text"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le libellé"
                    {...register('label')}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField label="Type" error={errors.type?.message}>
                  {!!editingPaymentMode && !editingPaymentMode.typeIsMutable ? (
                    <Tooltip title="Type immuable car lié à des paiements / mouvements">
                      <select
                        disabled
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('type')}
                        defaultValue={editingPaymentMode?.type}
                      >
                        {Object.entries(PaymentModeTypeLabels).map(
                          ([value, label]) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ),
                        )}
                      </select>
                    </Tooltip>
                  ) : (
                    <select
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      {...register('type')}
                      defaultValue={editingPaymentMode?.type}
                    >
                      {Object.entries(PaymentModeTypeLabels).map(
                        ([value, label]) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ),
                      )}
                    </select>
                  )}
                </FormField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField
                  label="Code section comptable"
                  error={errors.accountingSectionCode?.message}
                >
                  <input
                    type="text"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le code section comptable"
                    {...register('accountingSectionCode')}
                  />
                </FormField>
              </Grid>
            </Grid>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default PaymentModeForm
