import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Grid } from '@mui/material'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import {
  CashRegisterDTO,
  OpenCashRegisterDTO,
} from '@/services/dtos/AccountingV2Dto'
import { openCashRegisterSchema } from '@utils/schemas'
import InputField from '../inputs/InputField'
import {
  getCashRegisters,
  getMyCashRegister,
  openCashRegister,
} from '@state/reducers/cashRegisterReducer'

type Props = {
  cashRegister: CashRegisterDTO
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const OpenCashRegisterForm: React.FC<Props> = ({ cashRegister, onClose }) => {
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm<OpenCashRegisterDTO>({
    resolver: yupResolver(openCashRegisterSchema),
  })

  const dispatch = useAppDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: OpenCashRegisterDTO) => {
    setIsSubmitting(true)
    dispatch(openCashRegister({ id: cashRegister.id, dto: data }))
      .unwrap()
      .then(async () => cb('La caisse a été ouverte avec succès'))
      .finally(() => {
        setIsSubmitting(false)
        dispatch(getCashRegisters())
        dispatch(getMyCashRegister())
      })
  }

  const fields: {
    name: 'cashAmount' | 'checkAmount' | 'voucherAmount'
    label: string
  }[] = [
    { name: 'cashAmount', label: 'Montant en espèces' },
    { name: 'checkAmount', label: 'Montant en chèques' },
    { name: 'voucherAmount', label: 'Montant des bons' },
  ]

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Ouverture caisse</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Valider
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Contenu
            </legend>

            <Grid
              container
              rowSpacing={2}
              columnSpacing={4}
              mt={1}
              className="mb-3"
            >
              {fields.map(({ name, label }) => (
                <Grid item xs={12} sm={6} key={name}>
                  <InputField
                    control={control}
                    name={name}
                    label={label}
                    type="number"
                  />
                </Grid>
              ))}
            </Grid>

            <FormField label="Description" error={errors.description?.message}>
              <textarea
                rows={4}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                {...register('description')}
              />
            </FormField>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default OpenCashRegisterForm
