import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Autocomplete, Grid, TextField } from '@mui/material'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import {
  CashRegisterDTO,
  CashRegisterTransactionType,
  CreateCashRegisterTransactionDto,
} from '@services/dtos/AccountingV2Dto'
import { cashRegisterTransactionSchema } from '@utils/schemas'
import InputField from '../inputs/InputField'
import { createCashRegisterTransaction } from '@state/reducers/cashRegisterReducer'
import { getPaymentModes } from '@state/reducers/paymentModeReducer'
import SelectField from '../inputs/SelectField'

type Props = {
  cashRegister: CashRegisterDTO
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const CashRegisterTransactionForm: React.FC<Props> = ({
  cashRegister,
  onClose,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm<CreateCashRegisterTransactionDto>({
    resolver: yupResolver(cashRegisterTransactionSchema),
  })

  const dispatch = useAppDispatch()
  const { paymentModes } = useAppSelector(({ paymentMode }) => ({
    paymentModes: paymentMode.paymentModes,
  }))

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    dispatch(getPaymentModes())
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreateCashRegisterTransactionDto) => {
    setIsSubmitting(true)
    dispatch(createCashRegisterTransaction({ id: cashRegister.id, dto: data }))
      .unwrap()
      .then(async () => cb('Le mouvement a été enregistré avec succès'))
      .finally(() => setIsSubmitting(false))
  }

  const fields: {
    name: 'amount'
    label: string
    type: 'number'
  }[] = [{ name: 'amount', label: 'Montant', type: 'number' }]

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Mouvement</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Valider
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Informations
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              {fields.map(({ name, label }) => (
                <Grid item xs={12} sm={6} key={name}>
                  <InputField control={control} name={name} label={label} />
                </Grid>
              ))}

              <Grid item xs={12} sm={6}>
                <SelectField
                  control={control}
                  name="transactionType"
                  options={[
                    {
                      label: 'Débit',
                      value: CashRegisterTransactionType.DEBIT,
                    },
                    {
                      label: 'Crédit',
                      value: CashRegisterTransactionType.CREDIT,
                    },
                  ]}
                  label="Type"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="paymentModeId"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={
                        paymentModes.find((mode) => mode.id === field.value) ||
                        null
                      }
                      options={paymentModes}
                      getOptionLabel={(option) =>
                        `${option.label} (${option.code})`
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(_, value) => field.onChange(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Mode de paiement"
                          error={!!errors.paymentModeId}
                          helperText={errors.paymentModeId?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid className="mt-4">
              <FormField
                label="Description"
                error={errors.description?.message}
              >
                <textarea
                  rows={4}
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  {...register('description')}
                />
              </FormField>
            </Grid>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default CashRegisterTransactionForm
