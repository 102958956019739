import React, { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { CreateLotPaymentDto, LotDto, PaymentModeType } from '@services/dtos'
import { organismPaymentSchema } from '@utils/schemas'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { getPaymentModes } from '@state/reducers/paymentModeReducer'
import { PaymentModeTypeLabels } from '@utils/constants'
import { UpTransition } from '../animations'
import { getBankEstablishments } from '@state/reducers/bankEstablishmentReducer'
import BankEstablishmentForm from './BankEstablishmentForm'
import moment from 'moment'
import PaymentModeForm from './PaymentModeForm'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import {
  createLotPayment,
  getLots,
  removeLotPayment,
  updateLotPayment,
} from '@state/reducers/lotReducer'

type Props = {
  lot: LotDto
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const OrganismPaymentForm: React.FC<Props> = ({ lot, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CreateLotPaymentDto>({
    defaultValues: lot.organismPayment
      ? {
          comment: lot.organismPayment.comment,
          drawerName: lot.organismPayment.cheque?.drawerName,
          chequeNumber: lot.organismPayment.cheque?.chequeNumber,
          date: lot.organismPayment.date,
        }
      : undefined,
    resolver: yupResolver(organismPaymentSchema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [deletingFormOpen, setDeletingFormOpen] = useState(false)
  const [isPaymentModeFormOpen, setIsPaymentModeFormOpen] = useState(false)
  const [isBankEstablishmentFormOpen, setIsBankEstablishmentFormOpen] =
    useState(false)

  const { paymentModes, bankEstablishments } = useAppSelector(
    ({ paymentMode, bankEstablishment }) => ({
      paymentModes: paymentMode.paymentModes,
      bankEstablishments: bankEstablishment.bankEstablishments,
    }),
  )
  const dispatch = useAppDispatch()

  const date = watch('date')
  const paymentModeId = watch('paymentModeId')
  const paymentModeType = useMemo(
    () => paymentModes.find(({ id }) => id === +paymentModeId)?.type,
    [paymentModeId],
  )
  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    if (paymentModes.length > 0) {
      if (lot.organismPayment) {
        setValue('paymentModeId', lot.organismPayment.paymentMode.id)
      } else {
        const validPaymentModes = paymentModes.filter(({ type }) =>
          [PaymentModeType.CHEQUE, PaymentModeType.BANK_TRANSFER].includes(
            type,
          ),
        )
        if (validPaymentModes.length > 0) {
          setValue('paymentModeId', validPaymentModes[0].id)
        }
      }
    }
  }, [paymentModes])

  useEffect(() => {
    if (
      bankEstablishments &&
      bankEstablishments.length > 0 &&
      lot.organismPayment
    ) {
      setValue(
        'bankEstablishmentId',
        lot.organismPayment.bankTransferPayment?.bankEstablishment?.id ||
          lot.organismPayment.cheque?.bankEstablishment?.id,
      )
    }
  }, [bankEstablishments])

  useEffect(() => {
    dispatch(getPaymentModes())
    dispatch(getBankEstablishments())
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreateLotPaymentDto) => {
    clearErrors()

    let hasError = false
    if (paymentModeType === PaymentModeType.CHEQUE) {
      if (!data.chequeNumber) {
        setError('chequeNumber', {
          type: 'manual',
          message: 'Requis',
        })
        hasError = true
      }
      if (!data.drawerName) {
        setError('drawerName', {
          type: 'manual',
          message: 'Requis',
        })
        hasError = true
      }
    }
    if (hasError) return

    setIsSubmitting(true)

    if (lot.organismPayment) {
      dispatch(updateLotPayment({ id: lot.id, dto: data }))
        .unwrap()
        .then(async () => cb('Le paiement a été modifié avec succès'))
        .finally(() => {
          setIsSubmitting(false)
          dispatch(getLots())
        })
    } else {
      dispatch(createLotPayment({ id: lot.id, dto: data }))
        .unwrap()
        .then(async () => cb('Le paiement a été créé avec succès'))
        .finally(() => {
          setIsSubmitting(false)
          dispatch(getLots())
        })
    }
  }

  return (
    <div className="w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Paiement organisme
          </h2>
          <div className="flex items-center space-x-4">
            {lot.organismPayment?.paymentMode && (
              <button
                type="button"
                onClick={() => setDeletingFormOpen(true)}
                className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded-lg flex items-center disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner className="mr-2" size={18} />}
                Supprimer
              </button>
            )}
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {lot.organismPayment ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Informations
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              <Grid item xs={12} sm={6}>
                <fieldset className="border border-gray-200 p-4 rounded-lg mb-4">
                  <legend className="text-sm font-medium text-gray-700 mb-1 border border-gray-200 p-2 rounded-lg">
                    Mode de paiement
                    <span className="inline-flex items-center">
                      <Tooltip title="Ajouter un mode de paiement" arrow>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsPaymentModeFormOpen(true)}
                          sx={{
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                            paddingLeft: 1,
                            paddingRight: 1,
                            minWidth: 'auto',
                            width: 'auto',
                            marginLeft: 1,
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Tooltip>
                    </span>
                  </legend>

                  <FormField label="" error={errors.paymentModeId?.message}>
                    {paymentModes.length > 0 ? (
                      <select
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        {...register('paymentModeId')}
                      >
                        {paymentModes
                          .filter(({ type }) =>
                            [
                              PaymentModeType.CHEQUE,
                              PaymentModeType.BANK_TRANSFER,
                            ].includes(type),
                          )
                          .map(({ id, label, type }) => (
                            <option key={id} value={id}>
                              {label} ({PaymentModeTypeLabels[type]})
                            </option>
                          ))}
                      </select>
                    ) : (
                      <Typography variant="body1">
                        Aucun mode de paiement enregistré
                      </Typography>
                    )}
                    <Typography variant="caption">
                      Chèque / virement uniquement.
                    </Typography>
                  </FormField>
                </fieldset>
              </Grid>

              <Grid item xs={12} sm={6}>
                <fieldset className="border border-gray-200 p-4 rounded-lg mb-4">
                  <legend className="text-sm font-medium text-gray-700 mb-1 border border-gray-200 p-2 rounded-lg">
                    Banque
                    <span className="inline-flex items-center">
                      <Tooltip title="Ajouter un établissement bancaire" arrow>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsBankEstablishmentFormOpen(true)}
                          sx={{
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                            paddingLeft: 1,
                            paddingRight: 1,
                            minWidth: 'auto',
                            width: 'auto',
                            marginLeft: 1,
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Tooltip>
                    </span>
                  </legend>

                  <FormField
                    label=""
                    error={errors.bankEstablishmentId?.message}
                  >
                    <select
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      {...register('bankEstablishmentId')}
                    >
                      <option key={'emptyBankOption'} value={''}></option>
                      {bankEstablishments.map(({ id, label }) => (
                        <option key={id} value={id}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </FormField>
                </fieldset>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField label="Date" error={errors.date?.message}>
                  <input
                    type="date"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    {...register('date')}
                    onChange={(e) =>
                      setValue(
                        'date',
                        e.target.value
                          ? moment(e.target.value, 'YYYY-MM-DD').toDate()
                          : moment().toDate(),
                      )
                    }
                    value={date ? moment(date).format('YYYY-MM-DD') : ''}
                    placeholder="Choisissez une date"
                  />
                </FormField>
              </Grid>

              {paymentModeType === PaymentModeType.CHEQUE && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="Tireur"
                      error={errors.drawerName?.message}
                    >
                      <input
                        type="text"
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Entrez le tireur"
                        {...register('drawerName')}
                      />
                    </FormField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="N° de chèque"
                      error={errors.chequeNumber?.message}
                    >
                      <input
                        type="text"
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Entrez le numéro de chèque"
                        {...register('chequeNumber')}
                      />
                    </FormField>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <FormField label="Commentaires" error={errors.comment?.message}>
                  <textarea
                    rows={3}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez vos commentaires"
                    {...register('comment')}
                  />
                </FormField>
              </Grid>
            </Grid>
          </fieldset>
        </div>
      </form>

      <Dialog
        open={isPaymentModeFormOpen || isBankEstablishmentFormOpen}
        onClose={() =>
          isPaymentModeFormOpen
            ? setIsPaymentModeFormOpen(false)
            : setIsBankEstablishmentFormOpen(false)
        }
        TransitionComponent={UpTransition}
        slotProps={{ backdrop: {} }}
      >
        <DialogContent>
          {isPaymentModeFormOpen && (
            <PaymentModeForm
              onClose={() => {
                setIsPaymentModeFormOpen(false)
                dispatch(getPaymentModes())
              }}
            />
          )}

          {isBankEstablishmentFormOpen && (
            <BankEstablishmentForm
              onClose={() => {
                setIsBankEstablishmentFormOpen(false)
                dispatch(getBankEstablishments())
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title="Supprimer le paiement"
        message='Voulez-vous vraiment supprimer le paiement ? Le lot repassera au statut "En attente".'
        open={deletingFormOpen}
        onConfirm={() => {
          if (deletingFormOpen) {
            dispatch(removeLotPayment(lot.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'Le paiement a été supprimé avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => {
                setDeletingFormOpen(false)
                dispatch(getLots())
                onClose()
              })
          }
        }}
        onCancel={() => {
          setDeletingFormOpen(false)
        }}
      />
    </div>
  )
}

export default OrganismPaymentForm
