export default class StorageService {
  private static qrCodeReaderDeviceId = 'qrCodeReaderDeviceId'
  private static memberSituationCache = 'memberSituationCache'

  static getAuthToken() {
    return localStorage.getItem('authToken')
  }
  static setAuthToken(token: string) {
    localStorage.setItem('authToken', token)
  }
  static removeAuthToken() {
    localStorage.removeItem('authToken')
  }

  static getQrCodeReaderDeviceId() {
    return localStorage.getItem(this.qrCodeReaderDeviceId)
  }
  static setQrCodeReaderDeviceId(deviceId: string) {
    localStorage.setItem(this.qrCodeReaderDeviceId, deviceId)
  }

  static getMemberSituationCache() {
    const data = localStorage.getItem(this.memberSituationCache)
    return data ? JSON.parse(data) : null
  }

  static setMemberSituationCache(member, memberSituation) {
    localStorage.setItem(
      this.memberSituationCache,
      JSON.stringify({ member, memberSituation }),
    )
  }
}
