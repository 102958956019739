import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { TimePicker } from '@mui/x-date-pickers-pro'
import { TextFieldProps } from '@mui/material'
import moment from 'moment'

type DateTimeInputProps = TextFieldProps & {
  control: Control<any>
  name: string
  errors?: DeepMap<any, FieldError>
  fullWidth?: boolean
  defaultValue?: Date
  // defaultValue?: Date | number
}

export function TimeInput({
  name,
  control,
  defaultValue = new Date(),
}: DateTimeInputProps) {
  const intl = useIntl()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TimePicker
          label={intl.formatMessage({ id: name })}
          value={moment(value)}
          onChange={onChange}
          slotProps={{
            textField: {
              fullWidth: true,
            },
          }}
        />
      )}
    />
  )
}
