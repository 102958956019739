import { PatientPaymentService } from '@services/patientPaymentService'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ChequeDto,
  CreatePatientPaymentDto,
  PatientPaymentDto,
} from '@services/dtos'
import { PaginatedQuery } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

export interface UndepositedChequesFilters extends PaginatedQuery {
  drawerName?: string
  chequeNumber?: string
  from?: string
  to?: string
  requestedDepositDateChecked?: boolean
  includeChequeDepositId?: number
}

export interface PatientPaymentState {
  undepositedCheques: PaginatedDto<ChequeDto>
  undepositedChequesFilters: UndepositedChequesFilters
}

const initialState: PatientPaymentState = {
  undepositedCheques: {
    datas: [],
    totalCount: 0,
  },
  undepositedChequesFilters: {
    requestedDepositDateChecked: true,
    limit: 20,
    page: 0,
  },
}

export const getUndepositedCheques = createAsyncThunk<
  PaginatedDto<ChequeDto>,
  void
>('patient-payments/find/undeposited-cheques', async (_, { getState }) => {
  const { undepositedChequesFilters } = (
    getState() as { patientPayment: PatientPaymentState }
  ).patientPayment
  return PatientPaymentService.getUndepositedCheques(undepositedChequesFilters)
})

/*export const removePayment = createAsyncThunk<void, number>(
  'patient-payments/remove',
  async (id) => PatientPaymentService.remove(id),
)*/

export const createPatientPayment = createAsyncThunk<
  PatientPaymentDto,
  CreatePatientPaymentDto
>('payments/create', async (dto) => PatientPaymentService.create(dto))

/*export const updatePayment = createAsyncThunk<
  PaymentDto,
  { id: number; dto: UpdatePaymentDto }
>('payments/update', async ({ id, dto }) =>
  PaymentService.update(id, dto),
)*/

export const patientPaymentReducer = createSlice({
  name: 'patient-payments',
  initialState,
  reducers: {
    updateUndepositedChequesFilters: (
      state,
      action: PayloadAction<Partial<UndepositedChequesFilters>>,
    ) => {
      state.undepositedChequesFilters = {
        ...state.undepositedChequesFilters,
        page: 0,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUndepositedCheques.fulfilled, (state, action) => {
      state.undepositedCheques = action.payload
    })
    /*builder.addCase(getPayments.fulfilled, (state, action) => {
      state.patientPayments = action.payload
    })
    builder.addCase(removePayment.fulfilled, (state, action) => {
      state.patientPayments = state.patientPayments.filter(
        (patientPayment) => patientPayment.id !== action.meta.arg,
      )
    })
    builder.addCase(updatePayment.fulfilled, (state, action) => {
      state.paymentModes = state.paymentModes.map((paymentMode) =>
        paymentMode.id === action.payload.id ? action.payload : paymentMode,
      )
    })
    builder.addCase(createPayment.fulfilled, (state, action) => {
      state.paymentModes.unshift(action.payload)
    })*/
  },
})

export default patientPaymentReducer.reducer

export const { updateUndepositedChequesFilters } = patientPaymentReducer.actions
