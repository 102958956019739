import { BankAccountService } from '@services/bankAccountService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  CreateBankAccountDto,
  BankAccountDto,
  UpdateBankAccountDto,
} from '@services/dtos'

export interface BankAccountState {
  bankAccounts: BankAccountDto[]
  loading: boolean
}

const initialState: BankAccountState = {
  bankAccounts: [],
  loading: false,
}

export const getBankAccounts = createAsyncThunk<
  BankAccountDto[],
  { includeInactive?: boolean } | undefined
>('bank-accounts/find', (params) => BankAccountService.getAll(params))

export const removeBankAccount = createAsyncThunk<void, number>(
  'bank-accounts/remove',
  async (id) => BankAccountService.remove(id),
)

export const createBankAccount = createAsyncThunk<
  BankAccountDto,
  CreateBankAccountDto
>('bank-accounts/create', async (dto) => BankAccountService.create(dto))

export const updateBankAccount = createAsyncThunk<
  BankAccountDto,
  { id: number; dto: UpdateBankAccountDto }
>('bank-accounts/update', async ({ id, dto }) =>
  BankAccountService.update(id, dto),
)

export const bankAccountReducer = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBankAccounts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getBankAccounts.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getBankAccounts.fulfilled, (state, action) => {
      state.bankAccounts = action.payload
      state.loading = false
    })
    builder.addCase(removeBankAccount.fulfilled, (state, action) => {
      state.bankAccounts = state.bankAccounts.filter(
        (bankAccount) => bankAccount.id !== action.meta.arg,
      )
    })
    builder.addCase(updateBankAccount.fulfilled, (state, action) => {
      state.bankAccounts = state.bankAccounts.map((bankAccount) =>
        bankAccount.id === action.payload.id ? action.payload : bankAccount,
      )
    })
    builder.addCase(createBankAccount.fulfilled, (state, action) => {
      state.bankAccounts.unshift(action.payload)
    })
  },
})

export default bankAccountReducer.reducer
