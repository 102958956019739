import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { debounce } from 'lodash'
import { DateInputField } from '@components/inputs/DateInputField'
import moment from 'moment'
import {
  LotsFilters,
  LotStatus,
  setLotFilters,
} from '@state/reducers/lotReducer'

const LotsTableToolbar = () => {
  const dispatch = useAppDispatch()

  const { filters } = useAppSelector(({ lot }) => ({
    filters: lot.filters,
  }))

  const updateFilter = debounce((filter: LotsFilters) => {
    dispatch(setLotFilters(filter))
  }, 500)

  const handleFilterChange = (filter: LotsFilters) => {
    updateFilter(filter)
  }

  return (
    <div className="ml-6 mt-4 flex items-center flex-wrap space-x-2">
      <FormControl size="small">
        <InputLabel>Statut</InputLabel>
        <Select
          value={filters.status}
          onChange={(event) =>
            handleFilterChange({
              status: (event.target.value as LotStatus) || undefined,
            })
          }
          style={{
            minWidth: '200px',
            marginRight: 6,
          }}
        >
          <MenuItem value="">
            <em>Tous</em>
          </MenuItem>
          {[
            { label: 'En attente', value: LotStatus.PENDING },
            { label: 'Payé', value: LotStatus.PAYED },
          ].map(({ label, value }, index) => (
            <MenuItem key={`selectLotState${index}`} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Rechercher N° lot"
        variant="outlined"
        onChange={(e) => {
          handleFilterChange({ numero: e.target.value || undefined })
        }}
        size="small"
      />
      <DateInputField
        value={filters.from ? moment(filters.from) : undefined}
        onChange={(date) => {
          dispatch(
            setLotFilters({
              from: date ? date.startOf('day').toISOString() : undefined,
            }),
          )
        }}
      />
      <Typography>au</Typography>
      <DateInputField
        value={filters.to ? moment(filters.to) : undefined}
        onChange={(date) => {
          dispatch(
            setLotFilters({
              to: date ? date.endOf('day').toISOString() : undefined,
            }),
          )
        }}
      />
    </div>
  )
}

export default LotsTableToolbar
