import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { CreateBankAccountDto, BankAccountDto } from '@services/dtos'
import { bankAccountSchema } from '@utils/schemas'
import { useAppDispatch } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Grid } from '@mui/material'
import {
  createBankAccount,
  updateBankAccount,
} from '@state/reducers/bankAccountReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'

type Props = {
  editingBankAccount?: BankAccountDto | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const BankAccountForm: React.FC<Props> = ({ editingBankAccount, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateBankAccountDto>({
    defaultValues: editingBankAccount
      ? (({
          code,
          name,
          bankCode,
          branchCode,
          accountNumber,
          controlKey,
          swiftCode,
          countryCode,
        }) => ({
          code,
          name,
          bankCode,
          branchCode,
          accountNumber,
          controlKey,
          swiftCode,
          countryCode,
        }))(editingBankAccount)
      : {},
    resolver: yupResolver(bankAccountSchema),
  })

  const dispatch = useAppDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreateBankAccountDto) => {
    setIsSubmitting(true)
    if (editingBankAccount) {
      dispatch(
        updateBankAccount({
          id: editingBankAccount.id,
          dto: { ...data, swiftCode: data.swiftCode.replaceAll(/\s+/g, '') },
        }),
      )
        .unwrap()
        .then(async () => cb('Le compte bancaire a été modifié avec succès'))
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(
        createBankAccount({
          ...data,
          swiftCode: data.swiftCode.replaceAll(/\s+/g, ''),
        }),
      )
        .unwrap()
        .then(async () => cb('Le compte bancaire a été créé avec succès'))
        .finally(() => setIsSubmitting(false))
    }
  }

  const fields: {
    name:
      | 'bankCode'
      | 'branchCode'
      | 'accountNumber'
      | 'controlKey'
      | 'swiftCode'
      | 'code'
      | 'name'
      | 'countryCode'
    label: string
    placeholder: string
  }[] = [
    { name: 'code', label: 'Identifiant court', placeholder: 'Entrez le code' },
    { name: 'name', label: 'Nom', placeholder: 'Entrez le nom' },
    {
      name: 'countryCode',
      label: 'Code pays',
      placeholder: 'Entrez le code pays',
    },
    {
      name: 'bankCode',
      label: 'Code banque',
      placeholder: 'Entrez le code banque',
    },
    {
      name: 'branchCode',
      label: 'Code agence/guichet',
      placeholder: 'Entrez le code agence/guichet',
    },
    {
      name: 'accountNumber',
      label: 'Numéro de compte',
      placeholder: 'Entrez le numéro de compte',
    },
    {
      name: 'controlKey',
      label: 'Clé de contrôle/RIB',
      placeholder: 'Entrez la clé de contrôle/RIB',
    },
    {
      name: 'swiftCode',
      label: 'Code BIC/Swift',
      placeholder: 'Entrez le code BIC/Swift',
    },
  ]

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Configuration</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingBankAccount ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Compte bancaire
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              {fields.map(({ name, label, placeholder }) => (
                <Grid item xs={12} sm={6} key={name}>
                  <FormField label={label} error={errors[name]?.message}>
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder={placeholder}
                      {...register(name)}
                    />
                  </FormField>
                </Grid>
              ))}
            </Grid>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default BankAccountForm
