import { DocumentConfigService } from '@services/DocumentConfigService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DocumentConfigDto } from '@services/dtos'

export interface DocumentConfigState {
  documentConfig?: DocumentConfigDto | null
}

const initialState: DocumentConfigState = {
  documentConfig: null,
}

export const getDocumentConfig = createAsyncThunk<DocumentConfigDto, void>(
  'documentConfig/find',
  async () => DocumentConfigService.get(),
)

export const documentConfigReducer = createSlice({
  name: 'documentConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDocumentConfig.fulfilled, (state, action) => {
      state.documentConfig = action.payload
    })
  },
})

export default documentConfigReducer.reducer
