import React, { useEffect, useRef, useState } from 'react'
import {
  CreateQuoteDto,
  CreateQuotePrecotationDto,
  PrecotationDto,
  QuoteDto,
  UpdateQuoteDto,
} from '@services/dtos'
import PatientSearchComponent from '../patient/PatientSearchComponent'
import { Spinner } from '../loadings'
import ManagePatientComponent, {
  ManagepatientComponentRef,
} from './ManagePatientComponent'
import { ExamDto, MemberDto, PatientDto, SiteDto } from '@services/api'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material'
import { Add, AddCircle, Close as CloseIcon } from '@mui/icons-material'
import ExamSelectorDialog from '../dialogs/ExamSelectorDialog'
import { useAppDispatch } from '@hooks/reduxHooks'
import { useSelector } from '@state/store'
import {
  findAll as findAllExam,
  findCcam,
  findNgap,
} from '@state/thunks/procedureThunk'
import QuotationTable from '../../containers/app/Worklists/Quotation/QuotationTable'
import { ccamsToPrecotations, ngapsToPrecotations } from '../Helpers'
import { uid } from 'uid'
import {
  createQuote,
  getQuotes,
  updateQuote,
} from '@state/reducers/quoteReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { enqueueSnackbar as notistackEnqueueSnackbar } from 'notistack'
import {
  doFindMembers,
  findSitesWithBillingCompany,
} from '@state/thunks/memberThunk'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { quoteSchema } from '@utils/schemas'
import {
  doValidatePrecotations,
  setValidation,
} from '@state/reducers/billingReducer'
import { UpTransition } from '../animations'
import { doCreatePatient } from '@state/thunks/patientsThunk'
import { UserIcon } from 'lucide-react'
import BillSummaryComponent from '../billing/BillingSummaryComponent'

type Props = {
  editingQuote?: QuoteDto | null
  onClose: () => void
}

const QuoteForm: React.FC<Props> = ({ editingQuote, onClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<UpdateQuoteDto>({
    defaultValues: editingQuote
      ? {
          patientId: editingQuote.patient.id,
          attendingDoctorId: editingQuote.attendingDoctor?.id,
          attendingDoctorSiteId: editingQuote.attendingDoctorSite?.id,
        }
      : undefined,
    resolver: yupResolver(quoteSchema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [attendingDoctor, setAttendingDoctor] = useState<MemberDto | null>(null)
  const [attendingDoctorSites, setAttendingDoctorSites] = useState<SiteDto[]>(
    [],
  )
  const [activeTab, setActiveTab] = useState(0)
  const [currentExamId, setCurrentExamId] = useState<number | null>(null)
  const [isExamSelectorVisible, setIsExamSelectorVisible] = useState(false)
  const [selectedExams, setSelectedExams] = useState<
    (ExamDto & { precotations?: PrecotationDto[] })[]
  >([])
  const [isValidating, setIsValidating] = useState(false)
  const managePatientRef = useRef<ManagepatientComponentRef>(null)

  const { exams, members, validation } = useSelector(
    ({ procedure, member, billing }) => ({
      exams: procedure.exams.datas,
      members: member.members,
      validation: billing.validation,
    }),
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(findAllExam({}))
    dispatch(doFindMembers({ limit: 400 }))

    if (editingQuote) {
      if (editingQuote.attendingDoctor && editingQuote.attendingDoctorSite) {
        dispatch(findSitesWithBillingCompany(editingQuote.attendingDoctor.id))
          .unwrap()
          .then(async (sites) => setAttendingDoctorSites(sites as SiteDto[]))
      }
      const examsWithPrecotations = editingQuote.quotePrecotations.reduce<
        (ExamDto & { precotations?: PrecotationDto[] })[]
      >((acc, precotation) => {
        const existingExam = acc.find(({ id }) => id === precotation.exam.id)

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { exam, ...precotationWithoutExam } = precotation

        if (existingExam) {
          existingExam.precotations = [
            ...(existingExam.precotations || []),
            precotationWithoutExam,
          ]
        } else {
          acc.push({
            ...precotation.exam,
            precotations: [precotationWithoutExam],
          })
        }

        return acc
      }, [])

      setSelectedExams(examsWithPrecotations)
    }

    return () => {
      reset()
    }
  }, [])

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  const handleAttendingDoctorChange = (id?: number) => {
    const member = members.datas.find((m) => m.id === id)
    if (member) {
      setAttendingDoctor(member)
      setValue('attendingDoctorId', id)
    }
  }

  const patientId = watch('patientId')

  const onSubmit = async (data: CreateQuoteDto) => {
    setIsSubmitting(true)

    const createQuotePrecotationDto: CreateQuotePrecotationDto[] =
      selectedExams.flatMap((exam) =>
        (exam.precotations || []).map((precotation) => ({
          ...precotation,
          identifier: precotation.identifier || '',
          examId: exam.id,
        })),
      )
    const dataWithPrecotations = {
      ...data,
      precotations: createQuotePrecotationDto,
    }

    if (editingQuote) {
      dispatch(updateQuote({ id: editingQuote.id, dto: dataWithPrecotations }))
        .unwrap()
        .then(async () => cb('Le devis a été modifié avec succès'))
        .finally(() => {
          setIsSubmitting(false)
          dispatch(getQuotes())
        })
    } else {
      dispatch(createQuote(dataWithPrecotations))
        .unwrap()
        .then(async () => cb('Le devis a été créé avec succès'))
        .finally(() => {
          setIsSubmitting(false)
          dispatch(getQuotes())
        })
    }
  }

  const handlePatientChange = (selectedPatient?: PatientDto) => {
    setValue('patientId', selectedPatient?.id)
  }

  const handleExamSelectionChange = (examIds: number[]) => {
    setSelectedExams((prevSelectedExams) => {
      const examsToKeep = prevSelectedExams.filter((exam) =>
        examIds.includes(exam.id),
      )

      const newExams = examIds
        .filter((id) => !prevSelectedExams.some((exam) => exam.id === id))
        .map((id) => exams.find((exam) => exam.id === id))
        .filter(Boolean)

      return [
        ...examsToKeep,
        ...newExams.filter((exam): exam is ExamDto => exam !== undefined),
      ]
    })
  }

  const currentExam = selectedExams.find(({ id }) => currentExamId === id)

  const handlePatientFound = async (patient: PatientDto) => {
    setValue('patientId', patient.id)
  }

  useEffect(() => {
    if (!currentExam || currentExam.precotations) return

    const handlePrecotations = (
      findAction: any,
      codes: string[],
      mapToPrecotation: (res: any) => PrecotationDto[],
    ) => {
      dispatch(
        findAction({
          codes,
          examDate: new Date(),
        }),
      )
        .unwrap()
        .then((res) => {
          setSelectedExams((prevExams) =>
            prevExams.map((exam) =>
              exam.id === currentExam.id
                ? { ...exam, precotations: mapToPrecotation(res) }
                : exam,
            ),
          )
        })
    }

    if (currentExam.cotation_ccam.length > 0) {
      handlePrecotations(
        findCcam,
        currentExam.cotation_ccam,
        ccamsToPrecotations,
      )
    }

    if (currentExam.cotation_ngap.length > 0) {
      handlePrecotations(
        findNgap,
        currentExam.cotation_ngap,
        ngapsToPrecotations,
      )
    }
  }, [currentExamId])

  useEffect(() => {
    if (
      selectedExams.length > 0 &&
      (!currentExamId ||
        !selectedExams.map(({ id }) => id).includes(currentExamId))
    ) {
      setCurrentExamId(selectedExams[0].id)
    }
    if (selectedExams.length === 0 && currentExamId) {
      setCurrentExamId(null)
    }
  }, [selectedExams])

  useEffect(() => {
    if (!attendingDoctor) return
    dispatch(findSitesWithBillingCompany(attendingDoctor.id))
      .unwrap()
      .then(async (sites) => setAttendingDoctorSites(sites as SiteDto[]))
  }, [attendingDoctor])

  useEffect(() => {
    if (members && editingQuote?.attendingDoctor) {
      const member = members.datas.find(
        (m) => m.id === editingQuote.attendingDoctor?.id,
      )
      if (member) setAttendingDoctor(member)
    }
  }, [members])

  const removeExam = (examId: number) => {
    const isConfirmed = window.confirm(
      'Voulez-vous vraiment supprimer cet examen ?',
    )
    if (!isConfirmed) {
      return
    }

    setSelectedExams((prevExams) => prevExams.filter(({ id }) => id !== examId))
  }

  const handleSavePatient = async () => {
    setIsSubmitting(true)
    try {
      const dto = await managePatientRef.current
        ?.getPatientFormRef()
        ?.getValues()
      if (dto) {
        const p = await dispatch(doCreatePatient(dto)).unwrap()
        setValue('patientId', p.id)
      }
    } catch (err) {
      notistackEnqueueSnackbar((err as Error).message, { variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleUpdatePrecotation = (updatedRow: PrecotationDto) => {
    setSelectedExams((prevExams) =>
      prevExams.map((exam) =>
        exam.id === currentExamId
          ? {
              ...exam,
              precotations: exam.precotations?.map((precotation) =>
                precotation.identifier === updatedRow.identifier
                  ? updatedRow
                  : precotation,
              ),
            }
          : exam,
      ),
    )
  }

  const handleRemovePrecotation = (removedRow: PrecotationDto) => {
    // setTimeout : fix No row with id #[...] found
    setTimeout(() => {
      setSelectedExams((prevExams) =>
        prevExams.map((exam) =>
          exam.id === currentExamId
            ? {
                ...exam,
                precotations: exam.precotations?.filter(
                  (precotation) =>
                    precotation.identifier !== removedRow.identifier,
                ),
              }
            : exam,
        ),
      )
    })
  }

  const handleAddPrecotation = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setSelectedExams((prevExams) => {
      const updatedExams = prevExams.map((exam) => {
        if (exam.id === currentExamId) {
          return {
            ...exam,
            precotations: [
              ...(exam.precotations ?? []),
              {
                cotation: '',
                cotation_type: currentExam?.cotation_ccam ? 'CCAM' : 'NGAP',
                prix_unitaire: 0,
                identifier: uid(10).toString(),
              },
            ],
          }
        }
        return exam
      })

      return updatedExams
    })
  }

  const precotations = selectedExams
    .map(({ precotations }) => precotations)
    .filter((precotation): precotation is PrecotationDto[] => !!precotation)
    .flatMap((precotation) => precotation)

  const handleValidate = async () => {
    if (precotations.length === 0) return
    setIsValidating(true)
    dispatch(doValidatePrecotations(precotations)).finally(() =>
      setIsValidating(false),
    )
  }

  return (
    <div className="w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Devis</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingQuote ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>
        <div className="flex flex-row gap-x-4 items-center">
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            className="mb-4"
          >
            <Tab label="Patient" />
            <Tab
              label={
                <div className="flex items-center gap-2">
                  {!patientId && <i className="fas fa-lock text-gray-400" />}
                  Examens
                  {!patientId && (
                    <Tooltip title="Veuillez sélectionner un patient d'abord">
                      <i className="fas fa-exclamation-circle text-yellow-500" />
                    </Tooltip>
                  )}
                </div>
              }
              disabled={!patientId}
            />
          </Tabs>
          {!patientId && (
            <Button
              onClick={handleSavePatient}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={20} style={{ color: 'gray' }} />
              ) : (
                <UserIcon size={16} />
              )}
              <span className="ml-1">Sauvegarder patient</span>
            </Button>
          )}
        </div>

        <fieldset
          style={{
            display: activeTab === 0 ? 'block' : 'none',
          }}
          className="border border-gray-300 p-4 rounded-lg shadow-md pr-[140px]"
        >
          {errors.patientId?.message && (
            <div className="h-5">
              <p className="text-red-500 text-sm">
                {errors.patientId?.message}
              </p>
            </div>
          )}
          <PatientSearchComponent
            defaultValue={editingQuote?.patient.id}
            onPatientChange={handlePatientChange}
          />
          <ManagePatientComponent
            patientId={patientId}
            ref={managePatientRef}
            onPatientFoundByVCard={handlePatientFound}
          />
        </fieldset>

        {activeTab === 1 && (
          <fieldset className="border border-gray-300 p-4 rounded-lg shadow-md">
            <Autocomplete
              className="mb-4"
              options={members.datas.map((member) => ({
                label: `${member.firstName} ${member.lastName}`,
                value: member.id,
              }))}
              disableClearable
              getOptionLabel={(option) => option.label}
              onChange={(_, v) => handleAttendingDoctorChange(v?.value)}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Praticien" />
              )}
              defaultValue={
                editingQuote?.attendingDoctor
                  ? {
                      value: editingQuote.attendingDoctor.id,
                      label: `${editingQuote.attendingDoctor.firstName} ${editingQuote.attendingDoctor.lastName}`,
                    }
                  : undefined
              }
            />
            {attendingDoctorSites && (
              <TextField
                size="small"
                fullWidth
                label="Site"
                select
                {...register('attendingDoctorSiteId')}
                onChange={(e) =>
                  setValue('attendingDoctorSiteId', +e.target.value)
                }
                defaultValue={editingQuote?.attendingDoctorSite?.id}
              >
                {attendingDoctorSites.map((site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <div className="mb-4 mt-6 flex justify-between items-center">
              <Button
                variant="contained"
                onClick={() => setIsExamSelectorVisible(true)}
                size="small"
                style={{ marginTop: '12px' }}
              >
                <Add />
                Ajouter un exam
              </Button>
              {currentExam?.precotations !== undefined && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleValidate}
                    disabled={
                      isValidating || currentExam.precotations.length === 0
                    }
                    style={{
                      transition: 'all 0.3s',
                    }}
                  >
                    {isValidating && (
                      <CircularProgress
                        size={18}
                        style={{ color: 'gray', marginRight: '8px' }}
                      />
                    )}
                    Vérifier les précotations
                  </Button>
                  <IconButton
                    onClick={handleAddPrecotation}
                    aria-label="add"
                    disabled={precotations.length === 0}
                  >
                    <AddCircle />
                  </IconButton>
                </>
              )}
            </div>
            {/* TODO: delete > doesn't delete in ExamSelectorDialog : need global state / parent state */}
            <ExamSelectorDialog
              isOpen={isExamSelectorVisible}
              onClose={() => setIsExamSelectorVisible(false)}
              handleConfirm={handleExamSelectionChange}
              excludedExams={[]}
            />
            <List className="border border-1 mt-4" style={{ padding: 0 }}>
              {selectedExams.map((selectedExam) => (
                <ListItem
                  key={selectedExam.id}
                  button
                  onClick={() => setCurrentExamId(selectedExam.id)}
                  className={'hover:bg-gray-100 cursor-pointer'}
                  style={{
                    backgroundColor:
                      currentExamId === selectedExam.id
                        ? 'rgba(26, 188, 156,0.4)'
                        : '',
                  }}
                  secondaryAction={
                    <>
                      <IconButton
                        onClick={() => removeExam(selectedExam.id)}
                        edge="end"
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText
                    style={{ fontSize: '10px' }}
                    primary={`(${selectedExam.code}) ${selectedExam.label}`}
                  />
                </ListItem>
              ))}
            </List>
            {currentExam && (
              <div
                style={{
                  height: '400px',
                  display: 'flex',
                }}
              >
                <QuotationTable
                  isLoading={!currentExam.precotations}
                  rows={currentExam.precotations ?? []}
                  onUpdateRow={handleUpdatePrecotation}
                  onRemoveRow={handleRemovePrecotation}
                />
              </div>
            )}
          </fieldset>
        )}
      </form>

      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '80vh',
          },
        }}
        open={!!validation}
        TransitionComponent={UpTransition}
      >
        <div className="flex justify-end p-4">
          <button
            type="button"
            className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
            onClick={() => dispatch(setValidation(null))}
          >
            Fermer
          </button>
        </div>
        <DialogContent>
          {validation && validation.visite && (
            <BillSummaryComponent data={validation} quoteCase />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default QuoteForm
