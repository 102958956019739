export interface VitalCardDto {
  id: number
}

export interface OrganismeInfo {
  id: number
  type: number
  code: string
  regime: string
  caisse: string
  centre: string
  libelle: string
  codeDestinataire: string
  centreInfo: string
  teletransmissible: boolean
  active: boolean
}

export interface CouvertureDto {
  id: number
  numeroSerieCv: string
  beneficiaireNom: string
  beneficiairePrenom: string
  beneficiaireNir: string
  beneficaireDateNaissance: string
  beneficaireNomUsuel: string
  assureNom: string
  assureNomUsuel: string
  assurePrenom: string
  assureNir: string
  rangNaissance: number | null
  qualite: number
  idOrganismeAmo?: number
  organismeAmo: OrganismeInfo | null
  codeGestionAmo: string | null
  codeServiceAmo: string | null
  codeSituationAmo: string | null
  dateDebutAmo: string | null
  dateFinAmo: string | null
  idOrganismeAmc?: number
  organismeAmc: OrganismeInfo | null
  dateDebutAmc: string | null
  dateFinAmc: string | null
  numeroAdherentAmc: string | null
  indicateurTraitementAmc: string | null
  codeRoutageAmc: string | null
  identifiantHoteAmc: string | null
  nomDomaineAmc: string | null
  codeAiguillageStsAmc: string | null
  idPatient: number | null
  idVisit: number | null
  idNature: number | null
  natureAssurance: string | null
  idExoneration: number | null
  exoneration: string | null
  date_accident_travail: string | null
  numero_at: string | null
  date_grossesse: string | null
  indicateur_parcours_soins: string | null
  medecin_declare: boolean
  medecin_traitant_id: number | null
}


export interface CouvertureResponseDto {
  erreur: boolean
  erreurCode: string
  erreurMessage: string
  couverture: CouvertureDto
}

export interface QuotationDto {
  id: number
  code: string
  libelle: string
  codeRegroupement: string
  prixOptam: number
  prixNonOptam: number
  modificateurs: string
}

export enum OrderStatusEnum {
  pendingRegistration = 'TO_REGISTER',
  registered = 'REGISTERED',
  toQuote = 'TO_QUOTE',
  toInvoice = 'TO_INVOICE',
  medicalOrderCancelled = 'CANCELED',
}
export interface DateRange {
  startDate: Date
  endDate: Date
}

export enum FileTypeEnum {
  PRESCRIPTION = 'PRESCRIPTION',
  REPORT = 'REPORT',
  ID = 'ID',
  LETTER = 'LETTER',
  PREVIOUS_EXAM = 'PREVIOUS_EXAM',
  CONSENT = 'CONSENT',
  LABRESULT = 'LABRESULT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  EFFORT_TEST = 'EFFORT_TEST',
  CHAT_FILE = 'CHAT_FILE',
  SECONDARY_CAPTURE = 'SECONDARY_CAPTURE',
  PROTOCOL_REALISATION = 'PROTOCOL_REALISATION',
  SITE_ACQ_PROTOCOL = 'SITE_ACQ_PROTOCOL',
}

export interface BillingPaginatedResponse<T> {
  items: T[]
  totalCount: number
  page: number
  pageSize: number
}

export interface FindOrganismQuery {
  page: number
  pageSize: number
  code?: string
  regime?: string
  caisse?: string
  centre?: string
  Type?: number[]
  Libelle?: string
  mutuelle?: string
}
export class FindPatientsQuery {
  search?: string

  sex?: 'F' | 'M'

  firstName?: string

  lastName?: string

  birthDate?: Date

  ssn?: string

  externalId?: string

  identifier?: string

  page?: number

  limit?: number
}

