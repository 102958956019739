import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { CreateBillingCompanyDto, BillingCompanyDto } from '@services/dtos'
import { billingCompanySchema } from '@utils/schemas'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { findAll as findSites } from '@state/thunks/siteThunk'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  createBillingCompany,
  updateBillingCompany,
} from '@state/reducers/billingCompanyReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { UpTransition } from '../animations'
import BankAccountForm from './BankAccountForm'
import { getBankAccounts } from '@state/reducers/bankAccountReducer'

type Props = {
  editingBillingCompany?: BillingCompanyDto | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const BillingCompanyForm: React.FC<Props> = ({
  editingBillingCompany,
  onClose,
}) => {
  const {
    watch,
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateBillingCompanyDto>({
    defaultValues: editingBillingCompany
      ? (({
          code,
          label,
          site,
          bankAccount,
          registrationNumber,
          taxNumber,
          address,
          postalCode,
          city,
          country,
          finessNumber,
        }) => ({
          code,
          label,
          registrationNumber,
          taxNumber,
          address,
          postalCode,
          city,
          country,
          siteId: site.id,
          bankAccountId: bankAccount.id,
          finessNumber,
        }))(editingBillingCompany)
      : undefined,
    resolver: yupResolver(billingCompanySchema),
  })

  const dispatch = useAppDispatch()
  const { sites, bankAccounts, loadingBanks } = useAppSelector(
    ({ site, bankAccount }) => ({
      sites: site.sites,
      bankAccounts: bankAccount.bankAccounts,
      loadingBanks: bankAccount.loading,
    }),
  )
  const [isBankAccountFormOpen, setIsBankAccountFormOpen] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    dispatch(findSites())
    dispatch(getBankAccounts())
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreateBillingCompanyDto) => {
    setIsSubmitting(true)

    if (editingBillingCompany) {
      dispatch(
        updateBillingCompany({ id: editingBillingCompany.id, dto: data }),
      )
        .unwrap()
        .then(async () =>
          cb('La société de facturation a été modifiée avec succès'),
        )
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(createBillingCompany(data))
        .unwrap()
        .then(async () =>
          cb('La société de facturation a été créée avec succès'),
        )
        .finally(() => setIsSubmitting(false))
    }
  }

  const fields: {
    name:
      | 'code'
      | 'label'
      | 'registrationNumber'
      | 'taxNumber'
      | 'address'
      | 'postalCode'
      | 'city'
      | 'country'
      | 'finessNumber'
    label: string
    placeholder: string
  }[] = [
    { name: 'code', label: 'Identifiant court', placeholder: 'Entrez le code' },
    { name: 'label', label: 'Libellé', placeholder: 'Entrez le libellé' },
    {
      name: 'registrationNumber',
      label: 'Numéro d\'immatriculation',
      placeholder: 'Entrez le numéro d\'immatriculation',
    },
    {
      name: 'finessNumber',
      label: 'Numéro FINESS',
      placeholder: 'Entrez le numéro FINESS',
    },
    {
      name: 'taxNumber',
      label: 'Numéro fiscal',
      placeholder: 'Entrez le numéro fiscal',
    },
    { name: 'address', label: 'Adresse', placeholder: 'Entrez l\'adresse' },
    {
      name: 'postalCode',
      label: 'Code postal',
      placeholder: 'Entrez le code postal',
    },
    { name: 'city', label: 'Ville', placeholder: 'Entrez la ville' },
    { name: 'country', label: 'Pays', placeholder: 'Entrez le pays' },
  ]

  const bankAccountId = watch('bankAccountId')
  const siteId = watch('siteId')

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Configuration</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingBillingCompany ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-md mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Société de facturation
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              {fields.map(({ name, label, placeholder }) => (
                <Grid
                  item
                  xs={12}
                  sm={name === 'address' ? 12 : 6}
                  md={name === 'address' ? 12 : 4}
                  key={name}
                >
                  <FormField label={label} error={errors[name]?.message}>
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder={placeholder}
                      {...register(name)}
                    />
                  </FormField>
                </Grid>
              ))}

              <Grid item xs={12} sm={6} md={4}>
                <FormField label={'Site'}>
                  {sites.datas.length > 0 ? (
                    <Controller
                      name="siteId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          value={
                            sites.datas.find((site) => site.id === siteId) ||
                            null
                          }
                          options={sites.datas}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={!!errors.siteId}
                              helperText={errors.siteId?.message}
                            />
                          )}
                        />
                      )}
                    />
                  ) : (
                    <Typography variant="body1">
                      Aucun site enregistré
                    </Typography>
                  )}
                </FormField>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <fieldset className="border border-gray-200 p-4 rounded-lg">
                  <legend className="text-sm font-medium text-gray-700 mb-1 border border-gray-200 p-2 rounded-lg">
                    Compte bancaire
                    <span className="inline-flex items-center">
                      <Tooltip title="Ajouter un compte bancaire" arrow>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsBankAccountFormOpen(true)}
                          sx={{
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                            paddingLeft: 1,
                            paddingRight: 1,
                            minWidth: 'auto',
                            width: 'auto',
                            marginLeft: 1,
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Tooltip>
                    </span>
                  </legend>
                  {bankAccounts.length > 0 ? (
                    <Controller
                      name="bankAccountId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          value={
                            bankAccounts.find(
                              (bankAccount) => bankAccount.id === bankAccountId,
                            ) || null
                          }
                          options={bankAccounts}
                          getOptionLabel={(option) =>
                            `${option.name} (${option.code})`
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              error={!!errors.bankAccountId}
                              helperText={errors.bankAccountId?.message}
                            />
                          )}
                        />
                      )}
                    />
                  ) : (
                    <Typography variant="caption">
                      {loadingBanks
                        ? 'Chargement...'
                        : 'Aucun compte bancaire enregistré'}
                    </Typography>
                  )}
                </fieldset>
              </Grid>
            </Grid>
          </fieldset>
        </div>
      </form>

      <Dialog
        open={isBankAccountFormOpen}
        onClose={() => setIsBankAccountFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isBankAccountFormOpen && (
            <BankAccountForm
              onClose={() => {
                setIsBankAccountFormOpen(false)
                dispatch(getBankAccounts())
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default BillingCompanyForm
