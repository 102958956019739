import { BankEstablishmentService } from '@services/bankEstablishmentService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  CreateBankEstablishmentDto,
  BankEstablishmentDto,
  UpdateBankEstablishmentDto,
} from '@services/dtos'

export interface BankEstablishmentState {
  bankEstablishments: BankEstablishmentDto[]
}

const initialState: BankEstablishmentState = {
  bankEstablishments: [],
}

export const getBankEstablishments = createAsyncThunk<
  BankEstablishmentDto[],
  { includeInactive?: boolean } | undefined
>('bank-establishments/find', (params) =>
  BankEstablishmentService.getAll(params),
)

export const removeBankEstablishment = createAsyncThunk<void, number>(
  'bank-establishments/remove',
  async (id) => BankEstablishmentService.remove(id),
)

export const createBankEstablishment = createAsyncThunk<
  BankEstablishmentDto,
  CreateBankEstablishmentDto
>('bank-establishments/create', async (dto) =>
  BankEstablishmentService.create(dto),
)

export const updateBankEstablishment = createAsyncThunk<
  BankEstablishmentDto,
  { id: number; dto: UpdateBankEstablishmentDto }
>('bank-establishments/update', async ({ id, dto }) =>
  BankEstablishmentService.update(id, dto),
)

export const bankEstablishmentReducer = createSlice({
  name: 'bankEstablishments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBankEstablishments.fulfilled, (state, action) => {
      state.bankEstablishments = action.payload
    })
    builder.addCase(removeBankEstablishment.fulfilled, (state, action) => {
      state.bankEstablishments = state.bankEstablishments.filter(
        (bankEstablishment) => bankEstablishment.id !== action.meta.arg,
      )
    })
    builder.addCase(updateBankEstablishment.fulfilled, (state, action) => {
      state.bankEstablishments = state.bankEstablishments.map(
        (bankEstablishment) =>
          bankEstablishment.id === action.payload.id
            ? action.payload
            : bankEstablishment,
      )
    })
    builder.addCase(createBankEstablishment.fulfilled, (state, action) => {
      state.bankEstablishments.unshift(action.payload)
    })
  },
})

export default bankEstablishmentReducer.reducer
