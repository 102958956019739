import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { UpTransition } from '@components/animations'
import BankEstablishmentForm from '@components/forms/BankEstablishmentForm'
import { BankEstablishmentDto } from '@/services/dtos'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ToggleOn as ToggleOnIcon,
  ToggleOffOutlined as ToggleOffOutlinedIcon,
} from '@mui/icons-material'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import {
  getBankEstablishments,
  removeBankEstablishment,
  updateBankEstablishment,
} from '@state/reducers/bankEstablishmentReducer'

export default function BankEstablishmentsTable() {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [editingBankEstablishment, setEditingBankEstablishment] =
    useState<BankEstablishmentDto | null>(null)
  const [isBankEstablishmentFormOpen, setIsBankEstablishmentFormOpen] =
    useState(false)
  const [deletingBankEstablishmentForm, setDeletingBankEstablishmentForm] =
    useState<BankEstablishmentDto | null>(null)
  const [
    toggleBankEstablishmentActivation,
    setToggleBankEstablishmentActivation,
  ] = useState<BankEstablishmentDto | null>(null)
  const { bankEstablishments } = useAppSelector((state) => ({
    bankEstablishments: state.bankEstablishment.bankEstablishments,
  }))

  useEffect(() => {
    setIsLoading(true)
    dispatch(getBankEstablishments({ includeInactive: true })).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const actionBtnObj = [
    {
      id: 1,
      btnName: () => 'Modifier',
      icon: () => <EditIcon color="primary" />,
      action: (row: BankEstablishmentDto) => {
        if (row.isDefaultValue) return

        setEditingBankEstablishment(row)
        setIsBankEstablishmentFormOpen(true)
      },
      actionKey: 'editBankEstablishment',
    },
    {
      id: 2,
      btnName: (row: BankEstablishmentDto) =>
        row.isActive ? 'Désactiver' : 'Activer',
      icon: (row: BankEstablishmentDto) =>
        row.isActive ? (
          <ToggleOnIcon fontSize="small" color="primary" />
        ) : (
          <ToggleOffOutlinedIcon fontSize="small" color="primary" />
        ),
      action: (row: BankEstablishmentDto) =>
        setToggleBankEstablishmentActivation(row),
      actionKey: 'updateActivationBankEstablishment',
    },
    {
      id: 3,
      btnName: () => 'Supprimer',
      icon: () => <DeleteIcon color="primary" />,
      action: (row: BankEstablishmentDto) =>
        !row.isDefaultValue && setDeletingBankEstablishmentForm(row),
      actionKey: 'deleteBankEstablishment',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        const bankEstablishment = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              const actionDisabled =
                bankEstablishment.isDefaultValue &&
                el.actionKey !== 'updateActivationBankEstablishment'
              acc.push(
                <Tooltip
                  key={
                    bankEstablishment.id + '-' + el.btnName(bankEstablishment)
                  }
                  title={
                    actionDisabled
                      ? 'Valeur par défaut immuable'
                      : el.btnName(bankEstablishment)
                  }
                >
                  <div
                    className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                      actionDisabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(bankEstablishment)
                    }}
                  >
                    {el.icon(bankEstablishment)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 70,
    },
    {
      field: 'label',
      headerName: 'Libellé',
      width: 180,
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 65px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                pagination
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <div className="ml-2 mt-2">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsBankEstablishmentFormOpen(true)}
                        >
                          <i className="fas fa-plus"></i>{' '}
                          <span className="ml-2">Ajouter</span>
                        </Button>
                      </div>
                    </GridToolbarContainer>
                  ),
                }}
                columns={columns}
                rows={bankEstablishments}
                rowCount={bankEstablishments.length}
                disableSelectionOnClick
                disableMultipleSelection
                loading={isLoading}
                getRowClassName={(params) =>
                  params.row.isActive ? '' : 'text-gray-400'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={isBankEstablishmentFormOpen}
        onClose={() => setIsBankEstablishmentFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isBankEstablishmentFormOpen && (
            <BankEstablishmentForm
              onClose={() => {
                setIsBankEstablishmentFormOpen(false)
                setEditingBankEstablishment(null)
              }}
              editingBankEstablishment={editingBankEstablishment}
            />
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title={
          deletingBankEstablishmentForm
            ? 'Suppression'
            : toggleBankEstablishmentActivation?.isActive
            ? 'Désactiver'
            : 'Activer'
        }
        message={
          deletingBankEstablishmentForm
            ? 'Voulez-vous vraiment supprimer cet établissement bancaire ?'
            : `Voulez-vous vraiment ${
                toggleBankEstablishmentActivation?.isActive
                  ? 'désactiver'
                  : 'activer'
              } cet établissement bancaire ?`
        }
        open={
          deletingBankEstablishmentForm !== null ||
          toggleBankEstablishmentActivation !== null
        }
        onConfirm={() => {
          if (deletingBankEstablishmentForm) {
            dispatch(removeBankEstablishment(deletingBankEstablishmentForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message:
                      'L\'établissement bancaire a été supprimé avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setDeletingBankEstablishmentForm(null))
          } else if (toggleBankEstablishmentActivation) {
            dispatch(
              updateBankEstablishment({
                id: toggleBankEstablishmentActivation.id,
                dto: { isActive: !toggleBankEstablishmentActivation?.isActive },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: `L'établissement bancaire a été ${
                      toggleBankEstablishmentActivation.isActive
                        ? 'masqué'
                        : 'activé'
                    } avec succès`,
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setToggleBankEstablishmentActivation(null))
          }
        }}
        onCancel={() => {
          setDeletingBankEstablishmentForm(null)
          setToggleBankEstablishmentActivation(null)
        }}
      />
    </Container>
  )
}
