import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import moment from 'moment'
import { debounce } from 'lodash'
import {
  getCashRegisters,
  getMyCashRegister,
  removeCashRegister,
  setTab,
} from '@state/reducers/cashRegisterReducer'
import { UpTransition } from '../animations'
import CashRegisterForm from '../forms/CashRegisterForm'
import { CashRegisterDTO } from '@/services/dtos/AccountingV2Dto'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import OpenCashRegisterForm from '../forms/OpenCashRegisterForm'
import CloseCashRegisterForm from '../forms/CloseCashRegisterForm'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import CashRegisterTransactionForm from '../forms/CashRegisterTransactionForm'
import CashRegisterLogsTable from './CashRegisterLogsTable'
import ListAltIcon from '@mui/icons-material/ListAlt'

export default function CashRegistersTable() {
  const [isCashRegisterFormOpen, setIsCashRegisterFormOpen] = useState(false)
  const [toggleCashRegisterFormOpen, setToggleCashRegisterFormOpen] =
    useState<CashRegisterDTO | null>(null)
  const [editingCashRegister, setEditingCashRegister] =
    useState<CashRegisterDTO | null>(null)
  const [openLogsCashRegister, setOpenLogsCashRegister] =
    useState<CashRegisterDTO | null>(null)
  const [deletingPaymentForm, setDeletingPaymentForm] =
    useState<CashRegisterDTO | null>(null)
  const [cashRegisterTransactionFormOpen, setCashRegisterTransactionFormOpen] =
    useState<CashRegisterDTO | null>(null)

  const dispatch = useAppDispatch()
  const { cashRegisters, myCashRegister, filters, loading, activeTab } =
    useAppSelector(({ cashRegister }) => ({
      cashRegisters: cashRegister.list,
      loading: cashRegister.loading,
      filters: cashRegister.filters,
      myCashRegister: cashRegister.mine,
      activeTab: cashRegister.tab,
    }))

  useEffect(() => {
    dispatch(getMyCashRegister())
  }, [])

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(getCashRegisters())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const actionBtnObj = useMemo(
    () => [
      {
        id: 1,
        btnName: (row: CashRegisterDTO) =>
          !row.open ? 'Modifier' : 'Non disponible',
        icon: () => <EditIcon color="primary" />,
        action: (row: CashRegisterDTO) => {
          if (row.open) return
          setEditingCashRegister(row)
          setIsCashRegisterFormOpen(true)
        },
        actionKey: 'editCashRegister',
        disabled: (row: CashRegisterDTO) => row.open,
      },
      {
        id: 2,
        btnName: (row: CashRegisterDTO) =>
          row.open ? 'Non disponible' : 'Supprimer',
        icon: () => <DeleteIcon color="primary" />,
        action: (row: CashRegisterDTO) =>
          !row.open && setDeletingPaymentForm(row),
        actionKey: 'deleteCashRegister',
        disabled: (row: CashRegisterDTO) => row.open,
      },
      {
        id: 3,
        btnName: (row: CashRegisterDTO) => {
          if (!row.active) return 'Non disponible'
          if (myCashRegister?.id === row.id) return 'Fermer'
          if (!myCashRegister && !row.open) return 'Ouvrir'
          return 'Non disponible'
        },
        icon: (row: CashRegisterDTO) =>
          row.open ? (
            <MoveToInboxIcon color="primary" />
          ) : (
            <UnarchiveIcon color="primary" />
          ),
        action: (row: CashRegisterDTO) =>
          row.active &&
          (myCashRegister?.id === row.id || (!myCashRegister && !row.open)) &&
          setToggleCashRegisterFormOpen(row),
        actionKey: 'toggleCashRegister',
        disabled: (row: CashRegisterDTO) => {
          if (!row.active) return true
          if (myCashRegister?.id === row.id) return false
          if (!myCashRegister && !row.open) return false
          return true
        },
      },
      {
        id: 4,
        btnName: (row: CashRegisterDTO) =>
          myCashRegister?.id === row.id
            ? 'Ajouter un mouvement'
            : 'Non disponible',
        icon: () => <SaveAsIcon color="primary" />,
        action: (row: CashRegisterDTO) =>
          myCashRegister?.id === row.id &&
          setCashRegisterTransactionFormOpen(row),
        actionKey: 'addCashRegisterTransaction',
        disabled: (row: CashRegisterDTO) => myCashRegister?.id !== row.id,
      },
      {
        id: 5,
        btnName: () => 'Consulter les logs',
        icon: () => <ListAltIcon color="primary" />,
        action: (row: CashRegisterDTO) => setOpenLogsCashRegister(row),
        actionKey: 'openLogsCashRegister',
        disabled: () => false,
      },
    ],
    [myCashRegister, cashRegisters],
  )

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 215,
      sortable: false,
      renderCell: (params) => {
        const cashRegister = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              acc.push(
                <Tooltip
                  key={`${cashRegister.id}-${el.id}`}
                  title={el.btnName(cashRegister)}
                >
                  <div
                    className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                      el.disabled(cashRegister)
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(cashRegister)
                    }}
                  >
                    {el.icon(cashRegister)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Etat',
      width: 130,
      renderCell: ({ row }) => (
        <Tooltip
          title={
            !row.active
              ? 'Hors-service'
              : row.open
              ? `Ouverte par ${row.openBy?.firstName} ${row.openBy?.lastName}`
              : 'Fermée'
          }
        >
          <Chip
            color={row.open ? 'primary' : 'default'}
            label={
              !row.active ? 'Hors-service' : row.open ? 'Ouverte' : 'Fermée'
            }
          ></Chip>
        </Tooltip>
      ),
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 120,
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'name',
      headerName: 'Nom',
      width: 140,
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'balance',
      headerName: 'Dernier solde déclaré',
      width: 180,
      valueGetter: ({ row }) => `${row.balance}€`,
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'site',
      headerName: 'Site',
      width: 120,
      valueGetter: ({ row }) => row.site.label,
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'location',
      headerName: 'Localisation',
      width: 140,
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
      cellClassName: ({ row }) =>
        myCashRegister?.id === row.id ? 'font-bold' : '',
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 120px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                pagination
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                getRowClassName={(params) =>
                  params.row.active ? '' : 'text-gray-400'
                }
                columns={columns}
                rows={cashRegisters}
                disableSelectionOnClick
                loading={loading}
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer className="mt-3 ml-5">
                      <div>
                        <ToggleButtonGroup
                          value={activeTab}
                          exclusive
                          color="secondary"
                          onChange={(_, value) =>
                            value !== null && dispatch(setTab(value))
                          }
                          size="small"
                          unselectable="off"
                          sx={{
                            '& .MuiToggleButton-root': {
                              textTransform: 'none',
                              backgroundColor: '#fff',
                              marginTop: 1,
                              '&.Mui-selected': {
                                backgroundColor: '#3A0CA1',
                                color: '#fff',
                                '&:hover': {
                                  // keep the same color when hover
                                  backgroundColor: '#3A0CA1',
                                },
                              },
                            },
                          }}
                        >
                          {[
                            {
                              value: 0,
                              label: 'Caisses',
                            },
                            {
                              value: 1,
                              label: 'Journal',
                            },
                          ].map(({ value, label }) => (
                            <ToggleButton key={`tab-${value}`} value={value}>
                              {label}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </div>
                      <div className="ml-4 mt-2">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsCashRegisterFormOpen(true)}
                        >
                          <i className="fas fa-plus"></i>{' '}
                          <span className="ml-2">Ajouter</span>
                        </Button>
                      </div>
                    </GridToolbarContainer>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={isCashRegisterFormOpen}
        onClose={() => setIsCashRegisterFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isCashRegisterFormOpen && (
            <CashRegisterForm
              onClose={() => {
                setIsCashRegisterFormOpen(false)
                setEditingCashRegister(null)
              }}
              editingCashRegister={editingCashRegister}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!cashRegisterTransactionFormOpen}
        onClose={() => setCashRegisterTransactionFormOpen(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {!!cashRegisterTransactionFormOpen && (
            <CashRegisterTransactionForm
              onClose={() => setCashRegisterTransactionFormOpen(null)}
              cashRegister={cashRegisterTransactionFormOpen}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!toggleCashRegisterFormOpen}
        onClose={() => setToggleCashRegisterFormOpen(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {!!toggleCashRegisterFormOpen &&
            (toggleCashRegisterFormOpen.open ? (
              <CloseCashRegisterForm
                cashRegister={toggleCashRegisterFormOpen}
                onClose={() => setToggleCashRegisterFormOpen(null)}
              />
            ) : (
              <OpenCashRegisterForm
                cashRegister={toggleCashRegisterFormOpen}
                onClose={() => setToggleCashRegisterFormOpen(null)}
              />
            ))}
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!openLogsCashRegister}
        onClose={() => setOpenLogsCashRegister(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {!!openLogsCashRegister && (
            <>
              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
                  onClick={() => setOpenLogsCashRegister(null)}
                >
                  Fermer
                </button>
              </div>
              <CashRegisterLogsTable cashRegister={openLogsCashRegister} />
            </>
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title={'Suppression'}
        message={'Voulez-vous vraiment supprimer cette caisse ?'}
        open={!!deletingPaymentForm}
        onConfirm={() => {
          if (deletingPaymentForm) {
            dispatch(removeCashRegister(deletingPaymentForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'La caisse a été supprimée avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setDeletingPaymentForm(null))
          }
        }}
        onCancel={() => {
          setDeletingPaymentForm(null)
        }}
      />
    </Container>
  )
}
