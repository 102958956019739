import ResizeHandle from '@components/common/ResizeHandle'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import { Document, MedicalOrder } from '@services/api'
import React, { useEffect, useState } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'
import { TransitionProps } from '@mui/material/transitions'
import MedicalOrderForm from '@components/medical-order/MedicalOrderForm'
import DocumentListViewer from '@components/dialogs/DocumentListViewer'
import Quotation from './Quotation/Quotation'
import { OrderDto } from '@services/dtos'
import { Add } from '@mui/icons-material'
import { ExamSelectorDialog } from '@components/dialogs/ExamSelectorDialog'
import { useAppDispatch } from '@hooks/reduxHooks'
import {
  doAddMedicalOrder,
  doRemoveOrder,
  setSelectedOrder as setReduxSelectedOrder,
} from '@state/reducers/orderReducer'
import OrderHistoryComponent from '@components/OrderHistoryComponent'
import report from '../../../assets/attachments/report_rt.pdf'
import PatientDataContainer from '@components/patient/PatientDataContainer'
import OrderRecipientList from '@components/order/OrderRecipientList'
import DocumentsList from '@components/documents/DocumentsList'
import { setPatientDetails } from '../../../state/reducers/patientsReducer'
import {
  doGetMemberSituation,
  setMemberSituation,
} from '../../../state/reducers/billingReducer'
import { RightTransition } from '../../../components/animations'

type Props = {
  order?: OrderDto
  isOpen: boolean
  onClose: () => void
  isNested?: boolean
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const SlideInPortal = ({
  isOpen,
  onClose,
  patientId,
  visitId,
}: {
  isOpen: boolean
  onClose: () => void
  patientId?: number
  visitId?: number
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
      fullWidth
      fullScreen
      TransitionComponent={RightTransition}
    >
      <div className="relative h-full z-[10001]">
        <button
          onClick={onClose}
          className="absolute top-[20px] right-12 text-gray-500 hover:text-gray-700 transition-colors z-50"
          type="button"
        >
          <i className="fas fa-times text-3xl text-black"></i>
        </button>

        <div className="p-6 h-full overflow-y-auto relative">
          {patientId && (
            <PatientDataContainer
              patientId={patientId}
              visitId={visitId}
              containerClassName="max-h-full"
            />
          )}
        </div>
      </div>
    </Dialog>
  )
}

const OrderDialog: React.FC<Props> = ({ order, isOpen, onClose, isNested }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(setPatientDetails(null))
    }
  }, [])

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        fullScreen
        disableEscapeKeyDown
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: '#3f3f3f' }}>
          <Toolbar
            style={{
              color: '#FAFAFB',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              className="flex-row items-center mr-2 inline-block"
              autoFocus
              color="inherit"
              onClick={onClose}
              variant="outlined"
            >
              <i className="fas fa-times mr-2"></i>
              <span className="inline-block">Fermer</span>
            </Button>
            <div className="ml-2"></div>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ backgroundColor: '#F3F4F6' }}>
          {!order ? (
            <></>
          ) : (
            <OrderDetailsComponent order={order} isNested={isNested} />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export const OrderDetailsComponent = ({
  order,
  editByDefault = false,
  isNested = false,
}: {
  order: OrderDto
  editByDefault?: boolean
  isNested?: boolean
}) => {
  const dispatch = useAppDispatch()
  // --------------------------------------- State ---------------------------------------
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  )
  const [isExamSelectorVisible, setIsExamSelectorVisible] = useState(false)
  const [isPatientInfoOpen, setIsPatientInfoOpen] = useState(false)
  const [isEditingDocuments, setisEditingDocuments] = useState(false)

  // --------------------------------------- Hooks ---------------------------------------

  useEffect(() => {
    if (order?.attendingDoctor) {
      dispatch(doGetMemberSituation(order.attendingDoctor.id))
    }
  }, [order?.attendingDoctor])

  useEffect(() => {
    if (order) {
      if (order.attendingDoctor) {
        dispatch(doGetMemberSituation(order.attendingDoctor.id))
      } else {
        dispatch(setMemberSituation(null))
      }
    }
  }, [order])

  // --------------------------------------- Handlers ---------------------------------------
  const handleExamSelectionChange = (examIds: number[]) => {
    if (!order) {
      return
    }
    for (const exId of examIds) {
      dispatch(doAddMedicalOrder({ examId: exId, orderId: order.id }))
    }
  }

  const onMedicalOrderRemoved = (mo: MedicalOrder) => {
    if (!order) {
      return
    }
    const newvalue = { ...order }
    newvalue.medicalOrders = newvalue.medicalOrders.filter(
      (medicalOrder) => medicalOrder.id !== mo.id,
    )
  }

  const handleOnFileUploaded = (doc: Document) => {
    if (!order) {
      return
    }
    setisEditingDocuments(false)
    const newDocuments = [...order.documents]
    newDocuments.push(doc)
    const newOrder = { ...order, documents: newDocuments }
    dispatch(setReduxSelectedOrder(newOrder))
  }

  const handleDocumentDeleted = (doc: Document) => {
    if (!order) {
      return
    }
    const newOrder = { ...order }
    newOrder.documents = newOrder.documents.filter(
      (document) => document.id !== doc.id,
    )
    dispatch(setReduxSelectedOrder(newOrder))
  }

  return (
    <div className="h-full">
      <PanelGroup direction="horizontal">
        <Panel id="left-panel" collapsible order={1} defaultSize={20}>
          <>
            <div
              onClick={() => setIsPatientInfoOpen(true)}
              className="flex flex-row cursor-pointer items-center mb-4 border border-1 border-gray-200 rounded-lg p-1 hover:bg-gray-200 transition-all relative hover:scale-105"
            >
              <div className="relative group cursor-pointer bg-gray-100 p-1 rounded-lg ">
                <Avatar
                  className=" mr-2 transition-transform "
                  style={{
                    width: '60px',
                    height: '60px',
                  }}
                  src="https://education.gsu.edu/files/2019/08/Headshot_Placeholder.jpg"
                />
                <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 text-xs text-[#3A0CA1] font-medium whitespace-nowrap bg-white px-2 rounded-full shadow-sm border border-gray-200">
                  Voir détails
                </div>
              </div>
              <div className="leading-tight ml-2">
                <h4 className="text-md font-bold">
                  {order.patient.firstName} {order.patient.lastName}
                </h4>
                <Typography variant="body2" gutterBottom>
                  {order.patient.birthDateStr}
                </Typography>
                <p className="text-xs leading-tight">Exam: 0 Solde: 0.00 €</p>
              </div>
            </div>
            <Paper className="p-2 space-y-6 flex flex-col flex-1 h-[calc(100vh-200px)] overflow-y-scroll">
              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Compte Rendu
                </Typography>
                <Paper elevation={0} className="bg-gray-50 p-4 rounded">
                  {/* Render the report here */}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      window.open(report, '_blank')
                    }}
                  >
                    <i className="fas fa-file-pdf"></i>
                    <span className="ml-2">Compte-rendu</span>
                  </Button>
                </Paper>
              </Box>
              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Destinataires
                </Typography>
                <OrderRecipientList order={order} />
              </Box>

              <Divider />

              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Fichiers attachés
                </Typography>
                <Box className="">
                  <DocumentsList
                    entityId={order.id}
                    entityName="order"
                    documents={order.documents}
                    setisEditingDocuments={setisEditingDocuments}
                    handleOnFileUploaded={handleOnFileUploaded}
                    isEditingDocuments={isEditingDocuments}
                    onDocumentDeleted={handleDocumentDeleted}
                    setSelectedDocument={setSelectedDocument}
                  />
                </Box>
              </Box>
              {!isNested && (
                <Box>
                  <Typography variant="h6" className="font-bold mb-3">
                    Historique
                  </Typography>
                  <Paper elevation={0} className="bg-gray-50 p-4 rounded">
                    <OrderHistoryComponent order={order} />
                  </Paper>
                </Box>
              )}
            </Paper>
          </>
        </Panel>
        <ResizeHandle horizontal className="" />
        <Panel order={2} defaultSize={80}>
          <Paper className="p-2 h-full flex flex-col">
            <h4 className="text-md font-bold">Demande</h4>
            <Divider
              sx={{
                marginTop: 2,
                marginBottom: 1,
              }}
            />
            <MedicalOrderForm order={order} editByDefault={editByDefault} />
            <div className="mb-4 mt-6">
              <Button
                variant="contained"
                onClick={() => setIsExamSelectorVisible(true)}
                size="small"
                style={{ marginTop: '12px' }}
              >
                <Add />
                Ajouter un exam
              </Button>
            </div>
            {order && order.medicalOrders.length !== 0 && (
              <Quotation
                order={order}
                onMedicalOrderRemoved={onMedicalOrderRemoved}
              />
            )}
          </Paper>
        </Panel>
      </PanelGroup>
      {order && (
        <SlideInPortal
          isOpen={isPatientInfoOpen}
          onClose={() => setIsPatientInfoOpen(false)}
          patientId={order.patient.id}
          visitId={order.visit?.id}
        />
      )}

      {order && (
        <DocumentListViewer
          isOpen={selectedDocument !== null}
          documents={order.documents}
          onClose={() => {
            setSelectedDocument(null)
          }}
          selectedDocument={selectedDocument || undefined}
          onSelectedDocumentChange={(doc) => {
            setSelectedDocument(doc)
          }}
        />
      )}
      <ExamSelectorDialog
        isOpen={isExamSelectorVisible}
        onClose={() => setIsExamSelectorVisible(false)}
        handleConfirm={handleExamSelectionChange}
        // excludedExams={selectedOrder.medicalOrders.map((mo) => mo.exam)}
        excludedExams={[]}
      />
    </div>
  )
}

export default OrderDialog
