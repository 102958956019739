import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  IconButton,
  Input,
  InputAdornment,
  Tooltip,
  debounce,
  Select,
  MenuItem,
  SelectChangeEvent,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogContent,
} from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useIntl } from 'react-intl'
import SearchIcon from '@mui/icons-material/Search'
import { useMemo, useState } from 'react'
import { Site } from '@services/api'
import { doFindOrders, setOrderFilters } from '@state/reducers/orderReducer'
import DateFilterComponent, { DateRange } from '@components/DateFilterComponent'
import moment from 'moment'
import { OrderStatusEnum } from '../../../common/interfaces'
import DoctorList from '../../../containers/app/parameters/doctors/DoctorList'
import ClearIcon from '@mui/icons-material/Clear'

type OrderToolbarProps = {
  onInstantBillClick: () => void
}

export const OrderToolbar: React.FC<OrderToolbarProps> = ({
  onInstantBillClick,
}) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const [isDoctorsOpen, setisDoctorsOpen] = useState(false)

  const filters = useAppSelector(({ order }) => order.filters)
  const user = useAppSelector(({ auth }) => auth.user)

  const [selectedDateRange, setselectedDateRange] = useState<DateRange | null>(
    filters.startDate && filters.endDate
      ? {
          startDate: moment(filters.startDate).toDate(),
          endDate: moment(filters.endDate).toDate(),
        }
      : null,
  )

  const [searchText, setSearchText] = useState('')

  const handleRefresh = () => {
    dispatch(doFindOrders())
  }

  const onSearchChange = (value: string) => {
    searchMedicalOrders(value)
  }

  const searchMedicalOrders = useMemo(() => {
    return debounce((value: string) => {
      dispatch(
        setOrderFilters({
          search: value,
          page: 0,
        }),
      )
    }, 300)
  }, [filters])

  const onSiteChange = (ev: SelectChangeEvent<string>) => {
    const siteId = ev.target.value as string
    dispatch(
      setOrderFilters({
        ...filters,
        siteId: parseInt(siteId, 10),
      }),
    )
  }

  const sites = useMemo(() => {
    const _sites = (user as any & { sites: Site[] })?.sites
    if (_sites?.length === 1) {
      dispatch(
        setOrderFilters({
          siteId: _sites[0].id,
        }),
      )
    }
    return _sites
  }, [user])

  const handleDateRangeChange = (dateRange: DateRange) => {
    setselectedDateRange(dateRange)
    dispatch(
      setOrderFilters({
        startDate: dateRange.startDate.getTime(),
        endDate: dateRange.endDate.getTime(),
      }),
    )
  }

  const handleStatusChange = (
    _: React.SyntheticEvent,
    newValue: OrderStatusEnum,
  ) => {
    if (!newValue) {
      return
    }
    dispatch(setOrderFilters({ status: newValue }))
  }

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(ev.target.value)
    onSearchChange(ev.target.value)
  }

  const handleClearSearch = () => {
    setSearchText('')
    onSearchChange('')
  }

  return (
    <>
      <GridToolbarContainer>
        <div className="ml-2">
          <GridToolbarColumnsButton
            sx={{
              backgroundColor: '#3f3f3f',
              color: '#fff',
              marginRight: '10px',
              '&:hover': {
                backgroundColor: '#3f3f3f70',
              },
            }}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          <GridToolbarDensitySelector
            sx={{
              backgroundColor: '#3f3f3f',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#3f3f3f70',
              },
            }}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {/* <GridToolbarExport /> */}
        </div>

        <Select
          value={filters.siteId?.toString()}
          onChange={onSiteChange}
          style={{ minWidth: '200px', marginRight: '16px' }}
          size="small"
        >
          {sites.map((site: Site) => (
            <MenuItem key={site.id} value={site.id}>
              {site.label}
            </MenuItem>
          ))}
        </Select>
        <Input
          value={searchText}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            searchText && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={handleClearSearch}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
          placeholder={intl.formatMessage({
            id: 'searchPatient',
            defaultMessage: 'Rechercher un patient',
          })}
          style={{
            height: '40px',
            width: '250px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '0 10px',
          }}
          disableUnderline
        />
        <div className="ml-2">
          <DateFilterComponent
            dateRange={selectedDateRange}
            onDateRangeChange={handleDateRangeChange}
            disabled={!!filters.search}
          />
        </div>

        <ToggleButtonGroup
          disabled={!!filters.search}
          value={filters.status}
          exclusive
          color="secondary"
          onChange={handleStatusChange}
          size="small"
          className="ml-2"
          unselectable="off"
          sx={{
            '& .MuiToggleButton-root': {
              textTransform: 'none',
              backgroundColor: '#fff',
              '&.Mui-selected': {
                backgroundColor: '#3A0CA1',
                color: '#fff',
                '&:hover': {
                  // keep the same color when hover
                  backgroundColor: '#3A0CA1',
                },
              },
            },
          }}
        >
          {Object.values(OrderStatusEnum).map((statusValue) => (
            <ToggleButton
              key={statusValue}
              value={filters.search ? 'all' : statusValue}
            >
              {intl.formatMessage({
                id: `orderStatus.${statusValue}`,
                defaultMessage: statusValue.replace(/_/g, ' '),
              })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Tooltip title="Rechercher un médecin">
          <button
            className="btn btn-primary px-4 py-1 border border-gray-200  ml-4 w-24 bg-[#3F3F3F] rounded-md"
            onClick={() => setisDoctorsOpen(true)}
            type="button"
          >
            <i className="fas fa-search text-md text-gray-200 mr-2"></i>
            <i className="fas fa-user-md text-lg text-white"></i>
          </button>
        </Tooltip>

        <Tooltip title="Facturer un patient">
          <button
            className="btn btn-primary px-4 py-1 border border-gray-200  ml-4 w-24 bg-[#3F3F3F] rounded-md"
            onClick={onInstantBillClick}
            type="button"
          >
            <i className="fas fa-file-invoice text-lg text-white"></i>
          </button>
        </Tooltip>
      </GridToolbarContainer>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isDoctorsOpen}
        onClose={() => setisDoctorsOpen(false)}
      >
        <DialogContent sx={{ height: '100vh' }}>
          <DoctorList />
        </DialogContent>
      </Dialog>
    </>
  )
}
