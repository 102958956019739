import { Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'

export const UpTransition = forwardRef(function Transition(
  {
    direction,
    ...props
  }: TransitionProps & {
    children: React.ReactElement
    direction?: 'up' | 'right' | 'left' | 'down'
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction={direction || 'up'} ref={ref} {...props} />
})

export const RightTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />
})
