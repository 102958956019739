import { useEffect, useState } from 'react'
import { CouvertureDto } from '../../common/interfaces'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { setReaderId } from '../../state/reducers/patientsReducer'
import { getCartVitalUrl } from '../../state/thunks/cardReadThunk'
import { getUrl, sendReaderResponse } from '../../services/CardReadService'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { isBase64 } from '../../utils/dto.mapper'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { Dialog } from '@mui/material'
import { BillingService } from '../../services/BillingService'
import MemberAutocomplete from '../MemberAutocomplete'
import { MemberDto } from '../../services/api'
import { doGetMemberSituation } from '../../state/reducers/billingReducer'

type Props = {
  isOpen: boolean
  onFinish: (dto: CouvertureDto) => void
  visitId: number
  readDate: string
  onClose: () => void
}

const ApcvNFCReaderComponent: React.FC<Props> = ({
  isOpen,
  onFinish,
  visitId,
  readDate,
  onClose,
}) => {
  const dispatch = useAppDispatch()

  const [url, setUrl] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState<MemberDto | null>(null)
  const { memberSituation } = useAppSelector((state) => ({
    memberSituation: state.billing.memberSituation,
  }))

  useEffect(() => {
    if (!isOpen) {
      if (url) {
        setUrl('')
      }
      return
    }

    dispatch(getCartVitalUrl())
      .unwrap()
      .then(async (dto) => {
        dispatch(setReaderId(dto.id))
        handleOpenNFCReader()
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: 'Erreur lors de la détection du lecteur',
            options: { variant: 'error' },
          }),
        )
        onClose()
      })
  }, [dispatch, isOpen, memberSituation])

  useEffect(() => {
    if (selectedMember) {
      dispatch(doGetMemberSituation(selectedMember.id))
        .unwrap()
        .then(() => {
          handleOpenNFCReader()
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              message:
                'Erreur lors de la récupération de la situation du médecin',
              options: { variant: 'error' },
            }),
          )
        })
    }
  }, [selectedMember, dispatch])

  useEffect(() => {
    const handlePostBack = async (msg: MessageEvent) => {
      const data = msg.data
      if (!isBase64(data)) {
        return
      }

      const decodedStr = atob(data)
      const obj = JSON.parse(decodedStr)
      if (!obj.method) {
        dispatch(
          enqueueSnackbar({
            message: 'Erreur de lecture',
            options: { variant: 'error' },
          }),
        )
        onClose()
        return
      }
      if (obj.method.cancel) {
        onClose()
        return
      }
      const dto = await sendReaderResponse(visitId, readDate, obj)

      if (!dto) {
        dispatch(
          enqueueSnackbar({
            message: 'Erreur de lecture',
            options: { variant: 'error' },
          }),
        )
        return
      }
      onFinish(dto)
      onClose()
    }

    window.addEventListener('message', handlePostBack, false)
    return () => {
      window.removeEventListener('message', handlePostBack)
    }
  }, [visitId, readDate])

  const handleCloseNFCReader = () => {
    setDialogOpen(false)
  }

  const handleOpenNFCReader = () => {
    setDialogOpen(true)
  }

  const handleMemberSelect = (member: MemberDto | null) => {
    setSelectedMember(member)
  }

  const initNFCReading = async () => {
    handleCloseNFCReader()
    const readerDto = await getUrl()
    if (!memberSituation) {
      dispatch(
        enqueueSnackbar({
          message: 'Situation du médecin manquante',
          options: { variant: 'error' },
        }),
      )
      return
    }
    try {
      const data = await BillingService.getUrlByNFC({
        idLecteur: readerDto.id,
        idResip: memberSituation?.idJfse ?? 1,
        dateLecture: readDate,
        typeLecture: 0,
      })
      console.log(data)
      if (data.erreur) {
        dispatch(
          enqueueSnackbar({
            message: data.erreurMessage,
            options: { variant: 'error' },
          }),
        )
        return
      }
      setUrl(data.url)
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Erreur de lecture',
          options: { variant: 'error' },
        }),
      )
      onClose()
    }
  }

  const onIframClose = () => {
    setUrl('')
    onClose()
  }

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          handleCloseNFCReader()
          onClose()
        }}
      >
        <Box sx={{ padding: '20px' }}>
          <Typography variant="h6">Médecin</Typography>
          <MemberAutocomplete
            label="Médecin"
            value={selectedMember}
            onChange={handleMemberSelect}
          />
          {!memberSituation && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              La situation du médecin est manquante
            </Alert>
          )}
        </Box>
        {memberSituation && (
          <Box sx={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6">Lecture NFC</Typography>
            <Box sx={{ mt: 2 }}>
              <Button onClick={initNFCReading} variant="contained">
                Démarrer la lecture NFC
              </Button>
            </Box>
          </Box>
        )}
      </Dialog>

      <Dialog
        open={!!url}
        onClose={onIframClose}
        className="z-[100000]"
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: '#E2E6F2',
            zIndex: 100001,
          },
        }}
      >
        <DialogTitle
          style={{
            background: '#fff',
            color: '#000',
          }}
        >
          Lecture de la carte vitale
        </DialogTitle>
        <DialogContent
          style={{
            minWidth: '850px',
            display: 'flex',
            justifyContent: 'center',
            height: '80vh',
            alignItems: 'center',
            zIndex: 100000001,
          }}
        >
          {url === '' ? (
            <CircularProgress className="!text-purple-600" />
          ) : (
            <iframe
              style={{
                width: '100%',
                height: '80vh',
                border: 'none',
                overflow: 'hidden',
                marginTop: '20px',
              }}
              src={url}
            ></iframe>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ApcvNFCReaderComponent
