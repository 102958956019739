import React, { useState, useEffect, useMemo } from 'react'
import QuotationTable from './QuotationTable'
import { ExamDto, MedicalOrder } from '@services/api'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import AsyncAutoComplete from '@components/inputs/AsyncAutoComplete'
import {
  findAll as findExams,
  findCcam,
  findNgap,
} from '@state/thunks/procedureThunk'
import {
  doCreateBill,
  doCreateInvoiceOnly,
  doFindPrecotations,
  doSavePrecotations,
  doValidatePrecotations,
  setPrecotation,
  setValidation,
} from '@state/reducers/billingReducer'
import {
  CCAMDto,
  CreateBillDTO,
  CreateInvoiceOnlyDto,
  OrderDto,
  PrecotationDto,
  TransactionType,
} from '@services/dtos'
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'

import * as uid from 'uid'
import {
  doRemoveMedicalOrder,
  doUpdateOrder,
} from '@state/reducers/orderReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { UpTransition } from '../../../../components/animations'
import BillSummaryComponent from '../../../../components/billing/BillingSummaryComponent'
import { getCartVitalUrl } from '@state/thunks/cardReadThunk'
import { doGetCouverture } from '../../../../state/thunks/patientsThunk'
import { BillingDialog } from '../../billing/BillingDialog'
import moment from 'moment'
import { BillModes } from '../../../../utils/constants'
import { BillingService } from '../../../../services/BillingService'
import {
  doUpdateCouverture,
  setReaderId,
} from '../../../../state/reducers/patientsReducer'
import { isBase64 } from '../../../../utils/dto.mapper'
import { CouvertureDto } from '../../../../common/interfaces'
import PatientPaymentForm from '@components/forms/PatientPaymentForm'
import { getMyCashRegister } from '@state/reducers/cashRegisterReducer'
import { getInvoice } from '@state/reducers/invoiceReducer'

type Props = {
  order: OrderDto
  onMedicalOrderRemoved: (medicalOrder: MedicalOrder) => void
}
const Quotation: React.FC<Props> = ({ order, onMedicalOrderRemoved }) => {
  // --------------------------------------- Other Hooks ---------------------------------------
  const dispatch = useAppDispatch()

  // --------------------------------------- State ---------------------------------------
  const {
    invoice,
    exams,
    precotations,
    ccams,
    ngaps,
    validation,
    readerId,
    couverture,
    memberSituation,
    myCashRegister,
  } = useAppSelector(
    ({ procedure, billing, patients, cashRegister, invoice }) => ({
      invoice: invoice.invoice,
      exams: procedure.exams,
      precotations: billing.precotation,
      validation: billing.validation,
      ccams: procedure.ccam,
      ngaps: procedure.ngap,
      readerId: patients.patientCardReaderId,
      couverture: patients.patientCouverture,
      memberSituation: billing.memberSituation,
      myCashRegister: cashRegister.mine,
    }),
  )

  const [openPatientPayment, setOpenPatientPayment] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const [cotationAnchor, setCotationAnchor] =
    React.useState<HTMLButtonElement | null>(null)

  const [billModeAnchor, setBillModeAnchor] =
    React.useState<HTMLElement | null>(null)

  const [isEditingExam, setIsEditingExam] = useState(false)
  const [selectedExam, setselectedExam] = useState<ExamDto>(
    order.medicalOrders[0].exam,
  )
  const [selectedMedicalOrder, setSelectedMedicalOrder] = useState(
    order.medicalOrders[0],
  )
  const [isValidating, setIsValidating] = useState(false)
  const [isFetching, setisFetching] = useState(true)
  const [billUrl, setBillUrl] = useState<string>('')
  const [isCreatingBill, setIsCreatingBill] = useState(false)
  const [billMode, setBillMode] = useState<string>('')
  const [codeSituationSelectorUrl, setCodeSituationSelectorUrl] =
    useState<string>('')

  // ---------------------------------------  Hooks ---------------------------------------

  const autocompleteRef = React.useRef<HTMLDivElement>(null)
  const examOptions = useMemo(() => {
    return exams.datas.map((exam) => ({
      label: exam.label,
      value: exam,
    }))
  }, [exams])

  const cotationType = useMemo(() => {
    if (selectedMedicalOrder.exam.cotation_ccam.length !== 0) {
      return 'CCAM'
    } else if (selectedMedicalOrder.exam.cotation_ngap.length !== 0) {
      return 'NGAP'
    }
    return ''
  }, [selectedMedicalOrder.exam])

  useEffect(() => {
    setisFetching(true)
    dispatch(doFindPrecotations(selectedMedicalOrder.id))
      .unwrap()
      .then(async (res) => {
        let ccamCodes = res.reduce((acc, pr) => {
          if (pr.cotation_type === 'CCAM') {
            acc.push(pr.cotation)
          }
          return acc
        }, [] as string[])
        if (selectedMedicalOrder.exam.cotation_ccam.length !== 0) {
          ccamCodes.push(...selectedMedicalOrder.exam.cotation_ccam)
        }
        ccamCodes = Array.from(new Set(ccamCodes))

        let ngapCodes = res.reduce((acc, pr) => {
          if (pr.cotation_type === 'NGAP') {
            acc.push(pr.cotation)
          }
          return acc
        }, [] as string[])
        if (selectedMedicalOrder.exam.cotation_ngap.length !== 0) {
          ngapCodes.push(...selectedMedicalOrder.exam.cotation_ngap)
        }
        ngapCodes = Array.from(new Set(ngapCodes))

        const _precotations: PrecotationDto[] = []
        const ccams = await dispatch(
          findCcam({ codes: ccamCodes, examDate: new Date() }),
        ).unwrap()
        const ngaps = await dispatch(
          findNgap({ codes: ngapCodes, examDate: new Date() }),
        ).unwrap()

        if (res.length === 0) {
          const rows: PrecotationDto[] = ccams.map((ccam) => ({
            cotation: ccam.code,
            cotation_type: 'CCAM',
            prix_unitaire: ccam.prixNonOptam,
            id_medical_order: selectedMedicalOrder.id,
            identifier: uid.uid(10).toString(),
            modificateur: ccam.modificateurs,
            date_execution: moment(order.registeredAt).format('YYYY-MM-DD'),
          }))

          const ngapRows: PrecotationDto[] = ngaps.map((ngap) => ({
            cotation: ngap.code,
            cotation_type: 'NGAP',
            prix_unitaire: ngap.prix,
            id_medical_order: selectedMedicalOrder.id,
            identifier: uid.uid(10).toString(),
            modificateur: '',
            date_execution: moment(order.registeredAt).format('YYYY-MM-DD'),
          }))

          _precotations.push(...rows)
          _precotations.push(...ngapRows)
        } else {
          for (const pr of res) {
            if (pr.cotation_type === 'CCAM') {
              const ccam = ccams.find((c) => c.code === pr.cotation)
              if (ccam) {
                const modifiers = pr.modificateur?.split('')
                modifiers?.forEach((m, i) => {
                  pr[`m${i + 1}`] = m
                })
                _precotations.push({
                  ...pr,
                  prix_unitaire: ccam.prixNonOptam,
                  modificateur: ccam.modificateurs,
                })
              }
            } else {
              _precotations.push(pr)
            }
          }
        }
        dispatch(setPrecotation(_precotations))
      })
      .finally(() => {
        setisFetching(false)
      })
  }, [order, selectedMedicalOrder.exam, cotationType])

  useEffect(() => {
    if (!cotationType) {
      return
    }
    if (cotationType === 'CCAM' && ccams.length === 0) {
      dispatch(
        findCcam({
          codes: selectedMedicalOrder.exam.cotation_ccam,
          examDate: new Date(selectedMedicalOrder.plannedDate),
        }),
      )
    } else if (cotationType === 'NGAP' && ngaps.length === 0) {
      dispatch(
        findNgap({
          codes: selectedMedicalOrder.exam.cotation_ngap,
          examDate: new Date(selectedMedicalOrder.plannedDate),
        }),
      )
    }
  }, [selectedMedicalOrder, cotationType])

  useEffect(() => {
    dispatch(getInvoice({ orderId: order.id }))
    dispatch(getMyCashRegister())
    return () => {
      dispatch(setPrecotation([]))
    }
  }, [])

  useEffect(() => {
    if (order.patient.id) {
      dispatch(
        doGetCouverture({
          idPatient: order.patient.id,
          idVisit: order.visitId,
        }),
      )
    }
  }, [order.patient.id])

  useEffect(() => {
    if (!codeSituationSelectorUrl) {
      return
    }
    const handleMessage = (event: MessageEvent) => {
      if (!isBase64(event.data)) {
        return
      }
      const decodedStr = atob(event.data)
      const obj = JSON.parse(decodedStr) as any
      if (obj.method && obj.method.name === 'SIT-gui-choix/situation') {
        if (obj.method.cancel) {
          setCodeSituationSelectorUrl('')
        } else if (
          obj.method.output &&
          obj.method.output.selection &&
          couverture
        ) {
          const { id, ...rest } = couverture
          dispatch(
            doUpdateCouverture({
              id: id,
              dto: {
                ...rest,
                codeSituationAmo: obj.method.output.selection,
              } as CouvertureDto,
            }),
          ).then(() => {
            setIsValidating(true)
            dispatch(doValidatePrecotations(precotations)).finally(() => {
              setIsValidating(false)
              setCodeSituationSelectorUrl('')
            })
          })
        }
      }
    }

    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [memberSituation, readerId, codeSituationSelectorUrl])

  // --------------------------------------- Handlers ---------------------------------------

  const handleAddExam = (exam: ExamDto | null) => {
    if (exam) {
      setselectedExam(exam)
      if (autocompleteRef.current) {
        (autocompleteRef.current as any).clearValue()
      }
    }
  }

  // const handleSaveExam = () => {
  //   if (selectedExam) {
  //     dispatch(
  //       updateMedicalOrder({
  //         id: selectedMedicalOrder.id,
  //         dto: {
  //           examId: selectedExam.id,
  //         },
  //       }),
  //     )
  //     setIsEditingExam(false)
  //   }
  // }

  const handleRemoveExam = (medicalOrder: MedicalOrder) => {
    const isConfirmed = window.confirm(
      'Voulez-vous vraiment supprimer cet examen ?',
    )
    if (!isConfirmed) {
      return
    }
    if (order.medicalOrders.length > 1) {
      let nextExamIndex = 0
      while (order.medicalOrders[nextExamIndex].id === medicalOrder.id) {
        nextExamIndex++
      }
      setSelectedMedicalOrder(order.medicalOrders[nextExamIndex])
      setselectedExam(order.medicalOrders[nextExamIndex].exam)
    }
    dispatch(
      doRemoveMedicalOrder({
        orderId: order.id,
        medicalOrderId: medicalOrder.id,
      }),
    ).then(() => {
      onMedicalOrderRemoved(medicalOrder)
      dispatch(
        enqueueSnackbar({
          message: `Examen "${medicalOrder.exam.label}" supprimé`,
          options: { variant: 'success' },
        }),
      )
    })
  }

  const handleAddQuotationRow = (ccam?: CCAMDto) => {
    const { exam } = selectedMedicalOrder
    const newRow: PrecotationDto = {
      cotation: ccam?.code || '',
      cotation_type: exam.cotation_ccam?.length !== 0 ? 'CCAM' : 'NGAP',
      prix_unitaire: ccam?.prixOptam || 0,
      id_medical_order: selectedMedicalOrder.id,
      identifier: uid.uid(10).toString(),
      modificateur: ccam?.modificateurs || '',
    }
    dispatch(setPrecotation([...precotations, newRow]))
  }

  const onExamSearch = (v: string) => {
    return dispatch(findExams({ search: v })).unwrap()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      (cotationType === 'CCAM' && ccams.length !== 0) ||
      (cotationType === 'NGAP' && ngaps.length !== 0)
    ) {
      setCotationAnchor(event.currentTarget)
    } else {
      const newRow: PrecotationDto = {
        cotation: '',
        cotation_type: cotationType,
        prix_unitaire: 0,
        id_medical_order: selectedMedicalOrder.id,
        identifier: uid.uid(10).toString(),
      }
      dispatch(setPrecotation([...precotations, newRow]))
    }
  }

  const handleClose = () => {
    setCotationAnchor(null)
  }

  const handleValidate = async (event: React.MouseEvent<HTMLElement>) => {
    setBillModeAnchor(event.currentTarget)
  }

  const handleSave = async () => {
    setIsValidating(true)
    if (order.deletedAt) {
      dispatch(doUpdateOrder({ id: order.id, dto: { deletedAt: null } }))
    }
    dispatch(
      doSavePrecotations({
        data: precotations.map((pr) => {
          const r = { ...pr, modificateur: '' }
          const modifiersFields = ['m1', 'm2', 'm3', 'm4']
          for (const field of modifiersFields) {
            if (r[field]) {
              r.modificateur += r[field]
            }
          }
          return r
        }),
        medicalOrderId: selectedMedicalOrder.id,
      }),
    )
      .then(() => {
        dispatch(
          enqueueSnackbar({
            message: 'Cotations enregistrées',
            options: { variant: 'success' },
          }),
        )
      })
      .finally(() => {
        setIsValidating(false)
      })
  }

  // const getAllPrecots = async (): Promise<PrecotationDto[]> => {
  //   const allPrecots: PrecotationDto[] = []
  //   for (const mo of order.medicalOrders) {
  //     const precots = await dispatch(doFindPrecotations(mo.id)).unwrap()
  //     precots.forEach((p) => {
  //       allPrecots.push(p)
  //     })
  //   }
  //   return allPrecots
  // }

  // const validatePrecot = async (): Promise<boolean> => {
  //   const precots = await getAllPrecots()
  //   setIsValidating(true)
  //   try {
  //     const res = await dispatch(doValidatePrecotations(precots)).unwrap()
  //     if (res.erreurs.length !== 0) {
  //       return false
  //     }
  //     return true
  //   } catch (e) {
  //     console.error(e)
  //     return false
  //   } finally {
  //     setIsValidating(false)
  //   }
  // }

  const handleCloseSummary = () => {
    dispatch(setValidation(null))
  }

  const handleCreateBill = async () => {
    if (!order.visitId || !memberSituation) {
      return
    }
    setIsCreatingBill(true)
    let _readerId = readerId || ''
    if (!readerId) {
      try {
        const res = await dispatch(getCartVitalUrl()).unwrap()
        _readerId = res.id
      } catch (e) {
        console.error(e)
        setIsCreatingBill(false)
        dispatch(
          enqueueSnackbar({
            message: 'Erreur lors de la récupération de l\'id du lecteur',
            options: { variant: 'error' },
          }),
        )
        return
      }
    }
    const dto: CreateBillDTO = {
      idLecteur: _readerId,
      idResip: memberSituation.idJfse,
      // Right now just send all order ids and later we decide if we implement some filter UI to select which exams to send
      medicalOrderIds: order.medicalOrders.map((mo) => mo.id),
      idVisit: order.visitId,
      idOrder: order.id,
      securisationFacture: billMode,
    }

    dispatch(doCreateBill(dto))
      .unwrap()
      .then((res) => {
        setBillUrl(res.url)
        handleCloseSummary()
      })
      .finally(() => {
        setIsCreatingBill(false)
      })
  }

  const handleInvoiceOnly = () => {
    if (!memberSituation || !order.visitId || precotations.length === 0) return

    if (!myCashRegister) {
      dispatch(
        enqueueSnackbar({
          message:
            'Vous devez ouvrir une caisse avant de procéder à un paiement',
          options: { variant: 'error' },
        }),
      )
      return
    }

    if (invoice) {
      setOpenPatientPayment(true)
      return
    }

    const dto: CreateInvoiceOnlyDto = {
      idMemberSituation: memberSituation.identifiant,
      medicalOrderIds: order.medicalOrders.map((mo) => mo.id),
      idVisit: order.visitId,
      idOrder: order.id,
    }

    dispatch(doCreateInvoiceOnly(dto))
      .unwrap()
      .then(() => {
        dispatch(getInvoice({ orderId: order.id }))
          .unwrap()
          .then(() => setOpenPatientPayment(true))
          .catch(() => {
            dispatch(
              enqueueSnackbar({
                message:
                  'Une erreur est survenue lors de la récupération de la facture',
                options: { variant: 'error' },
              }),
            )
          })
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar({
            message:
              'Une erreur est survenue lors de la création de la facture',
            options: { variant: 'error' },
          }),
        )
      })
  }

  const open = Boolean(cotationAnchor)
  const isBillModeOpen = Boolean(billModeAnchor)
  const id = open ? 'simple-popover' : undefined

  const billModesOptions = BillModes.map((item) => ({
    value: item.value,
    label: item.label,
  }))

  const handleBillSuccess = async (data: any) => {
    const _readerId = await getReaderId()
    setReaderId(_readerId || '')
    if (!memberSituation || !_readerId || !order.visitId) {
      return
    }
    const res = await BillingService.finalizeBill({
      data,
      idResip: memberSituation.idJfse,
      idLecteur: _readerId,
      medicalOrderIds: order.medicalOrders.map((mo) => mo.id),
      idVisit: order.visitId,
      idOrder: order.id,
      idMemberSituation: memberSituation.identifiant,
    })

    if (res.Error) {
      dispatch(
        enqueueSnackbar({
          message: res.Error,
          options: { variant: 'error' },
        }),
      )
      return
    }

    dispatch(
      enqueueSnackbar({
        message: 'Facture créée avec succès',
        options: { variant: 'success' },
      }),
    )
    setBillUrl('')
    console.log('res', res)
  }

  const getReaderId = async () => {
    if (readerId) {
      return readerId
    }
    const res = await dispatch(getCartVitalUrl()).unwrap()
    return res.id
  }

  const shouldUpdateSituationAmo = (mode: number): boolean => {
    if (mode === 0 || mode === 2) return true
    return false
  }

  const handleBillModeClick = async (value: number) => {
    await handleSave()
    setIsValidating(true)
    setBillMode(value.toString())
    setBillModeAnchor(null)
    if (shouldUpdateSituationAmo(value)) {
      try {
        const _readerId = await getReaderId()
        setReaderId(_readerId || '')
        if (!order.id || !_readerId || !memberSituation) {
          return
        }
        const url = await BillingService.getCodeSituationSelector(
          order.id,
          _readerId,
          memberSituation.idJfse,
        )
        setCodeSituationSelectorUrl(url)
        setIsValidating(false)
        return
      } catch (e) {
        console.error(e)
        setIsValidating(false)
        dispatch(
          enqueueSnackbar({
            message: 'Erreur lors de la récupération de l\'url de la situation',
            options: { variant: 'error' },
          }),
        )
      }
    }

    dispatch(doValidatePrecotations(precotations)).finally(() => {
      setIsValidating(false)
    })
  }

  return (
    <div className="w-full flex-1 flex flex-col">
      <h3 className="font-bold mb-3">Examen</h3>
      <List className="border border-1 mt-4" style={{ padding: 0 }}>
        {order.medicalOrders.map((mo) => {
          return (
            <ListItem
              key={mo.id}
              button
              onClick={() => setSelectedMedicalOrder(mo)}
              className={'hover:bg-gray-100 cursor-pointer'}
              style={{
                backgroundColor:
                  selectedMedicalOrder.id === mo.id
                    ? 'rgba(26, 188, 156,0.4)'
                    : '',
              }}
              secondaryAction={
                <>
                  <IconButton
                    onClick={() => handleRemoveExam(mo)}
                    edge="end"
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemText
                style={{ fontSize: '10px' }}
                primary={`(${mo.exam.code}) ${mo.exam.label}`}
              />
            </ListItem>
          )
        })}
      </List>
      {isEditingExam && (
        <AsyncAutoComplete
          label="Ajouter un examen"
          options={examOptions}
          getValue={(el) => el.id}
          onChange={handleAddExam}
          handleSearch={onExamSearch}
          ref={autocompleteRef}
          value={null}
        />
      )}
      <div className="flex flex-row justify-between align-center mt-2">
        <h2 className="text-lg font-bold mt-2">Cotation</h2>
        {validation && validation?.erreurs?.length !== 0 && (
          <div className="text-red-500">
            {validation.erreurs.map((err, index) => (
              <Alert key={index} severity="error">
                {err.messageErreur}
              </Alert>
            ))}
          </div>
        )}
        {!memberSituation && (
          <Alert severity="warning" className="mb-2">
            Il est nécessaire de mettre à jour la situation du Practicien pour
            procéder à la facturation
          </Alert>
        )}
        {!order.patient.isForeigner && !couverture && (
          <Alert severity="warning" className="mb-2">
            Il est nécessaire de mettre à jour la couverture pour ce patient
            pour procéder à la facturation
          </Alert>
        )}
        <div className="flex flex-row space-x-2 pb-2">
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isValidating}
            style={{
              transition: 'all 0.3s',
            }}
          >
            {isValidating && (
              <CircularProgress
                size={18}
                style={{ color: 'gray', marginRight: '8px' }}
              />
            )}
            Sauvegarder
          </Button>
          {order.patient.isForeigner && (
            <Button
              variant="contained"
              disabled={!memberSituation || precotations.length === 0}
              onClick={() => handleInvoiceOnly()}
            >
              Réglement 100% patient
            </Button>
          )}
          {!order.patient.isForeigner && (
            <>
              <Button
                variant="contained"
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                disabled={false}
                style={{ transition: 'all 0.3s' }}
              >
                {isValidating && (
                  <CircularProgress
                    size={18}
                    style={{ color: 'gray', marginRight: '8px' }}
                  />
                )}
                Facturer
              </Button>

              <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
              >
                <MenuItem onClick={handleInvoiceOnly}>
                  Paiement en amont
                </MenuItem>
                <MenuItem onClick={handleValidate}>Facturation finale</MenuItem>
              </Menu>
            </>
          )}
          <IconButton
            aria-describedby={id}
            onClick={handleClick}
            className="float-right"
          >
            <AddCircle />
          </IconButton>
        </div>
      </div>
      <Popover open={open} anchorEl={cotationAnchor} onClose={handleClose}>
        <List
          sx={{
            bgcolor: 'background.paper',
          }}
          component="nav"
        >
          <ListItem>
            <Button variant="text" onClick={() => handleAddQuotationRow()}>
              <ListItemText
                primary="Ajouter une cotation vide"
                sx={{ textAlign: 'start' }}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: 'text.primary', display: 'inline' }}
                    >
                      Cliquez pour ajouter une cotation
                    </Typography>
                  </React.Fragment>
                }
              />
            </Button>
          </ListItem>
          {cotationType === 'CCAM'
            ? ccams.map((ccam) => (
                <ListItem key={ccam.code}>
                  <Button
                    variant="text"
                    onClick={() => handleAddQuotationRow(ccam)}
                  >
                    <ListItemText
                      sx={{ textAlign: 'start' }}
                      primary={ccam.code}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                          >
                            {ccam.libelle}
                          </Typography>
                          {ccam.prixNonOptam} €
                        </React.Fragment>
                      }
                    />
                  </Button>
                </ListItem>
              ))
            : ngaps.map((ngap) => (
                <div key={ngap.code}>
                  <div>{ngap.code}</div>
                  <div>{ngap.libelle}</div>
                  <div>{ngap.prix}</div>
                </div>
              ))}
        </List>
      </Popover>

      <Popover
        open={isBillModeOpen}
        anchorEl={billModeAnchor}
        onClose={() => setBillModeAnchor(null)}
      >
        <List>
          {billModesOptions.map((item) => (
            <ListItem key={item.value}>
              <Button
                onClick={() => handleBillModeClick(item.value)}
                variant="text"
              >
                {item.label}
              </Button>
            </ListItem>
          ))}
        </List>
      </Popover>
      <QuotationTable
        isLoading={isFetching}
        rows={precotations}
        onAddRow={() => {
          handleAddQuotationRow()
        }}
        medicalOrderId={selectedMedicalOrder.id}
        registeredAt={order.registeredAt}
      />
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '80vh',
          },
        }}
        open={!!validation}
        TransitionComponent={UpTransition}
      >
        <DialogContent>
          {validation && validation.visite && (
            <BillSummaryComponent data={validation} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSummary}>Fermer</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateBill}
            disabled={isCreatingBill}
          >
            {isCreatingBill && (
              <CircularProgress
                size={18}
                style={{ color: 'gray', marginRight: '8px' }}
              />
            )}
            Créer la facture
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!codeSituationSelectorUrl}
        onClose={() => setCodeSituationSelectorUrl('')}
        fullScreen
        TransitionComponent={UpTransition}
      >
        <DialogContent>
          <iframe src={codeSituationSelectorUrl} width="100%" height="100%" />
        </DialogContent>
      </Dialog>
      <BillingDialog
        isOpen={!!billUrl}
        onClose={() => setBillUrl('')}
        billUrl={billUrl}
        onSuccess={handleBillSuccess}
      />

      <Dialog
        open={openPatientPayment && !!invoice}
        onClose={() => setOpenPatientPayment(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullScreen
      >
        {openPatientPayment && !!invoice && (
          <DialogContent>
            <PatientPaymentForm
              paymentCase={TransactionType.PAYMENT}
              invoiceToPay={invoice}
              onClose={() => setOpenPatientPayment(false)}
            />
          </DialogContent>
        )}
      </Dialog>
    </div>
  )
}

export default Quotation
