import React, { useEffect, useMemo } from 'react'
import { TextField, TextFieldProps, Button } from '@mui/material'
import moment from 'moment'
import { formatSsnPrefix } from '../../utils/ssnUtil'
import { SexEnum } from '../../services/api'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#f8f9fa',
      border: 'none',
      '& fieldset': {
        border: '1px solid #e9ecef',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 2px #e9ecef',
      },
    },
    '& .MuiInputBase-input': {
      padding: 16,
    },
  },
}))

interface SSNInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  birthDate?: Date
  birthPlace?: string
  sex?: SexEnum
  value?: string
  isForeigner?: boolean
  onChange?: (value: string) => void
  errorMessage?: string
}

export const SSNInput: React.FC<SSNInputProps> = ({
  birthDate,
  birthPlace,
  sex,
  value = '',
  isForeigner = false,
  onChange,
  errorMessage,
  ...props
}) => {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    if (val.replace(/\s/g, '').length <= 15) {
      onChange?.(val)
    }
  }

  useEffect(() => {
    if (birthDate && birthPlace && sex) {
      handleAutofill()
    }
  }, [birthDate, birthPlace, sex, isForeigner])

  const handleAutofill = () => {
    if (!birthDate || !birthPlace || !sex) {
      return
    }
    try {
      const formattedDate = moment(birthDate).format('YYYYMM')
      const gender = sex === 'M' ? '1' : '2'
      const department = isForeigner ? '99' : birthPlace.substring(0, 2)
      const ssnPrefix = `${gender} ${formattedDate.substring(
        2,
        4,
      )} ${formattedDate.substring(4, 6)} ${department}`

      const formattedPrefix = formatSsnPrefix(ssnPrefix)
      onChange?.(formattedPrefix)
    } catch (error) {
      console.error('Error computing SSN prefix:', error)
    }
  }

  const handleBlur = () => {
    if (!value) return
    const formattedValue = value
      .replace(/\s/g, '')
      .replace(
        /(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})?/g,
        '$1 $2 $3 $4 $5 $6 $7',
      )
      .trim()
    onChange?.(formattedValue)
  }

  const computedPrefix = useMemo(() => {
    if (!birthDate || !birthPlace || !sex) return ''
    try {
      const formattedDate = moment(birthDate).format('YYYYMM')
      const gender = sex === 'M' ? '1' : '2'
      const department = isForeigner ? '99' : birthPlace.substring(0, 2)
      const ssnPrefix = `${gender} ${formattedDate.substring(
        2,
        4,
      )} ${formattedDate.substring(4, 6)} ${department}`
      return formatSsnPrefix(ssnPrefix)
    } catch (error) {
      return ''
    }
  }, [birthDate, birthPlace, sex, isForeigner])

  const computedPrefixDigits = computedPrefix.replace(/\s/g, '')
  const inputPrefixDigits = value
    .replace(/\s/g, '')
    .slice(0, computedPrefixDigits.length)
  const showAutofillButton =
    computedPrefixDigits && computedPrefixDigits !== inputPrefixDigits

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <TextField
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        label="NIR"
        error={!!errorMessage}
        helperText={errorMessage}
        InputLabelProps={{
          shrink: true,
          style: {
            color: 'black',
          },
        }}
        className={classes.textField}
        sx={{
          flex: 1,
          backgroundColor: props.disabled ? '#F5F5F5' : 'inherit',
        }}
      />
      {showAutofillButton && (
        <Button
          className="!text-xs"
          variant="outlined"
          onClick={handleAutofill}
        >
          Préremplir
        </Button>
      )}
    </div>
  )
}
