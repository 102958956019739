import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DunningNoticeConfigDto,
  InvoiceEntity,
  InvoiceDto,
  InvoicePaymentStatus,
  UpdateDunningNoticeConfigDto,
  UpdateInvoiceDto,
} from '@services/dtos'
import { PaginatedQuery } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { InvoiceService } from '@services/invoiceService'

export interface InvoicesFilters extends PaginatedQuery {
  entity?: InvoiceEntity
  status?: InvoicePaymentStatus
  siteId?: number
  billingCompanyId?: number
  patientId?: number
  from?: string
  to?: string
  id?: string
}

export interface InvoiceState {
  invoice: InvoiceDto | null
  invoices: PaginatedDto<InvoiceDto>
  filters: InvoicesFilters
  loading: boolean
  dunningNoticeConfig: DunningNoticeConfigDto[]
}

const initialState: InvoiceState = {
  invoice: null,
  invoices: {
    datas: [],
    totalCount: 0,
  },
  filters: {
    limit: 20,
    page: 0,
  },
  loading: false,
  dunningNoticeConfig: [],
}

export const getInvoice = createAsyncThunk<InvoiceDto, { orderId: number }>(
  'invoices/findby',
  async ({ orderId }) => InvoiceService.getBy({ orderId })
)

export const getInvoices = createAsyncThunk<PaginatedDto<InvoiceDto>, void>(
  'invoices/find',
  async (_, { getState }) => {
    const { filters } = (getState() as { invoice: InvoiceState }).invoice
    return InvoiceService.getAll(filters)
  },
)

export const getDunningNoticeConfig = createAsyncThunk<
  DunningNoticeConfigDto[],
  void
>('invoices/get/dunning-notice-config', async () =>
  InvoiceService.getDunningNoticeConfig(),
)

export const saveDunningNoticeConfig = createAsyncThunk<
  DunningNoticeConfigDto[],
  { dto: UpdateDunningNoticeConfigDto }
>('invoices/save/dunning-notice-config', async ({ dto }) =>
  InvoiceService.saveDunningNoticeConfig(dto),
)

export const saveDunningNotice = createAsyncThunk<
  InvoiceDto,
  { invoiceId: number }
>('invoices/save/dunning-notice', async ({ invoiceId }, { getState }) => {
  const { filters } = (getState() as { invoice: InvoiceState }).invoice
  if (filters.entity) {
    return await InvoiceService.saveDunningNotice(invoiceId, filters.entity)
  }
  throw new Error('Entity filter is missing')
})

export const updateInvoice = createAsyncThunk<
  InvoiceDto,
  { id: number; dto: UpdateInvoiceDto }
>('invoices/update', async ({ id, dto }) => InvoiceService.update(id, dto))

export const invoiceReducer = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoiceFilters: (state, action: PayloadAction<InvoicesFilters>) => {
      state.loading = true
      state.filters = {
        ...state.filters,
        page: 0,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.loading = true
      })
      .addCase(getInvoices.rejected, (state) => {
        state.loading = false
      })
      .addCase(getInvoice.pending, (state) => {
        state.loading = true
      })
      .addCase(getInvoice.rejected, (state) => {
        state.loading = false
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload
        state.loading = false
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload
        state.loading = false
      })
      .addCase(getDunningNoticeConfig.fulfilled, (state, action) => {
        state.dunningNoticeConfig = action.payload
      })
      .addCase(saveDunningNoticeConfig.fulfilled, (state, action) => {
        state.dunningNoticeConfig = action.payload
      })
  },
})

export default invoiceReducer.reducer

export const { setInvoiceFilters } = invoiceReducer.actions
