import { RppsDto } from '@services/api'
import { DirectoryService } from '@services/api'
import { Close, Add } from '@mui/icons-material'
import {
  Box,
  debounce,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Paper,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useMemo, useRef, useState } from 'react'
import Text from '@components/Text'
import DoctorForm from './forms/DoctorForm'
import { DoctorV2Service } from '@services/DoctorService'

type RppsAutocompleteByNameInputProps = {
  onChange: (rpps: RppsDto | null) => void
  label: string | React.ReactNode
  value: RppsDto | null
  disabled?: boolean
  size?: 'small' | 'medium'
  error?: string
  hidden?: boolean
  showCreateOption?: boolean
  showLocalSearchOption?: boolean
}

const useStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  searchResult: {
    minHeight: 300,
  },
  backgroundOverlay: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    zIndex: 2,
  },
  resultBackground: {
    padding: '10px 15px',
    transition: 'background-color 0.2s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e8f4fd',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
      '&:hover': {
        backgroundColor: '#e8f4fd',
      },
    },
  },
  dialogPaper: {
    minWidth: '80%',
    maxWidth: '90%',
    maxHeight: '90vh',
  },
  dropdownContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 30,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  dropdownAbove: {
    bottom: '100%',
    marginBottom: '4px',
  },
  dropdownBelow: {
    top: '100%',
    marginTop: '4px',
  },
  dropdownList: {
    maxHeight: '300px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bdbdbd',
      borderRadius: '6px',
    },
  },
  inputContainer: {
    position: 'relative',
    width: '100%',
    marginBottom: '40px',
  },
}) as any

export function RppsAutocompleteByNameInput({
  onChange: setValue,
  disabled,
  label,
  value,
  size,
  error,
  hidden = false,
  showCreateOption = true,
  showLocalSearchOption = true,
}: RppsAutocompleteByNameInputProps) {
  const [options, setOptions] = useState<RppsDto[]>([])
  const [inputValue, setInputValue] = useState('')
  const [inputDisplay, setInputDisplay] = useState('')
  const [page, setPage] = useState(0)
  const limit = useRef(20).current
  const scrollPos = useRef(0)
  const listInnerRef = useRef<any>()
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [showDoctorForm, setShowDoctorForm] = useState(false)
  const [searchLocalFirst, setSearchLocalFirst] = useState(false)
  const [showAbove, setShowAbove] = useState(false)

  const classes = useStyles()

  const checkPosition = () => {
    if (inputContainerRef.current) {
      const rect = inputContainerRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      const bottomSpace = viewportHeight - rect.bottom

      // If there's less than 350px of space below the input, show dropdown above
      setShowAbove(bottomSpace < 350)
    }
  }

  const autocompleteByName = async (name: string) => {
    try {
      setIsFetching(true)

      if (searchLocalFirst) {
        try {
          const localDoctors = await DoctorV2Service.findMany({
            search: name,
            page: page + 1,
            limit,
          })

          if (localDoctors.datas.length > 0) {
            const rppsMap: { [key: string]: RppsDto } = {}
            localDoctors.datas.forEach((_doctor: any) => {
              if (_doctor.id) {
                rppsMap[_doctor.rppsNumber] = _doctor as RppsDto
              } else {
                if (rppsMap[_doctor.rppsNumber]) {
                  if (
                    !rppsMap[_doctor.rppsNumber].zipCode.includes(
                      _doctor.zipCode,
                    )
                  ) {
                    rppsMap[_doctor.rppsNumber].zipCode +=
                      ', ' + _doctor.zipCode
                  }
                } else {
                  rppsMap[_doctor.rppsNumber] = _doctor as RppsDto
                }
              }
            })

            if (page !== 0) {
              setOptions([...options, ...Object.values(rppsMap)])
            } else {
              setOptions(Object.values(rppsMap))
            }
            return
          }
        } catch (e) {
          console.error('Error searching local doctors:', e)
        }
      }

      const doctors = await DirectoryService.autocompleteByName({
        name,
        page,
        limit,
      })
      const rppsMap: { [key: string]: RppsDto } = {}
      doctors.forEach((_doctor) => {
        if (rppsMap[_doctor.rppsNumber]) {
          if (!rppsMap[_doctor.rppsNumber].zipCode.includes(_doctor.zipCode)) {
            rppsMap[_doctor.rppsNumber].zipCode += ', ' + _doctor.zipCode
          }
        } else {
          rppsMap[_doctor.rppsNumber] = _doctor
        }
      })
      if (page !== 0) {
        setOptions([...options, ...Object.values(rppsMap)])
      } else {
        setOptions(Object.values(rppsMap))
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsFetching(false)
    }
  }

  const fetch = useMemo(
    () =>
      debounce((v: string) => {
        autocompleteByName(v.toLowerCase())
      }, 500),
    [page, limit, searchLocalFirst],
  )
  useEffect(() => {
    if (!listInnerRef.current) {
      return
    }
    setTimeout(() => {
      listInnerRef.current?.scrollTo(0, scrollPos.current)
    }, 10)
  }, [scrollPos.current, options.length])

  useEffect(() => {
    if (inputValue === '') {
      setOptions([])
      return undefined
    }
    if (!isFetching) {
      fetch(inputValue)
    }
  }, [inputValue, fetch])

  useEffect(() => {
    // Check position when options change
    if (options.length > 0) {
      checkPosition()
    }

    // Add resize listener to handle viewport changes
    window.addEventListener('resize', checkPosition)

    return () => {
      window.removeEventListener('resize', checkPosition)
    }
  }, [options])

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 0.6) {
        setPage(page + 1)
        scrollPos.current = scrollTop
      }
    }
  }

  const handleRppsClick = (rpps: RppsDto) => {
    setInputDisplay(
      rpps.firstName.charAt(0).toUpperCase() +
        rpps.firstName.slice(1) +
        ' ' +
        rpps.lastName.charAt(0).toUpperCase() +
        rpps.lastName.slice(1),
    )

    if (setValue) {
      setValue(rpps)
    }
    setOptions([])
    setPage(0)

    // Prevent fetch again because of scroll size bug
    setIsFetching(true)
    setTimeout(() => {
      setIsFetching(false)
    }, 600)
  }

  const renderRpps = (rpps: RppsDto) => {
    return (
      <Box
        key={rpps.id}
        justifyContent="center"
        onClick={() => handleRppsClick(rpps)}
        className={classes.resultBackground}
      >
        <Text
          text={
            rpps.firstName.charAt(0).toUpperCase() +
            rpps.firstName.slice(1) +
            ' ' +
            rpps.lastName.charAt(0).toUpperCase() +
            rpps.lastName.slice(1) +
            ' '
          }
          variant="h5"
          style={{
            color: '#E91E63',
            fontSize: '1.1em',
            fontWeight: 600,
          }}
        />
        <Text
          style={{
            textTransform: 'capitalize',
            fontWeight: 500,
            fontStyle: 'italic',
          }}
          text={
            rpps.profession !== 'Médecin'
              ? rpps.profession
              : rpps.skillType?.trim()
              ? rpps.skillType
              : rpps.category
          }
        />
        <Text
          text={`N°RPPS: ${rpps.rppsNumber}`}
          variant="body1"
          style={{ fontSize: '14px', color: '#5e5e5e' }}
        />
        <Text
          text={
            rpps.zipCode?.trim() !== ''
              ? `Lieux d'activité: (${rpps.zipCode})`
              : ''
          }
          variant="body1"
          style={{ fontSize: '14px', color: '#5e5e5e' }}
        />
      </Box>
    )
  }
  const _handleInputChange = (value: string) => {
    setInputValue(value)
    setInputDisplay(value)
    if (setValue) {
      setValue(null)
    }
    setOptions([])
    if (page !== 0) {
      setPage(0)
    }
  }
  const _handleOverlayClick = () => {
    setOptions([])
    if (page !== 0) {
      setPage(0)
    }
  }

  const _isAutocompleteVisible = options.length !== 0
  const _handleRppsUnselect = () => {
    setValue(null)
    setInputValue('')
    setInputDisplay('')
  }

  const handleOpenDoctorForm = () => {
    setShowDoctorForm(true)
  }

  const handleDoctorFormClose = () => {
    setShowDoctorForm(false)
  }

  const handleDoctorCreated = (rpps: RppsDto) => {
    if (setValue) {
      setValue(rpps)
    }
    setShowDoctorForm(false)
  }

  return (
    <>
      <Grid
        container
        style={{ position: 'relative', display: hidden ? 'none' : 'flex' }}
      >
        {showLocalSearchOption && (
          <Grid item xs={12} sx={{ mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchLocalFirst}
                  onChange={(e) => setSearchLocalFirst(e.target.checked)}
                  color="primary"
                  size="small"
                />
              }
              label="Chercher dans la base locale"
            />
          </Grid>
        )}

        {_isAutocompleteVisible && (
          <div
            id="backgroundOverlay"
            onClick={_handleOverlayClick}
            className={classes.backgroundOverlay}
          ></div>
        )}

        <Grid
          item
          xs={12}
          className={classes.inputContainer}
          ref={inputContainerRef}
        >
          {!value && (
            <TextField
              variant="outlined"
              label={label}
              fullWidth
              size={size === 'small' ? 'small' : 'medium'}
              disabled={disabled}
              value={inputDisplay}
              onChange={(ev) => _handleInputChange(ev.target.value)}
              placeholder="Veuillez rechercher comme ceci: Prénom Nom"
              error={!!error}
              helperText={error}
              InputProps={
                showCreateOption
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleOpenDoctorForm}
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              marginRight: '-8px',
                            }}
                            aria-label="Créer un nouveau médecin"
                          >
                            <Add sx={{ fontSize: 24 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
          )}
          {value && (
            <Grid container justifyContent={'center'} alignItems={'center'}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: '2px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{
                    marginLeft: '5px',
                    color: '#4ea5d9',
                    fontWeight: 'bold',
                  }}
                >
                  {label}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                style={{
                  backgroundColor: '#4ea5d9',
                  borderRadius: 25,
                  fontSize: 12,
                  fontWeight: 'bold',
                  padding: '12px',
                  width: '100%',
                }}
              >
                <span
                  style={{
                    fontSize: '1.0em',
                    color: '#fff',
                    textShadow: '1px 1px 2px #3f3f3f90',
                  }}
                >
                  {value.firstName.charAt(0).toUpperCase() +
                    value.firstName.slice(1) +
                    ' ' +
                    value.lastName.charAt(0).toUpperCase() +
                    value.lastName.slice(1) +
                    (value.rppsNumber
                      ? ' (RPPS: ' + value.rppsNumber + ')'
                      : '')}
                </span>
                <Close
                  onClick={_handleRppsUnselect}
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    color: '#e44b8d',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    padding: 1,
                    textAlign: 'right',
                  }}
                />
              </Grid>
            </Grid>
          )}

          {options.length > 0 && (
            <Paper
              className={`${classes.dropdownContainer} ${
                showAbove ? classes.dropdownAbove : classes.dropdownBelow
              }`}
              elevation={3}
            >
              <div
                onScroll={() => handleScroll()}
                ref={listInnerRef}
                className={classes.dropdownList}
              >
                {options.map((op) => renderRpps(op))}
              </div>
            </Paper>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={showDoctorForm}
        onClose={handleDoctorFormClose}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <DoctorForm
            onClose={handleDoctorFormClose}
            hideRppsAutocomplete={true}
            onDoctorCreated={handleDoctorCreated}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
