import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  CreateBankEstablishmentDto,
  BankEstablishmentDto,
} from '@services/dtos'
import { bankEstablishmentSchema } from '@utils/schemas'
import { useAppDispatch } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Grid } from '@mui/material'
import {
  createBankEstablishment,
  updateBankEstablishment,
} from '@state/reducers/bankEstablishmentReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'

type Props = {
  editingBankEstablishment?: BankEstablishmentDto | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const BankEstablishmentForm: React.FC<Props> = ({
  editingBankEstablishment,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateBankEstablishmentDto>({
    defaultValues: editingBankEstablishment
      ? (({ code, label }) => ({ code, label }))(editingBankEstablishment)
      : undefined,
    resolver: yupResolver(bankEstablishmentSchema),
  })

  const dispatch = useAppDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreateBankEstablishmentDto) => {
    setIsSubmitting(true)

    if (editingBankEstablishment) {
      dispatch(
        updateBankEstablishment({ id: editingBankEstablishment.id, dto: data }),
      )
        .unwrap()
        .then(async () =>
          cb('L\'établissement bancaire a été modifié avec succès'),
        )
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(createBankEstablishment(data))
        .unwrap()
        .then(async () => cb('L\'établissement bancaire a été créé avec succès'))
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Configuration</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingBankEstablishment ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Etablissement bancaire
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Identifiant court"
                  error={errors.code?.message}
                >
                  <input
                    type="text"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le code"
                    {...register('code')}
                  />
                </FormField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField label="Libellé" error={errors.label?.message}>
                  <input
                    type="text"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Entrez le libellé"
                    {...register('label')}
                  />
                </FormField>
              </Grid>
            </Grid>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default BankEstablishmentForm
