import { QuoteService } from '@services/quoteService'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateQuoteDto, QuoteDto, UpdateQuoteDto } from '@services/dtos'
import { PaginatedDto } from '@/services/extendedType'
import { PaginatedQuery } from '@/services/api'

export interface QuotesFilters extends PaginatedQuery {
  from?: string
  to?: string
  id?: string
}

export interface QuoteState {
  quotes: PaginatedDto<QuoteDto>
  filters: QuotesFilters
  loading: boolean
  loadingUpdate: boolean
}

const initialState: QuoteState = {
  quotes: {
    datas: [],
    totalCount: 0,
  },
  filters: {
    limit: 20,
    page: 0,
  },
  loading: false,
  loadingUpdate: false,
}

export const getQuotes = createAsyncThunk<PaginatedDto<QuoteDto>, void>(
  'quotes/find',
  async (_, { getState }) => {
    const { filters } = (getState() as { quote: QuoteState }).quote
    return QuoteService.getAll(filters)
  },
)

export const removeQuote = createAsyncThunk<void, number>(
  'quotes/remove',
  async (id) => QuoteService.remove(id),
)

export const createQuote = createAsyncThunk<QuoteDto, CreateQuoteDto>(
  'quotes/create',
  async (dto) => QuoteService.create(dto),
)

export const updateQuote = createAsyncThunk<
  QuoteDto,
  { id: number; dto: UpdateQuoteDto }
>('quotes/update', async ({ id, dto }) => QuoteService.update(id, dto))

export const quoteReducer = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    setQuoteFilters: (state, action: PayloadAction<QuotesFilters>) => {
      state.filters = {
        ...state.filters,
        page: 0,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuotes.pending, (state) => {
        state.loading = true
      })
      .addCase(getQuotes.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateQuote.pending, (state) => {
        state.loadingUpdate = true
      })
      .addCase(updateQuote.rejected, (state) => {
        state.loadingUpdate = false
      })
      .addCase(getQuotes.fulfilled, (state, action) => {
        state.quotes = action.payload
        state.loading = false
      })
      .addCase(updateQuote.fulfilled, (state, action) => {
        state.quotes.datas = state.quotes.datas.map((quote) =>
          quote.id === action.payload.id ? action.payload : quote,
        )
        state.loadingUpdate = false
      })
  },
})

export default quoteReducer.reducer

export const { setQuoteFilters } = quoteReducer.actions
