import { QuotesFilters, setQuoteFilters } from '@state/reducers/quoteReducer'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { TextField, Typography } from '@mui/material'
import { debounce } from 'lodash'
import { DateInputField } from '@components/inputs/DateInputField'
import moment from 'moment'

const QuotesTableToolbar = () => {
  const dispatch = useAppDispatch()

  const { filters } = useAppSelector(({ quote }) => ({
    filters: quote.filters,
  }))

  const updateFilter = debounce((filter: QuotesFilters) => {
    dispatch(setQuoteFilters(filter))
  }, 500)

  const handleFilterChange = (filter: QuotesFilters) => {
    updateFilter(filter)
  }

  return (
    <>
      <TextField
        label="Rechercher N° Devis"
        variant="outlined"
        onChange={(e) =>
          handleFilterChange({ id: e.target.value || undefined })
        }
        size="small"
      />
      <DateInputField
        value={filters.from ? moment(filters.from) : undefined}
        onChange={(date) => {
          dispatch(
            setQuoteFilters({
              from: date ? date.startOf('day').toISOString() : undefined,
            }),
          )
        }}
      />
      <Typography>au</Typography>
      <DateInputField
        value={filters.to ? moment(filters.to) : undefined}
        onChange={(date) => {
          dispatch(
            setQuoteFilters({
              to: date ? date.endOf('day').toISOString() : undefined,
            }),
          )
        }}
      />
    </>
  )
}

export default QuotesTableToolbar
