import {
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
  Button,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { UpTransition } from '../animations'
import { OrderDetailsComponent } from '../../containers/app/Worklists/MedicalOrderDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import {
  doCreateEmptyOrder,
  doUpdateOrder,
  setSelectedOrder,
} from '../../state/reducers/orderReducer'
import {
  doCreateCoverege,
  doUpdateCouverture,
  setPatientDetails,
} from '../../state/reducers/patientsReducer'
import PatientSearchComponent from '../patient/PatientSearchComponent'
import ManagePatientComponent, {
  ManagepatientComponentRef,
} from '../forms/ManagePatientComponent'
import { User as UserIcon } from 'lucide-react'
import {
  doCreatePatient,
  doGetCouverture,
} from '../../state/thunks/patientsThunk'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import { enqueueSnackbar } from 'notistack'
import { PatientDto } from '../../services/api'
import { OrderDto } from '../../services/dtos'
import { CouvertureDto } from '../../common/interfaces'
import moment from 'moment'

type Props = {
  isOpen: boolean
  onClose: () => void
  patientId?: number
}
const InstantBillComponent: React.FC<Props> = ({
  isOpen,
  onClose,
  patientId,
}) => {
  const dispatch = useAppDispatch()
  const managePatientRef = useRef<ManagepatientComponentRef>(null)

  // ============================== STATE ==============================
  const order = useAppSelector((state) => state.order.selectedOrder)
  const [selectedPatientId, setSelectedPatientId] = useState<
    number | undefined
  >(patientId)
  const [isCreatingOrder, setIsCreatingOrder] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCoverageFetching, setIsCoverageFetching] = useState(false)

  // ============================== EFFECTS ==============================
  useEffect(() => {
    dispatch(setPatientDetails(null))
    setSelectedPatientId(undefined)
    dispatch(setSelectedOrder(null))
  }, [isOpen])

  useEffect(() => {
    if (selectedPatientId) {
      setIsCreatingOrder(true)
      dispatch(doCreateEmptyOrder(selectedPatientId))
        .unwrap()
        .then(async () => {
          const managePatient = managePatientRef.current
          if (!managePatient) {
            return
          }

          const couvertureRef = managePatientRef.current?.getCouvertureFormRef()
          if (!couvertureRef) {
            return
          }
          const values = await couvertureRef.getValues()
          if (couvertureHasValues(values)) {
            return
          }

          const coverage = await dispatch(
            doGetCouverture({
              idPatient: selectedPatientId,
            }),
          ).unwrap()
          if (coverage) {
            const coverageRef = managePatient.getCouvertureFormRef()
            if (coverageRef) {
              coverageRef.setValues(coverage)
            }
          } else {
            const patientFormRef = managePatient.getPatientFormRef()
            if (!patientFormRef) {
              return
            }
            const patient = await patientFormRef.getValues()

            if (patient) {
              const coverageFormRef =
                managePatientRef.current?.getCouvertureFormRef()
              const coverageInfos = await coverageFormRef?.getValues()

              const dto: Partial<CouvertureDto> = {}

              if (!coverageInfos?.beneficaireNomUsuel) {
                dto.beneficaireNomUsuel = patient.lastName || undefined
              }
              if (!coverageInfos?.beneficiairePrenom) {
                dto.beneficiairePrenom = patient.firstName || undefined
              }
              if (!coverageInfos?.beneficiaireNom) {
                dto.beneficiaireNom = patient.lastName || undefined
              }
              if (
                !coverageInfos?.beneficaireDateNaissance &&
                patient.birthDate
              ) {
                const birthDate = (
                  patient.birthDate as any as moment.Moment
                ).format('YYYYMMDD')
                dto.beneficaireDateNaissance = birthDate
              }

              if (couvertureRef) {
                couvertureRef.setValues(dto as CouvertureDto)
                await couvertureRef.validate()
              }
            }
          }
          managePatientRef.current?.setActiveForm('Couverture')
        })
        .finally(() => {
          setIsCreatingOrder(false)
        })
    }
  }, [selectedPatientId])

  useEffect(() => {
    return () => {
      dispatch(setPatientDetails(null))
    }
  }, [])

  // ============================== HANDLERS ==============================
  const handlePatientChange = (patientId?: number) => {
    setSelectedPatientId(patientId)
    if (!patientId) {
      setSelectedOrder(null)
      setActiveTab(0)
      managePatientRef.current?.clear()
    }
  }

  const couvertureHasValues = (couverture: CouvertureDto | null) => {
    if (!couverture) {
      return false
    }
    return (
      couverture.beneficaireNomUsuel ||
      couverture.beneficiairePrenom ||
      couverture.beneficiaireNom ||
      couverture.beneficaireDateNaissance ||
      couverture.beneficiaireNir ||
      couverture.assureNir ||
      couverture.assureNom ||
      couverture.assurePrenom ||
      couverture.assureNomUsuel
    )
  }

  const handleSavePatient = async () => {
    setIsSubmitting(true)
    try {
      const dto = await managePatientRef.current
        ?.getPatientFormRef()
        ?.getValues()
      if (dto) {
        const p = await dispatch(doCreatePatient(dto)).unwrap()
        setSelectedPatientId(p.id)
      }
    } catch (err) {
      enqueueSnackbar((err as Error).message, { variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSaveOrder = async () => {
    if (!order) {
      return
    }

    setIsSubmitting(true)
    try {
      await dispatch(
        doUpdateOrder({
          id: order.id,
          dto: {
            deletedAt: null,
          },
        }),
      ).unwrap()
    } catch (err) {
      enqueueSnackbar((err as Error).message, { variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClose = () => {
    if (order?.deletedAt) {
      setOpenConfirmDialog(true)
    } else {
      onClose()
    }
  }

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false)
    onClose()
  }

  const handleCancelClose = () => {
    setOpenConfirmDialog(false)
  }

  const handleSaveCoverage = async (
    _order: OrderDto,
    onSuccess?: () => void,
  ) => {
    setIsSubmitting(true)
    const coverage = await managePatientRef.current
      ?.getCouvertureFormRef()
      ?.getValues()
    if (!coverage) {
      managePatientRef.current?.setActiveForm('Couverture')
      setIsSubmitting(false)
      return
    }
    try {
      if (selectedPatientId && _order?.visitId) {
        coverage.idPatient = selectedPatientId
        coverage.idVisit = _order.visitId
        let res: CouvertureDto | null = null

        if (coverage.id) {
          res = await dispatch(
            doUpdateCouverture({ id: coverage.id, dto: coverage }),
          ).unwrap()
        } else {
          res = await dispatch(
            doCreateCoverege({ patientId: selectedPatientId, dto: coverage }),
          ).unwrap()
        }
        managePatientRef.current?.getCouvertureFormRef()?.setValues(res)
        managePatientRef.current?.setIsCoverageSet(true)
        onSuccess?.()
      }
    } catch (err) {
      managePatientRef.current?.setActiveForm('Couverture')
      enqueueSnackbar((err as Error).message, { variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handlePatientFound = async (patient: PatientDto) => {
    setSelectedPatientId(patient.id)
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={UpTransition}
        fullScreen
        disableEscapeKeyDown
      >
        <button
          type="button"
          className="btn btn-primary absolute top-4 right-8 z-40"
          onClick={handleClose}
        >
          <i className="fas fa-times text-3xl"></i>
        </button>

        <DialogContent>
          <div className="flex flex-row gap-x-4 items-center">
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
            >
              <Tab label="Patient" />
              <Tab
                label={
                  <div className="flex items-center gap-2">
                    {!order &&
                      (isCreatingOrder ? (
                        <CircularProgress size={16} />
                      ) : (
                        <i className="fas fa-lock text-gray-400" />
                      ))}
                    Examens
                    {!order && (
                      <Tooltip title="Veuillez sélectionner un patient d'abord">
                        <i className="fas fa-exclamation-circle text-yellow-500" />
                      </Tooltip>
                    )}
                  </div>
                }
                disabled={!order}
              />
            </Tabs>

            {!selectedPatientId && (
              <Button
                onClick={handleSavePatient}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} style={{ color: 'gray' }} />
                ) : (
                  <UserIcon size={16} />
                )}
                <span className="ml-1">Sauvegarder patient</span>
              </Button>
            )}
            {order && !!order.deletedAt && (
              <Button
                onClick={handleSaveOrder}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} style={{ color: 'gray' }} />
                ) : (
                  <i className="fas fa-save text-white" />
                )}
                Sauvegarder la demande
              </Button>
            )}
            {selectedPatientId && !!order?.visitId && (
              <Button
                onClick={() => handleSaveCoverage(order)}
                disabled={isSubmitting || isCoverageFetching}
                variant="contained"
                color="primary"
              >
                {isSubmitting ? (
                  <CircularProgress size={20} style={{ color: 'gray' }} />
                ) : (
                  <i className="fas fa-save mr-1" />
                )}
                Sauvegarder la couverture
              </Button>
            )}
          </div>
          <div className="mt-4">
            <fieldset
              style={{
                display: activeTab === 0 ? 'block' : 'none',
              }}
              className="border border-gray-300 p-4 rounded-lg shadow-md pr-[140px]"
            >
              <legend className="font-bold text-lg text-gray-700">
                Patient Section
              </legend>
              <PatientSearchComponent
                defaultValue={selectedPatientId}
                onChange={handlePatientChange}
              />
              <ManagePatientComponent
                onFetchingCoverageUpdated={setIsCoverageFetching}
                patientId={selectedPatientId}
                ref={managePatientRef}
                visitId={order?.visitId}
                onPatientFoundByVCard={handlePatientFound}
              />
            </fieldset>

            {activeTab === 1 && (
              <fieldset className="border border-gray-300 p-4 rounded-lg shadow-md">
                <legend className="font-bold text-lg text-gray-700">
                  Order Details
                </legend>
                {order && <OrderDetailsComponent order={order} editByDefault />}
              </fieldset>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={openConfirmDialog}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
        title="Fermeture de la fenêtre"
        message="La demande n'est pas encore sauvegardée. Voulez-vous vraiment fermer la fenêtre ?"
      />
    </div>
  )
}

export default InstantBillComponent
