import {
  DialogTitle as DialogTitleBase,
  DialogTitleProps,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'

import Text from '../Text'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 10,
    height: 30,
    backgroundColor: '#fff',
    border: 'none,',
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#fff',
  },
}))

type CustomDialogTitleProps = DialogTitleProps & {
  title: string
  onClose: () => void
  format?: boolean
}

export function DialogTitle({
  title,
  onClose,
  format = false,
  ...other
}: CustomDialogTitleProps) {
  const classes = useStyles()
  return (
    <DialogTitleBase className={classes.root} {...other}>
      <Text variant="h6" text={title} format={format} />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes?.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitleBase>
  )
}
