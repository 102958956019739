import { BillingCompanyService } from '@services/billingCompanyService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  CreateBillingCompanyDto,
  BillingCompanyDto,
  UpdateBillingCompanyDto,
} from '@services/dtos'

export interface FindBillingCompanies {
  includeInactive?: boolean
  siteId?: number
}

export interface BillingCompanyState {
  billingCompanies: BillingCompanyDto[]
}

const initialState: BillingCompanyState = {
  billingCompanies: [],
}

export const getBillingCompanies = createAsyncThunk<
  BillingCompanyDto[],
  FindBillingCompanies | undefined
>('billing-companys/find', (params) => BillingCompanyService.getAll(params))

export const removeBillingCompany = createAsyncThunk<void, number>(
  'billing-companys/remove',
  async (id) => BillingCompanyService.remove(id),
)

export const createBillingCompany = createAsyncThunk<
  BillingCompanyDto,
  CreateBillingCompanyDto
>('billing-companys/create', async (dto) => BillingCompanyService.create(dto))

export const updateBillingCompany = createAsyncThunk<
  BillingCompanyDto,
  { id: number; dto: UpdateBillingCompanyDto }
>('billing-companys/update', async ({ id, dto }) =>
  BillingCompanyService.update(id, dto),
)

export const BillingCompanyReducer = createSlice({
  name: 'billingCompanies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBillingCompanies.fulfilled, (state, action) => {
      state.billingCompanies = action.payload
    })
    builder.addCase(removeBillingCompany.fulfilled, (state, action) => {
      state.billingCompanies = state.billingCompanies.filter(
        (billingCompany) => billingCompany.id !== action.meta.arg,
      )
    })
    builder.addCase(updateBillingCompany.fulfilled, (state, action) => {
      state.billingCompanies = state.billingCompanies.map((billingCompany) =>
        billingCompany.id === action.payload.id
          ? action.payload
          : billingCompany,
      )
    })
    builder.addCase(createBillingCompany.fulfilled, (state, action) => {
      state.billingCompanies.unshift(action.payload)
    })
  },
})

export default BillingCompanyReducer.reducer
