import { UpTransition } from '@components/animations'
import {
  Chip,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { LotDto } from '@services/dtos'
import moment from 'moment'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import OrganismPaymentForm from '../forms/OrganismPaymentForm'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { debounce } from 'lodash'
import { getLots, setLotFilters } from '@state/reducers/lotReducer'
import LotsTableToolbar from './Toolbars/LotsTableToolbar'

export default function LotsTable() {
  const [openLotId, setOpenLotId] = useState<number | null>(null)
  const [editingLot, setEditingLot] = useState<LotDto | null>(null)

  const dispatch = useAppDispatch()
  const { lots, loading, filters } = useAppSelector(({ lot }) => ({
    lots: lot.lots,
    loading: lot.loading,
    filters: lot.filters,
  }))

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(getLots())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const actionBtnObj = [
    {
      id: 1,
      btnName: 'Voir les factures',
      icon: <OpenInNewIcon color="primary" />,
      action: (row: LotDto) => setOpenLotId(row.id),
      actionKey: 'viewLot',
    },
    {
      id: 2,
      btnName: 'Gérer le réglement',
      icon: <RequestQuoteIcon color="primary" />,
      action: (row: LotDto) => setEditingLot(row),
      actionKey: 'editLot',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const invoiceBatch = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              acc.push(
                <Tooltip
                  key={invoiceBatch.id + '-' + el.btnName}
                  title={el.btnName}
                >
                  <div
                    className="cursor-pointer p-1 hover:bg-gray-200 rounded"
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(invoiceBatch)
                    }}
                  >
                    {el.icon}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'state',
      headerName: 'Etat',
      renderCell: ({ row }) => (
        <Chip
          color={row.organismPayment ? 'primary' : 'default'}
          label={row.organismPayment ? 'Payé' : 'En attente'}
        ></Chip>
      ),
      width: 115,
    },
    {
      field: 'numero',
      headerName: 'Numéro lot',
      width: 130,
    },
    {
      field: 'dateLot',
      headerName: 'Date lot',
      width: 100,
      valueGetter: ({ row }) => moment(row.dateLot).format('DD/MM/YYYY'),
    },
    {
      field: 'typeLot',
      headerName: 'Type',
      valueGetter: ({ row }) => {
        switch (row.typeLot) {
          case 0:
            return 'AMO'
          case 1:
            return 'AMC'
          case 2:
            return 'Forfait technique'
          default:
            break
        }
      },
      width: 140,
    },
    {
      field: 'dateHeureTeletrans',
      headerName: 'Date télétransmission',
      valueGetter: ({ row }) =>
        moment(row.dateHeureTeletrans, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 170,
    },
    {
      field: 'montantTotal',
      headerName: 'Total',
      valueGetter: ({ row }) => `${row.montantTotal}€`,
      width: 120,
    },
    {
      field: 'invoicesCount',
      headerName: 'Nombre de factures',
      valueGetter: ({ row }) => row.invoices?.length,
      width: 160,
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) =>
        row.organismPayment
          ? moment(row.organismPayment.updatedAt).format('DD/MM/YYYY')
          : '',
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) => {
        if (!row.organismPayment) return ''
        return row.updatedBy
          ? `${row.organismPayment?.updatedBy.firstName ?? ''} ${
              row.organismPayment?.updatedBy.lastName ?? ''
            }`
          : `${row.organismPayment?.createdBy.firstName ?? ''} ${
              row.organismPayment?.createdBy.lastName ?? ''
            }`
      },
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 120px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={lots.datas}
                rowCount={lots.totalCount}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSize={20}
                onPageChange={(page) => {
                  dispatch(setLotFilters({ page }))
                }}
                loading={loading}
                components={{
                  Toolbar: LotsTableToolbar,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={!!openLotId}
        onClose={() => setOpenLotId(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {openLotId && (
            <>
              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
                  onClick={() => setOpenLotId(null)}
                >
                  Fermer
                </button>
              </div>
              <DataGridPro
                density="standard"
                style={{
                  height: 'calc(100vh - 300px)',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={[
                  {
                    field: 'id',
                    headerName: 'Identifiant',
                    width: 100,
                  },
                  {
                    field: 'date',
                    headerName: 'Date',
                    width: 100,
                    valueGetter: ({ row }) =>
                      moment(row.date).format('DD/MM/YYYY'),
                  },

                  {
                    field: 'patientName',
                    headerName: 'Patient',
                    valueGetter: ({ row }) =>
                      `${row.patient.firstName ?? ''} ${
                        row.patient.lastName ?? ''
                      }`,
                    width: 140,
                  },
                  {
                    field: 'patientBirthDate',
                    headerName: 'Date de naissance',
                    valueGetter: ({ row }) =>
                      moment(row.patient.birthDate, 'YYYY-MM-DD').format(
                        'DD/MM/YYYY',
                      ),
                    width: 140,
                  },
                  {
                    field: 'payedAmo',
                    headerName: 'Payé AMO',
                    width: 100,
                    valueGetter: ({ row }) => `${row.amo_payed}€`,
                  },
                  {
                    field: 'totalAmo',
                    headerName: 'Total AMO',
                    width: 100,
                    valueGetter: ({ row }) => `${row.amo_total}€`,
                  },
                  {
                    field: 'payedAmc',
                    headerName: 'Payé AMC',
                    width: 100,
                    valueGetter: ({ row }) => `${row.amc_payed}€`,
                  },
                  {
                    field: 'totalAmc',
                    headerName: 'Total AMC',
                    width: 100,
                    valueGetter: ({ row }) => `${row.amc_total}€`,
                  },
                  {
                    field: 'payedPatient',
                    headerName: 'Payé Patient',
                    width: 100,
                    valueGetter: ({ row }) => `${row.patient_payed}€`,
                  },
                  {
                    field: 'totalPatient',
                    headerName: 'Total Patient',
                    width: 100,
                    valueGetter: ({ row }) => `${row.patient_total}€`,
                  },
                  {
                    field: 'payedTotal',
                    headerName: 'Payé Total',
                    width: 100,
                    valueGetter: ({ row }) =>
                      `${row.patient_payed + row.amc_payed + row.amo_payed}€`,
                  },
                  {
                    field: 'total',
                    headerName: 'Total',
                    width: 100,
                    valueGetter: ({ row }) => `${row.total}€`,
                  },
                ]}
                rows={
                  lots.datas.find(({ id }) => id === openLotId)?.invoices ?? []
                }
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!editingLot}
        onClose={() => setEditingLot(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
      >
        {editingLot && (
          <DialogContent>
            <OrganismPaymentForm
              lot={editingLot}
              onClose={() => setEditingLot(null)}
            />
          </DialogContent>
        )}
      </Dialog>
    </Container>
  )
}
