import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import {
  InvoiceEntity,
  InvoiceDto,
  InvoicePaymentStatus,
  TransactionType,
} from '@services/dtos'
import moment from 'moment'
import { debounce } from 'lodash'
import PaymentIcon from '@mui/icons-material/Payment'
import {
  getDunningNoticeConfig,
  getInvoices,
  saveDunningNotice,
  setInvoiceFilters,
  updateInvoice,
} from '@state/reducers/invoiceReducer'
import { UpTransition } from '../animations'
import PatientPaymentForm from '../forms/PatientPaymentForm'
import InvoicePdfTemplate from '../pdf/InvoicePdfTemplate'
import generatePDF, { Margin } from 'react-to-pdf'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PaymentsIcon from '@mui/icons-material/Payments'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import PatientPaymentsTable from './PatientPaymentsTable'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import DunningNoticePdfTemplate from '../pdf/DunningNoticePdfTemplate'
import { DunningNoticeNextLevel } from '@utils/constants'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import InvoicesTableToolbar from './Toolbars/InvoicesTableToolbar'
import * as uid from 'uid'
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff'
import { Redeem } from '@mui/icons-material'
import { getDocumentConfig } from '@state/reducers/documentConfigReducer'
import { getMyCashRegister } from '@state/reducers/cashRegisterReducer'

export default function PatientInvoicesTable() {
  const [paymentInvoice, setPaymentInvoice] = useState<InvoiceDto | null>(null)
  const [openPatientPayments, setOpenPatientPayments] =
    useState<InvoiceDto | null>(null)
  const [dunningNoticeSendForm, setDunningNoticeSendForm] =
    useState<InvoiceDto | null>(null)
  const [lossForm, setLossForm] = useState<InvoiceDto | null>(null)

  const dispatch = useAppDispatch()
  const { invoices, loading, filters, documentConfig, myCashRegister } =
    useAppSelector(({ invoice, documentConfig, cashRegister }) => ({
      invoices: invoice.invoices,
      loading: invoice.loading,
      filters: invoice.filters,
      documentConfig: documentConfig.documentConfig,
      myCashRegister: cashRegister.mine,
    }))

  useEffect(() => {
    dispatch(getDunningNoticeConfig())
    dispatch(getDocumentConfig())
    dispatch(getMyCashRegister())
    dispatch(
      setInvoiceFilters({
        entity: InvoiceEntity.PATIENT,
        status: InvoicePaymentStatus.TO_BE_PAID,
      }),
    )
  }, [])

  useEffect(() => {
    if (!filters.entity || !filters.status) return

    const debouncedSearch = debounce(() => {
      dispatch(getInvoices())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const InvoicePdfCellRenderer = ({ row }: { row: InvoiceDto }) => {
    if (!filters.entity) return <></>
    const ref = useRef<HTMLDivElement>(null)

    return (
      <div className="ml-0">
        <InvoicePdfTemplate
          entity={filters.entity}
          invoice={row}
          ref={ref}
          documentConfig={documentConfig}
        />
        <Tooltip title="Voir la facture">
          <IconButton
            onClick={() =>
              generatePDF(ref, {
                method: 'open',
                page: {
                  margin: Margin.MEDIUM,
                },
              })
            }
            color="primary"
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const DunningNoticePdfCellRenderer = ({ row }: { row: InvoiceDto }) => {
    const ref = useRef<HTMLDivElement>(null)

    return (
      <div className="ml-0">
        <DunningNoticePdfTemplate invoice={row} ref={ref} />
        <Tooltip title="Voir le fichier de relance">
          <IconButton
            onClick={() =>
              generatePDF(ref, {
                method: 'open',
                page: {
                  margin: Margin.MEDIUM,
                },
              })
            }
            color="primary"
          >
            <FileOpenIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const actionBtnObj = useMemo(() => {
    switch (filters.status) {
      case InvoicePaymentStatus.TO_BE_PAID:
        return [
          {
            id: 1,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
          {
            id: 2,
            btnName: 'Paiement',
            icon: <PaymentIcon color="primary" />,
            action: (row: InvoiceDto) => {
              if (!myCashRegister) {
                dispatch(
                  enqueueSnackbar({
                    message:
                      'Vous devez ouvrir une caisse avant de procéder à un paiement',
                    options: { variant: 'error' },
                  }),
                )
                return
              }
              setPaymentInvoice(row)
            },
            actionKey: 'paymentInvoice',
          },
          {
            id: 3,
            btnName: 'Historique paiements',
            icon: <PaymentsIcon color="primary" />,
            action: (row: InvoiceDto) =>
              row.patientPayments.length > 0 && setOpenPatientPayments(row),
            actionKey: 'viewPaymentsInvoice',
          },
          {
            id: 4,
            btnName: 'Noter comme perte',
            icon: <DoDisturbOffIcon color="primary" />,
            action: (row: InvoiceDto) => setLossForm(row),
            actionKey: 'lossInvoice',
          },
        ]
      case InvoicePaymentStatus.OVERPAID:
        return [
          {
            id: 1,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
          {
            id: 2,
            btnName: 'Faire un avoir',
            icon: <Redeem color="primary" />,
            action: (row: InvoiceDto) => setPaymentInvoice(row),
            actionKey: 'paymentInvoice',
          },
          {
            id: 3,
            btnName: 'Historique paiements',
            icon: <PaymentsIcon color="primary" />,
            action: (row: InvoiceDto) =>
              row.patientPayments.length > 0 && setOpenPatientPayments(row),
            actionKey: 'viewPaymentsInvoice',
          },
        ]
      case InvoicePaymentStatus.FINALIZED:
        return [
          {
            id: 1,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
          {
            id: 2,
            btnName: 'Historique paiements',
            icon: <PaymentsIcon color="primary" />,
            action: (row: InvoiceDto) =>
              row.patientPayments.length > 0 && setOpenPatientPayments(row),
            actionKey: 'viewPaymentsInvoice',
          },
        ]
      case InvoicePaymentStatus.NEED_DUNNING_NOTICE:
        return [
          {
            id: 1,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
          {
            id: 2,
            renderCell: (row: InvoiceDto) => (
              <DunningNoticePdfCellRenderer row={row} />
            ),
            actionKey: 'generateDunningNoticeInvoice',
          },
          {
            id: 3,
            btnName: 'Noter comme envoyée',
            icon: <MarkEmailReadIcon color="primary" />,
            action: (row: InvoiceDto) => setDunningNoticeSendForm(row),
            actionKey: 'dunningNoticeSendInvoice',
          },
          {
            id: 4,
            btnName: 'Noter comme perte',
            icon: <DoDisturbOffIcon color="primary" />,
            action: (row: InvoiceDto) => setLossForm(row),
            actionKey: 'lossInvoice',
          },
        ]
      default:
        return []
    }
  }, [filters.entity, filters.status, documentConfig, myCashRegister])

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width:
        filters.status === InvoicePaymentStatus.FINALIZED
          ? 100
          : filters.status === InvoicePaymentStatus.OVERPAID
          ? 140
          : 175,
      sortable: false,
      renderCell: (params) => {
        const invoice: InvoiceDto = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              const actionDisabled =
                el.actionKey === 'viewPaymentsInvoice' &&
                invoice.patientPayments.length === 0
              acc.push(
                <Tooltip key={uid.uid()} title={el.btnName}>
                  {el.renderCell ? (
                    el.renderCell(invoice)
                  ) : (
                    <div
                      className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                        actionDisabled ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation()
                        const action = el.action.bind({})
                        action(invoice)
                      }}
                    >
                      {el.icon}
                    </div>
                  )}
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Numéro facture',
      width: 130,
    },
    {
      field: 'date',
      headerName: 'Date de création',
      valueGetter: ({ row }) =>
        moment(row.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 140,
    },
    {
      field: 'patientName',
      headerName: 'Patient',
      valueGetter: ({ row }) =>
        `${row.patient.firstName ?? ''} ${row.patient.lastName ?? ''}`,
      width: 140,
    },
    {
      field: 'patientBirthDate',
      headerName: 'Date de naissance',
      valueGetter: ({ row }) =>
        moment(row.patient.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 140,
    },
    ...(filters.status &&
    [
      InvoicePaymentStatus.NEED_DUNNING_NOTICE,
      InvoicePaymentStatus.TO_BE_PAID,
    ].includes(filters.status)
      ? [
          {
            field: 'amountToPay',
            headerName: 'Reste à payer',
            valueGetter: ({ row }) =>
              `${row.patient_total - row.patient_payed} €`,
            width: 120,
          },
        ]
      : []),
    ...(filters.status === InvoicePaymentStatus.NEED_DUNNING_NOTICE
      ? [
          {
            field: 'dunningNoticesLevel',
            headerName: 'Niveau de relance',
            valueGetter: ({ row }) =>
              DunningNoticeNextLevel[row.nextDunningNoticeLevelPatient],
            width: 140,
          },
        ]
      : []),
    ...(filters.status === InvoicePaymentStatus.OVERPAID
      ? [
          {
            field: 'leftToRefund',
            headerName: 'Reste à rembourser',
            valueGetter: ({ row }) => `${row.leftToRefund} €`,
            width: 160,
          },
        ]
      : []),
    ...(filters.status === InvoicePaymentStatus.FINALIZED
      ? [
          {
            field: 'total',
            headerName: 'Total',
            valueGetter: ({ row }) => `${row.patient_total} €`,
            width: 100,
          },
          {
            field: 'loss',
            headerName: 'Notée comme perte',
            valueGetter: ({ row }) => (row.patient_state === 5 ? 'Oui' : 'Non'),
            width: 150,
          },
        ]
      : []),
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 120px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={loading ? [] : invoices.datas}
                rowCount={loading ? 0 : invoices.totalCount}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSize={20}
                onPageChange={(page) => {
                  dispatch(setInvoiceFilters({ page }))
                }}
                components={{
                  Toolbar: InvoicesTableToolbar,
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={!!paymentInvoice}
        onClose={() => setPaymentInvoice(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullScreen
      >
        {paymentInvoice && (
          <DialogContent>
            <PatientPaymentForm
              paymentCase={
                paymentInvoice?.patient_state === 3
                  ? TransactionType.REFUND
                  : TransactionType.PAYMENT
              }
              invoiceToPay={paymentInvoice}
              onClose={() => setPaymentInvoice(null)}
            />
          </DialogContent>
        )}
      </Dialog>

      <Dialog
        open={!!openPatientPayments}
        onClose={() => setOpenPatientPayments(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {openPatientPayments && (
            <>
              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
                  onClick={() => setOpenPatientPayments(null)}
                >
                  Fermer
                </button>
              </div>
              <PatientPaymentsTable invoice={openPatientPayments} />
            </>
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title="Relance envoyée"
        message="Voulez-vous vraiment noter cette relance comme envoyée ?"
        open={!!dunningNoticeSendForm}
        onConfirm={() => {
          if (dunningNoticeSendForm) {
            dispatch(saveDunningNotice({ invoiceId: dunningNoticeSendForm.id }))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'La relance a été enregistrée avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => {
                setDunningNoticeSendForm(null)
                dispatch(getInvoices())
              })
          }
        }}
        onCancel={() => {
          setDunningNoticeSendForm(null)
        }}
      />

      <ConfirmDialog
        title="Perte"
        message={
          'Voulez-vous vraiment noter cette facture en "perte" ? Elle sera classée directement dans l\'historique.'
        }
        open={!!lossForm}
        onConfirm={() => {
          if (lossForm) {
            dispatch(
              updateInvoice({
                id: lossForm.id,
                dto: { patientPartLoss: true },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'La facture a été notée en "perte" avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => {
                setLossForm(null)
                dispatch(getInvoices())
              })
          }
        }}
        onCancel={() => {
          setLossForm(null)
        }}
      />
    </Container>
  )
}
