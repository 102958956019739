import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Button, MenuItem, TextField } from '@mui/material'
import { FindVisitQuery } from '@services/dtos'
import { setVisitFilters } from '@state/reducers/visitReducer'
import { findAll as findSites } from '@state/thunks/siteThunk'
import { debounce } from 'lodash'
import React, { useEffect } from 'react'

type Props = {
  onAddVisit: () => void
}

const VisitTableToolbar: React.FC<Props> = ({ onAddVisit }) => {
  const dispatch = useAppDispatch()

  // ============================================= State =============================================
  const sites = useAppSelector(({ site }) => site.sites)

  const updateFilter = debounce((filter: FindVisitQuery) => {
    dispatch(setVisitFilters(filter))
  }, 500)

  useEffect(() => {
    dispatch(findSites())
  }, [])

  const handleFilterChange = (filter: FindVisitQuery) => {
    updateFilter(filter)
  }

  return (
    <div className="flex flex-row justify-between">
      <Button variant="outlined" color="primary" onClick={onAddVisit}>
        <i className="fas fa-plus"></i> <span className="ml-2">Ajouter</span>
      </Button>
      <div className="flex flex-row gap-x-4">
        <TextField
          label="Rechercher"
          variant="outlined"
          onChange={(e) => handleFilterChange({ search: e.target.value })}
          size="small"
        />
        <TextField
          label="Site"
          select
          className="w-64"
          size="small"
          onChange={(e) =>
            handleFilterChange({ siteId: parseInt(e.target.value, 10) })
          }
        >
          {sites.datas.map((site) => (
            <MenuItem key={site.id} value={site.id}>
              {site.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div />
    </div>
  )
}

export default VisitTableToolbar
