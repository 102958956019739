import { FC, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { doDeleteALD, doFetchALD } from '../state/reducers/aldReducer'
import { PatientAldDTO } from '../services/dtos'
import {
  Tab,
  Tabs,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Tooltip,
} from '@mui/material'
import ALDForm from '../containers/app/billing/form/ALDForm'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import ConfirmDialog from './dialogs/ConfirmDialog'
import { doFindOrders } from '../state/reducers/orderReducer'

type Props = {
  patientId: number
}
const PatientAldList: FC<Props> = ({ patientId }) => {
  const dispatch = useAppDispatch()

  const { list } = useAppSelector((state) => state.ald)
  const [activeAldTab, setActiveAldTab] = useState<string | null>(null)
  const [deletingALD, setDeletingALD] = useState<string | null>(null)

  useEffect(() => {
    dispatch(doFetchALD(patientId))
  }, [patientId])

  useEffect(() => {
    if (list.length > 0 && !activeAldTab) {
      setActiveAldTab(list[0].id)
      console.log('list', list)
    }
  }, [list.length])

  const activeAld = useMemo(
    () => list.find((ald) => ald.id === activeAldTab),
    [list, activeAldTab],
  )

  const handleDeleteALD = () => {
    if (deletingALD) {
      setActiveAldTab(null)
      dispatch(doDeleteALD(deletingALD))
        .unwrap()
        .then(() => {
          enqueueSnackbar('ALD supprimé avec succès', { variant: 'success' })
          dispatch(doFindOrders())
        })
        .finally(() => setDeletingALD(null))
    }
  }

  return (
    <div>
      <Box
        sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider', mb: 4 }}
      >
        <Tabs
          onChange={(_, value) => setActiveAldTab(value)}
          value={activeAldTab}
          sx={{
            '& .Mui-selected': {
              color: 'primary.main',
              fontWeight: 'bold',
            },
          }}
        >
          {list.map((ald) => {
            let label = ''
            if (ald.libelle) {
              label = ald.libelle
            } else if (ald.comment) {
              label = 'Commentaire'
            }

            const displayLabel =
              label.length > 20 ? label.substring(0, 20) + '...' : label
            return (
              <Tab
                key={ald.id}
                label={
                  <Tooltip
                    title={label === 'Commentaire' ? ald.comment : label}
                  >
                    <span
                      className={
                        activeAldTab === ald.id
                          ? 'text-primary font-bold text-md'
                          : 'text-black'
                      }
                    >
                      {displayLabel}
                    </span>
                  </Tooltip>
                }
                value={ald.id}
              />
            )
          })}
        </Tabs>
        <Button
          onClick={() => setActiveAldTab('add')}
          sx={{
            minHeight: 48,
            textTransform: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          + Ajouter
        </Button>
      </Box>
      {activeAld && (
        <PatientALD
          ald={activeAld}
          onDelete={() => setDeletingALD(activeAld.id)}
        />
      )}
      {activeAldTab === 'add' && (
        <ALDForm
          patientId={patientId}
          onClose={(dto) => setActiveAldTab(dto?.id || null)}
        />
      )}
      <ConfirmDialog
        open={!!deletingALD}
        title="Suppression ALD"
        message="Voulez-vous vraiment supprimer cet ALD ?"
        onCancel={() => setDeletingALD(null)}
        onConfirm={handleDeleteALD}
      />
    </div>
  )
}

type PatientALDProps = {
  ald: PatientAldDTO
  onDelete: () => void
}
export const PatientALD = ({ ald, onDelete }: PatientALDProps) => {
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <ALDForm
        patientId={ald.patientId}
        editingAld={ald}
        onClose={() => setIsEditing(false)}
      />
    )
  }

  return (
    <Card variant="outlined" sx={{ margin: 2, padding: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="div">
              ALD Information
            </Typography>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsEditing(true)}
                sx={{ mr: 1 }}
              >
                Modifier
              </Button>
              <Button variant="outlined" color="error" onClick={onDelete}>
                Supprimer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Code:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{ald.code}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Libellé:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{ald.libelle}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Date Fin:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {ald.dateFin ? moment(ald.dateFin).format('DD/MM/YYYY') : ''}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Source:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{ald.source}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Créé par:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {ald.createdBy.firstName} {ald.createdBy.lastName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Date de création:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {moment(ald.createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Code CIM:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{ald.cimCode}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary">Commentaire:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{ald.comment}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PatientAldList
