import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FindPatientsQuery, PatientDto } from '@services/api'
import { BillingService } from '@services/BillingService'
import { DocumentDTO, InvoiceDto } from '@services/dtos'
import { PaginatedDto } from '@services/extendedType'
import {
  PatientMergeHistoryDto,
  PatientServiceV2,
} from '@services/PatientService'
import { updatePatient } from '@state/thunks/authThunk'
import {
  doCreatePatient,
  doFetchMergeHistory,
  fetchOnePatientById,
  fetchPatients,
  doGetCouverture,
  findInvoices,
} from '@state/thunks/patientsThunk'
import { CouvertureDto } from 'src/common/interfaces'

export type PatientsState = {
  patientsIds: number[]
  patients: PatientDto[]
  patientsFilter: FindPatientsQuery
  currentPatientId: number
  currentPatientInfo: PatientDto | null
  patientCouverture: CouvertureDto | null
  mergeHistories: PatientMergeHistoryDto[]
  patientCoverage: CouvertureDto | null
  // TODO: move this somewhere that makes sense
  patientCardReaderId: string | null
  documents: DocumentDTO[]
  loadingInvoices: boolean
}

const initialState: PatientsState = {
  patientsIds: [],
  patients: [],
  currentPatientId: 0,
  currentPatientInfo: null,
  patientCouverture: null,
  patientsFilter: {
    limit: 20,
    page: 1,
    siteId: 1,
  },
  mergeHistories: [],
  patientCoverage: null,
  patientCardReaderId: null,
  documents: [],
  loadingInvoices: false,
}

export const doCreateCoverege = createAsyncThunk<
  CouvertureDto,
  {
    patientId: number
    dto: CouvertureDto
  }
>('patients/createCoverage', async ({ patientId, dto }) => {
  const response = await BillingService.createCouverture(patientId, dto)
  return response
})

export const doUpdateCouverture = createAsyncThunk<
  CouvertureDto,
  { id: number; dto: CouvertureDto }
>('patients/updateCoverage', async ({ id, dto }) => {
  const response = await BillingService.updateCouverture(id, dto)
  return response
})

export const doGetPatientDocuments = createAsyncThunk<DocumentDTO[], number>(
  'patients/getDocuments',
  async (pId, { rejectWithValue }) => {
    try {
      return await PatientServiceV2.getDocuments(pId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<PatientDto>) => {
      state.patientsIds.push(action.payload.id)
    },
    getPatient: (state, action: PayloadAction<number[]>) => {
      state.currentPatientId = action.payload[0]
      state.patientsIds = action.payload.map((patientId: number) => patientId)
    },
    getPatientById: (state, action: PayloadAction<number>) => {
      state.currentPatientId = action.payload
    },
    setCouverture: (state, action: PayloadAction<CouvertureDto | null>) => {
      state.patientCouverture = action.payload
    },
    setPatients: (state, action: PayloadAction<PatientDto[]>) => {
      state.patients = action.payload
    },
    setPatientsFilter: (state, action: PayloadAction<FindPatientsQuery>) => {
      state.patientsFilter = { ...state.patientsFilter, ...action.payload }
    },
    setReaderId: (state, action: PayloadAction<string>) => {
      state.patientCardReaderId = action.payload
    },
    setPatientDetails: (state, action: PayloadAction<PatientDto | null>) => {
      state.currentPatientInfo = action.payload
    },
    setPatientDocuments: (state, action: PayloadAction<DocumentDTO[]>) => {
      state.documents = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPatients.fulfilled,
      (state, { payload }: PayloadAction<PaginatedDto<PatientDto>>) => {
        state.patients = payload.datas
      },
    )
    builder.addCase(fetchOnePatientById.fulfilled, (state, action) => {
      state.currentPatientInfo = action.payload as PatientDto
    })
    builder.addCase(doGetCouverture.fulfilled, (state, action) => {
      state.patientCouverture = action.payload
    })
    builder.addCase(updatePatient.fulfilled, (state, action) => {
      state.currentPatientInfo = action.payload
    })
    builder.addCase(doFetchMergeHistory.fulfilled, (state, action) => {
      state.mergeHistories = action.payload
    })
    builder.addCase(doCreatePatient.fulfilled, (state, action) => {
      state.patients.push(action.payload)
    })
    builder.addCase(doGetPatientDocuments.fulfilled, (state, action) => {
      state.documents = action.payload
    })
    builder.addCase(doUpdateCouverture.fulfilled, (state, action) => {
      state.patientCouverture = action.payload
    })
    builder.addCase(findInvoices.pending, (state) => {
      state.loadingInvoices = true
    })
    builder.addCase(findInvoices.rejected, (state) => {
      state.loadingInvoices = false
    })
    builder.addCase(
      findInvoices.fulfilled,
      (
        state,
        { payload, meta }: PayloadAction<InvoiceDto[], string, { arg: number }>,
      ) => {
        const patientId = meta.arg
        const patientIndex = state.patients.findIndex(
          ({ id }) => +patientId === id,
        )
        if (patientIndex !== -1) {
          state.patients[patientIndex].invoices = payload
        }
        state.loadingInvoices = false
      },
    )
  },
})

export const {
  addPatient,
  getPatient,
  setCouverture,
  setPatientsFilter,
  setPatients,
  setReaderId,
  setPatientDetails,
  setPatientDocuments,
} = patientsSlice.actions

export default patientsSlice.reducer
