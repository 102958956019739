import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CashRegisterDTO,
  CashRegisterLogDTO,
  CashRegisterTransactionDTO,
  CloseCashRegisterDTO,
  CreateCashRegisterDTO,
  CreateCashRegisterTransactionDto,
  ListCashRegisterDTO,
  ListCashRegisterLogDTO,
  OpenCashRegisterDTO,
  UpdateCashRegisterDTO,
} from '../../services/dtos/AccountingV2Dto'
import { CashRegisterService } from '@services/cashRegisterService'
import { PaginatedDto } from '@/services/extendedType'
import { PaginatedQuery } from '@/services/api'

export interface CashRegisterTransactionsFilters extends PaginatedQuery {
  from?: string
  to?: string
  cashRegisterId?: number
  patientId?: number
}

export interface CashRegisterState {
  tab: 0 | 1
  mine: CashRegisterDTO | null
  list: CashRegisterDTO[]
  transactionList: PaginatedDto<CashRegisterTransactionDTO>
  filters: ListCashRegisterDTO
  logsFilters: PaginatedQuery
  transactionFilters: CashRegisterTransactionsFilters
  transactions: CashRegisterTransactionDTO[]
  logs: PaginatedDto<CashRegisterLogDTO>
  logFilter: ListCashRegisterLogDTO
  loading: boolean
  logsLoading: boolean
  transactionsLoading: boolean
}

export const initialCashRegisterState: CashRegisterState = {
  tab: 0,
  mine: null,
  list: [],
  transactionList: {
    datas: [],
    totalCount: 0,
  },
  logsFilters: {},
  filters: {},
  transactionFilters: {},
  transactions: [],
  logs: {
    datas: [],
    totalCount: 0,
  },
  logFilter: {},
  loading: false,
  logsLoading: false,
  transactionsLoading: false,
}

export const getCashRegisters = createAsyncThunk<CashRegisterDTO[], void>(
  'cash-registers/find',
  async (_, { getState }) => {
    const { filters } = (getState() as { cashRegister: CashRegisterState })
      .cashRegister
    return CashRegisterService.getAll(filters)
  },
)

export const getLogs = createAsyncThunk<
  PaginatedDto<CashRegisterLogDTO>,
  number
>('cash-registers/find/logs', async (id, { getState }) => {
  const { logsFilters } = (getState() as { cashRegister: CashRegisterState })
    .cashRegister
  return CashRegisterService.getLogs(id, logsFilters)
})

export const getMyCashRegister = createAsyncThunk<CashRegisterDTO | null, void>(
  'cash-registers/find/current',
  async () => CashRegisterService.getMine(),
)

export const getCashRegisterTransactions = createAsyncThunk<
  PaginatedDto<CashRegisterTransactionDTO>,
  void
>('cash-registers/transactions/find', async (_, { getState }) => {
  const { transactionFilters } = (
    getState() as { cashRegister: CashRegisterState }
  ).cashRegister
  return CashRegisterService.getAllTransactions(transactionFilters)
})

export const createCashRegister = createAsyncThunk<
  CashRegisterDTO,
  CreateCashRegisterDTO
>('cash-registers/create', async (dto) => CashRegisterService.create(dto))

export const createCashRegisterTransaction = createAsyncThunk<
  CashRegisterTransactionDTO,
  { id: number; dto: CreateCashRegisterTransactionDto }
>('cash-registers/transaction/create', async ({ id, dto }) =>
  CashRegisterService.createTransaction(id, dto),
)

export const updateCashRegister = createAsyncThunk<
  CashRegisterDTO,
  { id: number; dto: UpdateCashRegisterDTO }
>('cash-registers/update', async ({ id, dto }) =>
  CashRegisterService.update(id, dto),
)

export const removeCashRegister = createAsyncThunk<void, number>(
  'cash-registers/remove',
  async (id) => CashRegisterService.remove(id),
)

export const openCashRegister = createAsyncThunk<
  CashRegisterLogDTO,
  { id: number; dto: OpenCashRegisterDTO }
>('cash-registers/open', async ({ id, dto }) =>
  CashRegisterService.open(id, dto),
)

export const closeCashRegister = createAsyncThunk<
  CashRegisterLogDTO,
  { id: number; dto: CloseCashRegisterDTO }
>('cash-registers/close', async ({ id, dto }) =>
  CashRegisterService.close(id, dto),
)

export const cashRegisterReducer = createSlice({
  name: 'cashRegister',
  initialState: initialCashRegisterState,
  reducers: {
    setTransactionFilters: (
      state,
      action: PayloadAction<CashRegisterTransactionsFilters>,
    ) => {
      state.transactionFilters = {
        ...state.transactionFilters,
        page: 0,
        ...action.payload,
      }
    },
    setLogFilters: (state, action: PayloadAction<PaginatedQuery>) => {
      state.logsFilters = {
        ...state.logsFilters,
        page: 0,
        ...action.payload,
      }
    },
    setTab: (state, action: PayloadAction<0 | 1>) => {
      state.tab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCashRegisters.pending, (state) => {
        state.loading = true
      })
      .addCase(getCashRegisters.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCashRegisters.fulfilled, (state, action) => {
        state.list = action.payload
        state.loading = false
      })
      .addCase(getMyCashRegister.fulfilled, (state, action) => {
        state.mine = action.payload
      })
      .addCase(getCashRegisterTransactions.pending, (state) => {
        state.transactionsLoading = true
      })
      .addCase(getCashRegisterTransactions.rejected, (state) => {
        state.transactionsLoading = false
      })
      .addCase(getCashRegisterTransactions.fulfilled, (state, action) => {
        state.transactionList = action.payload
        state.transactionsLoading = false
      })
      .addCase(getLogs.pending, (state) => {
        state.logsLoading = true
      })
      .addCase(getLogs.rejected, (state) => {
        state.logsLoading = false
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.logs = action.payload
        state.logsLoading = false
      })
      .addCase(createCashRegister.fulfilled, (state, action) => {
        state.list.unshift(action.payload)
      })
      .addCase(updateCashRegister.fulfilled, (state, action) => {
        state.list = state.list.map((cashRegister) =>
          cashRegister.id === action.payload.id ? action.payload : cashRegister,
        )
      })
      .addCase(removeCashRegister.fulfilled, (state, action) => {
        state.list = state.list.filter(
          (cashRegister) => cashRegister.id !== action.meta.arg,
        )
      })
  },
})

export default cashRegisterReducer.reducer

export const { setTransactionFilters, setLogFilters, setTab } =
  cashRegisterReducer.actions
