import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { IconButton, Popover, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import 'moment/locale/fr'
import { Button } from './buttons/Button'

export interface DateRange {
  startDate: Date
  endDate: Date
}

export interface DateFilterComponentProps {
  dateRange: DateRange | null
  onDateRangeChange: (dateRange: DateRange) => void
  disabled?: boolean
}

type PresetKey =
  | 'yesterday'
  | 'tomorrow'
  | '-1w'
  | '-2w'
  | '+1w'
  | '+2w'
  | 'custom'
  | 'today'

const presetButtons = [
  { label: 'Aujourd\'hui', days: 0, preset: 'today' as PresetKey },
  { label: 'Hier', days: -1, preset: 'yesterday' as PresetKey },
  { label: '-1 Semaine', days: -7, preset: '-1w' as PresetKey },
  { label: '-2 Semaines', days: -14, preset: '-2w' as PresetKey },
  { label: 'Demain', days: 1, preset: 'tomorrow' as PresetKey },
  { label: '+1 Semaine', days: 7, preset: '+1w' as PresetKey },
  { label: '+2 Semaines', days: 14, preset: '+2w' as PresetKey },
]

const DateFilterComponent: React.FC<DateFilterComponentProps> = ({
  dateRange,
  onDateRangeChange,
  disabled,
}) => {
  const getInitialPreset = (range: DateRange | null): PresetKey => {
    if (!range) {
      return 'today'
    }
    const start = moment(range.startDate).startOf('day')
    const end = moment(range.endDate).startOf('day')
    const today = moment().startOf('day')

    for (const button of presetButtons) {
      if (button.days === 0 && start.isSame(today) && end.isSame(today)) {
        return 'today'
      }
      if (
        button.days === 1 &&
        start.isSame(today.clone().add(1, 'days')) &&
        end.isSame(today.clone().add(1, 'days'))
      ) {
        return 'tomorrow'
      }
      if (
        button.days === -1 &&
        start.isSame(today.clone().add(-1, 'days')) &&
        end.isSame(today.clone().add(-1, 'days'))
      ) {
        return 'yesterday'
      }
      if (button.days < 0) {
        if (
          start.isSame(today.clone().add(button.days, 'days')) &&
          end.isSame(today)
        ) {
          return button.preset
        }
      } else if (button.days > 0) {
        if (
          start.isSame(today) &&
          end.isSame(today.clone().add(button.days, 'days'))
        ) {
          return button.preset
        }
      }
    }

    return 'custom'
  }

  const [localDateRange, setLocalDateRange] = useState<DateRange>(
    dateRange || {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    },
  )
  const [activePreset, setActivePreset] = useState<PresetKey | null>(() =>
    dateRange ? getInitialPreset(dateRange) : null,
  )
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (dateRange) {
      setLocalDateRange(dateRange)
      setActivePreset(getInitialPreset(dateRange))
    } else {
      setActivePreset(null)
    }
  }, [dateRange])

  const handleDateChange = (date: moment.Moment | null, isStart: boolean) => {
    if (date) {
      const newDate = isStart
        ? date.startOf('day').toDate()
        : date.endOf('day').toDate()
      const newDateRange = isStart
        ? { ...localDateRange, startDate: newDate }
        : { ...localDateRange, endDate: newDate }
      setLocalDateRange(newDateRange)
      onDateRangeChange(newDateRange)
      setActivePreset('custom')
    }
  }

  const handlePresetRange = (days: number, preset: PresetKey) => {
    const today = moment()
    let newDateRange: DateRange

    if (days === -1 || days === 1) {
      const targetDate = today.clone().add(days, 'days')
      newDateRange = {
        startDate: targetDate.startOf('day').toDate(),
        endDate: targetDate.endOf('day').toDate(),
      }
    } else {
      newDateRange =
        days > 0
          ? {
              startDate: today.startOf('day').toDate(),
              endDate: today.clone().add(days, 'days').endOf('day').toDate(),
            }
          : {
              startDate: today
                .clone()
                .add(days, 'days')
                .startOf('day')
                .toDate(),
              endDate: today.endOf('day').toDate(),
            }
    }

    setLocalDateRange(newDateRange)
    onDateRangeChange(newDateRange)
    setActivePreset(preset)
  }

  const getDisplayText = () => {
    if (!dateRange) {
      return 'Sélectionner une date'
    }

    if (activePreset !== 'custom') {
      return (
        presetButtons.find((btn) => btn.preset === activePreset)?.label ||
        'Personnalisé'
      )
    }
    return `${moment(localDateRange.startDate).format('D MMM YYYY')} - ${moment(
      localDateRange.endDate,
    ).format('D MMM YYYY')}`
  }

  const displayText = getDisplayText()

  return (
    <div className="flex items-center space-x-2">
      <Button
        size="small"
        disabled={disabled}
        text={displayText}
        color="inherit"
        textColor="white"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ backgroundColor: '#3F3F3F', height: 36, fontSize: '14px' }}
        className="border border-1 px-2 py-4 rounded-md flex flex-row items-center hover:!bg-black"
        startIcon={<i className="fas fa-calendar-alt mr-2 text-white"></i>}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="p-4 space-y-4">
          <div className="flex justify-between items-center">
            <Typography variant="h6">Modifier la plage de dates</Typography>
            <IconButton size="small" onClick={() => setAnchorEl(null)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="flex space-x-4">
            <DatePicker
              label="Date de début"
              value={moment(localDateRange.startDate) as any}
              onChange={(date) => handleDateChange(date, true)}
              className="w-full"
            />
            <DatePicker
              label="Date de fin"
              value={moment(localDateRange.endDate) as any}
              onChange={(date) => handleDateChange(date, false)}
              className="w-full"
            />
          </div>
          <div className="flex flex-wrap gap-2">
            {presetButtons.map((button) => (
              <button
                key={button.preset}
                onClick={() => handlePresetRange(button.days, button.preset)}
                className={`
                  border border-gray-300 rounded p-1  cursor-pointer
                  ${
                    activePreset === button.preset
                      ? 'bg-[#3A0CA1] text-white'
                      : 'text-gray-700'
                  }
                  `}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default DateFilterComponent
