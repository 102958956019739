import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Grid, Switch, Typography } from '@mui/material'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import {
  CashRegisterDTO,
  CreateCashRegisterDTO,
} from '@/services/dtos/AccountingV2Dto'
import { cashRegisterSchema } from '@utils/schemas'
import { findAll as findSites } from '@state/thunks/siteThunk'
import InputField from '../inputs/InputField'
import SelectField from '../inputs/SelectField'
import {
  createCashRegister,
  updateCashRegister,
} from '@state/reducers/cashRegisterReducer'

type Props = {
  editingCashRegister?: CashRegisterDTO | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const CashRegisterForm: React.FC<Props> = ({
  editingCashRegister,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateCashRegisterDTO>({
    defaultValues: editingCashRegister
      ? {
          active: editingCashRegister.active,
          balance: editingCashRegister.balance,
          code: editingCashRegister.code,
          location: editingCashRegister.location,
          name: editingCashRegister.name,
          siteId: editingCashRegister.site?.id,
        }
      : {},
    resolver: yupResolver(cashRegisterSchema),
  })

  const dispatch = useAppDispatch()
  const { sites } = useAppSelector(({ site }) => ({
    sites: site.sites,
  }))

  const siteId = watch('siteId')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    dispatch(findSites())
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreateCashRegisterDTO) => {
    setIsSubmitting(true)
    if (editingCashRegister) {
      dispatch(updateCashRegister({ id: editingCashRegister.id, dto: data }))
        .unwrap()
        .then(async () => cb('La caisse a été modifiée avec succès'))
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(createCashRegister(data))
        .unwrap()
        .then(async () => cb('La caisse a été créée avec succès'))
        .finally(() => setIsSubmitting(false))
    }
  }

  const fields: {
    name: 'name' | 'code' | 'location'
    label: string
  }[] = [
    { name: 'code', label: 'Identifiant court' },
    { name: 'name', label: 'Nom' },
    {
      name: 'location',
      label: 'Localisation',
    },
  ]

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Configuration</h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingCashRegister ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <div className="max-w-screen-sm mx-auto mt-4">
          <fieldset className="border border-gray-200 p-4 rounded-lg">
            <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
              Caisse
            </legend>

            <Grid container rowSpacing={2} columnSpacing={4} mt={1}>
              {fields.map(({ name, label }) => (
                <Grid item xs={12} sm={6} key={name}>
                  <InputField control={control} name={name} label={label} />
                </Grid>
              ))}
              <Grid item xs={12} sm={6}>
                {sites.datas.length > 0 ? (
                  <SelectField
                    control={control}
                    name="siteId"
                    options={sites.datas.map(({ id, label }) => ({
                      label,
                      value: id,
                    }))}
                    value={siteId}
                    label="Sites"
                  />
                ) : (
                  <Typography variant="body1">Aucun site enregistré</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField label="En service" error={errors.active?.message}>
                  <Switch
                    {...register('active')}
                    defaultChecked={editingCashRegister?.active ?? true}
                    color="primary"
                  />
                </FormField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputField
                  control={control}
                  name="balance"
                  label="Solde actuel"
                  type="number"
                />
              </Grid>
            </Grid>
          </fieldset>
        </div>
      </form>
    </div>
  )
}

export default CashRegisterForm
