import moment from 'moment'
import { axios, FindPatientsQuery, getConfigs, IRequestConfig, MemberDto, PatientDto } from './api'
import { DocumentDTO } from './dtos'
import { PaginatedDto } from './extendedType'

export interface PatientMergeHistoryDto {
  id: number
  sourcePatient: PatientDto
  targetPatient: PatientDto
  createdAt: Date
  updatedAt: Date
  mergedBy: MemberDto
  reversedBy: MemberDto
  reversedAt: Date
}

export class PatientServiceV2 {
  private static readonly endpoint = 'patient'

  static findMany(query: FindPatientsQuery): Promise<PaginatedDto<PatientDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/v2`,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static mergePatient(
    sourcePatientId: number,
    targetPatientId: number,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${sourcePatientId}/merge`,
        {},
      )
      configs.data = {
        targetPatientId,
      }
      axios(configs, resolve, reject)
    })
  }

  static cancelPatientMerge(pId: number, mergeId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${pId}/cancel-merge/${mergeId}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static findMergeHistory(
    patientId: number,
  ): Promise<PatientMergeHistoryDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/${patientId}/merge-history`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static getDocuments(patientId: number): Promise<DocumentDTO[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/${patientId}/get-documents`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  public static getIdentifier(ssn: string, firstName: string, lastName: string, birthDate: string): string {
    let identifier = ''
    const ssnWithoutSpaces = ssn.replace(/\s/g, '').trim()
    if (ssnWithoutSpaces && firstName && lastName && birthDate) {
      const formattedBirthDate = `${birthDate.substring(6, 8)}-${birthDate.substring(4, 6)}-${birthDate.substring(0, 4)}`
      identifier = `${ssnWithoutSpaces}-${firstName.toLowerCase()}-${lastName.toLowerCase()}-${formattedBirthDate}`
    }
    return identifier
  }
}
