import { useIframeMessages } from '../../../hooks/useIframeMessages'

export const Scheduling = () => {
  const { registerIframe } = useIframeMessages()
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        style={{ width: '100%', height: '100%' }}
        src={process.env.REACT_APP_SCHEDULING_URL}
        onLoad={() => registerIframe(window)}
      ></iframe>
    </div>
  )
}

export default Scheduling
