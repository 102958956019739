import {
  AgeRangeEnum,
  BodyPartEnum,
  ModalityEnum,
  SexEnum,
} from '@services/api'
import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'
import { pathwayhealth } from './constants'
import { validate } from 'french-ssn'
import { PaymentModeType } from '@services/dtos'
import moment from 'moment'

// const RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const signFailSchema = Yup.object().shape({
  failure: Yup.number().required('validation.field.required'),
  period: Yup.number().required('validation.field.required'),
  window: Yup.number().required('validation.field.required'),
  timeout: Yup.number().required('validation.field.required'),
})

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  password: Yup.string().required('validation.password.required'),
  // captcha: Yup.string().required('validation.password.required'),
})

export const forgottenPassowrdSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})
// export const registerSchema = Yup.object().shape({
//   email: Yup.string().required('email.required').email('email.invalid'),
//   phoneNumber: Yup.string()
//     .required('register.msg.error.phone.required')
//     .test('isPhone', 'register.msg.error.phone.format', (v) =>
//       isValidPhoneNumber(v)
//     ),
// })

export const forgotpasswordSchema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
})

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const verifyEmailSchema = Yup.object().shape({
  password: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const updateEmailSchema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
})

export const createDoctorSchema = Yup.object().shape({
  email: Yup.string().email('email.invalid').required('email.required'),
  phoneNumber: Yup.string()
    .test('isPhone', 'register.msg.error.phone.format', (v) =>
      isValidPhoneNumber(v || ''),
    )
    .required('register.msg.error.phone.required'),
})

export const phoneCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required('validation.field.required')
    .length(6)
    .matches(/^[0-9]{6}$/, 'validation.code.match'),
})

export const addClientSchema = Yup.object().shape({
  name: Yup.string().required('validation.field.required'),
  status: Yup.string().required('validation.field.required'),
  siretNumber: Yup.string()
    .required('validation.field.required')
    .length(9)
    .matches(/^[0-9]{9}$/, 'validation.code.codeEin'),
  // address: Yup.string().required('validation.field.required'),
  streetAddress: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  phoneNumber: Yup.string()
    .required('validation.field.required')
    .min(14, 'phone number is not valid'),
  fax: Yup.string(),
  website: Yup.string(),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const addStaffSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  phoneNumber: Yup.string()
    .required('validation.field.required')
    .min(14, 'phone number is not valid'),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const addMemberSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  password: Yup.string().required('validation.field.required'),
  email: Yup.string()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const addRoleSchema = Yup.object().shape({
  name: Yup.string().required('validation.field.required'),
  description: Yup.string().required('validation.field.required'),
  siteId: Yup.string().required('validation.field.required'),
})

export const addQuestionSchema = Yup.object().shape({
  label: Yup.string().required('validation.field.required'),
  question: Yup.string().required('validation.field.required'),
  siteId: Yup.string().required('validation.field.required'),
  questionType: Yup.string().required('validation.field.required'),
})

export const siteSchema = Yup.object().shape({
  code: Yup.string(),
  label: Yup.string().required('validation.field.required'),
  capacity: Yup.number().nullable().optional(),
  // siteTypeId: Yup.string().required('validation.field.required'),
  finessNumber: Yup.string().required('validation.field.required'),
  streetAddress: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  email: Yup.string().email('validation.email.invalid').nullable().optional(),
  phoneNumber: Yup.string().required('validation.field.required'),
  fax: Yup.string().nullable().optional(),
  website: Yup.string().nullable().optional(),
})

export const addressSchema = Yup.object().shape({
  line1: Yup.string().required('validation.field.required'),
  line2: Yup.string().optional(),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  locationName: Yup.string().optional(),
  locationType: Yup.string()
    .oneOf(['Clinique', 'Hospital', 'Etablissement'])
    .optional()
    .nullable(),
  finessNumber: Yup.string().optional(),
})

export const doctorSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('validation.field.required'),
    lastName: Yup.string().required('validation.field.required'),
    rppsNumber: Yup.string().optional(),
    skill: Yup.string(),
    profession: Yup.string().optional(),
    category: Yup.string().optional(),
    email: Yup.string().email().optional(),
    addresses: Yup.array(addressSchema),
    phoneNumber: Yup.string().nullable().optional(),
    mobileNumber: Yup.string().nullable().optional(),
  },
  [['email', 'emailPro']],
)

export const itEquipmentSchema = Yup.object().shape({
  siteId: Yup.string().required('validation.field.required'),
  ip: Yup.string().required('validation.field.required'),
  port: Yup.string().required('validation.field.required'),
})

export const medicalEquipmentSchema = Yup.object().shape({
  manufacturer: Yup.string().required('validation.field.required'),
  model: Yup.string().required('validation.field.required'),
  commissioningDate: Yup.date().required('validation.field.required'),
  examRoomId: Yup.string().required('validation.field.required'),
})

export const procedureSchema = Yup.object().shape({
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
  modality: Yup.mixed<ModalityEnum>().oneOf(Object.values(ModalityEnum)),
  procedureTime: Yup.string()
    .required('validation.field.required')
    .nullable()
    .optional(),
  sex: Yup.mixed<SexEnum>().oneOf(Object.values(SexEnum)).nullable().optional(),
  bodyPart: Yup.mixed<BodyPartEnum>()
    .oneOf(Object.values(BodyPartEnum))
    .nullable()
    .optional(),
  ageRange: Yup.mixed<AgeRangeEnum>()
    .oneOf(Object.values(AgeRangeEnum))
    .nullable()
    .optional(),

  // instruction: Yup.mixed<InstructionEnum>().oneOf(
  //   Object.values(InstructionEnum),
  // ),
})

export const examTypeSchema = Yup.object().shape({
  siteId: Yup.string().required('validation.field.required'),
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
})

export const updateDoctorSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('validation.field.required'),
    lastName: Yup.string().required('validation.field.required'),
    rppsNumber: Yup.string().required('validation.field.required'),
    city: Yup.string().required('validation.field.required'),
    skill: Yup.string(),
    profession: Yup.string(),
    category: Yup.string().required('validation.field.required'),
    country: Yup.string().required('validation.field.required'),
    website: Yup.string(),
    fax: Yup.string(),
    phoneNumber: Yup.string()
      .required('validation.field.required')
      .min(14, 'phone number is not valid'),

    // phoneNumber: Yup.string().required('validation.field.required')
    // .length(9)
    // .matches(/^[0-9]{9}$/, 'validation.code.codeEin'),

    email: Yup.string()
      .email('validation.email.invalid')
      //.required('validation.field.required'),
      .when('emailPro', {
        is: (emailPro: string) => !emailPro || emailPro.length === 0,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    emailPro: Yup.string()
      .email('validation.email.invalid')
      .when('email', {
        is: (email: string) => !email || email.length === 0,
        then: Yup.string().required('validation.field.required'),
        otherwise: Yup.string(),
      }),
  },
  [['email', 'emailPro']],
)

export const updateGeneralInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  sex: Yup.string().required('validation.field.required'),
  street: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  birthPlace: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  ssn: Yup.string().required('validation.field.required'),
})

export const addRoomSchema = Yup.object().shape({
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
  openingTime: Yup.date().required('validation.field.required'),
  breakTime: Yup.date().required('validation.field.required'),
  reopeningTime: Yup.date().required('validation.field.required'),
  closingTime: Yup.date().required('validation.field.required'),
  color: Yup.string().required('validation.field.required'),
})
export const addReceptionSchema = Yup.object().shape({
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
  siteId: Yup.string().required('validation.field.required'),
})
export const addWaitingRoomSchema = Yup.object().shape({
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
  receptionId: Yup.string().required('validation.field.required'),
})
export const addOfficeSchema = Yup.object().shape({
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
  siteId: Yup.string().required('validation.field.required'),
})
export const addPrepRoomSchema = Yup.object().shape({
  code: Yup.string().required('validation.field.required'),
  label: Yup.string().required('validation.field.required'),
  examRoomId: Yup.string().required('validation.field.required'),
})

export const pidSchema = Yup.object().shape({
  PID1: Yup.string().required('validation.field.required'),
  PID2: Yup.string().required('validation.field.required'),
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  motherMaidenName: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  sex: Yup.string().required('validation.field.required'),
  streetAddress: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  countryCode: Yup.string().required('validation.field.required'),
  // phoneNumber: Yup.string()
  //   .test('isPhone', 'register.msg.error.phone.format', (v) =>
  //     isValidPhoneNumber(v),
  //   )
  //   .required('register.msg.error.phone.required'),
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  accountNumber: Yup.string().required('validation.field.required'),
  systemName: Yup.string().required('validation.field.required'),
  ssn: Yup.string().required('validation.field.required'),
})

export const pv1Schema = Yup.object().shape({
  attendingdoctorId: Yup.string().required('validation.field.required'),
  attendingDoctorFirstName: Yup.string().required('validation.field.required'),
  attendingDoctorLastName: Yup.string().required('validation.field.required'),
  attendingDoctorPrefix: Yup.string().required('validation.field.required'),
  attendingdoctorIdType: Yup.string().required('validation.field.required'),
  referringdoctorId: Yup.string().required('validation.field.required'),
  referringDoctorFirstName: Yup.string().required('validation.field.required'),
  referringDoctorLastName: Yup.string().required('validation.field.required'),
  referringDoctorPrefix: Yup.string().required('validation.field.required'),
  referringdoctorIdType: Yup.string().required('validation.field.required'),
})

export const orcSchema = Yup.object().shape({
  placeOrderNumber: Yup.string().required('validation.field.required'),
  fillerOrderNumber: Yup.string().required('validation.field.required'),
  orderStatus: Yup.string().required('validation.field.required'),
  quantity: Yup.string().required('validation.field.required'),
  interval: Yup.string().required('validation.field.required'),
  priority: Yup.string().required('validation.field.required'),
  startDateTime: Yup.date().required('validation.field.required'),
  attendingdoctorId: Yup.string().required('validation.field.required'),
  attendingDoctorFirstName: Yup.string().required('validation.field.required'),
  attendingDoctorLastName: Yup.string().required('validation.field.required'),
  attendingDoctorPrefix: Yup.string().required('validation.field.required'),
  attendingdoctorIdType: Yup.string().required('validation.field.required'),
})

export const obrSchema = Yup.object().shape({
  OBRID: Yup.string().required('validation.field.required'),
  entityIdentifier: Yup.string().required('validation.field.required'),
  systemName: Yup.string().required('validation.field.required'),
  accessionNumber: Yup.string().required('validation.field.required'),
  studyCode: Yup.string().required('validation.field.required'),
  studyTitle: Yup.string().required('validation.field.required'),
  plannedDate: Yup.date().required('validation.field.required'),
  attendingdoctorId: Yup.string().required('validation.field.required'),
  attendingDoctorFirstName: Yup.string().required('validation.field.required'),
  attendingDoctorLastName: Yup.string().required('validation.field.required'),
  attendingDoctorPrefix: Yup.string().required('validation.field.required'),
  attendingdoctorIdType: Yup.string().required('validation.field.required'),
  modality: Yup.string().required('validation.field.required'),
  quantity: Yup.string().required('validation.field.required'),
  interval: Yup.string().required('validation.field.required'),
  priority: Yup.string().required('validation.field.required'),
  pointOfCare: Yup.string().required('validation.field.required'),
  room: Yup.string().required('validation.field.required'),
})

export const obxSchema = Yup.object().shape({
  OBXID: Yup.string().required('validation.field.required'),
  systemName: Yup.string().required('validation.field.required'),
  DOC: Yup.string().required('validation.field.required'),
  JPEG: Yup.string().required('validation.field.required'),
  Base64: Yup.string().required('validation.field.required'),
  PDFBase64: Yup.string().required('validation.field.required'),
})

export const zdsSchema = Yup.object().shape({
  studyInstanceUID: Yup.string().required('validation.field.required'),
  ApplicationID: Yup.string().required('validation.field.required'),
  Application: Yup.string().required('validation.field.required'),
  DICOM: Yup.string().required('validation.field.required'),
})

export const ambulanceCompanySchema = Yup.object()
  .shape({
    name: Yup.string().required('validation.field.required'),
    address: Yup.string().required('validation.field.required'),
    zipCode: Yup.number().required('validation.field.required'),
    city: Yup.string().required('validation.field.required'),
    contact: Yup.string().required('validation.field.required'),
    siret: Yup.string().optional().max(14),
    email: Yup.string()
      .email('validation.email.invalid')
      .required('validation.email.required'),
    siteIds: Yup.array(Yup.number()).required('validation.siteId.required'),
  })
  .shape({})

export const patientTransportSchema = Yup.object()
  .shape({
    name: Yup.string().required('validation.field.required'),
    phoneNumber: Yup.string().required('validation.field.required'),
    companyId: Yup.number().optional(),
    plateNumber: Yup.string().optional(),
    transportType: Yup.string().required('validation.field.required'),
  })
  .shape({})
export const patientGeneralnfoSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  gender: Yup.string().required('validation.field.required'),
  email: Yup.string().required('validation.field.required').email(),
  phoneNumber: Yup.string().required('validation.field.required'),
})
// TODO: approv new schemas and enum
export const BankSchema = Yup.object().shape({
  bankCode: Yup.string().required('Required').max(50, 'Maximum 50 characters'),
  bankLabel: Yup.string().required('Required').max(50, 'Maximum 50 characters'),
})
export const CashRegisterSchema = Yup.object().shape({
  cashRegisterCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  cashRegisterLabel: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  siteCode: Yup.string().required('Required').max(50, 'Maximum 50 characters'),
  logCode: Yup.string().max(50, 'Maximum 50 characters'),
  responsible: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  checkboxOpen: Yup.boolean(),
  checkboxClose: Yup.boolean(),
  startDate: Yup.string()
    .required('Required')
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
  endDate: Yup.string()
    .optional()
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
})
export enum MovementTypeEnum {
  'IN' = 'IN',
  'OUT' = 'OUT',
  'BOTH' = 'BOTH',
}
export const CashRegisterMovSchema = Yup.object().shape({
  cashRegisterCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  cashRegisterLabel: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  generationAccoutantWrite: Yup.boolean(),
  movementType: Yup.mixed<MovementTypeEnum>().oneOf(
    Object.values(MovementTypeEnum),
  ),
})
export enum PaymentModeTypeEnum {
  'CB' = 'CB',
  'CHQ' = 'CHQ',
  'E' = 'E',
  'G' = 'G',
  'PP' = 'PP',
}
export const PaymentModeSchema = Yup.object().shape({
  paymentModeCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  paymentModeLabel: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  paymentModeType: Yup.mixed<PaymentModeTypeEnum>().oneOf(
    Object.values(PaymentModeTypeEnum),
  ),
  accountSectionCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  checkboxBank: Yup.boolean(),
  checkboxCheck: Yup.boolean(),
  checkboxPatientBank: Yup.boolean(),
})
export enum FeesJournalCodeEnum {
  'BQCARD' = 'Banque honoraires cardio',
  'CA282_H' = 'Banque honoraires CA',
  'SG998_H' = 'Banque honoraires SG',
}
export enum relaunchTriggerWhoEnum {
  'BYNAME' = 'Par les Nom/Prénom de l\'assuré',
  'BYNUM' = 'Par le numéro de facture',
}
export enum relaunchTriggerWhenEnum {
  'BYCOME' = 'Date d\'accueil',
  'BYFICHE' = 'Date du bordereau',
}
export const FinanceCaisseSchema = Yup.object().shape({
  auxiliaryAccountNumber: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  technicalFlatRateJournalCode: Yup.mixed<FeesJournalCodeEnum>().oneOf(
    Object.values(FeesJournalCodeEnum),
  ),
  feesJournalCode: Yup.mixed<FeesJournalCodeEnum>().oneOf(
    Object.values(FeesJournalCodeEnum),
  ),
  ignoreInRelaunches: Yup.boolean(),
  ignoreInGeneration: Yup.boolean(),
  manageClaimForms: Yup.boolean(),
  manageDisputes: Yup.boolean(),
  oneInvoicePerPage: Yup.boolean(),
  relaunchTriggerBy: Yup.mixed<relaunchTriggerWhoEnum>().oneOf(
    Object.values(relaunchTriggerWhoEnum),
  ),
  relaunchTriggerDate: Yup.mixed<relaunchTriggerWhenEnum>().oneOf(
    Object.values(relaunchTriggerWhenEnum),
  ),
  payerOrganization: Yup.string().required('Required'),
  comment: Yup.string().required('Required').max(300, 'Maximum 300 characters'),
})
export enum contactStatusEnum {
  'DR' = 'Doctor',
  'DRE' = 'Doctore',
  'MR' = 'Monsieur',
  'MME' = 'Madame',
  'ENF' = 'Enfant',
}
export const fundGeneralInfoSchema = Yup.object().shape({
  organizationCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  organizationName: Yup.string()
    .required('Required')
    .max(100, 'Maximum 100 characters'),
  refundRate: Yup.number()
    .required('Required')
    .min(0, 'Must be a valid number')
    .max(100, 'Must be a valid number'),
  lastBordereau: Yup.string().required('Required'),
  address: Yup.string().required('Required').max(200, 'Maximum 200 characters'),
  postalCode: Yup.number()
    .required('Required')
    .min(10000, 'Must be a valid postal code')
    .max(99999, 'Must be a valid postal code'),
  city: Yup.string().required('Required').max(100, 'Maximum 100 characters'),
  contactName: Yup.string()
    .required('Required')
    .max(100, 'Maximum 100 characters'),
  contactStatus: Yup.mixed<contactStatusEnum>().oneOf(
    Object.values(contactStatusEnum),
  ),
  email: Yup.string().email('Invalid email').required('Required'),
  professionalPhone: Yup.string()
    .required('Required')
    .matches(/^\d{10}$/, 'Must be a valid phone number'),
  fax: Yup.string()
    .optional()
    .matches(/^\d{10}$/, 'Must be a valid fax number'),
  socialSecurityRegime: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  socialSecurityFund: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  socialSecurityCenter: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  toTransmit: Yup.boolean().required('Required'),
  monacoFund: Yup.boolean().required('Required'),
  activeFrom: Yup.string()
    .required('Required')
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
  activeTo: Yup.string()
    .optional()
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
})
export enum TypeMutuelleEnum {
  'MUTUELLE' = 'Mutuelle',
  'AMC' = 'AMC',
}
export enum ThirdPartyPaymentEnum {
  'TP' = 'Tiers-payant (mutuel)',
  'HE' = '100% Honoraires (org.extérieur)',
  'HEFT' = '100% Honoraires + FT (org.extérieur)',
}
export const mutuelleGeneralInfoSchema = Yup.object().shape({
  organizationCode: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  organizationName: Yup.string()
    .required('Required')
    .max(100, 'Maximum 100 characters'),
  type: Yup.mixed<TypeMutuelleEnum>().oneOf(Object.values(TypeMutuelleEnum)),
  refundRate: Yup.number()
    .required('Required')
    .min(0, 'Must be a valid number')
    .max(100, 'Must be a valid number'),
  lastBordereau: Yup.string().required('Required'),
  thirdPartyPayment: Yup.mixed<ThirdPartyPaymentEnum>().oneOf(
    Object.values(ThirdPartyPaymentEnum),
  ),
  address: Yup.string().required('Required').max(200, 'Maximum 200 characters'),
  postalCode: Yup.number()
    .required('Required')
    .min(10000, 'Must be a valid postal code')
    .max(99999, 'Must be a valid postal code'),
  city: Yup.string().required('Required').max(100, 'Maximum 100 characters'),
  contactName: Yup.string()
    .required('Required')
    .max(100, 'Maximum 100 characters'),
  contactStatus: Yup.mixed<contactStatusEnum>().oneOf(
    Object.values(contactStatusEnum),
  ),
  email: Yup.string().email('Invalid email').required('Required'),
  professionalPhone: Yup.string()
    .required('Required')
    .matches(/^\d{10}$/, 'Must be a valid phone number'),
  fax: Yup.string()
    .optional()
    .matches(/^\d{10}$/, 'Must be a valid fax number'),
  mutuelleId: Yup.string()
    .required('Required')
    .max(50, 'Maximum 50 characters'),
  toTransmit: Yup.boolean().required('Required'),
  teletransmissionDate: Yup.string()
    .required('Required')
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
  activeFrom: Yup.string()
    .required('Required')
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
  activeTo: Yup.string()
    .optional()
    .matches(/^\d{2}-\d{2}-\d{4}$/, 'Must be a valid date (DD-MM-YYYY)'),
})

export const patientCoverageSchema = Yup.object().shape({
  sex: Yup.string().required('Merci de choisir une civilité'),
  nom: Yup.string().required('Merci de renseigner le nom du patient'),
  prenom: Yup.string().required('Merci de renseigner le prénom du patient'),
  dateNaissance: Yup.string().optional(),
  assureNom: Yup.string().required('Merci de renseigner le nom de l\'assuré'),
  assurePrenom: Yup.string().required(
    'Merci de renseigner le prénom de l\'assuré',
  ),
  qualite: Yup.string().optional(),
  rang: Yup.string().optional(),
  insNomNaissance: Yup.string().required(
    'Merci de renseigner le nom de naissance',
  ),
  premierPrenom: Yup.string().required('Merci de renseigner le premier prénom'),
  listePrenoms: Yup.string().required(
    'Merci de renseigner la liste des prénoms',
  ),
  lieuNaissance: Yup.string().required(
    'Merci de renseigner le lieu de naissance',
  ),
  ins: Yup.string().optional(),
  oid: Yup.string().optional(),
  natureAssurance: Yup.string().optional(),
  exoneration: Yup.string().optional(),
  amo: Yup.string().optional(),
  amc: Yup.string().optional(),
  debutAmo: Yup.date()
    .max(new Date(), 'Date cannot be in the future')
    .optional(),
  finAmo: Yup.date().min(new Date(), 'Date cannot be in the past').optional(),
  debutAmc: Yup.date()
    .max(new Date(), 'Date cannot be in the future')
    .optional(),
  finAmc: Yup.date().min(new Date(), 'Date cannot be in the past').optional(),
  numeroAdherent: Yup.number().positive().integer().optional(),
  gestion: Yup.boolean().optional(),
  parcoursSoins: Yup.mixed<pathwayhealth>().oneOf(Object.values(pathwayhealth)),
  medecinTraitantDeclare: Yup.object().required('Medecin traitant is required'),
  medecinTraitant: Yup.object().required('Medecin traitant is required'),
})

export const patientFormSchema = Yup.object().shape({
  sex: Yup.string().required('La civilité est obligatoire'),
  firstName: Yup.string().required('Le prénom est obligatoire'),
  lastName: Yup.string().required('Le nom est obligatoire'),
  motherMaidenName: Yup.string().optional(),
  birthDate: Yup.date()
    .optional()
    .nullable()
    .typeError('Veuillez saisir une date valide')
    .min(
      new Date(1900, 0, 1),
      'La date de naissance doit être postérieure à 1900',
    )
    .max(
      new Date(new Date().getTime() + 86400000),
      'La date de naissance ne peut pas être postérieure à aujourd\'hui',
    ),

  phoneNumber: Yup.string()
    .optional()
    .nullable()
    .test(
      'phone',
      'Le numéro de téléphone doit être un numéro français valide',
      (value) => {
        if (!value || value === '') return true
        return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value)
      },
    ),
  email: Yup.string().optional().email('L\'adresse email n\'est pas valide'),
  address: Yup.string().nullable().optional(),
  birthPlace: Yup.string()
    .optional()
    .nullable()
    .min(2, 'Birth place must be at least 2 characters'),
  birthPlaceDepartment: Yup.string().nullable().optional(),
  ssn: Yup.string()
    .optional()
    .test('valid-ssn', 'Numéro de sécurité sociale invalide', (value) => {
      if (!value) return true // Allow empty values since it's optional
      return validate(value)
    }),
  weight: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .optional()
    .test('weight-range', 'Poids trop élevé', (value) => {
      if (value === null || value === undefined) return true
      return value <= 400
    }),
  height: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .optional()
    .test('height-range', 'Taille trop élevée', (value) => {
      if (value === null || value === undefined) return true
      return value <= 300
    }),
  familyDoctor: Yup.object().optional().nullable(),
  comments: Yup.string()
    .optional()
    .max(500, 'Les commentaires ne peuvent pas dépasser 500 caractères'),
  iod: Yup.string().optional(),
  ins: Yup.string().optional(),
  files: Yup.array().optional(),
})

export const patientSearchSchema = Yup.object().shape({
  name: Yup.string().optional(),
  lastName: Yup.string().optional(),
  birthDate: Yup.string().optional(),
  ipp: Yup.string().optional(),
  originIpp: Yup.string().optional(),
  sex: Yup.string().optional(),
})

export const MedicalOrderSchema = Yup.object().shape({
  siteId: Yup.number()
    .typeError('Site est obligatoire')
    .required('Site est obligatoire'),
  attendingDoctor: Yup.object().nullable().optional(),
  referringDoctor: Yup.object().nullable().optional(),
  consultingDoctor: Yup.object().nullable().optional(),
  registerDate: Yup.object().optional().nullable(),
  comments: Yup.string().nullable().optional(),
})

export const createQuotationSchema = Yup.object().shape({
  cotation_type: Yup.string().required().oneOf(['CCAM', 'NGAP']),
  cotation: Yup.string().required(),
  modificateur: Yup.string().optional(),
  prix_unitaire: Yup.number().required().positive(),
  code_entente_prealable: Yup.string().nullable().optional(),
  date_entente_prealable: Yup.date().nullable().optional(),
})

export const visitSchema = Yup.object().shape({
  // patientId will be handeled manually in the submit callback, its required but not validated
  patientId: Yup.number().optional(),
  siteId: Yup.number().required('validation.field.required'),
  admissionDate: Yup.date()
    .typeError('Veuillez renseigner la date d\'admission')
    .required('Veuillez renseigner la date d\'admission'),
  dischargeDate: Yup.object().nullable().optional(),
  hospitalService: Yup.string().optional(),
})

export const couvertureFormSchema = Yup.object().shape({
  beneficiaireNom: Yup.string().required('Le nom de l\'assuré est obligatoire'),
  beneficiairePrenom: Yup.string().required(
    'Le prénom de l\'assuré est obligatoire',
  ),
})
export const patientAldSchema = Yup.object().shape({
  code: Yup.string().nullable().optional(),
  dateFin: Yup.date().nullable().optional(),
  etat: Yup.string().nullable().optional(),
  cimCode: Yup.string().nullable().optional(),
  comment: Yup.string().nullable().optional(),
})

export const paymentModeSchema = Yup.object().shape({
  code: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  label: Yup.string().required('Requis').max(100, '100 caractères maximum'),
  type: Yup.mixed<PaymentModeType>().oneOf(
    Object.values(PaymentModeType) as PaymentModeType[],
  ),
  accountingSectionCode: Yup.string()
    .optional()
    .max(255, '255 caractères maximum'),
})

export const billingCompanySchema = Yup.object().shape({
  code: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  label: Yup.string().required('Requis').max(100, '100 caractères maximum'),
  registrationNumber: Yup.string()
    .required('Requis')
    .max(50, '50 caractères maximum'),
  finessNumber: Yup.string()
    .required('Requis')
    .max(50, '50 caractères maximum'),
  taxNumber: Yup.string().required('Requis').max(50, '50 caractères maximum'),
  address: Yup.string().required('Requis').max(255, '255 caractères maximum'),
  postalCode: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  city: Yup.string().required('Requis').max(100, '100 caractères maximum'),
  country: Yup.string().required('Requis').max(100, '100 caractères maximum'),
  siteId: Yup.number().typeError('Requis').required('Requis'),
  bankAccountId: Yup.number().typeError('Requis').required('Requis'),
})

export const bankEstablishmentSchema = Yup.object().shape({
  code: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  label: Yup.string().required('Requis').max(100, '100 caractères maximum'),
})

export const bankAccountSchema = Yup.object().shape({
  code: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  name: Yup.string().required('Requis').max(100, '100 caractères maximum'),
  countryCode: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  bankCode: Yup.string().required('Requis').length(5, '5 caractères'),
  branchCode: Yup.string().required('Requis').length(5, '5 caractères'),
  accountNumber: Yup.string()
    .required('Requis')
    .max(100, '100 caractères maximum'),
  controlKey: Yup.string().required('Requis').length(2, '2 caractères'),
  swiftCode: Yup.string()
    .required('Requis')
    .test(
      'length',
      '8 ou 11 caractères',
      (value) =>
        !!value &&
        (value.replaceAll(/\s+/g, '').length === 8 ||
          value.replaceAll(/\s+/g, '').length === 11),
    ),
  siteIds: Yup.array(Yup.number()).optional(),
})

export const patientPaymentSchema = Yup.object().shape({
  paymentModeId: Yup.number().required('Requis'),
  amount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  comment: Yup.string()
    .nullable()
    .max(255, '255 caractères maximum')
    .optional(),
  // PaymentModeType.CHEQUE case
  chequeNumber: Yup.string().nullable().optional(),
  bankEstablishmentId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .optional(), // + PaymentModeType.BANK_TRANSFER case
  drawerName: Yup.string().nullable().optional(),
  minimumRequestedDepositDate: Yup.date()
    .transform((value, originalValue) => (!originalValue ? null : value))
    .min(new Date(), 'Impossible de choisir une date dans le passé')
    .max(
      moment().add(365, 'days').toDate(),
      'Impossible de choisir une date d\'encaissement supérieure à 1 an',
    )
    .nullable()
    .optional(),
})

export const chequeDepositSchema = Yup.object().shape({
  depositReference: Yup.string()
    .required('Requis')
    .max(50, '50 caractères maximum'),
  chequeIds: Yup.array().min(1, 'Requis').of(Yup.number()).required('Requis'),
})

export const quoteSchema = Yup.object().shape({
  patientId: Yup.number().required('Requis'),
  attendingDoctorId: Yup.number().nullable().optional(),
  attendingDoctorSiteId: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .optional(),
})

export const dunningNoticeConfigSchema = Yup.object().shape({
  daysLevel1: Yup.number()
    .integer('Invalide')
    .typeError('Requis')
    .required('Requis')
    .moreThan(0, 'Invalide'),
  daysLevel2: Yup.number()
    .integer('Invalide')
    .typeError('Requis')
    .required('Requis')
    .moreThan(Yup.ref('daysLevel1'), 'Doit être supérieur au niveau 1'),
  daysLevel3: Yup.number()
    .integer('Invalide')
    .typeError('Requis')
    .required('Requis')
    .moreThan(Yup.ref('daysLevel2'), 'Doit être supérieur au niveau 2'),
  content1: Yup.string().required('Requis'),
  content2: Yup.string().required('Requis'),
  content3: Yup.string().required('Requis'),
})

export const organismPaymentSchema = Yup.object().shape({
  paymentModeId: Yup.number().required('Requis'),
  date: Yup.date()
    .max(new Date(), 'Invalide')
    .typeError('Requis')
    .required('Requis'),
  comment: Yup.string().max(255, '255 caractères maximum').optional(),
  bankEstablishmentId: Yup.number().typeError('Requis').required('Requis'),
  // PaymentModeType.CHEQUE case
  chequeNumber: Yup.string().nullable().optional(),
  drawerName: Yup.string().nullable().optional(),
})

export const cashRegisterSchema = Yup.object().shape({
  code: Yup.string().required('Requis').max(10, '10 caractères maximum'),
  name: Yup.string().required('Requis').max(100, '100 caractères maximum'),
  location: Yup.string().required('Requis').max(255, '255 caractères maximum'),
  active: Yup.boolean(),
  balance: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  siteId: Yup.number().typeError('Requis').required('Requis'),
})

export const cashRegisterTransactionSchema = Yup.object().shape({
  paymentModeId: Yup.number().required('Requis'),
  amount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  description: Yup.string().max(255, '255 caractères maximum').optional(),
  transactionType: Yup.mixed().oneOf(['DEBIT', 'CREDIT']).required('Requis'),
})

export const openCashRegisterSchema = Yup.object().shape({
  cashAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  checkAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  voucherAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  description: Yup.string().max(255, '255 caractères maximum').optional(),
})

export const closeCashRegisterSchema = Yup.object().shape({
  cashAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  checkAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  voucherAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  transferAmount: Yup.number()
    .required('Requis')
    .typeError('Requis')
    .test('is-decimal', 'Format non valide', (value) =>
      /^\d+(\.\d{1,2})?$/.test((value ?? '').toString()),
    ),
  description: Yup.string().max(255, '255 caractères maximum').optional(),
})

export type PatientFormSchemaType = Yup.InferType<typeof patientFormSchema>
export type PatientSearchFormSchemaType = Yup.InferType<
  typeof patientSearchSchema
>
export type MedicalOrderSchemaType = Yup.InferType<typeof MedicalOrderSchema>
export type VisitSchemaType = Yup.InferType<typeof visitSchema>
