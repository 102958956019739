import { useAppDispatch } from '@hooks/reduxHooks'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  getVitalCardResipUrl,
  sendReaderResponse,
} from '@services/CardReadService'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { setReaderId } from '@state/reducers/patientsReducer'
import { getCartVitalUrl } from '@state/thunks/cardReadThunk'
import { isBase64 } from '@utils/dto.mapper'
import React, { useEffect, useState } from 'react'
import { CouvertureDto } from 'src/common/interfaces'

type Props = {
  isOpen: boolean
  onFinish: (dto: CouvertureDto) => void
  visitId: number
  readDate: string
  onClose: () => void
}

const VitalCardReaderComponent: React.FC<Props> = ({
  isOpen,
  onFinish,
  visitId,
  readDate,
  onClose,
}) => {
  const [url, setUrl] = useState('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isOpen) {
      if (url) {
        setUrl('')
      }

      return
    }

    dispatch(getCartVitalUrl())
      .unwrap()
      .then(async (dto) => {
        dispatch(setReaderId(dto.id))
        const url = await getVitalCardResipUrl(dto.id, readDate)
        setUrl(url)
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err.message,
            options: { variant: 'error' },
          }),
        )
      })
  }, [dispatch, isOpen])

  useEffect(() => {
    const handlePostBack = async (msg: MessageEvent) => {
      const data = msg.data
      if (!isBase64(data)) {
        return
      }

      const decodedStr = atob(data)
      const obj = JSON.parse(decodedStr)
      if (!obj.method) {
        dispatch(
          enqueueSnackbar({
            message: 'Erreur de lecture',
            options: { variant: 'error' },
          }),
        )
        onClose()
        return
      }
      if (obj.method.cancel) {
        onClose()
        return
      }
      const dto = await sendReaderResponse(visitId, readDate, obj)

      if (!dto) {
        dispatch(
          enqueueSnackbar({
            message: 'Erreur de lecture',
            options: { variant: 'error' },
          }),
        )
        return
      }
      onFinish(dto)
    }

    window.addEventListener('message', handlePostBack, false)
    return () => {
      window.removeEventListener('message', handlePostBack)
    }
  }, [visitId, readDate])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="z-[100000]"
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: 5,
          background: '#E2E6F2',
          zIndex: 100001,
        },
      }}
    >
      <DialogTitle
        style={{
          background: '#fff',
          color: '#000',
        }}
      >
        Lecture de la carte vitale
      </DialogTitle>
      <DialogContent
        style={{
          minWidth: '850px',
          display: 'flex',
          justifyContent: 'center',
          height: '80vh',
          alignItems: 'center',

          zIndex: 100000001,
        }}
      >
        {url === '' ? (
          <CircularProgress className="!text-purple-600" />
        ) : (
          <iframe
            style={{
              width: '100%',
              height: '80vh',
              border: 'none',
              overflow: 'hidden',
              marginTop: '20px',
            }}
            src={url}
          ></iframe>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default VitalCardReaderComponent
