import CashRegistersTable from './CashRegistersTable'
import { useAppSelector } from '@hooks/reduxHooks'
import CashRegisterTransactionsTable from './CashRegisterTransactionsTable'

export default function CashRegistersTab() {
  const { activeTab } = useAppSelector(({ cashRegister }) => ({
    activeTab: cashRegister.tab,
  }))

  return activeTab === 0 ? (
    <CashRegistersTable />
  ) : (
    <CashRegisterTransactionsTable />
  )
}
