import { UndepositedChequesFilters } from '@/state/reducers/patientPaymentReducer'
import { axios, getConfigs, IRequestConfig } from './api'
import { ChequeDto, CreatePatientPaymentDto, PatientPaymentDto } from './dtos'
import { PaginatedDto } from './extendedType'

export class PatientPaymentService {
  private static readonly endpoint = 'patient-payment'

  static getUndepositedCheques(
    query: UndepositedChequesFilters,
  ): Promise<PaginatedDto<ChequeDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/undeposited-cheques`,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreatePatientPaymentDto): Promise<PatientPaymentDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  /*static update(id: number, dto: UpdatePaymentDto): Promise<PaymentDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }*/

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
