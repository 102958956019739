import { axios, getConfigs, IRequestConfig } from './api'
import { DocumentConfigDto } from './dtos'

export class DocumentConfigService {
  private static readonly endpoint = 'document-config'

  static get(): Promise<DocumentConfigDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
