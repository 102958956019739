import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Link, Tab, Tabs, Dialog, Alert, Button } from '@mui/material'
import { ResipService } from '@services/ResipService'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { getCartVitalUrl } from '@state/thunks/cardReadThunk'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { MemberDto } from '../services/api'
import MemberAutocomplete from '../components/MemberAutocomplete'
import {
  doGetMemberSituation,
  setMemberSituation,
} from '../state/reducers/billingReducer'
import { UpTransition } from '../components/animations'

const urlsMap = {
  getDashboardUrl: ResipService.getDashboardUrl,
  getScorUrl: ResipService.getScorUrl,
  getTeletransmissionUrl: ResipService.getTeletransmissionUrl,
  getReturnManagementUrl: ResipService.getReturnManagementUrl,
  getUserListUrl: ResipService.getUserListUrl,
  getUserConfigurationUrl: ResipService.getUserConfigurationUrl,
  getGeneralParametersUrl: ResipService.getGeneralParametersUrl,
  getConfigurationDisplayUrl: ResipService.getConfigurationDisplayUrl,
  getVersionsUrl: ResipService.getVersionUrl,
}

const ResipLayout = () => {
  const dispatch = useAppDispatch()
  const [cardReaderId, setcardReaderId] = useState('')
  const [currentUrl, setCurrentUrl] = useState('')
  const [activeTab, setactiveTab] = useState('getDashboardUrl')
  const [selectedMember, setSelectedMember] = useState<MemberDto | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(!selectedMember)

  const { memberSituation } = useAppSelector((state) => ({
    memberSituation: state.billing.memberSituation,
  }))

  const intl = useIntl()

  useEffect(() => {
    dispatch(getCartVitalUrl())
      .unwrap()
      .then((dto) => {
        setcardReaderId(dto.id)
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err.message,
            options: { variant: 'error' },
          }),
        )
      })

    if (selectedMember) {
      urlsMap
        .getDashboardUrl(selectedMember.id.toString(), cardReaderId)
        .then(setCurrentUrl)
    }
  }, [dispatch, cardReaderId, selectedMember])

  useEffect(() => {
    if (selectedMember) {
      dispatch(doGetMemberSituation(selectedMember.id))
        .unwrap()
        .then(() => {
          setIsDialogOpen(false)
        })
    } else {
      dispatch(setMemberSituation(null))
    }
  }, [selectedMember, cardReaderId])

  const handleLinkClick = (key) => {
    const fn = urlsMap[key]
    if (fn && memberSituation) {
      const idResip = memberSituation.idJfse
      fn(idResip, cardReaderId).then(setCurrentUrl)
    }
    setactiveTab(key)
  }

  const handleMemberSelect = (member: MemberDto | null) => {
    setSelectedMember(member)
    if (member) {
      setIsDialogOpen(false)
    }
  }

  const links = useMemo(() => {
    const keys = Object.keys(urlsMap)
    return keys.map((key) => (
      <Tab
        key={key}
        label={intl.formatMessage({ id: `resip.${key}` })}
        value={key}
        onClick={() => handleLinkClick(key)}
        component={Link}
        style={{
          backgroundColor: activeTab === key ? '#7e57c2' : '#fff',
          color: activeTab === key ? '#ffffff' : '#555',
          fontWeight: 'bold',
          borderRadius: '4px',
          padding: '2px 6px',
          margin: '0 4px',
          minWidth: '100px',
          textAlign: 'center',
        }}
      />
    ))
  }, [urlsMap, activeTab, intl])

  const getMemberInitials = (member: MemberDto | null) => {
    if (!member?.firstName || !member?.lastName) return ''
    return `${member.firstName.charAt(0)}.${member.lastName.charAt(0)}`
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          position: 'absolute',
          right: '10px',
          top: '50px',
        }}
      >
        {selectedMember ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: '#fff',
              padding: '8px',
              borderRadius: '8px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
              cursor: 'pointer',
            }}
            onClick={() => setIsDialogOpen(true)}
          >
            <div
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                backgroundColor: '#7e57c2',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {getMemberInitials(selectedMember)}
            </div>
            <span>Modifier</span>
          </div>
        ) : (
          <Button
            variant="contained"
            onClick={() => setIsDialogOpen(true)}
            style={{ backgroundColor: '#7e57c2' }}
          >
            Sélectionner un médecin
          </Button>
        )}
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        TransitionComponent={UpTransition}
      >
        <div style={{ padding: '20px' }}>
          <MemberAutocomplete
            label="Médecin"
            value={selectedMember}
            onChange={handleMemberSelect}
          />
        </div>
      </Dialog>

      {memberSituation ? (
        <div style={{ width: '100%', flex: 1 }}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setactiveTab(value)}
            TabIndicatorProps={{ style: { display: 'none' } }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '6px 0',
            }}
          >
            {links}
          </Tabs>
          {!!currentUrl && (
            <iframe
              src={currentUrl}
              style={{
                width: '100%',
                height: 'calc(100vh - 156px)',
                border: 'none',
                borderRadius: '15px',
              }}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="info">
            Sélectionnez un médecin{' '}
            {selectedMember ? '( Ce médecin n\'a pas de situation.)' : ''}
          </Alert>
        </div>
      )}
    </div>
  )
}

export default ResipLayout
