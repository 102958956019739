import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  debounce,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import { PatientDto } from '@services/api'
import { setPatients, setPatientsFilter } from '@state/reducers/patientsReducer'
import { fetchOnePatientById, fetchPatients } from '@state/thunks/patientsThunk'
import React, { useEffect, useMemo, useState } from 'react'

type Props = {
  onChange?: (selectedPatientId?: number) => void
  onPatientChange?: (selectedPatient?: PatientDto) => void
  defaultValue?: number
  isPatientExists?: boolean
  disableClearable?: boolean
}
export const PatientSearchComponent = ({
  onChange,
  onPatientChange,
  defaultValue,
  isPatientExists: _isPatientExists,
  disableClearable,
}: Props) => {
  const dispatch = useAppDispatch()

  const [isPatientExists, setIsPatientExists] = useState(
    _isPatientExists || !!defaultValue,
  )
  const [isSearchPatientOpen, setIsSearchPatientOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [selectedPatient, setSelectedPatient] = useState<
    PatientDto | undefined
  >(defaultValue ? ({ id: defaultValue } as PatientDto) : undefined)

  const { patients, filters } = useAppSelector((state) => ({
    patients: state.patients.patients,
    filters: state.patients.patientsFilter,
  }))

  useEffect(() => {
    if (!filters.search) {
      return
    }
    dispatch(fetchPatients())
      .unwrap()
      .then(() => {
        setIsFetching(false)
      })
  }, [filters])

  useEffect(() => {
    onChange?.(selectedPatient?.id)
    onPatientChange?.(selectedPatient)
  }, [selectedPatient])

  useEffect(() => {
    if (defaultValue) {
      dispatch(fetchOnePatientById({ id: defaultValue }))
        .unwrap()
        .then((patient) => {
          setSelectedPatient(patient)
        })
    }
  }, [defaultValue])

  const findPatients = useMemo(
    () =>
      debounce((value: string) => {
        if (!value) {
          dispatch(setPatients([]))
        }
        dispatch(
          setPatientsFilter({
            search: value,
          }),
        )
      }, 400),
    [],
  )

  const onSearchPatient = (value: string) => {
    setIsFetching(true)
    findPatients(value)
  }

  const renderSelectedPatient = () => {
    return (
      <div className="w-full max-w-[400px] bg-gradient-to-r from-purple-600 to-purple-800 flex justify-between px-4 py-3 text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-200">
        <div className="flex items-center gap-3">
          <i className="fas fa-user-circle text-purple-200 text-lg" />
          <div className="flex flex-col">
            <span className="text-sm font-medium">
              {selectedPatient?.firstName} {selectedPatient?.lastName}
            </span>
            <span className="text-xs text-purple-200">
              SSN: {selectedPatient?.ssn}
            </span>
          </div>
        </div>
        {!disableClearable && (
          <IconButton
            size="small"
            className="hover:bg-purple-500/30 self-center"
            onClick={() => setSelectedPatient(undefined)}
          >
            <i className="fas fa-times text-purple-100 text-sm" />
          </IconButton>
        )}
      </div>
    )
  }

  const _renderSelectedPatient = renderSelectedPatient()

  return (
    <form>
      {!_isPatientExists && (
        <FormControl className="mb-4">
          <div className="flex items-center gap-2">
            <span className="text-gray-700 font-medium">Déjà enregistré?</span>
            <Checkbox
              onChange={(_, checked) => {
                if (!checked && selectedPatient) {
                  setSelectedPatient(undefined)
                }
                setIsPatientExists(checked)
              }}
              checked={isPatientExists}
              sx={{
                '&.Mui-checked': {
                  color: '#6D28D9',
                },
              }}
            />
          </div>
        </FormControl>
      )}

      <Grid item xs={12}>
        {selectedPatient && _renderSelectedPatient}
        {isPatientExists && !selectedPatient && (
          <Grid item xs={6} my={2}>
            <FormControl size="small" fullWidth>
              <Autocomplete
                sx={{
                  width: '100%',
                  maxWidth: '400px',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#6D28D9',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6D28D9',
                    },
                  },
                  '& .MuiAutocomplete-popper': {
                    width: '400px !important',
                  },
                  '& .MuiAutocomplete-listbox': {
                    padding: '8px',
                    '& .MuiAutocomplete-option': {
                      padding: '8px 12px',
                      borderRadius: '6px',
                      '&[aria-selected="true"]': {
                        backgroundColor: '#7c3aed20',
                      },
                      '&.Mui-focused': {
                        backgroundColor: '#7c3aed10',
                      },
                    },
                  },
                }}
                componentsProps={{
                  popper: {
                    style: {
                      width: '400px',
                    },
                  },
                }}
                open={isSearchPatientOpen}
                onOpen={() => {
                  setIsSearchPatientOpen(true)
                }}
                onClose={() => {
                  setIsSearchPatientOpen(false)
                }}
                isOptionEqualToValue={(option, value) => {
                  if (!value) {
                    return false
                  }
                  return option.id === value.id
                }}
                getOptionLabel={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                options={patients}
                onChange={(_, value) => setSelectedPatient(value ?? undefined)}
                loading={isFetching}
                loadingText="Chargement..."
                value={selectedPatient}
                getOptionKey={(option) => option.id.toString()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Rechercher un patient..."
                    onChange={(ev) => {
                      onSearchPatient(ev.target.value)
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isFetching ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </form>
  )
}

export default PatientSearchComponent
