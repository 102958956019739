import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { UpTransition } from '@components/animations'
import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  getQuotes,
  removeQuote,
  setQuoteFilters,
  updateQuote,
} from '@state/reducers/quoteReducer'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { QuoteDto } from '@services/dtos'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import { Margin } from 'react-to-pdf'
import { debounce } from 'lodash'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import QuoteForm from '../forms/QuoteForm'
import QuotePdfTemplate from '../pdf/QuotePdfTemplate'
import React from 'react'
import generatePDF from 'react-to-pdf'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import QuotesTableToolbar from './Toolbars/QuotesTableToolbar'
import {
  doValidatePrecotations,
  setValidation,
} from '@state/reducers/billingReducer'
import BillSummaryComponent from '../billing/BillingSummaryComponent'
import { getDocumentConfig } from '@state/reducers/documentConfigReducer'

export default function QuotesTable() {
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false)
  const [deletingQuoteForm, setDeletingQuoteForm] = useState<QuoteDto | null>(
    null,
  )
  const [editingQuote, setEditingQuote] = useState<QuoteDto | null>(null)
  const [lockQuoteForm, setLockQuoteForm] = useState<QuoteDto | null>(null)
  const [lockQuoteAlerts, setLockQuoteAlerts] = useState<QuoteDto | null>(null)

  const dispatch = useAppDispatch()
  const {
    quotes,
    loading,
    loadingUpdate,
    filters,
    validation,
    documentConfig,
  } = useAppSelector(({ quote, billing, documentConfig }) => ({
    quotes: quote.quotes,
    loading: quote.loading,
    loadingUpdate: quote.loadingUpdate,
    filters: quote.filters,
    validation: billing.validation,
    documentConfig: documentConfig.documentConfig,
  }))

  useEffect(() => {
    dispatch(getDocumentConfig())
  }, [])

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(getQuotes())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const QuotePdfCellRenderer = ({ row }: { row: QuoteDto }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [excessAmount, setExcessAmount] = useState<
      number | undefined | null
    >()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
      const generatePdfAsync = async () => {
        if (excessAmount !== undefined && ref.current) {
          await generatePDF(ref, {
            method: 'open',
            page: {
              margin: Margin.MEDIUM,
            },
          })
          setExcessAmount(undefined)
        }
      }
      generatePdfAsync()
    }, [excessAmount])

    const handleGeneratePDF = async () => {
      if (row.quotePrecotations.length === 0) {
        setExcessAmount(null)
        return
      }
      setLoading(true)
      await dispatch(
        doValidatePrecotations(
          row.quotePrecotations.map((quotePrecotation) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { exam, ...rest } = quotePrecotation
            return rest
          }),
        ),
      )
        .unwrap()
        .then((res) => {
          setExcessAmount(
            res.erreurs.length === 0 ? res.visite.montantDepassement : null,
          )
          dispatch(setValidation(null))
        })
        .finally(() => setLoading(false))
    }

    return (
      <div className="ml-0">
        <QuotePdfTemplate
          quote={row}
          excessAmount={excessAmount}
          documentConfig={documentConfig}
          ref={ref}
        />
        <IconButton
          onClick={handleGeneratePDF}
          color="primary"
          disabled={loading}
        >
          <PictureAsPdfIcon />
        </IconButton>
      </div>
    )
  }

  const actionBtnObj = useMemo(() => {
    return [
      {
        id: 1,
        btnName: (row: QuoteDto) =>
          `Générer en fichier pdf${!row.isLocked ? ' (provisoire)' : ''}`,
        renderCell: (row: QuoteDto) => <QuotePdfCellRenderer row={row} />,
        actionKey: 'viewQuote',
      },
      {
        id: 2,
        btnName: () => 'Modifier',
        icon: <EditIcon color="primary" />,
        action: (row: QuoteDto) => {
          if (row.isLocked) return
          setEditingQuote(row)
          setIsQuoteFormOpen(true)
        },
        actionKey: 'editQuote',
      },
      {
        id: 3,
        btnName: () => 'Rendre définitif',
        icon: <LockOpenIcon color="primary" />,
        action: (row: QuoteDto) => {
          if (row.isLocked) return
          if (
            !row.attendingDoctor ||
            !row.attendingDoctorSite ||
            row.quotePrecotations.length === 0
          ) {
            setLockQuoteAlerts(row)
            return
          }
          setLockQuoteForm(row)
        },
        actionKey: 'lockActionQuote',
      },
      {
        id: 4,
        btnName: () => 'Supprimer',
        icon: <DeleteIcon color="primary" />,
        action: (row: QuoteDto) => !row.isLocked && setDeletingQuoteForm(row),
        actionKey: 'deleteQuote',
      },
    ]
  }, [documentConfig])

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 190,
      sortable: false,
      renderCell: (params) => {
        const quote = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              const actionDisabled =
                quote.isLocked && el.actionKey !== 'viewQuote'
              acc.push(
                <Tooltip
                  key={quote.id + '-' + el.btnName(quote)}
                  title={
                    actionDisabled
                      ? 'Impossible si définitif'
                      : el.btnName(quote)
                  }
                >
                  {el.renderCell ? (
                    el.renderCell(quote)
                  ) : (
                    <div
                      className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                        actionDisabled ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation()
                        const action = el.action.bind({})
                        action(quote)
                      }}
                    >
                      {el.icon}
                    </div>
                  )}
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'id',
      headerName: 'N° devis',
      width: 140,
    },
    {
      field: 'createdAt',
      headerName: 'Date de création',
      valueGetter: ({ row }) =>
        moment(row.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 140,
    },
    {
      field: 'patientName',
      headerName: 'Patient',
      width: 140,
      valueGetter: ({ row }) =>
        `${row.patient.firstName ?? ''} ${row.patient.lastName ?? ''}`,
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <div
        style={{
          height: 'calc(100vh - 152px)',
          backgroundColor: 'white',
          borderRadius: '15px 15px 25px 25px',
          border: '1px solid rgba(224, 224, 224, 1)',
          width: '100%'
        }}
      >
        <div className="flex items-center gap-2 pt-3 pl-5">
          <div className="mr-4">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsQuoteFormOpen(true)}
            >
              <i className="fas fa-plus"></i>{' '}
              <span className="ml-2">Ajouter</span>
            </Button>
          </div>
          <QuotesTableToolbar />
        </div>
        <DataGridPro
          density="standard"
          style={{
            height: 'calc(100vh - 220px)',
            width: '100%',
            border: 0,
          }}
          columns={columns}
          rows={quotes.datas}
          rowCount={quotes.totalCount}
          disableSelectionOnClick
          pagination
          paginationMode="server"
          pageSize={20}
          onPageChange={(page) => {
            dispatch(setQuoteFilters({ page }))
          }}
          loading={loading}
        />
      </div>

      <Dialog
        fullScreen
        open={isQuoteFormOpen}
        onClose={() => setIsQuoteFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isQuoteFormOpen && (
            <QuoteForm
              onClose={() => {
                setIsQuoteFormOpen(false)
                setEditingQuote(null)
              }}
              editingQuote={editingQuote}
            />
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title="Suppression"
        message="Voulez-vous vraiment supprimer ce devis ?"
        open={!!deletingQuoteForm}
        onConfirm={() => {
          if (deletingQuoteForm) {
            dispatch(removeQuote(deletingQuoteForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'Le devis a été supprimé avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => {
                setDeletingQuoteForm(null)
                dispatch(getQuotes())
              })
          }
        }}
        onCancel={() => {
          setDeletingQuoteForm(null)
        }}
      />

      <Dialog
        open={!!lockQuoteAlerts}
        onClose={() => setLockQuoteAlerts(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {!lockQuoteAlerts?.attendingDoctor && (
            <Alert severity="error" className="mb-2">
              Le praticien doit être précisé.
            </Alert>
          )}
          {!lockQuoteAlerts?.attendingDoctorSite && (
            <Alert severity="error" className="mb-2">
              Le site est manquant.
            </Alert>
          )}
          {lockQuoteAlerts?.quotePrecotations.length === 0 && (
            <Alert severity="error" className="mb-2">
              Aucune précotation enregistrée.
            </Alert>
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title="Rendre définitif"
        message={
          'Voulez-vous vraiment rendre définitif ce devis ? Il ne sera plus modifiable et l\'annotation \'(PROVISOIRE)\' sera retirée du fichier pdf généré.'
        }
        open={!!lockQuoteForm}
        isProcessing={loadingUpdate}
        onConfirm={() => {
          if (lockQuoteForm) {
            dispatch(
              updateQuote({
                id: lockQuoteForm.id,
                dto: { isLocked: true },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'Le devis a été modifié avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .catch(() =>
                dispatch(
                  enqueueSnackbar({
                    message:
                      'Le devis n\'a pas pu être validé, vérifiez les informations',
                    options: { variant: 'error' },
                  }),
                ),
              )
              .finally(() => setLockQuoteForm(null))
          }
        }}
        onCancel={() => {
          setLockQuoteForm(null)
        }}
      />

      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '80vh',
          },
        }}
        open={!!validation?.erreurs && validation.erreurs.length > 0}
        TransitionComponent={UpTransition}
      >
        <div className="flex justify-end p-4">
          <button
            type="button"
            className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
            onClick={() => dispatch(setValidation(null))}
          >
            Fermer
          </button>
        </div>
        <DialogContent>
          {validation && validation.visite && (
            <BillSummaryComponent data={validation} quoteCase />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  )
}
