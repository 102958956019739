import React, { useEffect, useState } from 'react'
import { CouvertureDto } from '../../common/interfaces'
import { getCartVitalUrl } from '../../state/thunks/cardReadThunk'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { setReaderId } from '../../state/reducers/patientsReducer'
import { sendReaderResponse } from '../../services/CardReadService'
import { enqueueSnackbar } from '../../state/reducers/alertReducer'
import { isBase64 } from '../../utils/dto.mapper'
import { Dialog } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { BillingService } from '../../services/BillingService'
import { MemberDto } from '../../services/api'
import MemberAutocomplete from '../MemberAutocomplete'
import { doGetMemberSituation } from '../../state/reducers/billingReducer'
import StorageService from '../../services/storage'
import {
  Alert,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

type Props = {
  isOpen: boolean
  onFinish: (dto: CouvertureDto) => void
  visitId: number
  readDate: string
  onClose: () => void
}
const ContextComponent: React.FC<Props> = ({
  isOpen,
  onFinish,
  visitId,
  readDate,
  onClose,
}) => {
  const [url, setUrl] = useState('')
  const [selectedMember, setSelectedMember] = useState<MemberDto | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const dispatch = useAppDispatch()
  const { memberSituation } = useAppSelector((state) => ({
    memberSituation: state.billing.memberSituation,
  }))

  useEffect(() => {
    const cachedData = StorageService.getMemberSituationCache()
    if (cachedData && cachedData.member) {
      setSelectedMember(cachedData.member)
    }
  }, [])

  useEffect(() => {
    if (selectedMember) {
      dispatch(doGetMemberSituation(selectedMember.id))
        .unwrap()
        .catch(() => {
          dispatch(
            enqueueSnackbar({
              message:
                'Erreur lors de la récupération de la situation du médecin',
              options: { variant: 'error' },
            }),
          )
        })
    }
  }, [selectedMember, dispatch])

  useEffect(() => {
    if (memberSituation && selectedMember) {
      StorageService.setMemberSituationCache(selectedMember, memberSituation)
    }
  }, [memberSituation, selectedMember])

  const handleMemberSelect = (member: MemberDto | null) => {
    setSelectedMember(member)
  }

  const submitRestoreContext = () => {
    if (!isOpen) {
      if (url) {
        setUrl('')
      }
      return
    }

    if (!memberSituation) {
      dispatch(
        enqueueSnackbar({
          message: 'Situation du médecin manquante',
          options: { variant: 'error' },
        }),
      )
      return
    }

    dispatch(getCartVitalUrl())
      .unwrap()
      .then(async (dto) => {
        dispatch(setReaderId(dto.id))
        try {
          const { erreur, erreurMessage, url } =
            await BillingService.getUrlByContext({
              idResip: memberSituation?.idJfse || 0,
              idLecteur: dto.id,
            })
          if (erreur) {
            throw new Error(erreurMessage)
          }
          setUrl(url)
          setDialogOpen(true)
        } catch (err) {
          dispatch(
            enqueueSnackbar({
              message:
                err instanceof Error
                  ? err.message
                  : 'Erreur lors de la récupération du contexte',
              options: { variant: 'error' },
            }),
          )
          onClose()
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err.message,
            options: { variant: 'error' },
          }),
        )
      })
  }

  useEffect(() => {
    const handlePostBack = async (msg: MessageEvent) => {
      const data = msg.data
      if (!isBase64(data)) {
        return
      }

      const decodedStr = atob(data)
      const obj = JSON.parse(decodedStr)
      if (!obj.method) {
        dispatch(
          enqueueSnackbar({
            message: 'Erreur de lecture',
            options: { variant: 'error' },
          }),
        )
        onClose()
        return
      }
      if (obj.method.cancel) {
        onClose()
        return
      }
      try {
        const dto = await sendReaderResponse(visitId, readDate, obj)
        if (!dto) {
          throw new Error('Erreur de lecture')
        }
        onFinish(dto)
        onClose()
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message:
              error instanceof Error ? error.message : 'Erreur de lecture',
            options: { variant: 'error' },
          }),
        )
        return
      } finally {
        onClose()
      }
    }

    window.addEventListener('message', handlePostBack, false)
    return () => {
      window.removeEventListener('message', handlePostBack)
    }
  }, [visitId, readDate])

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen && !dialogOpen}
        onClose={onClose}
      >
        <Box sx={{ padding: '20px' }}>
          <Typography variant="h6">Médecin</Typography>
          <MemberAutocomplete
            label="Médecin"
            value={selectedMember}
            onChange={handleMemberSelect}
          />
          {!memberSituation && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              La situation du médecin est manquante
            </Alert>
          )}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={submitRestoreContext}
              disabled={!memberSituation}
            >
              Restituer le contexte
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={onClose}
        className="z-[100000]"
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: '#E2E6F2',
            zIndex: 100001,
          },
        }}
      >
        <DialogTitle
          style={{
            background: '#fff',
            color: '#000',
          }}
        >
          Restitution du contexte
        </DialogTitle>
        <DialogContent
          style={{
            minWidth: '850px',
            display: 'flex',
            justifyContent: 'center',
            height: '80vh',
            alignItems: 'center',
            zIndex: 100000001,
          }}
        >
          {url === '' ? (
            <CircularProgress className="!text-purple-600" />
          ) : (
            <iframe
              style={{
                width: '100%',
                height: '80vh',
                border: 'none',
                overflow: 'hidden',
                marginTop: '20px',
              }}
              src={url}
            ></iframe>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ContextComponent
