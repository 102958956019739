import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { UpTransition } from '@components/animations'
import ChequeDepositForm from '@components/forms/ChequeDepositForm'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  getChequeDeposits,
  removeChequeDeposit,
  setChequeDepositFilters,
  updateChequeDeposit,
} from '@state/reducers/chequeDepositReducer'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { ChequeDepositDto, ChequeDto } from '@services/dtos'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import ChequesTable from './ChequesTable'
import { debounce } from 'lodash'
import { DateInputField } from '../inputs/DateInputField'

export default function ChequeDepositsTable() {
  const [isChequeDepositFormOpen, setIsChequeDepositFormOpen] = useState(false)
  const [deletingChequeDepositForm, setDeletingChequeDepositForm] =
    useState<ChequeDepositDto | null>(null)
  const [lockChequeDepositForm, setLockChequeDepositForm] =
    useState<ChequeDepositDto | null>(null)
  const [editingChequeDeposit, setEditingChequeDeposit] =
    useState<ChequeDepositDto | null>(null)
  const [openChequeDepositId, setOpenChequeDepositId] = useState<number | null>(
    null,
  )

  const dispatch = useAppDispatch()
  const { chequeDeposits, loading, filters } = useAppSelector(
    ({ chequeDeposit }) => ({
      chequeDeposits: chequeDeposit.chequeDeposits,
      loading: chequeDeposit.loading,
      filters: chequeDeposit.filters,
    }),
  )

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(getChequeDeposits())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const actionBtnObj = [
    {
      id: 1,
      btnName: () => 'Voir les chèques',
      icon: () => <OpenInNewIcon color="primary" />,
      action: (row: ChequeDepositDto) => setOpenChequeDepositId(row.id),
      actionKey: 'viewChequeDeposit',
    },
    {
      id: 2,
      btnName: () => 'Modifier',
      icon: () => <EditIcon color="primary" />,
      action: (row: ChequeDepositDto) => {
        if (row.isLocked) return
        setEditingChequeDeposit(row)
        setIsChequeDepositFormOpen(true)
      },
      actionKey: 'editChequeDeposit',
    },
    {
      id: 3,
      btnName: (row: ChequeDepositDto) =>
        row.isLocked ? 'Déverrouiller' : 'Verrouiller',
      icon: (row: ChequeDepositDto) =>
        row.isLocked ? (
          <LockIcon color="primary" />
        ) : (
          <LockOpenIcon color="primary" />
        ),
      action: (row: ChequeDepositDto) => setLockChequeDepositForm(row),
      actionKey: 'lockActionChequeDeposit',
    },
    {
      id: 4,
      btnName: () => 'Supprimer',
      icon: () => <DeleteIcon color="primary" />,
      action: (row: ChequeDepositDto) =>
        !row.isLocked && setDeletingChequeDepositForm(row),
      actionKey: 'deleteChequeDeposit',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      sortable: false,
      renderCell: (params) => {
        const chequeDeposit = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              const actionDisabled =
                chequeDeposit.isLocked &&
                ['deleteChequeDeposit', 'editChequeDeposit'].includes(
                  el.actionKey,
                )
              acc.push(
                <Tooltip
                  key={chequeDeposit.id + '-' + el.btnName(chequeDeposit)}
                  title={
                    actionDisabled
                      ? 'Impossible si verrouillée'
                      : el.btnName(chequeDeposit)
                  }
                >
                  <div
                    className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                      actionDisabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(chequeDeposit)
                    }}
                  >
                    {el.icon(chequeDeposit)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date de création',
      valueGetter: ({ row }) =>
        moment(row.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 140,
    },
    {
      field: 'depositReference',
      headerName: 'Référence',
      width: 140,
    },
    {
      field: 'chequesCount',
      headerName: 'Nombre de chèques',
      valueGetter: ({ row }) => row.cheques?.length,
      width: 160,
    },
    {
      field: 'unpaidChequesCount',
      headerName: 'Nombre d\'impayés',
      valueGetter: ({ row }) =>
        row.cheques?.filter(({ unpaid }) => unpaid).length,
      width: 160,
    },
    {
      field: 'totalAmount',
      headerName: 'Montant total',
      valueGetter: ({ row }) =>
        `${row.cheques?.reduce(
          (acc: number, cheque: ChequeDto) =>
            acc + cheque.patientPayment.amount,
          0,
        )}€`,
      width: 130,
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 120px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={chequeDeposits.datas}
                rowCount={chequeDeposits.totalCount}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSize={20}
                onPageChange={(page) => {
                  dispatch(setChequeDepositFilters({ page }))
                }}
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <div className="flex ml-4 items-center gap-2 mt-2">
                        <div className="mr-4">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsChequeDepositFormOpen(true)}
                          >
                            <i className="fas fa-plus"></i>{' '}
                            <span className="ml-2">Ajouter</span>
                          </Button>
                        </div>
                        <DateInputField
                          value={
                            filters.from ? moment(filters.from) : undefined
                          }
                          onChange={(date) => {
                            dispatch(
                              setChequeDepositFilters({
                                from: date
                                  ? date.startOf('day').toISOString()
                                  : undefined,
                              }),
                            )
                          }}
                        />
                        <Typography>au</Typography>
                        <DateInputField
                          value={filters.to ? moment(filters.to) : undefined}
                          onChange={(date) => {
                            dispatch(
                              setChequeDepositFilters({
                                to: date
                                  ? date.endOf('day').toISOString()
                                  : undefined,
                              }),
                            )
                          }}
                        />
                      </div>
                    </GridToolbarContainer>
                  ),
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={isChequeDepositFormOpen}
        onClose={() => setIsChequeDepositFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isChequeDepositFormOpen && (
            <ChequeDepositForm
              onClose={() => {
                setIsChequeDepositFormOpen(false)
                setEditingChequeDeposit(null)
              }}
              editingChequeDeposit={editingChequeDeposit}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!openChequeDepositId}
        onClose={() => setOpenChequeDepositId(null)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {openChequeDepositId && (
            <>
              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
                  onClick={() => setOpenChequeDepositId(null)}
                >
                  Fermer
                </button>
              </div>
              <ChequesTable
                cheques={
                  chequeDeposits.datas.find(
                    ({ id }) => id === openChequeDepositId,
                  )?.cheques || []
                }
                chequeDepositId={openChequeDepositId}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title="Suppression"
        message="Voulez-vous vraiment supprimer cette remise de chèques ?"
        open={!!deletingChequeDepositForm}
        onConfirm={() => {
          if (deletingChequeDepositForm) {
            dispatch(removeChequeDeposit(deletingChequeDepositForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'La remise de chèques a été supprimée avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => {
                setDeletingChequeDepositForm(null)
                dispatch(getChequeDeposits())
              })
          }
        }}
        onCancel={() => {
          setDeletingChequeDepositForm(null)
        }}
      />

      <ConfirmDialog
        title="Verrouillage"
        message={`Voulez-vous vraiment ${
          lockChequeDepositForm?.isLocked ? 'déverrouiller' : 'verrouiller'
        } cette remise de chèques ?`}
        open={!!lockChequeDepositForm}
        onConfirm={() => {
          if (lockChequeDepositForm) {
            dispatch(
              updateChequeDeposit({
                id: lockChequeDepositForm.id,
                dto: { isLocked: !lockChequeDepositForm.isLocked },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'La remise de chèques a été modifiée avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setLockChequeDepositForm(null))
          }
        }}
        onCancel={() => {
          setLockChequeDepositForm(null)
        }}
      />
    </Container>
  )
}
