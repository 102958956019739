import { TextField, TextFieldProps } from '@mui/material'
import { Control, useController } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

export const useInputFieldStyles = makeStyles(() => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      backgroundColor: '#f8f9fa',
      border: 'none',
      '& fieldset': {
        border: '1px solid #e9ecef',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 2px #e9ecef',
      },
    },
    '& .MuiInputBase-input': {
      padding: 16,
    },
  },
}))

type Props = TextFieldProps & {
  control?: Control<any>
}
const InputField = ({ control, ...props }: Props) => {
  const { field, fieldState } = useController({
    control,
    name: props.name || '',
  })
  const classes = useInputFieldStyles()

  return (
    <TextField
      {...props}
      {...field}
      fullWidth
      error={!!fieldState.error}
      className={classes.textField}
      helperText={fieldState.error?.message}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export default InputField
