import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, useForm } from 'react-hook-form'
import {
  CreateChequeDepositDto,
  ChequeDepositDto,
  UpdateChequeDepositDto,
  ChequeDto,
} from '@services/dtos'
import { chequeDepositSchema } from '@utils/schemas'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { Box, Grid, Switch, Tooltip, Typography } from '@mui/material'
import {
  getUndepositedCheques,
  updateUndepositedChequesFilters,
} from '@state/reducers/patientPaymentReducer'
import moment from 'moment'
import { DataGridPro, GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { debounce } from 'lodash'
import {
  createChequeDeposit,
  getChequeDeposits,
  updateChequeDeposit,
} from '@state/reducers/chequeDepositReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ErrorIcon from '@mui/icons-material/Error'

type Props = {
  editingChequeDeposit?: ChequeDepositDto | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const ChequeDepositForm: React.FC<Props> = ({
  editingChequeDeposit,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UpdateChequeDepositDto>({
    defaultValues: editingChequeDeposit
      ? (({ depositReference }) => ({
          depositReference,
        }))(editingChequeDeposit)
      : undefined,
    resolver: yupResolver(chequeDepositSchema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [undepositedChequesSelected, setUndepositedChequesSelected] = useState<
    ChequeDto[]
  >([])

  const { undepositedCheques, undepositedChequesFilters } = useAppSelector(
    ({ patientPayment }) => ({
      undepositedCheques: patientPayment.undepositedCheques,
      undepositedChequesFilters: patientPayment.undepositedChequesFilters,
    }),
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (editingChequeDeposit) {
      dispatch(
        updateUndepositedChequesFilters({
          includeChequeDepositId: editingChequeDeposit.id,
        }),
      )
      setUndepositedChequesSelected(editingChequeDeposit.cheques)
    } else {
      dispatch(getUndepositedCheques())
    }

    return () => {
      reset()
      dispatch(
        updateUndepositedChequesFilters({
          chequeNumber: undefined,
          drawerName: undefined,
          to: undefined,
          from: undefined,
          includeChequeDepositId: undefined,
          requestedDepositDateChecked: true,
          page: 0,
        }),
      )
      setUndepositedChequesSelected([])
    }
  }, [])

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  const onSubmitForm = async (data: CreateChequeDepositDto) => {
    setIsSubmitting(true)

    if (editingChequeDeposit) {
      dispatch(updateChequeDeposit({ id: editingChequeDeposit.id, dto: data }))
        .unwrap()
        .then(async () => cb('La remise de chèques a été modifiée avec succès'))
        .finally(() => {
          setIsSubmitting(false)
          dispatch(getChequeDeposits())
        })
    } else {
      dispatch(createChequeDeposit(data))
        .unwrap()
        .then(async () => cb('La remise de chèques a été créée avec succès'))
        .finally(() => {
          setIsSubmitting(false)
          dispatch(getChequeDeposits())
        })
    }
  }

  // setTimeout : fix No row with id #[...] found
  const removeCheque = (id: GridRowId) => {
    setTimeout(() => {
      setUndepositedChequesSelected((prevRows) =>
        prevRows.filter((row) => +row.id !== +id),
      )
    })
  }

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(getUndepositedCheques())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [undepositedChequesFilters, dispatch])

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: ({ row }) => {
        const isSelected = undepositedChequesSelected
          .map(({ id }) => id)
          .includes(row.id)
        return (
          <Tooltip
            title={
              isSelected ? 'Retirer de la sélection' : 'Ajouter à la sélection'
            }
            arrow
          >
            <IconButton
              onClick={() =>
                isSelected
                  ? removeCheque(row.id)
                  : setUndepositedChequesSelected((prev) => [...prev, row])
              }
            >
              {isSelected ? (
                <CheckBoxIcon color="primary" />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </IconButton>
          </Tooltip>
        )
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      valueGetter: ({ row }) => moment(row.createdAt).format('DD/MM/YYYY'),
    },
    {
      field: 'chequeNumber',
      headerName: 'N° de chèque',
      width: 120,
      valueGetter: ({ row }) => row.chequeNumber,
    },
    {
      field: 'drawerName',
      headerName: 'Tireur',
      width: 140,
      valueGetter: ({ row }) => row.drawerName,
    },
    {
      field: 'minimumRequestedDepositDate',
      headerName: 'Date dépôt minimale demandée',
      width: 140,
      renderCell: ({ row }) => {
        if (!row.minimumRequestedDepositDate) return undefined

        const date = moment(row.minimumRequestedDepositDate).startOf('day')
        const isAfterToday = date.isAfter(moment())

        return (
          <Box display="flex" alignItems="center">
            {isAfterToday && <ErrorIcon className="text-red-500 mr-2" />}
            <Typography
              variant="body2"
              className={`${isAfterToday ? 'text-red-500 font-bold' : ''}`}
            >
              {date.format('DD/MM/YYYY')}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: 'maximumDepositDate',
      headerName: 'Date dépôt maximale',
      width: 145,
      valueGetter: ({ row }) =>
        moment(row.maximumDepositDate).format('DD/MM/YYYY'),
    },
    {
      field: 'bankEstablishment',
      headerName: 'Banque',
      width: 160,
      valueGetter: ({ row }) => row.bankEstablishment.label,
    },
    {
      field: 'amount',
      headerName: 'Montant',
      width: 120,
      valueGetter: ({ row }) => `${row.patientPayment.amount}€`,
    },
    {
      field: 'lastActionBy',
      headerName: 'Encaissé par',
      width: 120,
      valueGetter: ({ row }) =>
        `${row.patientPayment.createdBy.firstName ?? ''} ${
          row.patientPayment.createdBy.lastName ?? ''
        }`,
    },
  ]

  const selectedColumns: GridColumns = [
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: ({ row }) => (
        <Tooltip title="Retirer de la sélection" arrow>
          <IconButton onClick={() => removeCheque(row.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    ...columns.slice(1),
  ]

  const handlePreSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setValue(
      'chequeIds',
      undepositedChequesSelected.map((cheque) => cheque.id),
    )
    handleSubmit(onSubmitForm)()
  }

  return (
    <div className="w-full h-full p-4">
      <form onSubmit={handlePreSubmit} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Remise de chèques
          </h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingChequeDeposit ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item sm={4}>
            <fieldset className="border border-gray-200 p-4 rounded-lg">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Informations
              </legend>

              <FormField
                label="Référence de la remise"
                error={errors.depositReference?.message}
              >
                <input
                  type="text"
                  className="w-full border border-gray-200 rounded-lg p-2"
                  {...register('depositReference')}
                />
              </FormField>
            </fieldset>

            <fieldset className="border border-gray-200 p-4 rounded-lg mt-4">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Recherche de chèques
              </legend>
              <div className="flex items-center">
                <FormField label="">
                  <div className="flex items-center">
                    <span className="mr-2 text-sm font-medium text-gray-700">
                      Date minimale de dépôt valide
                    </span>
                    <Switch
                      checked={
                        undepositedChequesFilters.requestedDepositDateChecked
                      }
                      onChange={(e) =>
                        dispatch(
                          updateUndepositedChequesFilters({
                            requestedDepositDateChecked: e.target.checked,
                          }),
                        )
                      }
                      color="primary"
                    />
                  </div>
                </FormField>
              </div>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <FormField label="Règlements du">
                    <input
                      type="date"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) =>
                        dispatch(
                          updateUndepositedChequesFilters({
                            from: e.target.value
                              ? moment(e.target.value, 'YYYY-MM-DD')
                                  .startOf('day')
                                  .toISOString()
                              : undefined,
                          }),
                        )
                      }
                      value={
                        undepositedChequesFilters.from
                          ? moment(undepositedChequesFilters.from).format(
                              'YYYY-MM-DD',
                            )
                          : undefined
                      }
                      placeholder="Choisissez une date"
                    />
                  </FormField>
                </div>
                <div className="w-1/2">
                  <FormField label="au">
                    <input
                      type="date"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) =>
                        dispatch(
                          updateUndepositedChequesFilters({
                            to: e.target.value
                              ? moment(e.target.value, 'YYYY-MM-DD')
                                  .endOf('day')
                                  .toISOString()
                              : undefined,
                          }),
                        )
                      }
                      value={
                        undepositedChequesFilters.to
                          ? moment(undepositedChequesFilters.to).format(
                              'YYYY-MM-DD',
                            )
                          : undefined
                      }
                      placeholder="Choisissez une date"
                    />
                  </FormField>
                </div>
              </div>

              <div className="flex space-x-4 mt-4">
                <div className="w-1/2">
                  <FormField label="Tireur">
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) =>
                        dispatch(
                          updateUndepositedChequesFilters({
                            drawerName: e.target.value || undefined,
                          }),
                        )
                      }
                      value={undepositedChequesFilters.drawerName || ''}
                      placeholder="Tireur"
                    />
                  </FormField>
                </div>
                <div className="w-1/2">
                  <FormField label="N° de chèque">
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      onChange={(e) => {
                        dispatch(
                          updateUndepositedChequesFilters({
                            chequeNumber: e.target.value || undefined,
                          }),
                        )
                      }}
                      value={undepositedChequesFilters.chequeNumber || ''}
                      placeholder="Numéro de chèque"
                    />
                  </FormField>
                </div>
              </div>
            </fieldset>
          </Grid>

          <Grid item sm={8}>
            {errors.chequeIds && (
              <p className="text-red-500 text-sm mt-2">
                {(errors.chequeIds as unknown as FieldError).message}
              </p>
            )}
            <DataGridPro
              density="standard"
              style={{
                height: '40vh',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '15px 15px 25px 25px',
              }}
              className="mb-4"
              columns={columns}
              rows={undepositedCheques.datas}
              rowCount={undepositedCheques.totalCount}
              disableSelectionOnClick
              pagination
              paginationMode="server"
              disableMultipleSelection
              pageSize={20}
              onPageChange={(page) => {
                dispatch(updateUndepositedChequesFilters({ page }))
              }}
            />

            <DataGridPro
              density="standard"
              style={{
                height: '40vh',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '15px 15px 25px 25px',
              }}
              columns={selectedColumns}
              rows={undepositedChequesSelected}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default ChequeDepositForm
