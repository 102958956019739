import { axios, getConfigs, IRequestConfig } from './api'
import {
  CreateBankEstablishmentDto,
  BankEstablishmentDto,
  UpdateBankEstablishmentDto,
} from './dtos'

export class BankEstablishmentService {
  private static readonly endpoint = 'bank-establishment'

  static getAll(
    params: { includeInactive?: boolean } = {},
  ): Promise<BankEstablishmentDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = params
      axios(configs, resolve, reject)
    })
  }

  static create(
    dto: CreateBankEstablishmentDto,
  ): Promise<BankEstablishmentDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static update(
    id: number,
    dto: UpdateBankEstablishmentDto,
  ): Promise<BankEstablishmentDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
