import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: { main: '#3f3f3f' },
    secondary: { main: '#E91E63' },
    text: {
      primary: '#000',
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },

  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'white',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#d9e1f780',
          },
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },

    MuiIcon: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})
