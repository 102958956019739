import React, { useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import {
  AccountBalance as AccountBalanceIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import ChequeDepositsTable from '@components/tables/ChequeDepositsTable'
import QuotesTable from '@components/tables/QuotesTable'
import PatientInvoicesTable from '@components/tables/PatientInvoicesTable'
import PeopleIcon from '@mui/icons-material/People'
import DomainIcon from '@mui/icons-material/Domain'
import OrganismInvoicesTable from '@components/tables/OrganismInvoicesTable'
import InvoiceBatchsTable from '@components/tables/LotsTable'
import SourceIcon from '@mui/icons-material/Source'
import CashRegistersTab from '@components/tables/CashRegistersTab'

const AccountingLayout = () => {
  const [value, setValue] = useState('0')

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <TabContext value={value}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: '#fafafa',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="accounting tabs"
          sx={{
            minHeight: '48px',
            '& .MuiTab-root': {
              minHeight: '48px',
              padding: '6px 12px',
              fontSize: '0.875rem',
            },
            '& .MuiTab-iconWrapper': {
              marginRight: '8px',
            },
          }}
        >
          <Tab
            icon={<PointOfSaleIcon />}
            iconPosition="start"
            label="Caisses"
            value="0"
          />
          <Tab
            icon={<PeopleIcon />}
            iconPosition="start"
            label="Facturation patient"
            value="1"
          />
          <Tab
            icon={<DomainIcon />}
            iconPosition="start"
            label="Facturation organismes"
            value="2"
          />
          <Tab
            icon={<AccountBalanceIcon />}
            iconPosition="start"
            label="Remises de chèques"
            value="3"
          />
          <Tab
            icon={<DescriptionIcon />}
            iconPosition="start"
            label="Devis"
            value="4"
          />
          <Tab
            icon={<SourceIcon />}
            iconPosition="start"
            label="Lots"
            value="5"
          />
        </Tabs>
      </Box>
      <TabPanel value="0" sx={{ p: 0 }}>
        <CashRegistersTab />
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0 }}>
        <PatientInvoicesTable />
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0 }}>
        <OrganismInvoicesTable />
      </TabPanel>
      <TabPanel value="3" sx={{ p: 0 }}>
        <ChequeDepositsTable />
      </TabPanel>
      <TabPanel value="4" sx={{ p: 0 }}>
        <QuotesTable />
      </TabPanel>
      <TabPanel value="5" sx={{ p: 0 }}>
        <InvoiceBatchsTable />
      </TabPanel>
    </TabContext>
  )
}

export default AccountingLayout
