import React from 'react'
import { IconButton, MenuItem, Select } from '@mui/material'
import { useAppDispatch } from '../../../../hooks/reduxHooks'
import {
  removeUnfinishedPrecot,
  updatePrecotation,
} from '../../../../state/reducers/billingReducer'
import { CCAMDto, NGAPDto, PrecotationDto } from '../../../../services/dtos'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CcamAndNgapSearchComponent from '@components/billing/CcamAndNgapSearchComponent'
import DeleteIcon from '@mui/icons-material/Delete'
import { codeEntentePrealable } from '@utils/constants'
import { DateInputField } from '../../../../components/inputs/DateInputField'
import moment from 'moment'

interface QuotationTableProps {
  rows: PrecotationDto[]
  onAddRow?: () => void
  medicalOrderId?: number
  isLoading: boolean
  registeredAt?: Date
  onUpdateRow?: (updatedRow: PrecotationDto) => void
  onRemoveRow?: (updatedRow: PrecotationDto) => void
}

const QuotationTable: React.FC<QuotationTableProps> = ({
  rows,
  isLoading,
  registeredAt,
  onUpdateRow,
  onRemoveRow,
}) => {
  const dispatch = useAppDispatch()

  const inputClassName =
    'w-full px-3 py-1.5 text-sm rounded-md border-gray-300 border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200'
  const selectClassName =
    'w-full px-3 py-1.5 text-sm rounded-md border-gray-300 border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200 bg-white'

  const handleRemove = (cotation: PrecotationDto) => {
    onRemoveRow
      ? onRemoveRow(cotation)
      : dispatch(removeUnfinishedPrecot(cotation))
  }

  const handleUpdate = (updatedRow: PrecotationDto) => {
    onUpdateRow
      ? onUpdateRow(updatedRow)
      : dispatch(updatePrecotation(updatedRow))
  }

  const getModifierColumn = (key: string, name: string) => {
    return {
      field: key,
      headerName: name,
      flex: 1,
      renderCell: (params) => {
        const { value, row } = params
        const options = ['', ...(row.modificateur?.split('') || [])]
        const isNgap = row.cotation_type === 'NGAP'

        return isNgap ? (
          <></>
        ) : (
          <Select
            size="small"
            fullWidth
            value={value || ''}
            onChange={(e) => {
              const newValue = e.target.value || null
              const updatedRow = { ...row, [key]: newValue }
              handleUpdate(updatedRow as PrecotationDto)
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                fontSize: '0.875rem',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option === '' ? 'Aucun' : option}
              </MenuItem>
            ))}
          </Select>
        )
      },
    }
  }

  return (
    <div className="flex overflow-y-scroll flex-1">
      <DataGridPro
        loading={isLoading}
        sx={{
          width: '100%',
          height: '100%',
          '& .MuiDataGrid-cell': {
            padding: '8px',
          },
        }}
        columns={[
          {
            field: 'cotation',
            headerName: 'Code Acte',
            width: 160,
            renderCell: (params) => (
              <div className="flex flex-row items-center">
                {params.row.cotation}
                <CcamAndNgapSearchComponent
                  onChange={(v) => {
                    const row = params.api.getRow(params.id) as PrecotationDto
                    let newRow = { ...row }
                    if (v.cotationType === 'CCAM') {
                      const val = v.value as CCAMDto
                      newRow = {
                        ...newRow,
                        cotation: val.code,
                        prix_unitaire: val.prixNonOptam,
                        modificateur: val.modificateurs || '',
                        cotation_type: 'CCAM',
                      }
                    } else {
                      const val = v.value as NGAPDto
                      newRow = {
                        ...newRow,
                        cotation: val.code,
                        prix_unitaire: val.prix,
                        cotation_type: 'NGAP',
                        modificateur: '',
                        m1: '',
                        m2: '',
                        m3: '',
                        m4: '',
                      }
                    }
                    handleUpdate(newRow)
                  }}
                />
              </div>
            ),
          },
          {
            field: 'cotation_type',
            headerName: 'Cotation',
            flex: 1,
            editable: false,
          },
          {
            field: 'date_execution',
            headerName: 'Date de soin',
            width: 200,
            renderCell: (params) => (
              <DateInputField
                size="small"
                value={
                  params.value
                    ? moment(params.value)
                    : registeredAt
                    ? moment(registeredAt)
                    : undefined
                }
                onChange={(v) => {
                  const updatedRow = {
                    ...params.row,
                    date_execution: v?.format('YYYY-MM-DD') || '',
                  }
                  handleUpdate(updatedRow)
                }}
              />
            ),
          },
          {
            ...getModifierColumn('m1', 'M1'),
          },
          {
            ...getModifierColumn('m2', 'M2'),
          },
          {
            ...getModifierColumn('m3', 'M3'),
          },
          {
            ...getModifierColumn('m4', 'M4'),
          },
          {
            field: 'association',
            headerName: 'Association',
            flex: 1,
            renderCell: (params) => {
              const isNgap = params.row.cotation_type === 'NGAP'

              return isNgap ? (
                <></>
              ) : (
                <input
                  type="text"
                  value={params.value || ''}
                  onChange={(e) => {
                    const updatedRow = {
                      ...params.row,
                      association: Number(e.target.value),
                    }
                    handleUpdate(updatedRow)
                  }}
                  className={inputClassName}
                />
              )
            },
          },
          {
            field: 'prix_unitaire',
            headerName: 'Prix Unitaire',
            flex: 1,
            renderCell: (params) => (
              <input
                type="number"
                value={params.value || ''}
                onChange={(e) => {
                  const updatedRow = {
                    ...params.row,
                    prix_unitaire: Number(e.target.value),
                  }
                  handleUpdate(updatedRow)
                }}
                className={inputClassName}
              />
            ),
          },
          {
            field: 'total',
            headerName: 'Total',
            flex: 1,
          },
          {
            field: 'code_entente_prealable',
            headerName: 'Entente Préalable',
            flex: 1,
            renderCell: (params) => (
              <select
                value={params.value || ''}
                onChange={(e) => {
                  const updatedRow = {
                    ...params.row,
                    code_entente_prealable: e.target.value,
                  }
                  handleUpdate(updatedRow)
                }}
                className={selectClassName}
              >
                {codeEntentePrealable.map((code) => (
                  <option key={code.value} value={code.value}>
                    {code.label}
                  </option>
                ))}
              </select>
            ),
          },
          {
            field: 'date_entente_prealable',
            headerName: 'Date Entente Préalable',
            flex: 1,
            renderCell: (params) =>
              !params.row.code_entente_prealable ||
              params.row.code_entente_prealable === '0' ? (
                <></>
              ) : (
                // eslint-disable-next-line indent
                <input
                  type="date"
                  value={params.value || ''}
                  onChange={(e) => {
                    const updatedRow = {
                      ...params.row,
                      date_entente_prealable: e.target.value,
                    }
                    handleUpdate(updatedRow)
                  }}
                  disabled={!params.row.code_entente_prealable}
                  className={inputClassName}
                />
              ),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
              <div className="flex justify-center">
                <IconButton onClick={() => handleRemove(params.row)}>
                  <DeleteIcon sx={{ color: 'red' }} />
                </IconButton>
              </div>
            ),
          },
        ]}
        getRowId={(row) => row.id || row.identifier || ''}
        rows={rows}
      />
    </div>
  )
}

export default QuotationTable
