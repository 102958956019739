import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  getBillingCompanies,
  removeBillingCompany,
  updateBillingCompany,
} from '@state/reducers/billingCompanyReducer'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { UpTransition } from '@components/animations'
import BillingCompanyForm from '@components/forms/BillingCompanyForm'
import { BillingCompanyDto } from '@/services/dtos'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ToggleOn as ToggleOnIcon,
  ToggleOffOutlined as ToggleOffOutlinedIcon,
} from '@mui/icons-material'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import { enqueueSnackbar } from '@state/reducers/alertReducer'

export default function BillingCompanysTable() {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [editingBillingCompany, setEditingBillingCompany] =
    useState<BillingCompanyDto | null>(null)
  const [isBillingCompanyFormOpen, setIsBillingCompanyFormOpen] =
    useState(false)
  const [deletingBillingCompanyForm, setDeletingBillingCompanyForm] =
    useState<BillingCompanyDto | null>(null)
  const [togglingBillingCompany, setTogglingBillingCompany] =
    useState<BillingCompanyDto | null>(null)
  const { billingCompanies } = useAppSelector((state) => ({
    billingCompanies: state.billingCompany.billingCompanies,
  }))

  useEffect(() => {
    setIsLoading(true)
    dispatch(getBillingCompanies({ includeInactive: true })).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const actionBtnObj = [
    {
      id: 1,
      btnName: () => 'Modifier',
      icon: () => <EditIcon color="primary" />,
      action: (row: BillingCompanyDto) => {
        setEditingBillingCompany(row)
        setIsBillingCompanyFormOpen(true)
      },
      actionKey: 'editBillingCompany',
    },
    {
      id: 2,
      btnName: (row: BillingCompanyDto) =>
        row.isActive ? 'Désactiver' : 'Activer',
      icon: (row: BillingCompanyDto) =>
        row.isActive ? (
          <ToggleOnIcon fontSize="small" color="primary" />
        ) : (
          <ToggleOffOutlinedIcon fontSize="small" color="primary" />
        ),
      action: (row: BillingCompanyDto) => setTogglingBillingCompany(row),
      actionKey: 'toggleBillingCompany',
    },
    {
      id: 3,
      btnName: () => 'Supprimer',
      icon: () => <DeleteIcon color="primary" />,
      action: (row: BillingCompanyDto) => setDeletingBillingCompanyForm(row),
      actionKey: 'deleteBillingCompany',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        const billingCompany = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              acc.push(
                <Tooltip
                  key={billingCompany.id + '-' + el.btnName(billingCompany)}
                  title={el.btnName(billingCompany)}
                >
                  <div
                    className="cursor-pointer p-1 hover:bg-gray-200 rounded"
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(billingCompany)
                    }}
                  >
                    {el.icon(billingCompany)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 70,
    },
    {
      field: 'label',
      headerName: 'Libellé',
      width: 180,
    },
    {
      field: 'bankAccountName',
      headerName: 'Compte bancaire',
      width: 180,
      valueGetter: ({ row }) => row.bankAccount.name,
    },
    {
      field: 'siteLabel',
      headerName: 'Site',
      width: 180,
      valueGetter: ({ row }) => row.site.label,
    },
    {
      field: 'finessNumber',
      headerName: 'Numéro FINESS',
      width: 180,
    },
    {
      field: 'registrationNumber',
      headerName: 'Numéro d\'immatriculation',
      width: 220,
    },
    {
      field: 'taxNumber',
      headerName: 'Numéro fiscal',
      width: 180,
    },
    {
      field: 'fullAddress',
      headerName: 'Domiciliation',
      width: 180,
      renderCell: ({ row }) => (
        <div>
          <p>{row.address}</p>
          <p>
            {row.postalCode} {row.city}
          </p>
          <p>{row.country}</p>
        </div>
      ),
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 65px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                pagination
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <div className="ml-2 mt-2">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsBillingCompanyFormOpen(true)}
                        >
                          <i className="fas fa-plus"></i>{' '}
                          <span className="ml-2">Ajouter</span>
                        </Button>
                      </div>
                    </GridToolbarContainer>
                  ),
                }}
                rowHeight={80}
                columns={columns}
                rows={billingCompanies}
                rowCount={billingCompanies.length}
                disableSelectionOnClick
                disableMultipleSelection
                loading={isLoading}
                getRowClassName={(params) =>
                  params.row.isActive ? '' : 'text-gray-400'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={isBillingCompanyFormOpen}
        onClose={() => setIsBillingCompanyFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
      >
        <DialogContent>
          {isBillingCompanyFormOpen && (
            <BillingCompanyForm
              onClose={() => {
                setIsBillingCompanyFormOpen(false)
                setEditingBillingCompany(null)
              }}
              editingBillingCompany={editingBillingCompany}
            />
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title={
          togglingBillingCompany
            ? togglingBillingCompany.isActive
              ? 'Désactivation'
              : 'Activation'
            : 'Suppression'
        }
        message={
          deletingBillingCompanyForm
            ? 'Voulez-vous vraiment supprimer cette société de facturation ?'
            : `Voulez-vous vraiment ${
                togglingBillingCompany?.isActive ? 'désactiver' : 'activer'
              } cette société de facturation ?`
        }
        open={
          deletingBillingCompanyForm !== null || togglingBillingCompany !== null
        }
        onConfirm={() => {
          if (deletingBillingCompanyForm) {
            dispatch(removeBillingCompany(deletingBillingCompanyForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message:
                      'La société de facturation a été supprimée avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setDeletingBillingCompanyForm(null))
          } else if (togglingBillingCompany) {
            dispatch(
              updateBillingCompany({
                id: togglingBillingCompany.id,
                dto: { isActive: !togglingBillingCompany?.isActive },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: `La société de facturation a été ${
                      togglingBillingCompany.isActive ? 'désactivée' : 'activée'
                    } avec succès`,
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setTogglingBillingCompany(null))
          }
        }}
        onCancel={() => {
          setDeletingBillingCompanyForm(null)
          setTogglingBillingCompany(null)
        }}
      />
    </Container>
  )
}
