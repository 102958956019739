import { Container, Grid } from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { CashRegisterDTO } from '@/services/dtos/AccountingV2Dto'
import { getLogs, setLogFilters } from '@state/reducers/cashRegisterReducer'
import moment from 'moment'

interface CashRegisterLogsTableProps {
  cashRegister: CashRegisterDTO
}

export default function CashRegisterLogsTable({
  cashRegister,
}: CashRegisterLogsTableProps) {
  const dispatch = useAppDispatch()
  const { logs, loading } = useAppSelector(({ cashRegister }) => ({
    logs: cashRegister.logs,
    loading: cashRegister.logsLoading,
  }))

  useEffect(() => {
    dispatch(getLogs(cashRegister.id))
  }, [])

  const columns: GridColumns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      valueGetter: ({ row }) => moment(row.date).format('DD/MM/YYYY'),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 140,
      valueGetter: ({ row }) =>
        row.type === 'OPEN' ? 'Ouverture' : 'Fermeture',
    },
    {
      field: 'declaredBalance',
      headerName: 'Montant déclaré',
      width: 140,
      valueGetter: ({ row }) => `${row.declaredBalance}€`,
    },
    {
      field: 'calculatedBalance',
      headerName: 'Montant calculé',
      width: 140,
      valueGetter: ({ row }) => `${row.calculatedBalance}€`,
    },
    {
      field: 'difference',
      headerName: 'Différence',
      width: 140,
      valueGetter: ({ row }) => `${row.difference}€`,
    },
    {
      field: 'cashAmount',
      headerName: 'Montant espèces',
      width: 140,
      valueGetter: ({ row }) => `${row.cashAmount}€`,
    },
    {
      field: 'checkAmount',
      headerName: 'Montant chèques',
      width: 140,
      valueGetter: ({ row }) => `${row.checkAmount}€`,
    },
    {
      field: 'voucherAmount',
      headerName: 'Montant bons',
      width: 140,
      valueGetter: ({ row }) => `${row.voucherAmount}€`,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 220,
    },
    {
      field: 'createdBy',
      headerName: 'Enregistré par',
      width: 120,
      valueGetter: ({ row }) =>
        `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 300px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                loading={loading}
                columns={columns}
                rows={logs.datas}
                rowCount={logs.totalCount}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSize={20}
                onPageChange={(page) => {
                  dispatch(setLogFilters({ page }))
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
