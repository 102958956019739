import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Container, Grid, IconButton, Tooltip } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { InvoiceEntity, InvoiceDto, InvoicePaymentStatus } from '@services/dtos'
import moment from 'moment'
import { debounce } from 'lodash'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import {
  getDunningNoticeConfig,
  getInvoices,
  saveDunningNotice,
  setInvoiceFilters,
} from '@state/reducers/invoiceReducer'
import InvoicePdfTemplate from '../pdf/InvoicePdfTemplate'
import generatePDF, { Margin } from 'react-to-pdf'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { DunningNoticeNextLevel } from '@utils/constants'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import InvoicesTableToolbar from './Toolbars/InvoicesTableToolbar'
import { getDocumentConfig } from '@state/reducers/documentConfigReducer'

export default function OrganismInvoicesTable() {
  const [dunningNoticeSendForm, setDunningNoticeSendForm] =
    useState<InvoiceDto | null>(null)

  const dispatch = useAppDispatch()
  const { invoices, loading, filters, documentConfig } = useAppSelector(
    ({ invoice, documentConfig }) => ({
      invoices: invoice.invoices,
      loading: invoice.loading,
      filters: invoice.filters,
      documentConfig: documentConfig.documentConfig,
    }),
  )

  useEffect(() => {
    dispatch(getDunningNoticeConfig())
    dispatch(getDocumentConfig())
    dispatch(
      setInvoiceFilters({
        entity: InvoiceEntity.AMO,
        status: InvoicePaymentStatus.TO_BE_PAID,
      }),
    )
  }, [])

  useEffect(() => {
    if (!filters.entity || !filters.status) return

    const debouncedSearch = debounce(() => {
      dispatch(getInvoices())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const InvoicePdfCellRenderer = ({ row }: { row: InvoiceDto }) => {
    if (!filters.entity) return <></>
    const ref = useRef<HTMLDivElement>(null)

    return (
      <div className="ml-0">
        <InvoicePdfTemplate
          invoice={row}
          entity={filters.entity}
          ref={ref}
          documentConfig={documentConfig}
        />
        <Tooltip title="Voir la facture">
          <IconButton
            onClick={() =>
              generatePDF(ref, {
                method: 'open',
                page: {
                  margin: Margin.MEDIUM,
                },
              })
            }
            color="primary"
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const actionBtnObj = useMemo(() => {
    switch (filters.status) {
      case InvoicePaymentStatus.TO_BE_PAID:
        return [
          {
            id: 1,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
        ]
      case InvoicePaymentStatus.FINALIZED:
        return [
          {
            id: 1,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
        ]
      case InvoicePaymentStatus.NEED_DUNNING_NOTICE:
        return [
          {
            id: 2,
            renderCell: (row: InvoiceDto) => (
              <InvoicePdfCellRenderer row={row} />
            ),
            actionKey: 'viewInvoice',
          },
          {
            id: 1,
            btnName: 'Noter comme envoyée',
            icon: <MarkEmailReadIcon color="primary" />,
            action: (row: InvoiceDto) => setDunningNoticeSendForm(row),
            actionKey: 'dunningNoticeSendInvoice',
          },
        ]
      default:
        return []
    }
  }, [filters.entity, filters.status, documentConfig])

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width:
        filters.status === InvoicePaymentStatus.NEED_DUNNING_NOTICE ? 100 : 70,
      sortable: false,
      renderCell: (params) => {
        const invoice: InvoiceDto = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              acc.push(
                <Tooltip key={invoice.id + '-' + el.btnName} title={el.btnName}>
                  {el.renderCell ? (
                    el.renderCell(invoice)
                  ) : (
                    <div
                      className="cursor-pointer p-1 hover:bg-gray-200 rounded"
                      onClick={(e) => {
                        e.stopPropagation()
                        const action = el.action.bind({})
                        action(invoice)
                      }}
                    >
                      {el.icon}
                    </div>
                  )}
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Numéro facture',
      width: 130,
    },
    {
      field: 'date',
      headerName: 'Date de création',
      valueGetter: ({ row }) =>
        moment(row.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 140,
    },
    {
      field: 'patientName',
      headerName: 'Patient',
      valueGetter: ({ row }) =>
        `${row.patient.firstName ?? ''} ${row.patient.lastName ?? ''}`,
      width: 140,
    },
    {
      field: 'patientBirthDate',
      headerName: 'Date de naissance',
      valueGetter: ({ row }) =>
        moment(row.patient.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 140,
    },
    ...(filters.status &&
    [
      InvoicePaymentStatus.NEED_DUNNING_NOTICE,
      InvoicePaymentStatus.TO_BE_PAID,
    ].includes(filters.status)
      ? [
          {
            field: 'amountToPay',
            headerName: 'Reste à payer',
            valueGetter: ({ row }) => {
              return filters.entity === InvoiceEntity.AMC
                ? `${row.amc_total - row.amc_payed} €`
                : `${row.amo_total - row.amo_payed} €`
            },
            width: 120,
          },
        ]
      : []),
    ...(filters.status === InvoicePaymentStatus.NEED_DUNNING_NOTICE
      ? [
          {
            field: 'dunningNoticesLevel',
            headerName: 'Niveau de relance',
            valueGetter: ({ row }) => {
              switch (filters.entity) {
                case InvoiceEntity.AMC:
                  return DunningNoticeNextLevel[row.nextDunningNoticeLevelAmc]
                case InvoiceEntity.AMO:
                  return DunningNoticeNextLevel[row.nextDunningNoticeLevelAmo]
                default:
                  return '???'
              }
            },
            width: 140,
          },
        ]
      : []),
    ...(filters.status === InvoicePaymentStatus.FINALIZED
      ? [
          {
            field: 'total',
            headerName: 'Total',
            valueGetter: ({ row }) =>
              filters.entity === InvoiceEntity.AMC
                ? `${row.amc_total} €`
                : `${row.amo_total} €`,
            width: 100,
          },
        ]
      : []),
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 120px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={loading ? [] : invoices.datas}
                rowCount={loading ? 0 : invoices.totalCount}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                pageSize={20}
                onPageChange={(page) => {
                  dispatch(setInvoiceFilters({ page }))
                }}
                components={{
                  Toolbar: InvoicesTableToolbar,
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        title="Relance envoyée"
        message="Voulez-vous vraiment noter cette relance comme envoyée ?"
        open={!!dunningNoticeSendForm}
        onConfirm={() => {
          if (dunningNoticeSendForm) {
            dispatch(saveDunningNotice({ invoiceId: dunningNoticeSendForm.id }))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'La relance a été enregistrée avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => {
                setDunningNoticeSendForm(null)
                dispatch(getInvoices())
              })
          }
        }}
        onCancel={() => {
          setDunningNoticeSendForm(null)
        }}
      />
    </Container>
  )
}
