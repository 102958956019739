import { CouvertureDto } from 'src/common/interfaces'
import { axios, getConfigs, IRequestConfig } from './api'
import {
  CreateBillDTO,
  CreateInvoiceOnlyDto,
  ExonerationDto,
  FindPatientCoverageQuery,
  GetUrlByContextDto,
  GetUrlByNFCDTO,
  GetUrlByQRCodeDto,
  MemberSituationDTO,
  NatureDto,
  PrecotationDto,
  PrecotationValidationResponse,
  ValidatePrecotationDto,
} from './dtos'

export class BillingService {
  private static apiBackendEndpoint = process.env.REACT_APP_API_URL || ''

  static getCouverture(q: FindPatientCoverageQuery): Promise<CouvertureDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/couverture`,
        {},
      )
      configs.params = q
      axios(configs, resolve, reject)
    })
  }

  static createCouverture(
    patientId: number,
    dto: CouvertureDto,
  ): Promise<CouvertureDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/couverture/${patientId}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static updateCouverture(
    id: number,
    dto: CouvertureDto,
  ): Promise<CouvertureDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/couverture/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static createPrecot(dto: PrecotationDto): Promise<PrecotationDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/precotation/${dto.id_medical_order}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static getPrecotations(medicalOrderId: number): Promise<PrecotationDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/precotation?medicalOrderIds=${medicalOrderId}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static updatePrectotation(
    id: number,
    dto: PrecotationDto,
  ): Promise<PrecotationDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/precotation/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static removePrecotation(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/precotation/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static savePrecotations(
    medicalOrderId: number,
    data: PrecotationDto[],
  ): Promise<PrecotationDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/precotation/${medicalOrderId}`,
        {},
      )
      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static async validatePrecotation(
    dto: ValidatePrecotationDto,
  ): Promise<PrecotationValidationResponse> {
    const res = await fetch(
      `${this.apiBackendEndpoint}/fse-service/association/association/ControleTotal`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(dto),
      },
    )
    if (res.status !== 200) {
      throw new Error('Failed to validate precotation')
    }
    return res.json()
  }

  static async createBill(dto: CreateBillDTO) {
    const { idResip, idLecteur, securisationFacture, ...data } = dto

    const urlParams = new URLSearchParams({
      idResip: idResip.toString(),
      idLecteur,
      securisationFacture,
      apcv: 'false',
    })

    const res = await fetch(
      `${this.apiBackendEndpoint}/fse-service/sesamvitale/facturation/facturer?${urlParams.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      },
    )
    if (res.status !== 200) {
      throw new Error('Failed to create bill')
    }
    return res.json()
  }

  /* Cases:
  - Foreign patients
  - Non-foreign patient but payment before the consultation */
  static async createInvoiceOnly(dto: CreateInvoiceOnlyDto) {
    const res = await fetch(
      `${this.apiBackendEndpoint}/billing/database/invoice/GenerateFromDatas`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(dto),
      },
    )
    if (res.status !== 200) {
      throw new Error('Failed to create invoice')
    }
    return res.json()
  }

  static async getExonerations(): Promise<ExonerationDto[]> {
    const res = await fetch(`${this.apiBackendEndpoint}/billing/database/exoneration`)
    return res.json()
  }

  static async getNatures(): Promise<NatureDto[]> {
    const res = await fetch(
      `${this.apiBackendEndpoint}/billing/database/NatureAssurance`,
    )
    return res.json()
  }

  static async getIndicateursParcours(): Promise<
    { item1: string; item2: string }[]
  > {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/couverture/IndicateursParcours`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static async getMemberSituation(id: number): Promise<MemberSituationDTO | null> {
    const res = await new Promise<MemberSituationDTO[]>((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/database/MemberSituation?idMember=${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
    return res.length > 0 ? res[0] : null
  }

  static async getAlds(): Promise<{ label: string; code: string }[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing-api/ald`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static async getCodeSituationSelector(orderId: number, idLecteur: string, idResip: number): Promise<any> {
    const { codeRegime } = await this.getCodeRegime(orderId)
    if (!codeRegime) {
      throw new Error('Failed to get code regime')
    }
    const urlParams = new URLSearchParams({
      idLecteur,
      idResip: idResip.toString(),
      codeRegime: codeRegime.toString(),
    })
    const q = urlParams.toString()
    const res = await fetch(`${this.apiBackendEndpoint}/billing/SesamVitale/Facturation/situations?${q}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
    if (res.status !== 200) {
      throw new Error('Failed to get code situation url')
    }
    const data = await res.json()
    return data.url
  }

  static async finalizeBill(dto: { medicalOrderIds: number[], idVisit: number, idMemberSituation: number, data: any, idResip: number, idLecteur: string, idOrder: number }): Promise<any> {
    const { medicalOrderIds, idVisit, idMemberSituation, data, idResip, idLecteur, idOrder } = dto

    const urlParams = new URLSearchParams({
      idResip: idResip.toString(),
      idLecteur,
    })
    const q = urlParams.toString()
    const res = await fetch(`${this.apiBackendEndpoint}/billing/sesamvitale/facturation/finaliser?${q}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        facture: {
          idVisit,
          medicalOrderIds,
          idOrder,
          idMemberSituation,
        },
        pushReturn: data,
      }),
    })
    if (res.status !== 200) {
      throw new Error('Failed to finalize bill')
    }
    return res.json()
  }

  static async getCodeRegime(orderId: number): Promise<{ codeRegime: string | null }> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing-api/code-regime?orderId=${orderId}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static async getUrlByQRCode(dto: GetUrlByQRCodeDto): Promise<{
    erreur: boolean
    erreurCode: string
    erreurMessage: string
    url: string
  }> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/sesamvitale/apcv/lectureapcv`,
        {}
      )
      configs.params = dto
      axios(configs, resolve, reject)
    })
  }

  static async getUrlByNFC(dto: GetUrlByNFCDTO): Promise<{
    erreur: boolean
    erreurCode: string
    erreurMessage: string
    url: string
  }> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/sesamvitale/apcv/lectureapcv`,
        {}
      )
      configs.params = dto
      axios(configs, resolve, reject)
    })
  }

  static async getUrlByContext(dto: GetUrlByContextDto): Promise<{
    erreur: boolean
    erreurCode: string
    erreurMessage: string
    url: string
  }> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/sesamvitale/apcv/restituercontextapcv`,
        {}
      )
      configs.params = dto
      axios(configs, resolve, reject)
    })
  }
}
