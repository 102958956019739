import React, { useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CircularProgress,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Stack,
} from '@mui/material'
import { CreateDoctorDto, DoctorDto, RppsDto } from '@services/api'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import { doctorSchema } from '@utils/schemas'
import { doCreateDoctor, doUpdateDoctor } from '@state/reducers/doctorReducer'
import { useAppDispatch } from '@hooks/reduxHooks'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { Star, Close } from '@mui/icons-material'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import InputField from '@components/inputs/InputField'
import SelectField from '@components/inputs/SelectField'

type Props = {
  editingDoctor?: DoctorDto | null
  onClose: () => void
  hideRppsAutocomplete?: boolean
  onDoctorCreated?: (doctor: RppsDto) => void
}

const DoctorForm: React.FC<Props> = ({
  editingDoctor,
  onClose,
  hideRppsAutocomplete = false,
  onDoctorCreated,
}) => {
  const { setValue, control, handleSubmit, watch } = useForm<CreateDoctorDto>({
    resolver: yupResolver(doctorSchema),
    defaultValues: editingDoctor || {
      addresses: [],
    },
  })

  const dispatch = useAppDispatch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
  })

  const [rppsValue, setrppsValue] = useState<RppsDto | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [favoriteAddressIndex, setFavoriteAddressIndex] = useState(0)

  const handleRppsChange = (rpps: RppsDto | null) => {
    setrppsValue(rpps)

    if (!rpps) {
      return
    }

    setValue('rppsNumber', rpps.rppsNumber)
    setValue('firstName', rpps.firstName)
    setValue('lastName', rpps.lastName)
    setValue('email', rpps.email)
    setValue('skill', rpps.skillLabel)
    setValue('profession', rpps.professionLabel)
  }

  const onSubmit = (data: CreateDoctorDto) => {
    setIsSubmitting(true)
    const cb = (msg: string, createdDoctor?: DoctorDto) => {
      dispatch(
        enqueueSnackbar({
          message: msg,
          options: { variant: 'success' },
          type: 'success',
        }),
      )

      if (onDoctorCreated && createdDoctor) {
        const rppsDto = {
          rppsNumber: createdDoctor.rppsNumber,
          firstName: createdDoctor.firstName,
          lastName: createdDoctor.lastName,
          email: createdDoctor.email || '',
          skill: createdDoctor.skill || '',
          profession: createdDoctor.profession || '',
          skillLabel: createdDoctor.skill || '',
          professionLabel: createdDoctor.profession || '',
          title: '',
          id: createdDoctor.id || -1,
          fax: '',
          zipCode: '',
          skillType: '',
          category: '',
        } as RppsDto
        onDoctorCreated(rppsDto)
      } else {
        onClose()
      }
    }

    if (editingDoctor) {
      dispatch(doUpdateDoctor({ id: editingDoctor.id, dto: data }))
        .unwrap()
        .then((updatedDoctor) =>
          cb('Medecin modifié avec succès', updatedDoctor),
        )
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      dispatch(doCreateDoctor(data))
        .unwrap()
        .then((createdDoctor) => cb('Medecin créé avec succès', createdDoctor))
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  const handleSetFavorite = (index: number) => {
    setFavoriteAddressIndex(index)
  }

  const handleRemoveAddress = (index: number) => {
    if (index === activeTab) {
      setActiveTab(index > 0 ? index - 1 : 0)
    } else if (index < activeTab) {
      setActiveTab(activeTab - 1)
    }
    if (index === favoriteAddressIndex) {
      handleSetFavorite(0)
    } else if (index < favoriteAddressIndex) {
      handleSetFavorite(favoriteAddressIndex - 1)
    }
    remove(index)
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          maxWidth: '1280px',
          mx: 'auto',
        }}
      >
        <Paper
          sx={{
            flex: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Header */}
          <Box
            sx={{
              px: 3,
              py: 2,
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" fontWeight="600" color="text.primary">
              Informations du médecin
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Annuler
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                onClick={(e) => {
                  e.stopPropagation()
                  handleSubmit(onSubmit)()
                }}
                startIcon={isSubmitting ? <CircularProgress size={18} /> : null}
              >
                Sauvegarder
              </Button>
            </Stack>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            {/* Doctor Information Section */}
            <Box sx={{ p: 3, '& > *': { mb: 2 } }}>
              {/* RPPS Search */}
              <Box sx={{ width: '100%' }}>
                <RppsAutocompleteByNameInput
                  showCreateOption={false}
                  onChange={handleRppsChange}
                  label="RPPS"
                  value={rppsValue}
                  size="small"
                  hidden={hideRppsAutocomplete}
                  showLocalSearchOption={false}
                />
              </Box>

              {/* Main Info Grid */}
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <InputField
                    label="Nom *"
                    name="firstName"
                    control={control}
                    size="small"
                    placeholder="Nom"
                    InputProps={{ sx: { textTransform: 'capitalize' } }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Prénom *"
                    name="lastName"
                    control={control}
                    size="small"
                    placeholder="Prénom"
                    InputProps={{ sx: { textTransform: 'capitalize' } }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Numero RPPS"
                    name="rppsNumber"
                    control={control}
                    size="small"
                    placeholder="Numéro RPPS"
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Spécialité"
                    name="skill"
                    control={control}
                    size="small"
                    placeholder="Spécialité"
                    InputProps={{ sx: { textTransform: 'capitalize' } }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Email"
                    name="email"
                    control={control}
                    type="email"
                    size="small"
                    placeholder="Email"
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Téléphone"
                    name="mobileNumber"
                    control={control}
                    size="small"
                    placeholder="Téléphone"
                    InputProps={{ sx: { textTransform: 'capitalize' } }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Fax"
                    name="phoneNumber"
                    control={control}
                    size="small"
                    placeholder="Fax"
                    InputProps={{ sx: { textTransform: 'capitalize' } }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Addresses Section */}
            <Box
              sx={{
                px: 3,
                pb: 3,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0,
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  color="text.primary"
                  sx={{ mb: 2 }}
                >
                  Adresses
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1,
                  }}
                >
                  {fields.map((field, index) => {
                    const addressName =
                      watch(`addresses.${index}.locationName`) ||
                      `Address ${index + 1}`
                    return (
                      <Button
                        key={field.id}
                        variant={activeTab === index ? 'contained' : 'outlined'}
                        onClick={() => setActiveTab(index)}
                        size="small"
                        startIcon={
                          favoriteAddressIndex === index ? (
                            <Star fontSize="small" />
                          ) : null
                        }
                        sx={{
                          borderRadius: '20px',
                          textTransform: 'none',
                          px: 2,
                          py: 0.5,
                          fontWeight: 500,
                          ...(activeTab === index && {
                            boxShadow: 2,
                          }),
                          ...(favoriteAddressIndex === index &&
                            activeTab !== index && {
                              borderColor: 'warning.main',
                              color: 'warning.main',
                            }),
                        }}
                      >
                        {addressName}
                      </Button>
                    )
                  })}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      append({})
                      setActiveTab(fields.length)
                    }}
                    startIcon={<LocalHospitalIcon fontSize="small" />}
                    sx={{
                      borderRadius: '20px',
                      textTransform: 'none',
                      px: 2,
                      py: 0.5,
                      borderStyle: 'dashed',
                    }}
                  >
                    Ajouter une adresse
                  </Button>
                </Box>
              </Box>

              {/* Scrollable Content */}
              <Box sx={{ flex: 1, overflowY: 'auto' }}>
                {fields.length === 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '12rem',
                      border: '2px dashed',
                      borderColor: 'primary.light',
                      borderRadius: 3,
                      bgcolor: 'background.paper',
                      p: 3,
                    }}
                  >
                    <LocalHospitalIcon
                      sx={{ fontSize: 40, color: 'primary.light', mb: 2 }}
                    />
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      sx={{ mb: 1 }}
                    >
                      Aucune adresse ajoutée
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        append({})
                        setActiveTab(0)
                      }}
                      startIcon={<LocalHospitalIcon fontSize="small" />}
                      sx={{
                        mt: 1,
                        borderRadius: '20px',
                        textTransform: 'none',
                      }}
                    >
                      Ajouter une adresse
                    </Button>
                  </Box>
                ) : (
                  fields.map((field, index) => (
                    <Box
                      key={field.id}
                      sx={{
                        display: activeTab === index ? 'block' : 'none',
                      }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          position: 'relative',
                          p: 3,
                          borderRadius: 3,
                          border: '1px solid',
                          borderColor: 'primary.light',
                          bgcolor: 'background.paper',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: 4,
                          },
                        }}
                      >
                        {/* Header with actions */}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 3,
                            pb: 2,
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                          }}
                        >
                          <Typography variant="h6" color="primary.main">
                            {watch(`addresses.${index}.locationName`) ||
                              `Adresse ${index + 1}`}
                          </Typography>

                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                              size="small"
                              variant={
                                favoriteAddressIndex === index
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color="warning"
                              onClick={() => handleSetFavorite(index)}
                              startIcon={<Star fontSize="small" />}
                              sx={{
                                borderRadius: '20px',
                                textTransform: 'none',
                              }}
                            >
                              {favoriteAddressIndex === index
                                ? 'Favori'
                                : 'Définir comme favori'}
                            </Button>

                            <Button
                              size="small"
                              variant="outlined"
                              color="error"
                              onClick={() => handleRemoveAddress(index)}
                              startIcon={<Close fontSize="small" />}
                              sx={{
                                borderRadius: '20px',
                                textTransform: 'none',
                              }}
                            >
                              Supprimer
                            </Button>
                          </Box>
                        </Box>

                        {/* Form Grid */}
                        <Grid container spacing={3} sx={{ mb: 2 }}>
                          <Grid item xs={6}>
                            <InputField
                              label="Nom de l'adresse *"
                              name={`addresses.${index}.locationName`}
                              control={control}
                              size="small"
                              placeholder="Nom de l'adresse"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <SelectField
                              label="Type d'établissement *"
                              name={`addresses.${index}.locationType`}
                              control={control}
                              size="small"
                              options={[
                                { label: 'Sélectionner un type', value: '' },
                                { label: 'Clinique', value: 'Clinique' },
                                { label: 'Hôpital', value: 'Hospital' },
                                {
                                  label: 'Etablissement',
                                  value: 'Etablissement',
                                },
                              ]}
                              inputProps={{
                                sx: { borderRadius: 2 },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputField
                              label="Adresse ligne 1 *"
                              name={`addresses.${index}.line1`}
                              control={control}
                              size="small"
                              placeholder="Adresse ligne 1"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputField
                              label="Adresse ligne 2"
                              name={`addresses.${index}.line2`}
                              control={control}
                              size="small"
                              placeholder="Adresse ligne 2"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <InputField
                              label="Code postal"
                              name={`addresses.${index}.postalCode`}
                              control={control}
                              size="small"
                              placeholder="Code postal"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <InputField
                              label="Ville"
                              name={`addresses.${index}.city`}
                              control={control}
                              size="small"
                              placeholder="Ville"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <InputField
                              label="Pays"
                              name={`addresses.${index}.country`}
                              control={control}
                              size="small"
                              placeholder="Pays"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InputField
                              label="FINESS"
                              name={`addresses.${index}.finessNumber`}
                              control={control}
                              size="small"
                              placeholder="Numéro FINESS"
                              InputProps={{
                                sx: {
                                  textTransform: 'capitalize',
                                  borderRadius: 2,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default DoctorForm
