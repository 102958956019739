import { LotsFilters } from '@/state/reducers/lotReducer'
import { axios, getConfigs, IRequestConfig } from './api'
import {
  CreateLotPaymentDto,
  LotDto,
  OrganismPaymentDto,
  UpdateLotPaymentDto,
} from './dtos'
import { PaginatedDto } from './extendedType'

export class LotService {
  private static readonly endpoint = 'lot'

  static getAll(query: LotsFilters): Promise<PaginatedDto<LotDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static createPayment(
    id: number,
    dto: CreateLotPaymentDto,
  ): Promise<OrganismPaymentDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${id}/payment`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static updatePayment(
    id: number,
    dto: UpdateLotPaymentDto,
  ): Promise<OrganismPaymentDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}/payment`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static removePayment(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}/payment`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
