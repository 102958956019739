import { validate } from 'french-ssn'

export function formatSsnPrefix(ssnPrefix: string): string {
  if (ssnPrefix.length < 7) {
    return ssnPrefix
  }
  // format the string to respect this format X XX XX XX
  return ssnPrefix.replace(/(\d{1})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4')
}

// format the string to respect this format XXX XXX XX while typing
export function formatSsn(ssn: string): string {
  ssn = ssn.replace(/\s/g, '')

  if (ssn.length <= 3) {
    return ssn
  }
  if (ssn.length <= 6) {
    return ssn.replace(/(\d{3})(\d{0,3})/, '$1 $2')
  }
  return ssn.replace(/(\d{3})(\d{3})(\d{0,2})/, '$1 $2 $3')
}

export function formatSsnV2(ssn: string): string {
  return ssn
    .replace(/\s/g, '')
    .replace(
      /(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})?/g,
      '$1 $2 $3 $4 $5 $6 $7',
    )
    .trim()
}

export function validateSsn(ssn: string): boolean {
  return validate(ssn)
}
