import { CashRegisterTransactionsFilters } from '@/state/reducers/cashRegisterReducer'
import { axios, getConfigs, IRequestConfig, PaginatedQuery } from './api'
import {
  CashRegisterDTO,
  CashRegisterLogDTO,
  CashRegisterTransactionDTO,
  CloseCashRegisterDTO,
  CreateCashRegisterDTO,
  CreateCashRegisterTransactionDto,
  ListCashRegisterDTO,
  OpenCashRegisterDTO,
  UpdateCashRegisterDTO,
} from './dtos/AccountingV2Dto'
import { PaginatedDto } from './extendedType'

export class CashRegisterService {
  private static readonly endpoint = 'cash-register'

  static getAll(query: ListCashRegisterDTO): Promise<CashRegisterDTO[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static getMine(): Promise<CashRegisterDTO | null> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/current`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static getAllTransactions(
    query: CashRegisterTransactionsFilters,
  ): Promise<PaginatedDto<CashRegisterTransactionDTO>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/transactions`,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static getLogs(
    id: number,
    query: PaginatedQuery,
  ): Promise<PaginatedDto<CashRegisterLogDTO>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/${id}/logs`,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreateCashRegisterDTO): Promise<CashRegisterDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static createTransaction(
    id: number,
    dto: CreateCashRegisterTransactionDto,
  ): Promise<CashRegisterTransactionDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${id}/transaction`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static update(
    id: number,
    dto: UpdateCashRegisterDTO,
  ): Promise<CashRegisterDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static open(
    id: number,
    dto: OpenCashRegisterDTO,
  ): Promise<CashRegisterLogDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${id}/open`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static close(
    id: number,
    dto: CloseCashRegisterDTO,
  ): Promise<CashRegisterLogDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${id}/close`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }
}
