import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateBillDTO,
  CreateInvoiceOnlyDto,
  ExonerationDto,
  InvoiceDto,
  MemberSituationDTO,
  NatureDto,
  PrecotationDto,
  PrecotationValidationResponse,
  ValidatePrecotationDto,
} from '@services/dtos'
import { BillingService } from '../../services/BillingService'

export interface BillingState {
  precotation: PrecotationDto[]
  validation: PrecotationValidationResponse | null
  exonerations: ExonerationDto[]
  naturesAssurance: NatureDto[]
  memberSituation: MemberSituationDTO | null
}

const initialState: BillingState = {
  precotation: [],
  validation: null,
  exonerations: [],
  naturesAssurance: [],
  memberSituation: null,
}

export const doCreatePrecotation = createAsyncThunk(
  'billing/createPrecotation',
  async (data: PrecotationDto) => {
    const response = await BillingService.createPrecot(data)
    return response
  },
)

export const doSavePrecotations = createAsyncThunk<
  PrecotationDto[],
  { medicalOrderId: number; data: PrecotationDto[] }
>('billing/savePrecotations', async ({ medicalOrderId, data }) => {
  const response = await BillingService.savePrecotations(medicalOrderId, data)
  return response
})

export const doFindPrecotations = createAsyncThunk(
  'billing/findPrecotations',
  async (medicalOrderId: number) => {
    const response = await BillingService.getPrecotations(medicalOrderId)
    return response
  },
)

export const doUpdatePrecotation = createAsyncThunk(
  'billing/updatePrecotation',
  async (data: { id: number; dto: PrecotationDto }) => {
    const response = await BillingService.updatePrectotation(data.id, data.dto)
    return response
  },
)

export const doRemovePrecotation = createAsyncThunk(
  'billing/removePrecotation',
  async (id: number) => {
    const response = await BillingService.removePrecotation(id)
    return response
  },
)

export const doGetNaturesAssurance = createAsyncThunk<NatureDto[], void>(
  'billing/getNaturesAssurance',
  async () => {
    const response = await BillingService.getNatures()
    return response
  },
)

export const doValidatePrecotations = createAsyncThunk<
  PrecotationValidationResponse,
  PrecotationDto[]
>('cotation/validate', async (dto) => {
  const validateDto: ValidatePrecotationDto = {
    actes: dto.map((p, i) => ({
      modificateurs: [p.m1, p.m2, p.m3, p.m4].filter((m) => !!m) as string[],
      codeActe: p.cotation,
      codeActivite: 0,
      codePhaseTraitement: 0,
      lieuExecution: '',
      numPrestation: i + 1,
      identifiantLieuExecution: '',
      indicateurForcageAmo: '',
      supplementCharge: '',
      codeAssociation: 0,
      flagRemboursementExceptionnel: '',
    })),
    idMemberSituation: 1,
    dateApplication: new Date(),
  }
  const response = await BillingService.validatePrecotation(validateDto)
  return response
})

export const doCreateBill = createAsyncThunk<any, CreateBillDTO>(
  'billing/createBill',
  async (data) => {
    const response = await BillingService.createBill(data)
    return response
  },
)

export const doCreateInvoiceOnly = createAsyncThunk<any, CreateInvoiceOnlyDto>(
  'billing/createInvoiceOnly',
  async (data) => BillingService.createInvoiceOnly(data),
)

export const doGetExonerations = createAsyncThunk<ExonerationDto[], void>(
  'billing/getExonerations',
  async () => {
    const response = await BillingService.getExonerations()
    return response
  },
)

export const doGetMemberSituation = createAsyncThunk<
  MemberSituationDTO | null,
  number
>('billing/getMemberSituation', async (id) => {
  const response = await BillingService.getMemberSituation(id)
  return response
})

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setPrecotation: (state, action: PayloadAction<PrecotationDto[]>) => {
      state.precotation = action.payload
    },
    removeUnfinishedPrecot: (
      state,
      { payload }: PayloadAction<PrecotationDto>,
    ) => {
      if (payload.id) {
        state.precotation = state.precotation.filter((p) => p.id !== payload.id)
      } else if (payload.identifier) {
        state.precotation = state.precotation.filter(
          (p) => p.identifier !== payload.identifier,
        )
      }
    },
    updatePrecotation: (state, { payload }: PayloadAction<PrecotationDto>) => {
      if (payload.id) {
        const index = state.precotation.findIndex((p) => p.id === payload.id)
        if (index !== -1) {
          state.precotation[index] = payload
        }
      } else if (payload.identifier) {
        const index = state.precotation.findIndex(
          (p) => p.identifier === payload.identifier,
        )
        if (index !== -1) {
          state.precotation[index] = payload
        }
      }
    },
    setValidation: (
      state,
      { payload }: PayloadAction<PrecotationValidationResponse | null>,
    ) => {
      state.validation = payload
    },
    setMemberSituation: (
      state,
      { payload }: PayloadAction<MemberSituationDTO | null>,
    ) => {
      state.memberSituation = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doCreatePrecotation.fulfilled, (state, action) => {
      const precotationIndex = state.precotation.findIndex(
        (prec) => prec.cotation === action.payload.cotation,
      )
      if (precotationIndex === -1) {
        state.precotation.push(action.payload)
      } else {
        state.precotation[precotationIndex] = action.payload
      }
    })
    builder.addCase(doUpdatePrecotation.fulfilled, (state, action) => {
      const index = state.precotation.findIndex(
        (p) => p.id === action.payload.id,
      )
      if (index !== -1) {
        state.precotation[index] = action.payload
      }
    })
    builder.addCase(doRemovePrecotation.fulfilled, (state, action) => {
      state.precotation = state.precotation.filter(
        (p) => p.id !== action.meta.arg,
      )
    })
    builder.addCase(doValidatePrecotations.fulfilled, (state, { payload }) => {
      state.validation = payload
    })
    builder.addCase(doGetExonerations.fulfilled, (state, { payload }) => {
      state.exonerations = payload
    })
    builder.addCase(doGetNaturesAssurance.fulfilled, (state, { payload }) => {
      state.naturesAssurance = payload
    })
    builder.addCase(doGetMemberSituation.fulfilled, (state, { payload }) => {
      state.memberSituation = payload
    })
  },
})

export const {
  setPrecotation,
  removeUnfinishedPrecot,
  updatePrecotation,
  setValidation,
  setMemberSituation,
} = billingSlice.actions
export default billingSlice.reducer
