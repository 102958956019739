import { InvoicesFilters } from '@/state/reducers/invoiceReducer'
import { axios, getConfigs, IRequestConfig } from './api'
import {
  DunningNoticeConfigDto,
  InvoiceDto,
  InvoiceEntity,
  UpdateDunningNoticeConfigDto,
  UpdateInvoiceDto,
} from './dtos'
import { PaginatedDto } from './extendedType'

export class InvoiceService {
  private static readonly endpoint = 'invoice'

  static getBy(query: { orderId: number }): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static getAll(query: InvoicesFilters): Promise<PaginatedDto<InvoiceDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/all`,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static getDunningNoticeConfig(): Promise<DunningNoticeConfigDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/dunning-notice/config`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static saveDunningNoticeConfig(
    dto: UpdateDunningNoticeConfigDto,
  ): Promise<DunningNoticeConfigDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        `${this.endpoint}/dunning-notice/config`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static saveDunningNotice(
    invoiceId: number,
    entity: InvoiceEntity,
  ): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${invoiceId}/dunning-notice`,
        {},
      )
      configs.data = { entity }
      axios(configs, resolve, reject)
    })
  }

  static update(id: number, dto: UpdateInvoiceDto): Promise<InvoiceDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }
}
