import {
  CouvertureDto,
  FileTypeEnum,
  OrderStatusEnum,
} from 'src/common/interfaces'
import {
  DoctorDto,
  Document,
  ExamDto,
  GedFile,
  MedicalOrder,
  MedicalOrderStatusEnum,
  MemberDto,
  PaginatedQuery,
  PatientDto,
  PatientTransportDto,
  RppsDto,
  SexEnum,
  SiteDto,
} from './api'

export interface CreateMedicalOrderDto {
  siteId: string
  attendingDoctor: RppsDto | null
  referringDoctor: RppsDto | null
  consultingDoctor: RppsDto | null
  registerDate?: Date
  registerTime?: string
  comments?: string
  patientId: number
  isUrgent?: boolean
  isNightShift?: boolean
  isHoliday?: boolean
}

export interface CCAMDto {
  id: number
  code: string
  libelle: string
  codeRegroupement: string
  prixOptam: number
  prixNonOptam: number
  modificateurs: string
}

export interface NGAPDto {
  id: number
  code: string
  libelle: string
  prix: number
  dateDebut: Date
  dateFin: Date | null
}

export interface FindCCAMQuery {
  code?: string
  label?: string
  examDate: Date
}

export interface FindManyCCAMQuery {
  codes: string[]
  examDate: Date
}

export interface CreatePrecotationDto {
  id: number
  association?: number
  cotation_type: string
  cotation: string
  modificateur: string
  date_execution: string
  prix_unitaire: number
  code_entente_prealable: string
  date_entente_prealable: string
  id_medical_order: number
  m1: string
  m2: string
  m3: string
  m4: string
}

export interface PrecotationDto {
  id?: number
  identifier?: string
  cotation_type: string
  cotation: string
  modificateur?: string
  date_execution?: string
  prix_unitaire: number
  code_entente_prealable?: string
  date_entente_prealable?: string
  id_medical_order?: number
  association?: number
  m1?: string
  m2?: string
  m3?: string
  m4?: string
}

export type FindExamFromBillingQuery = {
  CodeCcam?: string
  CodeNgap?: string
  SiteId: number
}
export interface SearchCCAMQuery {
  libelle: string
  examDate: Date
}

export interface SearchNGAPQuery {
  libelle: string
  examDate: Date
}

export interface OrderDto {
  id: number
  site: SiteDto
  patient: PatientDto
  exam: ExamDto
  createdAt: Date
  siteId: number
  referringDoctor: DoctorDto
  attendingDoctor: MemberDto
  consultingDoctor: MemberDto
  medicalOrders: MedicalOrder[]
  secretary: MemberDto
  registeredAt?: Date
  isEmergency?: boolean
  isNightShift?: boolean
  isHoliday?: boolean
  comments?: string
  children: OrderDto[]
  parentId: number | null
  plannedDate: Date
  documents: Document[]
  visit?: VisitCouvertureDto
  visitId?: number
  previousVisits?: VisitDTO[] // Added this to simulate the data for now
  deletedAt?: Date
  aldStatus: AldStatusEnum
  patientTransport?: PatientTransportDto
  patientTransportId?: number
}

export interface PatientAldDTO {
  id: string
  libelle: string
  code: string
  etat: string
  dateFin?: string
  cimCode?: string
  source: 'ALDi' | 'User'
  comment?: string
  patientId: number
  createdBy: MemberDto
  createdAt: Date
}

export interface FindOrdersQuery extends PaginatedQuery {
  search?: string
  siteId?: number
  status?: OrderStatusEnum
  startDate?: string
  endDate?: string
  patientId?: number
}

export interface UpdateOrderDto {
  id?: number
  siteId?: number
  patientId?: number
  examId?: number
  status?: MedicalOrderStatusEnum
  plannedDate?: Date
  cancelDate?: Date
  description?: string
  studyInstanceUID?: string
  accessionNumber?: string
  service?: string
  attendingDoctorId?: number
  referringDoctorId?: number
  consultingDoctorId?: number
  attendingDoctorName?: string
  consultingDoctorName?: string
  referringDoctorName?: string
  patientTransportId?: number
  registerDate?: string | null
  registerTime?: string | null
  isEmergency?: boolean
  isNightShift?: boolean
  isHoliday?: boolean
  attendingDoctor?: MemberDto | null
  referringDoctor?: RppsDto | null
  consultingDoctor?: MemberDto | null
  familyDoctor?: RppsDto | null
  comments?: string
  titulaireMemberId?: number
  secretaryId?: number
  deletedAt?: Date | null
}

export interface PrecotValidationActItem {
  indicateurForcageAmo: string
  flagRemboursementExceptionnel: string
  supplementCharge: string
  numPrestation: number
  codeActe: string
  codeActivite: number
  codePhaseTraitement: number
  modificateurs: string[]
  codeAssociation: number
  lieuExecution: string
  identifiantLieuExecution: string
}

export interface ValidatePrecotationDto {
  idMemberSituation: number
  actes: PrecotValidationActItem[]
  dateApplication: Date
}

export interface CotationValidationError {
  codeErreur: number
  codeRegle: string
  messageErreur: string
  forcable: boolean
  acte: string
}

export interface FindVisitQuery extends PaginatedQuery {
  search?: string
  siteId?: number
}

export interface VisitDTO {
  id: number
  patient: PatientDto
  siteId: number
  site: SiteDto
  admissionDate: Date
  dischargedDate: Date
  hospitalService: string
  couverture?: CouvertureDto
  createdAt: Date
  orders: OrderDto[]
  updatedAt: Date
  referringDoctor: DoctorDto
}

export interface VisitCouvertureDto {
  id: number
  coverageId: number
  createdAt: Date
}

export interface UpdateVisitDTO {
  admissionDate?: Date
  dischargedDate?: Date
  hospitalService?: string
  patientId?: number
  siteId?: number
  medicalOrderIds?: number[]
}

export interface CreateVisitDTO {
  patientId: number
  siteId: number
  admissionDate?: Date
  dischargedDate?: Date
  hospitalService?: string
  referringDoctor?: RppsDto
}

export interface CreatePatientDTO {
  sex: SexEnum
  firstName: string | null
  lastName: string | null
  motherMaidenName: string | null
  birthDate?: Date | null
  phoneNumber?: string | null
  email?: string | null
  address?: string | null
  placeOfBirth?: string | null
  ssn?: string | null
  weight?: number | null
  height?: number | null
  familyDoctor?: RppsDto | null
  birthDateStr?: string | null
  isForeigner?: boolean
  identifier?: string
  // comments: string
  // iod: string
  // ins: string
}

export interface PatientBrifDTO {
  id: string
  sex: SexEnum
  firstName: string
  lastName: string
  maidenName: string
  birthDate?: Date | null
  phoneNumber: string
  email: string
  address: string
  birthDatestring: string
  birthPlace: string
  birthPlaceDepartment: string
  ssn: string
  weight: number
  height: number
  familyDoctor: RppsDto
  comments: string
  iod: string
  ins: string
}

export interface FindPatientCoverageQuery {
  idVisit?: number
  idPatient?: number
}

export interface FindDoctorsQuery {
  search?: string
  city?: string
  rppsNumber?: string
  page: number
  limit: number
}

export interface AddressDto {
  id: number
  line1: string
  line2: string
  postalCode: string
  city: string
  country: string
  locationName: string
  locationType: string
  finessNumber: string
}

export interface CreateRecipientsDTO {
  orderId?: number
  visitId?: number
  doctorId?: number
  memberId?: number
  selectedAddressId?: number
  selectedEmail?: string
  receiveByEmail?: boolean
  receiveByPost?: boolean
}

export interface RecipientDTO {
  id: number
  doctor?: DoctorDto
  member?: MemberDto
  createdAt: Date
  selectedAddressId?: number
  selectedAddress?: AddressDto
  selectedEmail?: string
  receiveByEmail?: boolean
  receiveByPost?: boolean
}

export interface CreateDocumentDto {
  url: string
  filename: string
  fileType: FileTypeEnum
  mimeType: string
  relatedEntity?: number
  entityName?: string
  data?: string
}

export interface ActeDTO {
  codeRegroupement: string
  libelleActe: string
  montantPrixUnitaire: number
  montantBaseRemboursement: number
  montantDepassement: number
  isSupplement: boolean
  numPrestation: number
  codeActe: string
  codeActivite: number
  codePhaseTraitement: number
  modificateurs: any[]
  codeAssociation: number
  lieuExecution: string
  identifiantLieuExecution: string
}

interface SituationPsDTO {
  codeSpecialite: string
  codeConventionel: string
  identFacturation: string
  cleIdentFacturation: string
  contratPs: number
}

interface VisiteBillingDTO {
  montantTotal: number
  montantDepassement: number
  actes: ActeDTO[]
  situationPs: SituationPsDTO
  dateApplication: string
}

export interface PrecotationValidationResponse {
  visite: VisiteBillingDTO
  erreurs: any[]
}

export interface CreateBillDTO {
  idResip: number
  idLecteur: string
  idVisit: number
  medicalOrderIds: number[]
  securisationFacture: string
  idOrder: number
}

export interface CreateInvoiceOnlyDto {
  idVisit: number
  medicalOrderIds: number[]
  idOrder: number
  idMemberSituation: number
}

export interface DocumentDTO {
  id: number
  fileId: number
  medicalOrderId: number
  fileType: FileTypeEnum
  gedFile: GedFile
  url: string
  createdAt: Date
}
export interface UpdateDocumentDTO {
  entityId?: number
  entityName: 'patient' | 'order' | 'visit'
}

export interface ExonerationDto {
  id: number
  code: string
  libelle: string
}

export interface NatureDto {
  id: number
  code: string
  libelle: string
  code_b2: string
}

export enum AldStatusEnum {
  ACTIVE = 'ACTIVE',
  UNFINISHED = 'UNFINISHED',
  NOT_FOUND = 'NOT_FOUND',
}

export interface MemberSituationDTO {
  identifiant: number
  idJfse: number
  idMember: number
  idSite: number
  identifiantLogique: number
  typeIdentificationStructure: number
  numIdentificationStructure: string
  raisonSociale: string
  numIdentificationPSFacturation: string
  cleIdentificationPSFacturation: string
  codeConventionnel: string
  codeSpecialite: string
  codeZoneTarif: string
  codeZoneIK: string
  codeAgrement1: string
  codeAgrement2: string
  codeAgrement3: string
  habilitationSignerFSE: boolean
  habilitationSignerLOT: boolean
  modeExercice: number
  statusExercice: number
  secteurActivite: number
  jsonSituation: string
  contratOptam: number
  member: MemberDto
  billingCompanyId: number
}

export interface PaymentModeDto {
  id: number
  isActive: boolean
  typeIsMutable: boolean
  code: string
  label: string
  type: PaymentModeType
  accountingSectionCode: string
  isDefaultValue: boolean
  updatedAt: Date
  updatedBy: MemberDto
  createdBy: MemberDto
}

export interface CreatePaymentModeDto {
  code: string
  label: string
  type: PaymentModeType
  accountingSectionCode: string
}

export interface UpdatePaymentModeDto extends Partial<CreatePaymentModeDto> {
  isActive?: boolean
}

export enum PaymentModeType {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CARD = 'CARD',
  FREE = 'FREE',
  LOSS_PROFIT = 'LOSS_PROFIT',
  REDISTRIBUTION = 'REDISTRIBUTION',
  DEBT_BALANCE = 'DEBT_BALANCE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  AMEX = 'AMEX',
}

export interface BillingCompanyDto {
  id: number
  isActive: boolean
  code: string
  finessNumber: string
  label: string
  registrationNumber: string
  taxNumber: string
  address: string
  postalCode: string
  city: string
  country: string
  updatedAt: Date
  updatedBy: MemberDto
  createdBy: MemberDto
  site: SiteDto
  bankAccount: BankAccountDto
}

export interface CreateBillingCompanyDto {
  code: string
  finessNumber: string
  label: string
  registrationNumber: string
  taxNumber: string
  address: string
  postalCode: string
  city: string
  country: string
  siteId: number
  bankAccountId: number
}

export interface UpdateBillingCompanyDto
  extends Partial<CreateBillingCompanyDto> {
  isActive?: boolean
}

export interface BankEstablishmentDto {
  id: number
  isActive: boolean
  code: string
  label: string
  isDefaultValue: boolean
  updatedAt: Date
  updatedBy: MemberDto
  createdBy: MemberDto
}

export interface CreateBankEstablishmentDto {
  code: string
  label: string
}

export interface UpdateBankEstablishmentDto
  extends Partial<CreateBankEstablishmentDto> {
  isActive?: boolean
}

export interface BankAccountDto {
  id: number
  isActive: boolean
  code: string //eg: SGN
  name: string // eg: Société Générale Nice
  countryCode: string //eg: FR01
  bankCode: string //eg: 30000
  branchCode: string //eg: 10000
  accountNumber: string //eg: 00012345678
  controlKey: string //eg: 06
  swiftCode: string //eg: SOGEFRPP
  isDeletable: boolean
  siteIdsNonDeletable: number[]
  updatedAt: Date
  updatedBy: MemberDto
  createdBy: MemberDto
}

export interface CreateBankAccountDto {
  code: string
  name: string
  countryCode: string
  bankCode: string
  branchCode: string
  accountNumber: string
  controlKey: string
  swiftCode: string
}

export interface UpdateBankAccountDto extends Partial<CreateBankAccountDto> {
  isActive?: boolean
}

export interface BankTransfertDto {
  id: number
  createdAt: Date
  bankEstablishment: BankEstablishmentDto
}

export interface PatientPaymentDto {
  transactionType: TransactionType
  id: number
  invoiceId: number
  paymentMode: PaymentModeDto
  amount: string
  cheque: ChequeDto
  bankTransferPayment: BankTransfertDto
  drawerName: string
  updatedAt: Date
  updatedBy: MemberDto
  createdAt: Date
  createdBy: MemberDto
  comment: string
}

export interface CreatePatientPaymentDto {
  transactionType: TransactionType
  paymentModeId: number
  amount: string
  comment?: string
  // PaymentModeType.CHEQUE case
  chequeNumber?: string
  bankEstablishmentId?: number // + PaymentModeType.BANK_TRANSFER case
  drawerName?: string
  minimumRequestedDepositDate?: Date
  invoiceId: number
}

export interface ChequeDto {
  id: number
  chequeNumber: string
  drawerName: string
  minimumRequestedDepositDate: Date
  maximumDepositDate: Date
  bankEstablishment: BankEstablishmentDto
  unpaid: boolean
  patientPayment: PatientPaymentDto
  createdAt: Date
  updatedBy: MemberDto
}

export interface ChequeDepositDto {
  id: number
  createdAt: Date
  depositReference: string
  cheques: ChequeDto[]
  isLocked: boolean
  updatedAt: Date
  updatedBy: MemberDto
  createdBy: MemberDto
}

export interface CreateChequeDepositDto {
  depositReference: string
  chequeIds: number[]
}

export interface UpdateChequeDepositDto
  extends Partial<CreateChequeDepositDto> {
  isLocked?: boolean
}

export interface UpdateChequeDto {
  unpaid?: boolean
}

export interface DunningNoticeSendDto {
  id: number
  level: DunningNoticeLevel
  createdAt: Date
  createdBy: MemberDto
}

export interface OrganismeDto {
  libelle: string
  phoneNumber: string
  adress: string
}

export interface InvoiceDto {
  id: number
  leftToRefund: number | null
  date: Date
  patient_total: number
  patient_payed: number
  patient_state: number
  total: number
  amo_total: number
  amo_payed: number
  amo_state: number
  amc_total: number
  amc_payed: number
  amc_state: number
  nextDunningNoticeLevelPatient: DunningNoticeLevel | null
  nextDunningNoticeLevelAmo: DunningNoticeLevel | null
  nextDunningNoticeLevelAmc: DunningNoticeLevel | null
  patient: Partial<PatientDto> // TODO
  dunningNoticeSends: DunningNoticeSendDto[]
  billing_companies: BillingCompanyDto
  patientPayments: PatientPaymentDto[]
  examLabels: string[]
  amoOrganisme: OrganismeDto
  amcOrganisme: OrganismeDto
}

export interface UpdateInvoiceDto {
  patientPartLoss?: boolean
}

export enum InvoicePaymentStatus {
  TO_BE_PAID = 'TO_BE_PAID',
  NEED_DUNNING_NOTICE = 'NEED_DUNNING_NOTICE',
  OVERPAID = 'OVERPAID',
  FINALIZED = 'FINALIZED',
}

interface BaseQuotePrecotationDto {
  identifier: string
  cotation_type: string
  cotation: string
  modificateur?: string
  date_execution?: string
  prix_unitaire: number
  code_entente_prealable?: string
  date_entente_prealable?: string
  association?: number
  m1?: string
  m2?: string
  m3?: string
  m4?: string
}

export interface QuotePrecotationDto extends BaseQuotePrecotationDto {
  id: number
  exam: ExamDto
}

export interface QuoteDto {
  id: number
  isLocked: boolean
  createdAt: Date
  updatedAt: Date
  updatedBy: MemberDto
  createdBy: MemberDto
  patient: PatientDto
  quotePrecotations: QuotePrecotationDto[]
  attendingDoctor?: MemberDto
  attendingDoctorSite?: SiteDto
  billingCompany?: BillingCompanyDto
}

export interface CreateQuotePrecotationDto extends BaseQuotePrecotationDto {
  examId: number
}

export interface CreateQuoteDto {
  patientId: number
  precotations: CreateQuotePrecotationDto[]
  attendingDoctorId?: number
  attendingDoctorSiteId?: number
}

export interface UpdateQuoteDto extends Partial<CreateQuoteDto> {
  isLocked?: boolean
}

export enum DunningNoticeLevel {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}

export interface UpdateDunningNoticeConfigDto {
  daysLevel1: number
  daysLevel2: number
  daysLevel3: number
  content1: string
  content2: string
  content3: string
}

export interface DunningNoticeConfigDto {
  id: number
  level: DunningNoticeLevel
  days: number
  content: string
  createdAt: Date
  updatedAt: Date
  updatedBy: MemberDto
}

export enum InvoiceEntity {
  PATIENT = 'PATIENT',
  AMC = 'AMC',
  AMO = 'AMO',
}

export enum TransactionType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export interface LotDto {
  id: number
  typeLot: number
  numero: string
  dateLot: Date
  dateHeureTeletrans: Date
  montantTotal: number
  invoices: InvoiceDto[]
  organismPayment?: OrganismPaymentDto
}

export interface OrganismPaymentDto {
  id: number
  date: Date
  comment: string
  cheque: ChequeDto
  paymentMode: PaymentModeDto
  bankTransferPayment: BankTransfertDto
  updatedAt: Date
  updatedBy: MemberDto
  createdAt: Date
  createdBy: MemberDto
}

export interface CreateLotPaymentDto {
  date: Date
  paymentModeId: number
  comment?: string
  bankEstablishmentId: number
  // PaymentModeType.CHEQUE case
  chequeNumber?: string
  drawerName?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateLotPaymentDto { }

export interface DocumentConfigDto {
  id: number
  logoUrl: string
  headerText: string
  headerImageUrl: string
  footerText: string
  footerImageUrl: string
}

export interface GetUrlByQRCodeDto {
  idResip: number
  idLecteur: string
  dateLecture: string
  typeLecture: number
  qrCode: string
}

export interface GetUrlByNFCDTO {
  idResip: number
  idLecteur: string
  dateLecture: string
  typeLecture: number
}

export interface GetUrlByContextDto {
  idResip: number
  idLecteur: string
}
