import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { UpTransition } from '@components/animations'
import BankAccountForm from '@components/forms/BankAccountForm'
import { BankAccountDto } from '@/services/dtos'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ToggleOn as ToggleOnIcon,
  ToggleOffOutlined as ToggleOffOutlinedIcon,
} from '@mui/icons-material'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import {
  getBankAccounts,
  removeBankAccount,
  updateBankAccount,
} from '@state/reducers/bankAccountReducer'

export default function BankAccountsTable() {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [editingBankAccount, setEditingBankAccount] =
    useState<BankAccountDto | null>(null)
  const [isBankAccountFormOpen, setIsBankAccountFormOpen] = useState(false)
  const [deletingBankAccountForm, setDeletingBankAccountForm] =
    useState<BankAccountDto | null>(null)
  const [toggleBankAccountActivation, setToggleBankAccountActivation] =
    useState<BankAccountDto | null>(null)
  const { bankAccounts } = useAppSelector((state) => ({
    bankAccounts: state.bankAccount.bankAccounts,
  }))

  useEffect(() => {
    setIsLoading(true)
    dispatch(getBankAccounts({ includeInactive: true })).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const actionBtnObj = [
    {
      id: 1,
      btnName: () => 'Modifier',
      icon: () => <EditIcon color="primary" />,
      action: (row: BankAccountDto) => {
        setEditingBankAccount(row)
        setIsBankAccountFormOpen(true)
      },
      actionKey: 'editBankAccount',
    },
    {
      id: 2,
      btnName: (row: BankAccountDto) =>
        row.isActive ? 'Désactiver' : 'Activer',
      icon: (row: BankAccountDto) =>
        row.isActive ? (
          <ToggleOnIcon fontSize="small" color="primary" />
        ) : (
          <ToggleOffOutlinedIcon fontSize="small" color="primary" />
        ),
      action: (row: BankAccountDto) => setToggleBankAccountActivation(row),
      actionKey: 'updateActivationBankAccount',
    },
    {
      id: 3,
      btnName: () => 'Supprimer',
      icon: () => <DeleteIcon color="primary" />,
      action: (row: BankAccountDto) =>
        row.isDeletable && setDeletingBankAccountForm(row),
      actionKey: 'deleteBankAccount',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        const bankAccount = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              acc.push(
                <Tooltip
                  key={bankAccount.id + '-' + el.btnName(bankAccount)}
                  title={
                    [
                      'deleteBankAccount',
                      'updateActivationBankAccount',
                    ].includes(el.actionKey) && !bankAccount.isDeletable
                      ? `${
                          el.actionKey === 'deleteBankAccount'
                            ? 'Suppression'
                            : 'Désactivation'
                        } impossible si lié à une société de facturation`
                      : el.btnName(bankAccount)
                  }
                >
                  <div
                    className={`cursor-pointer p-1 hover:bg-gray-200 rounded ${
                      [
                        'deleteBankAccount',
                        'updateActivationBankAccount',
                      ].includes(el.actionKey) && !bankAccount.isDeletable
                        ? 'opacity-50 cursor-not-allowed'
                        : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                      const action = el.action.bind({})
                      action(bankAccount)
                    }}
                  >
                    {el.icon(bankAccount)}
                  </div>
                </Tooltip>,
              )
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 70,
    },
    {
      field: 'name',
      headerName: 'Nom',
      width: 180,
    },
    {
      field: 'updatedAt',
      headerName: 'Dernière action le',
      width: 150,
      valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'lastActionBy',
      headerName: 'par',
      width: 120,
      valueGetter: ({ row }) =>
        row.updatedBy
          ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
          : `${row.createdBy.firstName ?? ''} ${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 65px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                pagination
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <div className="ml-2 mt-2">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsBankAccountFormOpen(true)}
                        >
                          <i className="fas fa-plus"></i>{' '}
                          <span className="ml-2">Ajouter</span>
                        </Button>
                      </div>
                    </GridToolbarContainer>
                  ),
                }}
                columns={columns}
                rows={bankAccounts}
                rowCount={bankAccounts.length}
                disableSelectionOnClick
                disableMultipleSelection
                loading={isLoading}
                getRowClassName={(params) =>
                  params.row.isActive ? '' : 'text-gray-400'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={isBankAccountFormOpen}
        onClose={() => setIsBankAccountFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isBankAccountFormOpen && (
            <BankAccountForm
              onClose={() => {
                setIsBankAccountFormOpen(false)
                setEditingBankAccount(null)
              }}
              editingBankAccount={editingBankAccount}
            />
          )}
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title={
          deletingBankAccountForm
            ? 'Suppression'
            : toggleBankAccountActivation?.isActive
            ? 'Désactivation'
            : 'Activation'
        }
        message={`Voulez-vous vraiment ${
          deletingBankAccountForm
            ? 'supprimer'
            : toggleBankAccountActivation &&
              toggleBankAccountActivation?.isActive
            ? 'désactiver'
            : 'activer'
        } ce compte bancaire ?`}
        open={!!deletingBankAccountForm || !!toggleBankAccountActivation}
        onConfirm={() => {
          if (deletingBankAccountForm) {
            dispatch(removeBankAccount(deletingBankAccountForm.id))
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: 'Le compte bancaire a été supprimé avec succès',
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setDeletingBankAccountForm(null))
          }

          if (toggleBankAccountActivation) {
            dispatch(
              updateBankAccount({
                id: toggleBankAccountActivation.id,
                dto: { isActive: !toggleBankAccountActivation?.isActive },
              }),
            )
              .unwrap()
              .then(() =>
                dispatch(
                  enqueueSnackbar({
                    message: `Le compte bancaire a été ${
                      toggleBankAccountActivation.isActive
                        ? 'désactivé'
                        : 'activé'
                    } avec succès`,
                    options: { variant: 'success' },
                  }),
                ),
              )
              .finally(() => setToggleBankAccountActivation(null))
          }
        }}
        onCancel={() => {
          deletingBankAccountForm
            ? setDeletingBankAccountForm(null)
            : setToggleBankAccountActivation(null)
        }}
      />
    </Container>
  )
}
