import React, { lazy, useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import logo from '../../../../src/assets/img/logos/ariane-fse.png'
import PaymentModesTable from '../../../components/tables/PaymentModesTable'

import {
  CreditCard as CreditCardIcon,
  AssuredWorkload as AssuredWorkloadIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Business as BusinessIcon,
} from '@mui/icons-material'
import BankEstablishmentsTable from '@components/tables/BankEstablishmentsTable'
import BankAccountsTable from '@components/tables/BankAccountsTable'
import BillingCompaniesTable from '@components/tables/BillingCompaniesTable'

export default function Settings() {
  const [value, setValue] = useState(0)

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <TabContext value={value.toString()}>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="settings tabs"
            sx={{
              backgroundColor: 'white',
              minHeight: '48px',
              '& .MuiTab-root': {
                minHeight: '48px',
                padding: '6px 12px',
                fontSize: '0.875rem',
              },
              '& .MuiTab-iconWrapper': {
                marginRight: '8px',
              },
            }}
          >
            <Tab
              icon={<CreditCardIcon />}
              iconPosition="start"
              label="Modes de paiement"
              {...a11yProps(0)}
            />
            <Tab
              icon={<AssuredWorkloadIcon />}
              iconPosition="start"
              label="Etablissements bancaires"
              {...a11yProps(1)}
            />
            <Tab
              icon={<AccountBalanceWalletIcon />}
              iconPosition="start"
              label="Comptes bancaires"
              {...a11yProps(2)}
            />
            <Tab
              icon={<BusinessIcon />}
              iconPosition="start"
              label="Sociétés de facturation"
              {...a11yProps(3)}
            />
          </Tabs>
          <TabPanel value="0">
            <PaymentModesTable />
          </TabPanel>
          <TabPanel value="1">
            <BankEstablishmentsTable />
          </TabPanel>
          <TabPanel value="2">
            <BankAccountsTable />
          </TabPanel>
          <TabPanel value="3">
            <BillingCompaniesTable />
          </TabPanel>
        </div>
      </TabContext>
      <div className="absolute rounded-full top-6 right-6 -translate-y-1/2">
        <img src={logo} alt="logo" style={{ width: '176px' }} />
      </div>
    </>
  )
}
