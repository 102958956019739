import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  FormHelperText,
} from '@mui/material'
import { Control, useController } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

export const useSelectFieldStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, -9px) scale(0.75)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      padding: '0 8px',
    },
  },
  select: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: 18,
      backgroundColor: '#f8f9fa',
      transition: 'all 0.2s ease-in-out',
      '& fieldset': {
        border: 'none',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
      },
    },
    '& .MuiSelect-select': {
      backgroundColor: '#f8f9fa',
      borderRadius: '18px',
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
  },

  menuItem: {
    '&.MuiMenuItem-root': {
      padding: '12px 16px',
      '&:hover': {
        backgroundColor: '#f8f9fa',
      },
      '&.Mui-selected': {
        backgroundColor: '#e9ecef',
        '&:hover': {
          backgroundColor: '#dee2e6',
        },
      },
    },
  },
}))

export const useAutocompleteStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, -9px) scale(0.75)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      padding: '0 8px',
    },
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: 18,
      backgroundColor: '#f8f9fa',
      transition: 'all 0.2s ease-in-out',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
      },
    },
    '& .MuiAutocomplete-input': {
      backgroundColor: '#f8f9fa',
      borderRadius: '18px',
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
  },
  option: {
    '&.MuiAutocomplete-option': {
      padding: '12px 16px',
      '&:hover': {
        backgroundColor: '#f8f9fa',
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#e9ecef',
        '&:hover': {
          backgroundColor: '#dee2e6',
        },
      },
    },
  },
}))

type Props = SelectProps & {
  control: Control<any>
  options: { label: string; value: string | number }[]
}

const SelectField = ({ control, options, label, ...props }: Props) => {
  const { field, fieldState } = useController({
    control,
    name: props.name || '',
  })
  const classes = useSelectFieldStyles()

  return (
    <FormControl
      className={classes.formControl}
      fullWidth
      error={!!fieldState.error}
    >
      {label && <InputLabel shrink>{label}</InputLabel>}

      <Select
        className={classes.select}
        error={!!fieldState.error}
        {...field}
        {...props}
        style={{ border: 'none' }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectField
