import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Fade,
  Typography,
  Stack,
  useTheme,
  alpha,
  ButtonBase,
} from '@mui/material'
import {
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  FilePresent as FileIcon,
  Description as DocIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  TableChart as ExcelIcon,
  Slideshow as PptIcon,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { UpTransition } from '@components/animations'
import { DocumentDTO } from '../../services/dtos'

const isImage = (doc: DocumentDTO) => {
  const mimeType = doc.gedFile.mimeType
  return mimeType.includes('image')
}

type Props = {
  documents: DocumentDTO[]
  isOpen: boolean
  onClose: () => void
  selectedDocument?: DocumentDTO | null
  onSelectedDocumentChange: (document: DocumentDTO) => void
}

const NavigationButton = ({
  direction,
  onClick,
  disabled,
}: {
  direction: 'left' | 'right'
  onClick: () => void
  disabled: boolean
}) => {
  const theme = useTheme()

  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        [direction]: 0,
        width: '100px',
        bgcolor: disabled
          ? 'transparent'
          : alpha(theme.palette.common.black, 0.3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.2s ease',
        cursor: disabled ? 'default' : 'pointer',
        '&:hover': {
          bgcolor: disabled
            ? 'transparent'
            : alpha(theme.palette.common.black, 0.5),
          '& .nav-arrow': {
            opacity: disabled ? 0 : 1,
            transform: `scale(1.2) ${
              direction === 'left' ? 'translateX(-4px)' : 'translateX(4px)'
            }`,
          },
        },
      }}
    >
      {!disabled && (
        <IconButton
          className="nav-arrow"
          sx={{
            color: 'white',
            opacity: 0.7,
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {direction === 'left' ? (
            <ChevronLeftIcon sx={{ fontSize: 40 }} />
          ) : (
            <ChevronRightIcon sx={{ fontSize: 40 }} />
          )}
        </IconButton>
      )}
    </ButtonBase>
  )
}

const getDocumentIcon = (fileName: string) => {
  const extension = fileName?.split('.').pop()?.toLowerCase()
  switch (extension) {
    case 'pdf':
      return <PdfIcon />
    case 'doc':
    case 'docx':
      return <DocIcon />
    case 'xls':
    case 'xlsx':
      return <ExcelIcon />
    case 'ppt':
    case 'pptx':
      return <PptIcon />
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return <ImageIcon />
    default:
      return <FileIcon />
  }
}

const DocumentListViewer: React.FC<Props> = ({
  documents,
  isOpen,
  onClose,
  selectedDocument,
  onSelectedDocumentChange,
}) => {
  const theme = useTheme()

  const currentIndex = selectedDocument
    ? documents.findIndex((doc) => doc.id === selectedDocument.id)
    : -1

  const getFileUrl = (document: DocumentDTO) => {
    return `${process.env.REACT_APP_API_URL}/${document.url}`
  }

  const handleNext = () => {
    if (currentIndex < documents.length - 1) {
      onSelectedDocumentChange(documents[currentIndex + 1])
    }
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      onSelectedDocumentChange(documents[currentIndex - 1])
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') handleNext()
      if (e.key === 'ArrowLeft') handlePrevious()
      if (e.key === 'Escape') onClose()
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [currentIndex, documents])

  return (
    <Dialog
      TransitionComponent={UpTransition}
      fullScreen
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          bgcolor: 'black',
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 2,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: 'white',
            bgcolor: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Navigation Buttons */}
      <NavigationButton
        direction="left"
        onClick={handlePrevious}
        disabled={currentIndex <= 0}
      />
      <NavigationButton
        direction="right"
        onClick={handleNext}
        disabled={currentIndex >= documents.length - 1}
      />

      <DialogContent
        sx={{ p: 0, height: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        {/* Thumbnails Bar */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: alpha(theme.palette.common.black, 0.8),
            p: 2,
            overflowX: 'auto',
            zIndex: 2,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mx: 'auto',
              maxWidth: '100%',
              pb: 1,
            }}
          >
            {documents.map((document) => (
              <Button
                key={document.id}
                onClick={() => onSelectedDocumentChange(document)}
                variant={
                  selectedDocument?.id === document.id
                    ? 'contained'
                    : 'outlined'
                }
                size="small"
                startIcon={getDocumentIcon(document.gedFile.filename)}
                sx={{
                  color: 'white',
                  borderColor: alpha(theme.palette.common.white, 0.3),
                  bgcolor:
                    selectedDocument?.id === document.id
                      ? alpha(theme.palette.primary.main, 0.8)
                      : 'transparent',
                  '&:hover': {
                    bgcolor:
                      selectedDocument?.id === document.id
                        ? alpha(theme.palette.primary.main, 0.9)
                        : alpha(theme.palette.common.white, 0.1),
                  },
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                }}
              >
                <Typography variant="caption" noWrap sx={{ maxWidth: 150 }}>
                  {document.gedFile.filename}
                </Typography>
              </Button>
            ))}
          </Stack>
        </Box>

        {/* Document Viewer */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
          }}
        >
          {selectedDocument &&
            (isImage(selectedDocument) ? (
              <img
                src={getFileUrl(selectedDocument)}
                alt={selectedDocument.gedFile.filename}
                style={{
                  maxHeight: 'calc(100vh - 200px)',
                  maxWidth: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <iframe
                src={getFileUrl(selectedDocument)}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  // bgcolor: 'white',
                }}
                title={selectedDocument.gedFile.filename}
              />
            ))}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DocumentListViewer
