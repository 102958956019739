import {
  DocumentConfigDto,
  InvoiceDto,
  InvoiceEntity,
  OrganismeDto,
} from '@services/dtos'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { forwardRef } from 'react'
import { PatientDto } from '@/services/api'

interface QuoteTemplateProps {
  invoice: InvoiceDto
  entity: InvoiceEntity
  documentConfig?: DocumentConfigDto | null
}

const InvoicePdfTemplate = forwardRef<HTMLDivElement, QuoteTemplateProps>(
  ({ invoice, entity, documentConfig }, ref) => {
    const getPatientInfo = ({
      firstName,
      lastName,
      address,
      email,
      phoneNumber,
      mobileNumber,
    }: Partial<PatientDto>) => ({
      ...(firstName || lastName
        ? {
            Nom: `${firstName || ''}${firstName && lastName ? ' ' : ''}${
              lastName || ''
            }`,
          }
        : {}),
      ...(address ? { Adresse: address } : {}),
      ...(email ? { Email: email } : {}),
      ...(phoneNumber || mobileNumber
        ? { Téléphone: phoneNumber || mobileNumber }
        : {}),
    })

    const getOrganismInfo = ({
      adress,
      libelle,
      phoneNumber,
    }: OrganismeDto) => ({
      ...(libelle ? { Libellé: libelle } : {}),
      ...(adress ? { Adresse: adress } : {}),
      ...(phoneNumber ? { Téléphone: phoneNumber } : {}),
    })

    const infos =
      entity === InvoiceEntity.PATIENT
        ? getPatientInfo(invoice.patient)
        : getOrganismInfo(
            entity === InvoiceEntity.AMC
              ? invoice.amcOrganisme
              : invoice.amoOrganisme,
          )

    const missingInfoPlaceholder = '???'
    const billingCompanyInfos = {
      Société: invoice.billing_companies?.label ?? missingInfoPlaceholder,
      Adresse: invoice.billing_companies?.address ?? missingInfoPlaceholder,
      Pays: invoice.billing_companies?.country ?? missingInfoPlaceholder,
      'Numéro d’entreprise':
        invoice.billing_companies?.registrationNumber ?? missingInfoPlaceholder,
      'Numéro FINESS':
        invoice.billing_companies?.finessNumber ?? missingInfoPlaceholder,
      'Numéro de TVA':
        invoice.billing_companies?.taxNumber ?? missingInfoPlaceholder,
    }

    const generateInfosBloc = (infos: Record<string, string>) => (
      <div className="flex flex-col mt-2 space-y-2">
        {Object.entries(infos).map(([infoKey, info], index) => (
          <div key={index} className="flex">
            <div className="w-32">
              <Typography fontSize={12} color="text.secondary">
                {infoKey}
              </Typography>
            </div>
            <div className="flex-1">
              <Typography
                fontSize={12}
                sx={{
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  overflowWrap: 'anywhere',
                }}
              >
                {info}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    )

    const getAmountDue = () => {
      switch (entity) {
        case InvoiceEntity.PATIENT:
          return invoice.patient_total - invoice.patient_payed
        case InvoiceEntity.AMO:
          return invoice.amo_total - invoice.amo_payed
        case InvoiceEntity.AMC:
          return invoice.amc_total - invoice.amc_payed
        default:
          break
      }
    }

    const getTotal = () => {
      switch (entity) {
        case InvoiceEntity.PATIENT:
          return invoice.patient_total
        case InvoiceEntity.AMO:
          return invoice.amo_total
        case InvoiceEntity.AMC:
          return invoice.amc_total
        default:
          break
      }
    }

    const examLabels =
      invoice.examLabels.length === 0 ? ['Examens'] : invoice.examLabels

    return (
      <div
        ref={ref}
        style={{
          position: 'fixed',
          left: '-1000rem',
          width: 794,
          height: 1123,
          paddingBottom: 16,
        }}
      >
        <div className="flex justify-between">
          <div>
            <div className="mb-2 pl-2">
              <Typography color="text.primary" variant="h4" fontSize={38}>
                Facture N°{invoice.id}
              </Typography>
            </div>
            <div className="mb-24 pl-2 flex gap-x-3">
              <Typography color="text.secondary" variant="body1" fontSize={20}>
                {moment(invoice.date, 'YYYY-MM-DD').format('DD MMMM YYYY')}
              </Typography>
            </div>
          </div>
          {documentConfig?.headerImageUrl && (
            <div className="flex justify-center mb-4">
              <img src={documentConfig?.headerImageUrl} className={''} alt="" />
            </div>
          )}
        </div>
        <div className="flex w-full mb-8">
          <div className="w-1/2 p-4 mb-4">
            <Typography variant="h6" fontSize={18}>
              Emetteur
            </Typography>
            {generateInfosBloc(billingCompanyInfos)}
          </div>

          <div className="w-1/2 p-4 mb-4">
            <Typography variant="h6" fontSize={18}>
              Destinataire
            </Typography>
            {generateInfosBloc(infos)}
          </div>
        </div>

        {documentConfig?.headerText && (
          <div className="mb-6">
            <Typography fontSize={14}>{documentConfig.headerText}</Typography>
          </div>
        )}

        <div className="mb-4">
          <Typography variant="h6" fontSize={18}>
            Détail
          </Typography>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: '#3A0CA1',
                    fontSize: 14,
                    color: 'white',
                    width: 'auto',
                  }}
                >
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontSize: 14, paddingBottom: '2rem' }}>
                  {examLabels.map((detail, index) => (
                    <div key={`details${index}`}>{detail}</div>
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-end mt-12 mr-2">
          <div>
            <Typography variant="h6" fontSize={14}>
              {invoice.leftToRefund ? 'Reste à rembourser' : 'Reste à payer'}
            </Typography>
          </div>
          <div className="ml-12">
            <Typography fontSize={14}>
              {invoice.leftToRefund ?? getAmountDue()} €
            </Typography>
          </div>
        </div>
        <div className="flex justify-end mt-2 mr-2">
          <div>
            <Typography variant="h6" fontSize={14}>
              Total
            </Typography>
          </div>
          <div className="ml-12">
            <Typography fontSize={14}>{getTotal()} €</Typography>
          </div>
        </div>

        {documentConfig?.footerText && (
          <div className="mt-6">
            <Typography fontSize={14}>{documentConfig.footerText}</Typography>
          </div>
        )}
        {documentConfig?.footerImageUrl && (
          <div className="mt-2">
            <img src={documentConfig?.footerImageUrl} className={''} alt="" />
          </div>
        )}
      </div>
    )
  },
)

export default InvoicePdfTemplate
