import { PaymentModeService } from '@services/paymentModeService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  CreatePaymentModeDto,
  PaymentModeDto,
  UpdatePaymentModeDto,
} from '@services/dtos'

export interface PaymentModeState {
  paymentModes: PaymentModeDto[]
}

const initialState: PaymentModeState = {
  paymentModes: [],
}

export const getPaymentModes = createAsyncThunk<
  PaymentModeDto[],
  { includeInactive?: boolean } | undefined
>('payment-modes/find', (params) => PaymentModeService.getAll(params))

export const removePaymentMode = createAsyncThunk<void, number>(
  'payment-modes/remove',
  async (id) => PaymentModeService.remove(id),
)

export const createPaymentMode = createAsyncThunk<
  PaymentModeDto,
  CreatePaymentModeDto
>('payment-modes/create', async (dto) => PaymentModeService.create(dto))

export const updatePaymentMode = createAsyncThunk<
  PaymentModeDto,
  { id: number; dto: UpdatePaymentModeDto }
>('payment-modes/update', async ({ id, dto }) =>
  PaymentModeService.update(id, dto),
)

export const paymentModeReducer = createSlice({
  name: 'paymentModes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentModes.fulfilled, (state, action) => {
      state.paymentModes = action.payload
    })
    builder.addCase(removePaymentMode.fulfilled, (state, action) => {
      state.paymentModes = state.paymentModes.filter(
        (paymentMode) => paymentMode.id !== action.meta.arg,
      )
    })
    builder.addCase(updatePaymentMode.fulfilled, (state, action) => {
      state.paymentModes = state.paymentModes.map((paymentMode) =>
        paymentMode.id === action.payload.id ? action.payload : paymentMode,
      )
    })
    builder.addCase(createPaymentMode.fulfilled, (state, action) => {
      state.paymentModes.unshift(action.payload)
    })
  },
})

export default paymentModeReducer.reducer
