export enum Actions {
  /** Auth thunks */
  LOGIN = 'auth/login',
  LOGOUT = 'auth/logout',
  REGISTER_PATIENT = 'auth/register/patient',
  REGISTER_DOCTOR = 'auth/register/doctor',
  FIRST_CONNEXION = 'auth/first-connexion',
  VERIFY_EMAIL = 'auth/verify-email',
  UPDATE_EMAIL = 'auth/update-email',
  UPDATE_PHONE = 'auth/update-phone',
  VERIFY_CODE = 'auth/verify-code',
  FORGOTTEN_PASSWORD = 'auth/forgotten-password',
  RESET_PASSWORD = 'auth/reset-password',
  UPDATE_PASSWORD = 'auth/update-password',
  FIND_MY_INFOS = 'user/findMyInfos',
  GET_CAPTCHA = 'auth/captcha',
  PATIENT_UPDATE = 'patient/update',
  /** Site thunks */
  SITE_FIND_ALL = 'site/findAll',
  SITE_FIND_ONE = 'site/findOne',
  SITE_FIND_BANK_ACCOUNTS = 'site/bank-accounts',
  SITE_UPDATE = 'site/update',
  SITE_CREATE = 'site/create',
  SITE_DELETE = 'site/delete',
  SITE_TYPE_FIND_ALL = 'site-type/findAll',
  /** Client thunks */
  CLIENT_FIND_ALL = 'client/findAll',
  CLIENT_FIND_ONE = 'client/findOne',
  CLIENT_UPDATE = 'client/update',
  CLIENT_CREATE = 'client/create',
  /** Member thunks */
  MEMBER_FIND_ALL = 'member/findAll',
  MEMBER_FIND_ONE = 'member/findOne',
  MEMBER_FIND_SITES_WITH_BILLING_COMPANY = 'member/sitesWithBillingCompany',
  MEMBER_UPDATE = 'member/update',
  MEMBER_CREATE = 'member/create',
  MEMBER_DELETE = 'member/delete',
  /** Role thunks */
  ROLE_FIND_ALL = 'role/findAll',
  ROLE_FIND_ONE = 'role/findOne',
  ROLE_UPDATE = 'role/update',
  ROLE_CREATE = 'role/create',
  ROLE_REMOVE = 'role/remove',
  /** Question thunks */
  QUESTION_FIND_ALL = 'question/findAll',
  QUESTION_FIND_ONE = 'question/findOne',
  QUESTION_UPDATE = 'question/update',
  QUESTION_CREATE = 'question/create',
  QUESTION_REMOVE = 'question/remove',
  /** Doctor thunks */
  DOCTOR_FIND_ALL = 'doctor/findAll',
  DOCTOR_FIND_ONE = 'doctor/findOne',
  DOCTOR_UPDATE = 'doctor/update',
  DOCTOR_CREATE = 'doctor/create',
  /** Procedure thunks */
  PROCEDURE_FIND_ALL = 'procedures/findAll',
  PROCEDURE_UPDATE = 'procedure/update',
  PROCEDURE_CREATE = 'procedure/create',
  PROCEURE_DELETE = 'procedure/delete',
  PROCEDURE_TYPE_FIND_ALL = 'procedures/type/findAll',
  PROCEDURE_TYPE_UPDATE = 'procedure/type/update',
  PROCEDURE_TYPE_CREATE = 'procedure/type/create',
  PROCEDURE_DELETE_TYPE = 'procedure/type/delete',
  INSTRUCTION_FIND_ALL = 'procedure/instruction/findAll',
  /**Room thunks */
  ROOM_FIND_ALL = 'room/findAll',
  ROOM_UPDATE = 'room/update',
  CREATE_RECEPTION = 'room/create_reception',
  CREATE_PREP_ROOM = 'room/create_prep_room',
  CREATE_EXAM_ROOM = 'room/create_exam_room',
  CREATE_OFFICE = 'room/create_office',
  CREATE_WAITING_ROOM = 'room/create_waiting_room',
  REMOVE_PREP_ROOM = 'room/remove_preproom',
  REMOVE_EXAM_ROOM = 'room/exam_room',
  REMOVE_RECEPTION = 'room/remove_reception',
  REMOVE_OFFICE = 'room/remove_office',
  REMOVE_WAITING_ROOM = 'room/remove_waiting_room',
  UPDATE_RECEPTION = 'room/update_reception',
  UPDATE_WAITING_ROOM = 'room/update_waiting_room',
  UPDATE_PREP_ROOM = 'room/update_prep_room',
  UPDATE_EXAM_ROOM = 'room/update_exam_room',
  UPDATE_OFFICE = 'room/update_office',
  /** MedicalEquipment thunks */
  MEDICAL_EQUIPMENT_FIND_ALL = 'medical-equipements/findAll',
  MEDICAL_EQUIPMENT_UPDATE = 'medical-equipement/update',
  MEDICAL_EQUIPMENT_CREATE = 'medical-equipement/create',
  MEDICAL_EQUIPMENT_DELETE = 'medical-equipement/delete',
  /** MedicalEquipment thunks */
  IT_EQUIPMENT_FIND_ALL = 'medical-equipements/findAll',
  IT_EQUIPMENT_UPDATE = 'medical-equipement/update',
  IT_EQUIPMENT_CREATE = 'medical-equipement/create',
  IT_EQUIPMENT_DELETE = 'medical-equipement/delete',
  /** Log thunks */
  LOG_FIND_ALL = 'log/findAll',
  /** Stats thunks */
  STATS_FILTER_TOPSTATS = '/stats/filter',
  STATS_GET_ALL_PRESCRIBER = '/stats/top/prescriber',
  STATS_GET_EXAM_BREACKDOWN = '/stats/examtype/breakdown',
  STATS_GET_TOP_EVENT = '/stats/top/event',
  // patients thunks
  PATIENTS_FIND_ALL = '/patients/findAll',
  PATIENT_FIND_ONE = '/patients/findOne',
  PATIENT_FIND_INVOICES = '/patients/invoices',
  PATIENT_CREATE = '/patient/create',
  NEW_PATIENT_UPDATE = '/patient/newupdate',
  GET_VITAL_URL = '/vital/geturl',
  GET_PATIENT_COVERAGE = 'GET_PATIENT_COVERAGE',
  MergePatients = 'MergePatients',
  FETCH_MERGE_HISTORY = 'FETCH_MERGE_HISTORY',
  CCAM_FIND_ALL = 'CCAM_FIND_ALL',
  NGAP_FIND = 'NGAP_FIND',
}

export enum AlertActions {
  ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',
}

export enum AmbulanceActions {
  FIND_ALL_COMPANIES = 'ambulance/findAllCompanies',
  CREATE_COMPANY = 'ambulance/createCompany',
  UPDATE_COMPANY = 'ambulance/updateCompany',
  DELETE_COMPANY = 'ambulance/deleteCompany',
  FIND_ALL_DRIVERS = 'ambulance/findAllDrivers',
  CREATE_DRIVER = 'ambulance/createDriver',
  UPDATE_DRIVER = 'ambulance/updateDriver',
  DELETE_DRIVER = 'ambulance/deleteDriver',
}

export interface SetAmbulanceFilterAction {
  page: number
  limit: number
  siteId: number
  search: string
}

export enum MedicalOrderActions {
  FIND = 'medical-order/find',
  UPDATE = 'medical-order/update',
}
