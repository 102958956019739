import { UpTransition } from '@components/animations'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import DoctorDetails from '@components/doctrs/DoctorDetails'
import DoctorForm from '@components/forms/DoctorForm'
import DoctorToolbar from '@components/tables/Toolbars/DoctorToolbar'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Dialog, DialogContent, LinearProgress, Checkbox } from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { DoctorDto } from '@services/api'
import {
  doFindDoctors,
  doRemoveDoctor,
  setDoctorFilters,
} from '@state/reducers/doctorReducer'
import { useEffect, useMemo, useState } from 'react'

type Props = {
  searchMode?: boolean
  onSelectionChange?: (doctors: DoctorDto[]) => void
  selectedRows?: DoctorDto[]
}

const DoctorList: React.FC<Props> = ({
  searchMode,
  onSelectionChange,
  selectedRows = [],
}) => {
  const dispatch = useAppDispatch()
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [editingDoctor, seteditingDoctor] = useState<DoctorDto | null>(null)
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorDto | null>(null)
  const [deletingDoctor, setDeletingDoctor] = useState<DoctorDto | null>(null)
  const [isLoading, setisLoading] = useState(false)

  const { doctors, filters } = useAppSelector(({ doctor }) => ({
    doctors: doctor.doctors,
    filters: doctor.filters,
  }))

  const isCheckboxSelection = !!onSelectionChange

  const columns: GridColumns = [
    {
      field: 'firstName',
      headerName: 'Prénom',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex items-center capitalize">
            {params.row.firstName}
          </div>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Nom',
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex items-center uppercase">
            {params.row.lastName}
          </div>
        )
      },
    },
    {
      field: 'rppsNumber',
      headerName: 'Numéro RPPS',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },
    {
      field: 'phoneNumber',
      headerName: 'Téléphone',
      width: 150,
    },
    {
      field: 'skill',
      headerName: 'Spécialité',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <button
            className="mr-2 bg-blue-400 text-white px-2 py-1 rounded-md"
            onClick={() => {
              setSelectedDoctor(params.row)
            }}
          >
            <i className="fas fa-eye"></i>
          </button>
          {!searchMode && (
            <>
              <button
                className="mr-2 bg-orange-400 text-white px-2 py-1 rounded-md"
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  seteditingDoctor(params.row)
                  setIsAddOpen(true)
                }}
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                className="bg-red-400 text-white px-2 py-1 rounded-md"
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  setDeletingDoctor(params.row)
                }}
              >
                <i className="fas fa-trash text-white"></i>
              </button>
            </>
          )}
        </div>
      ),
    },
  ]

  const handleDeleteDoctor = () => {
    if (deletingDoctor) {
      dispatch(doRemoveDoctor(deletingDoctor?.id))
        .unwrap()
        .then(() => {
          setDeletingDoctor(null)
        })
    }
  }

  useEffect(() => {
    dispatch(setDoctorFilters({ page: 1, search: '' }))
  }, [])

  useEffect(() => {
    setisLoading(true)
    dispatch(doFindDoctors()).finally(() => {
      setisLoading(false)
    })
  }, [filters])

  const handleCloseForm = () => {
    setIsAddOpen(false)
    if (editingDoctor) {
      seteditingDoctor(null)
    }
  }

  const filteredRows = useMemo(() => {
    return doctors.datas.filter(
      (d) => !selectedRows?.find((r) => r.id === d.id),
    )
  }, [doctors.datas, selectedRows])

  return (
    <div className="h-full p-4 rounded-md">
      <DataGridPro
        rows={doctors.datas}
        pagination
        paginationMode="server"
        onPageChange={(page) => {
          dispatch(setDoctorFilters({ page: page + 1 }))
        }}
        pinnedColumns={{
          right: ['actions'],
        }}
        columns={
          isCheckboxSelection
            ? [
                {
                  field: 'selection',
                  headerName: '',
                  width: 50,
                  sortable: false,
                  renderHeader: () => (
                    <Checkbox
                      checked={
                        filteredRows.length > 0 &&
                        selectedRows.length === filteredRows.length
                      }
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < filteredRows.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          const newSelection = [
                            ...selectedRows,
                            ...filteredRows.filter(
                              (row) =>
                                !selectedRows.find(
                                  (selected) => selected.id === row.id,
                                ),
                            ),
                          ]
                          onSelectionChange?.(newSelection)
                        } else {
                          onSelectionChange?.([])
                        }
                      }}
                    />
                  ),
                  renderCell: (params: any) => (
                    <Checkbox
                      checked={
                        !!selectedRows.find((doc) => doc.id === params.row.id)
                      }
                      onChange={(e) => {
                        const newSelection = e.target.checked
                          ? [...selectedRows, params.row]
                          : selectedRows.filter(
                              (doc) => doc.id !== params.row.id,
                            )
                        onSelectionChange?.(newSelection)
                      }}
                    />
                  ),
                },
                ...columns,
              ]
            : columns
        }
        loading={isLoading}
        components={{
          Toolbar: DoctorToolbar,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          toolbar: {
            onAddClick: () => setIsAddOpen(true),
            hideActions: searchMode,
          },
        }}
      />
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isAddOpen}
        // onClose={handleCloseForm}
        TransitionComponent={UpTransition}
        disableEscapeKeyDown
      >
        <DialogContent>
          <DoctorForm editingDoctor={editingDoctor} onClose={handleCloseForm} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        open={selectedDoctor !== null}
        TransitionComponent={UpTransition}
        maxWidth="lg"
      >
        <DialogContent>
          {selectedDoctor && (
            <DoctorDetails
              onClose={() => {
                setSelectedDoctor(null)
              }}
              doctor={selectedDoctor}
            />
          )}
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={!!deletingDoctor}
        onConfirm={handleDeleteDoctor}
        onCancel={() => setDeletingDoctor(null)}
        title="Suppression"
        message={`Supprimer le docteur ${deletingDoctor?.firstName} ${deletingDoctor?.lastName}`}
      />
    </div>
  )
}
export default DoctorList
