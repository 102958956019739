import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, Divider, Grid, TextField } from '@mui/material'
import {
  ConsoleConstructorEnum,
  CreateMedicalEquipmentDto,
  MagnetPowerEnum,
  MedicalEquipment,
  ModalityEnum,
  RoomType,
  UpdateMedicalEquipmentDto,
} from '@services/api'
import { EcSwitchInput, TextInput } from '@components/inputs'
import { Button } from '@components/buttons'
import { DateInput, SelectInput } from '@components/inputs'
import { equipmentBrand } from '@utils/constants'
import { LinacForm, MammographeForm } from './EquipmentsForms'
import { useSelector } from '@state/store'
import { ControlledAutocompleteInput } from '@components/inputs/ControlledAutocompleteInput'
import { medicalEquipmentSchema } from '@utils/schemas'
import { SpaceVertical } from '@utils/Spacing'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import { findAllRooms } from '@state/thunks/roomThunk'
import {
  createMedicalEquipment,
  updateMedicalEquipment,
} from '../../../state/thunks/medicalEquipmentThunk'
import moment from 'moment'

type AddEquipFormProps = {
  row?: MedicalEquipment
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function MedicalEquipmentForm({ row, onClose }: AddEquipFormProps) {
  const dispatch = useDispatch()
  // State
  const [selectedType, setSelectedType] = useState('')
  const [selectedSite, setSelectedSite] = useState<number | null>(null)

  // Submit
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateMedicalEquipmentDto>({
    resolver: yupResolver(medicalEquipmentSchema),
    defaultValues: {
      code: row?.code || '',
      commissioningDate: row?.commissioningDate || moment().toDate(),
      dose: row?.dose || false,
      manufacturer: row?.manufacturer || '',
      model: row?.model || '',
      serialNumber: row?.serialNumber || '',
      shared: row?.shared || false,
      examRoomId: row?.examRoom?.id,
      consoleConstructor: row?.consoleConstructor || undefined,
      magnetPower: row?.magnetPower || undefined,
      sharder: row?.sharder || undefined,
    },
  })

  const manufacturer = watch('manufacturer')

  const onSubmit = (values: MedicalEquipment) => {
    if (!selectedSite || !selectedType) {
      return
    }

    const isConsoleConstructor = ['CT', 'PT', 'NM', 'MR', 'MG'].includes(
      selectedType,
    )
    const isDigital = ['RCT', 'MG'].includes(selectedType)
    const isMagnetPower = ['MR'].includes(selectedType)

    const dto: CreateMedicalEquipmentDto = {
      code: values.code,
      commissioningDate: values.commissioningDate,
      dose: values.dose,
      manufacturer: values.manufacturer,
      model: values.model,
      serialNumber: values.serialNumber,
      shared: values.shared,
      sharder: values.sharder,
      consoleConstructor: values.consoleConstructor,
      magnetPower: values.magnetPower,
      examRoomId: values.examRoomId,
      siteId: selectedSite,
      type: selectedType as ModalityEnum,
      ...(isDigital && { digital: values.digital }),
      ...(isMagnetPower && { magnetPower: values.magnetPower }),
      ...(isConsoleConstructor && {
        consoleConstructor: values.consoleConstructor,
      }),
    }

    if (row?.id) {
      dispatch(
        updateMedicalEquipment({
          id: row.id,
          body: dto as UpdateMedicalEquipmentDto,
        }),
      )
    } else {
      dispatch(createMedicalEquipment(dto))
    }

    if (onClose) {
      onClose()
    }
  }
  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  // Reducers
  const { sites, rooms } = useSelector(({ site, room }) => ({
    sites: site.sites.datas,
    rooms: room.rooms,
  }))
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )
  useEffect(() => {
    if (!row) {
      return
    }
    setSelectedSite(row.siteId || null)
    setSelectedType(row.type)
  }, [row])

  useEffect(() => {
    dispatch(findAllSites())
    dispatch(findAllRooms())
  }, [])

  const examRoomsOptions = useMemo(
    () =>
      rooms.reduce(
        (acc, _examRoom) => {
          if (
            _examRoom.type === RoomType.ExamRoom &&
            _examRoom.siteId === selectedSite
          ) {
            acc.push({
              label: _examRoom.label,
              value: _examRoom.id,
            })
          }
          return acc
        },
        [] as {
          label: string
          value: number
        }[],
      ),
    [rooms, selectedSite],
  )

  const _handleTypeChange = (ev) => {
    setSelectedType(ev.target.value)
  }

  const _handleSiteChange = (ev) => {
    setSelectedSite(ev.target.value)
  }

  const consoleConstructorOptions = Object.values(ConsoleConstructorEnum).map(
    (_v) => ({
      label: _v,
      value: _v,
    }),
  )
  const magnetPowerOptions = Object.values(MagnetPowerEnum).map((value) => ({
    label: value,
    value,
  }))

  const typeOptions = Object.values(ModalityEnum).map((value) => ({
    label: value,
    value,
  }))
  console.log('errors', row?.manufacturer)
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="site"
          value={selectedSite}
          options={sitesOptions}
          onChange={_handleSiteChange}
        />
      </Grid>

      <Grid item xs={12} xl={6}>
        <SelectInput
          options={examRoomsOptions}
          control={control}
          name="examRoomId"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="type"
          options={typeOptions}
          onChange={_handleTypeChange}
          value={selectedType}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="code" control={control} errors={errors} />
      </Grid>
      {!selectedType && <Grid item xs={12} xl={6}></Grid>}
      {selectedType && (
        <>
          <Grid item xs={12} xl={6}>
            <Autocomplete
              options={equipmentBrand}
              onChange={(event, value) => {
                setValue('manufacturer', value?.value)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Manufacturer" />
              )}
              value={equipmentBrand.find(
                (brand) => brand.value === manufacturer,
              )}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TextInput name="model" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <TextInput name="serialNumber" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} xl={6}>
            <DateInput
              control={control}
              errors={errors}
              name="commissioningDate"
            />
          </Grid>
          <Grid item xs={6}>
            <EcSwitchInput control={control} input="dose" />
          </Grid>
          <Grid item xs={6}>
            <EcSwitchInput control={control} input="shared" name="shared" />
          </Grid>
          <Grid item xs={12}>
            <SpaceVertical size={10} />
            <Divider variant="middle" />
            <SpaceVertical size={10} />
          </Grid>

          {['CT', 'PT', 'NM', 'MR', 'MG'].includes(selectedType) && (
            <>
              <Grid item xs={12} xl={6}>
                <SelectInput
                  options={consoleConstructorOptions}
                  control={control}
                  name="consoleConstructor"
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} xl={6}></Grid>
            </>
          )}
          {['RCT', 'MG'].includes(selectedType) && (
            <Grid item xs={12}>
              <EcSwitchInput
                control={control}
                input="digital"
                defaultValue={0}
              />
            </Grid>
          )}
          {['MR'].includes(selectedType) && (
            <>
              <Grid item xs={12} xl={6}>
                <SelectInput
                  options={magnetPowerOptions}
                  control={control}
                  name="magnetPower"
                />
              </Grid>
              <Grid item xs={12} xl={6}></Grid>
            </>
          )}
          {['LINAC'].includes(selectedType) && <LinacForm control={control} />}
          {['MG'].includes(selectedType) && (
            <Grid item xs={12}>
              <MammographeForm control={control} />
            </Grid>
          )}
        </>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
