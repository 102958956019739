import { MemberDto, SiteDto } from '../api'
import { PaymentModeDto } from '../dtos'

export interface CreateCashRegisterDTO {
  name: string
  code: string
  location: string
  siteId: number
  balance?: number
  active?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateCashRegisterDTO {}

export interface CashRegisterDTO {
  id: number
  deletedAt: Date
  open: boolean
  openBy: MemberDto
  name: string
  code: string
  location: string
  site: SiteDto
  balance: number
  active: boolean
  createdBy: MemberDto
}

export interface CashRegisterMinDTO {
  id: number
  name: string
  code: string
  balance: number
}

export interface OpenCashRegisterDTO {
  description: string
  cashAmount: number
  checkAmount: number
  voucherAmount: number
}

export interface CloseCashRegisterDTO extends OpenCashRegisterDTO {
  transferAmount: number
}

export enum CashRegisterTransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export interface CashRegisterTransactionDTO {
  id: string
  transactionType: CashRegisterTransactionType
  paymentMode: PaymentModeDto
  amount: number
  description: string
  cashRegister: CashRegisterDTO
  createdAt: Date
  createdBy: MemberDto
}

export interface CreateCashRegisterTransactionDto {
  amount: number
  description?: string
  paymentModeId: number
  transactionType: CashRegisterTransactionType
}

export interface ListCashRegisterDTO {
  siteId?: number
  active?: boolean
}

export interface ListCashRegisterLogDTO {
  cashRegisterId?: number
  type?: string
  startDate?: Date
  endDate?: Date
  reference?: string
  description?: string
  createdById?: number
}

export interface CashRegisterLogDTO {
  id: number
  date: Date
  description: string
  type: string
  reference: string
  cashAmount: number
  checkAmount: number
  voucherAmount: number
  declaredBalance: number
  calculatedBalance: number
  difference: number
  createdBy: MemberDto
  cashRegister: CashRegisterMinDTO
}

export enum RegisterErrors {
  CASH_REGISTER_ALREADY_OPEN = 'CASH_REGISTER_ALREADY_OPEN',
  CASH_REGISTER_ALREADY_CLOSED = 'CASH_REGISTER_ALREADY_CLOSED',
}
