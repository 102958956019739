import { axios, getConfigs, IRequestConfig } from './api'
import {
  FindOrdersQuery,
  OrderDto,
  UpdateOrderDto,
} from './dtos'
import { PaginatedDto } from './extendedType'
import queryString from 'querystring'

export class OrderService {
  private static readonly endpoint = 'orders'

  static get(query: FindOrdersQuery): Promise<PaginatedDto<OrderDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + '?' + queryString.stringify({ ...query }),
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static getById(id: number): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + `/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static addMedicalOrder(orderId: number, examId: number): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${orderId}/add-medical-order`,
        {},
      )
      configs.data = { examId }
      axios(configs, resolve, reject)
    })
  }

  static removeMedicalOrder(
    orderId: number,
    medicalOrderId: number,
  ): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        this.endpoint + `/${orderId}/remove-medical-order`,
        {},
      )
      configs.data = { medicalOrderId }
      axios(configs, resolve, reject)
    })
  }

  static update(id: number, dto: UpdateOrderDto): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        this.endpoint + `/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static createChildOrder(orderID: number): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint + `/${orderID}/create-child`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static findChildOrders(orderID: number): Promise<OrderDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + `/${orderID}/children`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static removeOrder(orderID: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        this.endpoint + `/${orderID}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static getOrderHistory(query: FindOrdersQuery): Promise<PaginatedDto<OrderDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static createEmptyOrder(patientId: number): Promise<OrderDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint + '/create-empty',
        {},
      )
      configs.data = { patientId }
      axios(configs, resolve, reject)
    })
  }
}
