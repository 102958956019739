import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Switch,
  Tooltip,
  SwitchProps,
} from '@mui/material'
import { DoctorDto } from '@services/api'
import { AddressDto, OrderDto, RecipientDTO, VisitDTO } from '@services/dtos'
import {} from '@state/reducers/orderReducer'
import { FC, useEffect, useState } from 'react'
import DoctorList from '../../containers/app/parameters/doctors/DoctorList'
import EditIcon from '@mui/icons-material/Edit'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import {
  doAddRecipient,
  doGetRecipients,
  doRemoveRecipient,
  doUpdateRecipient,
} from '../../state/reducers/recipientReducer'
import DialogTitle from '../dialogs/DialogTitle'
import DoctorForm from '../forms/DoctorForm'
import { capitalize } from 'lodash'
import { AtSign, Mailbox, X } from 'lucide-react'

const TinySwitch: FC<SwitchProps> = (props) => (
  <div className="scale-75 origin-left">
    <Switch
      size="small"
      {...props}
      sx={{
        '& .MuiSwitch-switchBase': {
          padding: '1px',
          '&.Mui-checked': {
            color: '#4AD66D !important',
            '& + .MuiSwitch-track': {
              backgroundColor: '#8DF1B3 !important',
              opacity: 0.9,
              boxShadow: '0 0 8px rgba(141, 241, 179, 0.6)',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          width: 18,
          height: 18,
          marginTop: '2px',
        },
        '& .MuiSwitch-track': {
          borderRadius: 7,
          transition: 'all 0.3s ease',
        },
        width: 40,
        height: 24,
        ...props.sx,
      }}
    />
  </div>
)

const RenderList: FC<{
  recipients: RecipientDTO[]
  onDelete: (id: number) => void
  onSelectAddress: (recipient: RecipientDTO) => void
  onSelectEmail: (recipient: RecipientDTO) => void
  onReceiveMethodChange: (recipient: RecipientDTO) => void
}> = ({ recipients, onDelete, onSelectAddress, onReceiveMethodChange }) => {
  return (
    <div className="space-y-1">
      {recipients.map((rc) => (
        <div key={rc.id} className="border rounded-lg bg-gray-50 p-1 relative">
          <button
            type="button"
            className="absolute top-[-6px] right-[-6px] text-red-500 hover:text-red-700 p-1"
            onClick={() => onDelete(rc.id)}
          >
            <X size={20} />
          </button>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 flex-grow">
              {rc.doctor && (
                <div className="text-sm">
                  <span className="font-medium">
                    {capitalize(rc.doctor.firstName)}{' '}
                    {capitalize(rc.doctor.lastName)}
                  </span>
                  {!!rc.doctor.skill.trim() && (
                    <span className="text-xs ml-1">({rc.doctor.skill})</span>
                  )}
                  <span className="text-xs text-gray-600 ml-2">
                    {rc.doctor.email}
                  </span>
                </div>
              )}
            </div>

            <div className="flex items-center gap-2 pr-5">
              <div className="flex items-center">
                <Tooltip title="Recevoir par email">
                  <TinySwitch
                    checked={rc.receiveByEmail}
                    onChange={(_, checked) =>
                      onReceiveMethodChange({
                        ...rc,
                        receiveByEmail: checked,
                      })
                    }
                    color="primary"
                  />
                </Tooltip>
                <AtSign size={18} className="text-black text-md" />
              </div>

              <div className="flex items-center">
                <Tooltip title="Recevoir par courrier">
                  <TinySwitch
                    checked={rc.receiveByPost}
                    onChange={(_, checked) =>
                      onReceiveMethodChange({
                        ...rc,
                        receiveByPost: checked,
                      })
                    }
                    color="primary"
                  />
                </Tooltip>
                <Mailbox size={18} className="text-black text-md" />
              </div>

              {rc.selectedAddress && (
                <Tooltip
                  title={`${rc.selectedAddress.locationName}, ${rc.selectedAddress.line1}, ${rc.selectedAddress.postalCode} ${rc.selectedAddress.city}`}
                >
                  <div
                    className="flex items-center gap-1 text-xs border-l pl-2 cursor-pointer hover:bg-gray-200 rounded px-2 py-1 transition-colors"
                    onClick={() => onSelectAddress(rc)}
                  >
                    <i className="fas fa-map-marker-alt text-gray-500"></i>
                    <span className="truncate max-w-[150px]">
                      {rc.selectedAddress.locationName.length > 6
                        ? `${rc.selectedAddress.locationName.substring(
                            0,
                            6,
                          )}...`
                        : rc.selectedAddress.locationName}
                    </span>
                  </div>
                </Tooltip>
              )}

              {rc.doctor && !rc.selectedAddressId && rc.receiveByPost && (
                <Tooltip title="Aucune adresse sélectionnée">
                  <Button
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={() => onSelectAddress(rc)}
                    className="text-xs py-0 px-1 min-w-0"
                  >
                    <i className="fas fa-exclamation-triangle mr-1"></i>
                    Adresse
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

type Props = { order?: OrderDto; visit?: VisitDTO }

const OrderRecipientList: FC<Props> = ({ order, visit }) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [selectingRecipientAddress, setSelectingRecipientAddress] =
    useState<RecipientDTO | null>(null)
  const [deletingRecipient, setDeletingRecipient] = useState<number | null>(
    null,
  )
  const [editingDoctor, setEditingDoctor] = useState<DoctorDto | null>(null)

  const { recipients } = useAppSelector(({ recipient }) => ({
    recipients: recipient.recipients,
  }))

  const orderId = order?.id
  const visitId = visit?.id

  useEffect(() => {
    setIsLoading(true)
    if (!orderId && !visitId) {
      return
    }
    dispatch(doGetRecipients({ orderId, visitId })).finally(() =>
      setIsLoading(false),
    )
  }, [order, visit])

  useEffect(() => {
    if (selectingRecipientAddress) {
      const selectedRecipient = recipients.find(
        (r) => r.id === selectingRecipientAddress?.id,
      )
      if (selectedRecipient) {
        setSelectingRecipientAddress(selectedRecipient)
      }
    }
  }, [recipients])

  const handleDoctorSelected = (doctor: DoctorDto[]) => {
    const newRecipients: DoctorDto[] = []
    for (const d of doctor) {
      if (!recipients.find((rp) => rp.doctor?.id === d.id)) {
        newRecipients.push(d)
      }
    }
    for (const d of newRecipients) {
      dispatch(
        doAddRecipient({
          doctorId: d.id,
          orderId,
          visitId,
          ...(d.addresses?.length === 1 && {
            selectedAddressId: d.addresses[0].id,
          }),
        }),
      )
    }
  }

  const onDeleteRecipient = async () => {
    if (deletingRecipient) {
      await dispatch(doRemoveRecipient(deletingRecipient)).unwrap()

      setDeletingRecipient(null)
    }
  }

  const handleSelectAddress = async (address: AddressDto) => {
    if (selectingRecipientAddress) {
      await dispatch(
        doUpdateRecipient({
          id: selectingRecipientAddress.id,
          dto: { selectedAddressId: address.id },
        }),
      ).unwrap()

      setSelectingRecipientAddress(null)
    }
  }

  const onEditDoctor = (doctor: DoctorDto) => {
    setEditingDoctor(doctor)
  }

  const handleReceptionMethodChange = (recipient: RecipientDTO) => {
    dispatch(
      doUpdateRecipient({
        id: recipient.id,
        dto: {
          receiveByEmail: recipient.receiveByEmail,
          receiveByPost: recipient.receiveByPost,
        },
      }),
    )
  }

  const handleSelectEmail = (recipient: RecipientDTO) => {
    console.log(recipient)
  }

  return (
    <>
      <div className="p-0">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddOpen(true)}
          type="button"
          size="small"
        >
          <i className="fas fa-plus"></i>
          Ajouter un destinataire
        </Button>
        <div className="space-y-2">
          <RenderList
            recipients={recipients}
            onDelete={setDeletingRecipient}
            onSelectAddress={setSelectingRecipientAddress}
            onSelectEmail={handleSelectEmail}
            onReceiveMethodChange={handleReceptionMethodChange}
          />
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={isAddOpen}
        onClose={() => setIsAddOpen(false)}
      >
        <DialogTitle
          title="Sélectionner un ou plusieurs médecins"
          onClose={() => setIsAddOpen(false)}
        />
        <DialogContent className="p-0 overflow-hidden h-[calc(100vh-200px)]">
          <div className="flex-1 flex flex-row overflow-auto h-full">
            <div className="w-3/4 flex-1">
              <DoctorList
                onSelectionChange={handleDoctorSelected}
                selectedRows={recipients.reduce((acc, r) => {
                  if (r.doctor) {
                    acc.push(r.doctor)
                  }
                  return acc
                }, new Array<DoctorDto>())}
              />
            </div>
            <div className="w-1/4 bg-gray-50 p-4">
              <h3 className="text-lg font-semibold mb-4">
                Destinataires selectionnés
              </h3>
              <div className="space-y-2">
                <RenderList
                  recipients={recipients}
                  onDelete={setDeletingRecipient}
                  onSelectAddress={setSelectingRecipientAddress}
                  onReceiveMethodChange={handleReceptionMethodChange}
                  onSelectEmail={() => {}}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!selectingRecipientAddress}
        onClose={() => setSelectingRecipientAddress(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          title="Sélectionner une adresse"
          onClose={() => setSelectingRecipientAddress(null)}
        />
        <DialogContent>
          <div className="p-2">
            <div className="grid grid-cols-2 gap-4">
              {selectingRecipientAddress?.doctor?.addresses?.map((a) => (
                <div
                  key={a.id}
                  className={`p-3 bg-white rounded-lg border transition-all hover:shadow-md ${
                    selectingRecipientAddress.selectedAddressId === a.id
                      ? 'border-blue-500 ring-2 ring-blue-100'
                      : 'border-gray-200 hover:border-blue-300'
                  } cursor-pointer flex flex-col h-full`}
                  onClick={() => handleSelectAddress(a)}
                >
                  <div className="flex items-start justify-between">
                    <div className="font-medium text-gray-900 truncate">
                      {a.locationName}
                    </div>
                    {selectingRecipientAddress.selectedAddressId === a.id && (
                      <div className="bg-blue-500 text-white rounded-full p-1 -mt-1 -mr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="text-sm text-gray-600 mt-1 flex-grow">
                    <div className="truncate">{a.line1}</div>
                    {a.line2 && <div className="truncate">{a.line2}</div>}
                    <div className="truncate">
                      {a.postalCode} {a.city}
                    </div>
                  </div>
                  {a.finessNumber && (
                    <div className="text-xs text-gray-500 mt-2 border-t pt-1 border-gray-100">
                      N° FINESS: {a.finessNumber}
                    </div>
                  )}
                </div>
              ))}
              {selectingRecipientAddress?.doctor?.addresses?.length === 0 && (
                <div className="col-span-2 p-4 bg-white rounded-lg border border-gray-200 flex items-center justify-between">
                  <div className="text-sm text-gray-600">
                    Aucune adresse disponible pour ce médecin
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    }
                    onClick={() => {
                      if (selectingRecipientAddress.doctor) {
                        onEditDoctor(selectingRecipientAddress.doctor)
                      }
                    }}
                  >
                    Ajouter une adresse
                  </Button>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!editingDoctor}
        onClose={() => setEditingDoctor(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          title="Modifier le médecin"
          onClose={() => setEditingDoctor(null)}
        />
        <DialogContent>
          <DoctorForm
            editingDoctor={editingDoctor}
            onClose={() => setEditingDoctor(null)}
          />
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={!!deletingRecipient}
        title="Suppression d'un destinataire"
        message="Voulez-vous vraiment supprimer ce destinataire ?"
        onCancel={() => setDeletingRecipient(null)}
        onConfirm={onDeleteRecipient}
      />
    </>
  )
}

export default OrderRecipientList
