import { Dialog, DialogContent, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { UpTransition } from '../../../components/animations'
import CloseIcon from '@mui/icons-material/Close'
import { isBase64 } from '../../../utils/dto.mapper'

type Props = {
  isOpen: boolean
  onClose: () => void
  billUrl: string
  onSuccess: (data: any) => void
}
export const BillingDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  billUrl,
  onSuccess,
}) => {
  useEffect(() => {
    const handlePostBack = (msg: MessageEvent) => {
      const data = msg.data
      if (!isBase64(data)) {
        return
      }
      const decodedStr = atob(data)
      const obj = JSON.parse(decodedStr) as any
      if (obj.method) {
        if (obj.method.cancel) {
          onClose()
        } else if (obj.method.output.facture) {
          const data = obj
          onSuccess(data)
        }
      }
    }
    window.addEventListener('message', handlePostBack)
    return () => {
      window.removeEventListener('message', handlePostBack)
    }
  }, [billUrl])

  return (
    <Dialog
      className="relative"
      open={isOpen}
      TransitionComponent={UpTransition}
      fullScreen
    >
      <DialogContent style={{ position: 'relative' }}>
        <div className="absolute top-2 right-2 w-12">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <iframe src={billUrl} width="100%" height="100%" />
      </DialogContent>
    </Dialog>
  )
}

export default BillingDialog
