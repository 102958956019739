import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import {
  DataGridPro,
  GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro'
import moment from 'moment'
import { debounce } from 'lodash'
import {
  getCashRegisters,
  getCashRegisterTransactions,
  setTab,
  setTransactionFilters,
} from '@state/reducers/cashRegisterReducer'
import { DateInputField } from '../inputs/DateInputField'
import { CashRegisterTransactionTypeLabels } from '@utils/constants'

export default function CashRegisterTransactionsTable() {
  const dispatch = useAppDispatch()
  const { cashRegisters, transactions, loading, filters, activeTab } =
    useAppSelector(({ cashRegister }) => ({
      cashRegisters: cashRegister.list,
      transactions: cashRegister.transactionList,
      loading: cashRegister.transactionsLoading,
      filters: cashRegister.transactionFilters,
      activeTab: cashRegister.tab,
    }))

  useEffect(() => {
    dispatch(getCashRegisters())
  }, [])

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(getCashRegisterTransactions())
    }, 300)

    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [filters, dispatch])

  const columns: GridColumns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      valueGetter: ({ row }) =>
        moment(row.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      width: 100,
    },
    {
      field: 'transactionType',
      headerName: 'Type',
      valueGetter: ({ row }) =>
        CashRegisterTransactionTypeLabels[row.transactionType],
      width: 90,
    },
    {
      field: 'cashRegisterName',
      headerName: 'Caisse',
      valueGetter: ({ row }) =>
        `${row.cashRegister.name} ${
          row.cashRegister.deletedAt ? '(supprimée)' : ''
        }`,
      width: 140,
    },
    {
      field: 'paymentModeLabel',
      headerName: 'Mode de paiement',
      valueGetter: ({ row }) => row.paymentMode?.label,
      width: 150,
    },
    {
      field: 'amount',
      headerName: 'Montant',
      width: 85,
      valueGetter: ({ row }) => (row.amount ? `${row.amount}€` : ''),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180,
    },
    {
      field: 'createdBy',
      headerName: 'Enregistré par',
      width: 140,
      valueGetter: ({ row }) =>
        `${row.createdBy.firstName ?? ''}${
          row.createdBy.firstName && row.createdBy.lastName && ' '
        }${row.createdBy.lastName ?? ''}`,
    },
  ]

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 120px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={transactions.datas}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer className="flex items-center justify-between mr-4 mt-1">
                      <div className="flex items-center">
                        <ToggleButtonGroup
                          value={activeTab}
                          exclusive
                          color="secondary"
                          onChange={(_, value) =>
                            value !== null && dispatch(setTab(value))
                          }
                          size="small"
                          unselectable="off"
                          sx={{
                            marginLeft: 3,
                            '& .MuiToggleButton-root': {
                              textTransform: 'none',
                              backgroundColor: '#fff',
                              marginTop: 1,
                              '&.Mui-selected': {
                                backgroundColor: '#3A0CA1',
                                color: '#fff',
                                '&:hover': {
                                  backgroundColor: '#3A0CA1',
                                },
                              },
                            },
                          }}
                        >
                          {[
                            {
                              value: 0,
                              label: 'Caisses',
                            },
                            {
                              value: 1,
                              label: 'Journal',
                            },
                          ].map(({ value, label }) => (
                            <ToggleButton key={label} value={value}>
                              {label}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                        <div className="flex items-center gap-2 mt-2 ml-4">
                          <FormControl size="small">
                            <InputLabel>Caisse</InputLabel>
                            <Select
                              value={filters.cashRegisterId?.toString()}
                              onChange={(event) =>
                                dispatch(
                                  setTransactionFilters({
                                    cashRegisterId: +event.target.value,
                                  }),
                                )
                              }
                              style={{
                                minWidth: '200px',
                                marginRight: 6,
                              }}
                            >
                              <MenuItem value="">
                                <em>Toutes</em>
                              </MenuItem>
                              {cashRegisters.map((cashRegister) => (
                                <MenuItem
                                  key={cashRegister.id}
                                  value={cashRegister.id}
                                >
                                  {cashRegister.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <DateInputField
                            value={
                              filters.from ? moment(filters.from) : undefined
                            }
                            onChange={(date) => {
                              dispatch(
                                setTransactionFilters({
                                  from: date
                                    ? date.startOf('day').toISOString()
                                    : undefined,
                                }),
                              )
                            }}
                          />
                          <Typography>au</Typography>
                          <DateInputField
                            value={filters.to ? moment(filters.to) : undefined}
                            onChange={(date) => {
                              dispatch(
                                setTransactionFilters({
                                  to: date
                                    ? date.endOf('day').toISOString()
                                    : undefined,
                                }),
                              )
                            }}
                          />
                        </div>
                      </div>
                      <GridToolbarExport />
                    </GridToolbarContainer>
                  ),
                  Pagination: () => (
                    <TablePagination
                      component="div"
                      rowsPerPage={filters.limit ?? 20}
                      count={transactions.totalCount}
                      rowsPerPageOptions={[
                        20,
                        { value: transactions.totalCount, label: 'All' },
                      ]}
                      labelRowsPerPage="Rows per page:"
                      page={filters.page || 0}
                      onPageChange={(_event, page) => {
                        dispatch(setTransactionFilters({ page }))
                      }}
                      onRowsPerPageChange={(event) =>
                        dispatch(
                          setTransactionFilters({
                            limit: +event.target.value,
                          }),
                        )
                      }
                    />
                  ),
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
