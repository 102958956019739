import React, { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import {
  CreatePatientPaymentDto,
  InvoiceDto,
  TransactionType,
  PaymentModeType,
} from '@services/dtos'
import { patientPaymentSchema } from '@utils/schemas'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { getPaymentModes } from '@state/reducers/paymentModeReducer'
import { PaymentModeTypeLabels } from '@utils/constants'
import { UpTransition } from '../animations'
import { getBankEstablishments } from '@state/reducers/bankEstablishmentReducer'
import BankEstablishmentForm from './BankEstablishmentForm'
import moment from 'moment'
import PatientSearchComponent from '../patient/PatientSearchComponent'
import { findInvoices } from '@state/thunks/patientsThunk'
import { DataGridPro } from '@mui/x-data-grid-pro'
import PaymentModeForm from './PaymentModeForm'
import { createPatientPayment } from '@state/reducers/patientPaymentReducer'
import { getInvoices } from '@state/reducers/invoiceReducer'
import { DateInputField } from '../inputs/DateInputField'

type Props = {
  paymentCase: TransactionType
  invoiceToPay?: InvoiceDto
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const PatientPaymentForm: React.FC<Props> = ({
  invoiceToPay,
  paymentCase,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<CreatePatientPaymentDto>({
    resolver: yupResolver(patientPaymentSchema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentModeFormOpen, setIsPaymentModeFormOpen] = useState(false)
  const [isBankEstablishmentFormOpen, setIsBankEstablishmentFormOpen] =
    useState(false)
  const [patientId, setPatientId] = useState<number | undefined>(
    invoiceToPay ? invoiceToPay.patient.id : undefined,
  )
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDto | null>(
    invoiceToPay ?? null,
  )
  const [isTotalPayment, setIsTotalPayment] = useState(true)
  const [cashGiven, setCashGiven] = useState('')
  const [cashGivenInvalid, setCashGivenInvalid] = useState(false)

  const { paymentModes, bankEstablishments, patients } = useAppSelector(
    ({ paymentMode, bankEstablishment, patients }) => ({
      paymentModes: paymentMode.paymentModes,
      bankEstablishments: bankEstablishment.bankEstablishments,
      patients: patients.patients,
    }),
  )
  const dispatch = useAppDispatch()

  const paymentModeId = watch('paymentModeId')
  const paymentModeType = useMemo(
    () => paymentModes.find(({ id }) => id === +paymentModeId)?.type,
    [paymentModeId],
  )
  const minimumRequestedDepositDate = watch('minimumRequestedDepositDate')
  const amount = watch('amount')
  const bankEstablishmentId = watch('bankEstablishmentId')

  const handlePatientChange = (selectedPatientId?: number) => {
    setPatientId(selectedPatientId)
    if (selectedPatientId && !invoiceToPay) {
      setSelectedInvoice(null)
      dispatch(findInvoices(selectedPatientId))
    }
  }

  const invoices = useMemo(() => {
    if (!patientId) return null
    return patients.find(({ id }) => id === +patientId)?.invoices
  }, [patients])

  const amountDue = useMemo(() => {
    if (!selectedInvoice) return 0
    return paymentCase === TransactionType.PAYMENT
      ? selectedInvoice.patient_total - selectedInvoice.patient_payed
      : selectedInvoice.leftToRefund ?? 0
  }, [selectedInvoice])

  const cb = async (msg: string) => {
    dispatch(
      enqueueSnackbar({
        message: msg,
        options: { variant: 'success' },
      }),
    )
    onClose()
  }

  useEffect(() => {
    if (paymentModes.length > 0) {
      setValue('paymentModeId', paymentModes[0].id)
    }
  }, [paymentModes])

  useEffect(() => {
    dispatch(getPaymentModes())
    dispatch(getBankEstablishments())
    return () => {
      reset()
    }
  }, [dispatch])

  const onSubmitForm = async (data: CreatePatientPaymentDto) => {
    if (!selectedInvoice || !paymentModeType) return

    clearErrors()

    let hasError = false
    if (paymentModeType === PaymentModeType.CHEQUE && !data.chequeNumber) {
      setError('chequeNumber', {
        type: 'manual',
        message: 'Requis',
      })
      hasError = true
    }

    if (
      [PaymentModeType.BANK_TRANSFER, PaymentModeType.CHEQUE].includes(
        paymentModeType,
      ) &&
      !data.bankEstablishmentId
    ) {
      setError('bankEstablishmentId', {
        type: 'manual',
        message: 'Requis',
      })
      hasError = true
    }

    if (
      paymentCase === TransactionType.REFUND &&
      paymentModeType === PaymentModeType.CHEQUE &&
      !data.drawerName
    ) {
      setError('drawerName', {
        type: 'manual',
        message: 'Requis',
      })
      hasError = true
    }

    if (hasError) return

    setIsSubmitting(true)

    dispatch(
      createPatientPayment({
        ...data,
        transactionType: paymentCase,
        invoiceId: selectedInvoice.id,
      }),
    )
      .unwrap()
      .then(async () =>
        cb(
          `${
            paymentCase === TransactionType.PAYMENT ? 'Le paiement' : 'L\'avoir'
          } a été sauvegardé avec succès`,
        ),
      )
      .finally(() => {
        setIsSubmitting(false)
        dispatch(getInvoices())
      })
  }

  const handlePreSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!amount && isTotalPayment) setValue('amount', `${amountDue}`)
    handleSubmit(onSubmitForm)()
  }

  return (
    <div className="w-full h-full p-4">
      <form onSubmit={handlePreSubmit} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            {paymentCase === TransactionType.PAYMENT
              ? 'Encaissement'
              : 'Remboursement'}{' '}
            patient
          </h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={
                isSubmitting ||
                (paymentCase === TransactionType.PAYMENT && amountDue === 0)
              }
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Créer
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item sm={4}>
            <fieldset className="border border-gray-200 p-4 rounded-lg">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Informations
              </legend>

              <fieldset className="border border-gray-200 p-4 rounded-lg mb-4">
                <legend className="text-sm font-medium text-gray-700 mb-1 border border-gray-200 p-2 rounded-lg">
                  Mode de paiement
                  <span className="inline-flex items-center">
                    <Tooltip title="Ajouter un mode de paiement" arrow>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsPaymentModeFormOpen(true)}
                        sx={{
                          paddingTop: 0.5,
                          paddingBottom: 0.5,
                          paddingLeft: 1,
                          paddingRight: 1,
                          minWidth: 'auto',
                          width: 'auto',
                          marginLeft: 1,
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </Tooltip>
                  </span>
                </legend>
                <FormField label="" error={errors.paymentModeId?.message}>
                  {paymentModes.length > 0 ? (
                    <select
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      {...register('paymentModeId')}
                    >
                      {paymentModes.map(({ id, label, type }) => (
                        <option key={id} value={id}>
                          {label} ({PaymentModeTypeLabels[type]})
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Typography variant="body1">
                      Aucun mode de paiement enregistré
                    </Typography>
                  )}
                </FormField>
              </fieldset>

              {paymentModeType === PaymentModeType.CHEQUE && (
                <>
                  <FormField
                    label="N° de chèque"
                    error={errors.chequeNumber?.message}
                  >
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Entrez le numéro de chèque"
                      {...register('chequeNumber')}
                    />
                  </FormField>

                  <fieldset className="border border-gray-200 p-4 rounded-lg mb-4">
                    <legend className="text-sm font-medium text-gray-700 mb-1 border border-gray-200 p-2 rounded-lg">
                      Banque
                      <span className="inline-flex items-center">
                        <Tooltip
                          title="Ajouter un établissement bancaire"
                          arrow
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsBankEstablishmentFormOpen(true)}
                            sx={{
                              paddingTop: 0.5,
                              paddingBottom: 0.5,
                              paddingLeft: 1,
                              paddingRight: 1,
                              minWidth: 'auto',
                              width: 'auto',
                              marginLeft: 1,
                            }}
                          >
                            <i className="fas fa-plus"></i>
                          </Button>
                        </Tooltip>
                      </span>
                    </legend>
                    <Controller
                      name="bankEstablishmentId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          value={
                            bankEstablishments.find(
                              (bankEstablishment) =>
                                bankEstablishment.id === bankEstablishmentId,
                            ) || null
                          }
                          options={bankEstablishments}
                          getOptionLabel={(option) =>
                            `${option.label} (${option.code})`
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Rechercher une banque..."
                              error={!!errors.bankEstablishmentId}
                              helperText={errors.bankEstablishmentId?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </fieldset>

                  <FormField label="Tireur" error={errors.drawerName?.message}>
                    <input
                      type="text"
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Entrez le prénom et nom du tireur"
                      {...register('drawerName')}
                    />
                    {paymentCase === TransactionType.PAYMENT && (
                      <Typography variant="caption">
                        Si différent du patient
                      </Typography>
                    )}
                  </FormField>

                  {paymentCase === TransactionType.PAYMENT && (
                    <FormControl
                      fullWidth
                      error={!!errors.minimumRequestedDepositDate}
                      className="!mb-4"
                    >
                      <DateInputField
                        label="Date de dépôt minimale demandée"
                        name="minimumRequestedDepositDate"
                        value={
                          minimumRequestedDepositDate
                            ? moment(minimumRequestedDepositDate)
                            : null
                        }
                        onChange={(date) => {
                          setValue(
                            'minimumRequestedDepositDate',
                            date ? date.toDate() : undefined,
                          )
                        }}
                      />
                      {!!errors.minimumRequestedDepositDate && (
                        <FormHelperText error>
                          {errors.minimumRequestedDepositDate?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </>
              )}

              {paymentModeType === PaymentModeType.BANK_TRANSFER && (
                <fieldset className="border border-gray-200 p-4 rounded-lg mb-4">
                  <legend className="text-sm font-medium text-gray-700 mb-1 border border-gray-200 p-2 rounded-lg">
                    Banque
                    <span className="inline-flex items-center">
                      <Tooltip title="Ajouter un établissement bancaire" arrow>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsBankEstablishmentFormOpen(true)}
                          sx={{
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                            paddingLeft: 1,
                            paddingRight: 1,
                            minWidth: 'auto',
                            width: 'auto',
                            marginLeft: 1,
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Tooltip>
                    </span>
                  </legend>
                  <Controller
                    name="bankEstablishmentId"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={
                          bankEstablishments.find(
                            (bankEstablishment) =>
                              bankEstablishment.id === bankEstablishmentId,
                          ) || null
                        }
                        options={bankEstablishments}
                        getOptionLabel={(option) =>
                          `${option.label} (${option.code})`
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(_, value) => field.onChange(value?.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            error={!!errors.bankEstablishmentId}
                            helperText={errors.bankEstablishmentId?.message}
                          />
                        )}
                      />
                    )}
                  />
                </fieldset>
              )}

              <FormField label="Commentaires" error={errors.comment?.message}>
                <textarea
                  rows={3}
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Entrez vos commentaires"
                  {...register('comment')}
                />
              </FormField>
            </fieldset>
          </Grid>

          <Grid item sm={8}>
            <fieldset className="border border-gray-200 p-4 rounded-lg">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Facture
              </legend>

              <PatientSearchComponent
                isPatientExists
                onChange={handlePatientChange}
                defaultValue={
                  invoiceToPay ? invoiceToPay.patient.id : undefined
                }
                disableClearable={!!invoiceToPay}
              />

              {patientId && (invoices || invoiceToPay) && (
                <DataGridPro
                  style={{
                    marginTop: 20,
                    height: invoiceToPay ? 162 : 250,
                  }}
                  sx={{
                    '& .MuiDataGrid-row': {
                      backgroundColor: 'white',
                    },
                  }}
                  rows={(invoices || [invoiceToPay]).filter(
                    (invoiceToPay): invoiceToPay is InvoiceDto =>
                      invoiceToPay !== undefined,
                  )}
                  columns={[
                    {
                      field: 'id',
                      headerName: 'N° Facture',
                      width: 120,
                    },
                    {
                      field: 'total',
                      headerName: 'Total facture',
                      width: 120,
                      valueGetter: ({ row }) => `${row.total}€`,
                    },
                    {
                      field: 'date',
                      headerName: 'Date',
                      valueGetter: ({ row }) =>
                        moment(row.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      width: 140,
                    },
                    {
                      field: 'patient_total',
                      headerName: 'Total patient',
                      width: 120,
                      valueGetter: ({ row }) => `${row.patient_total}€`,
                    },
                    {
                      field: 'leftToPay',
                      headerName:
                        paymentCase === TransactionType.PAYMENT
                          ? 'Reste à payer'
                          : 'Reste à rembourser',
                      width:
                        paymentCase === TransactionType.PAYMENT ? 120 : 150,
                      cellClassName: 'font-bold',
                      valueGetter: () => `${amountDue}€`,
                    },
                  ]}
                  checkboxSelection={!invoiceToPay}
                  disableMultipleSelection
                  selectionModel={selectedInvoice ? [selectedInvoice.id] : []}
                  onSelectionModelChange={(newSelectionModel) => {
                    if (!invoices) return
                    setSelectedInvoice(
                      invoices.find(
                        ({ id }) =>
                          id ===
                          +newSelectionModel[
                            newSelectionModel.length > 1
                              ? newSelectionModel.length - 1
                              : 0
                          ],
                      ) ?? null,
                    )
                  }}
                />
              )}

              {selectedInvoice && (
                <FormField label="">
                  <div className="flex items-center">
                    <span className="mr-2 text-sm font-medium text-gray-700">
                      {paymentCase === TransactionType.PAYMENT
                        ? 'Paiement'
                        : 'Remboursement'}{' '}
                      total ({amountDue}€)
                    </span>
                    <Switch
                      checked={isTotalPayment}
                      onChange={(e) => setIsTotalPayment(e.target.checked)}
                      color="primary"
                    />
                  </div>
                </FormField>
              )}

              {!isTotalPayment && selectedInvoice && (
                <>
                  <Grid item xs={12}>
                    <FormField
                      label="Montant partiel"
                      error={errors.amount?.message}
                    >
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="0.00"
                            onChange={(e) => {
                              const value = e.target.value
                              if (+value > amountDue) {
                                field.onChange(amountDue)
                                return
                              }
                              const regex = /^\d*(\.\d{0,2})?$/
                              if (regex.test(value)) {
                                field.onChange(value)
                              }
                            }}
                          />
                        )}
                      />
                    </FormField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {TransactionType.PAYMENT
                        ? 'Le patient devra encore régler : '
                        : 'Il vous restera à rembourser : '}
                      {(amountDue - (+amount || 0)).toFixed(2)}€
                    </Typography>
                  </Grid>
                </>
              )}

              {selectedInvoice &&
                paymentModeType === PaymentModeType.CASH &&
                paymentCase === TransactionType.PAYMENT && (
                  <fieldset
                    className={`border border-gray-200 p-4 rounded-lg ${
                      !isTotalPayment && selectedInvoice ? 'mt-4' : ''
                    }`}
                  >
                    <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                      Rendu caisse
                    </legend>
                    <input
                      value={cashGiven}
                      type="text"
                      className={`mb-2 w-full p-2 border rounded ring-2 ring-indigo-500 border-indigo-500 ${
                        cashGivenInvalid ? 'border-red-500' : ''
                      }`}
                      placeholder="Entrez le montant donné en espèces"
                      onChange={(e) => {
                        const value = e.target.value
                        const regex = /^\d*(\.\d{0,2})?$/

                        if (regex.test(value)) {
                          setCashGiven(value)

                          setCashGivenInvalid(
                            parseFloat(value) <
                              (isTotalPayment
                                ? amountDue
                                : parseFloat(amount || '0')),
                          )
                        }
                      }}
                    />

                    <Grid item xs={12}>
                      <Typography variant="body1" className="flex items-center">
                        <i className="fas fa-cash-register mr-3 text-indigo-700 text-2xl"></i>
                        Rendu :{' '}
                        {+cashGiven -
                          (isTotalPayment
                            ? amountDue
                            : parseFloat(amount || '0')) <
                        0
                          ? 0
                          : (
                              +cashGiven -
                              (isTotalPayment
                                ? amountDue
                                : parseFloat(amount || '0'))
                            ).toFixed(2)}
                        €
                      </Typography>
                    </Grid>
                  </fieldset>
                )}
            </fieldset>
          </Grid>
        </Grid>
      </form>

      <Dialog
        open={isPaymentModeFormOpen || isBankEstablishmentFormOpen}
        onClose={() =>
          isPaymentModeFormOpen
            ? setIsPaymentModeFormOpen(false)
            : setIsBankEstablishmentFormOpen(false)
        }
        TransitionComponent={UpTransition}
        slotProps={{ backdrop: {} }}
      >
        <DialogContent>
          {isPaymentModeFormOpen && (
            <PaymentModeForm
              onClose={() => {
                setIsPaymentModeFormOpen(false)
                dispatch(getPaymentModes())
              }}
            />
          )}

          {isBankEstablishmentFormOpen && (
            <BankEstablishmentForm
              onClose={() => {
                setIsBankEstablishmentFormOpen(false)
                dispatch(getBankEstablishments())
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PatientPaymentForm
