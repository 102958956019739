import { ServerAutocomplete } from './autocomplete/ServerAutocomplete'
import { OrganismService } from '../services/organismService'
import { OrganismDTO } from '../services/dtos/OrganismDto'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { UpTransition } from './animations'
import { useState } from 'react'

type Props = {
  onSelect: (value: OrganismDTO | null) => void
  error?: string
  isOpen: boolean
  onClose: () => void
  type: string
}

const OrganismSelectorComponent = ({
  onSelect,
  error,
  isOpen,
  onClose,
  type,
}: Props) => {
  const [selectedOrganism, setSelectedOrganism] = useState<OrganismDTO | null>(
    null,
  )

  const fetchOrganismes = async (search: string) => {
    const response = await OrganismService.findAll({
      Libelle: search,
      page: 1,
      pageSize: 10,
      Type: type === 'amo' ? [0] : [1, 2],
    })
    return response.items
  }

  const handleChange = (value: OrganismDTO | null) => {
    setSelectedOrganism(value)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} TransitionComponent={UpTransition}>
      <DialogTitle>Selectionner un organisme</DialogTitle>
      <DialogContent>
        <ServerAutocomplete<OrganismDTO>
          fetchService={fetchOrganismes}
          onChange={handleChange}
          getValue={(value) => value?.id?.toString() || null}
          getOptionLabel={(value) => (value as OrganismDTO)?.libelle || ''}
          debounceMs={300}
          placeholder="Rechercher un organisme"
          label="Organisme"
          helperText={error}
          minSearchLength={0}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSelect(selectedOrganism)}>Valider</Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrganismSelectorComponent
