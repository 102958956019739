import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Divider } from '@mui/material'

import { BodyPartEnum, Exam, ModalityEnum } from '@services/api'
import { Button } from '@components/buttons'
import { CheckBoxGroupInput, SelectInput, TextInput } from '@components/inputs'
import { examDuration } from '@utils/constants'
import { SpaceVertical } from '@utils/Spacing'
import { procedureSchema } from '@utils/schemas'
import { createExam, updateExam } from '../../../state/thunks/procedureThunk'
import { useSelector } from '@state/store'
import { useIntl } from 'react-intl'

type AddProcedureFormProps = {
  row?: Exam
  onClose?: () => void
}

export function ProcedureForm({ row, onClose }: AddProcedureFormProps) {
  const dispatch = useDispatch()
  const [selectedProperties, setSelectedProperties] = useState<string[]>([])
  const [selectedInstructions, setSelectedInstructions] = useState<string[]>([])
  const intl = useIntl()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Exam>({
    resolver: yupResolver(procedureSchema),
    defaultValues: {
      ...row,
    },
  })
  const instructions = useSelector(({ procedure }) => procedure.instructions)

  const onSubmit = (values: Exam) => {
    const dose = selectedProperties.includes('dose')
    const contrast = selectedProperties.includes('contrast')
    const wc = selectedProperties.includes('wc')
    const shower = selectedProperties.includes('shower')
    const fasted = selectedProperties.includes('fasted')
    const medication = selectedProperties.includes('medication')

    if (row?.id) {
      //update existing exam
      dispatch(
        updateExam({
          id: row.id,
          body: {
            ...row,
            ...values,
            dose,
            contrast,
            shower,
            fasted,
            medication,
            wc,
            instructionsList: selectedInstructions,
          },
        }),
      )
    } else {
      //add exam
      dispatch(
        createExam({
          ...values,
          dose,
          contrast,
          shower,
          fasted,
          medication,
          wc,
          instructionsList: selectedInstructions,
        }),
      )
    }
    if (onClose) {
      onClose()
    }
  }

  const bodyParts = Object.values(BodyPartEnum).map((_bodyPart) => ({
    label: intl.formatMessage({ id: `enums.bodyPart.${_bodyPart}` }),
    value: _bodyPart,
  }))
  const modalities = Object.values(ModalityEnum).map((_m) => ({
    label: _m,
    value: _m,
  }))

  useEffect(() => {
    const _properties: string[] = []
    const _instructions: string[] = []

    if (row?.contrast) {
      _properties.push('contrast')
    }
    if (row?.dose) {
      _properties.push('dose')
    }
    if (row?.fasted) {
      _properties.push('fasted')
    }
    if (row?.shower) {
      _properties.push('shower')
    }
    if (row?.medication) {
      _properties.push('medication')
    }
    if (row?.wc) {
      _properties.push('wc')
    }
    if (row?.instructionsList) {
      row.instructionsList.map((instr) => {
        _instructions.push(instr.label)
      })
    }

    setSelectedProperties(_properties)
    setSelectedInstructions(_instructions)
  }, [row])

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }
  const renderInsturction = (instrLabel: string) => {
    const instruction = instructions.find((instr) => instr.label === instrLabel)
    if (!instruction) {
      return <></>
    }
    return (
      <img
        src={`${process.env.REACT_APP_API_URL}/static/${instruction.imageUrl}`}
        width={30}
        height={30}
      />
    )
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12} xl={6}>
        <TextInput name="code" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput
          name="label"
          placeholder="label"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="bodyPart"
          options={bodyParts}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="modality"
          options={modalities}
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12} xl={6}>
        <SelectInput
          name="procedureTime"
          options={examDuration}
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12} xl={6}>
        <SelectInput
          name="sex"
          options={[
            { value: 'F', label: 'Female' },
            { value: 'M', label: 'Male' },
            { value: 'A', label: 'All' },
          ]}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="ageRange"
          options={[
            { value: 'baby', label: 'Bébé' },
            { value: 'kid', label: 'Enfant' },
            { value: 'adult', label: 'Adulte' },
            { value: 'senior', label: 'Senior' },
            { value: 'all', label: 'Tous' },
          ]}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <CheckBoxGroupInput
          onValueChange={setSelectedProperties}
          values={selectedProperties}
          options={[
            { value: 'dose', label: 'Dose' },
            { value: 'contrast', label: 'Constrast product' },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <SpaceVertical size={10} />
        <Divider variant="middle" />
        <SpaceVertical size={10} />
      </Grid>

      <Grid item xs={12}>
        <CheckBoxGroupInput
          onValueChange={setSelectedInstructions}
          values={selectedInstructions}
          renderText={renderInsturction}
          options={instructions.map((instr) => ({
            value: instr.label,
            label: instr.label,
          }))}
        />
      </Grid>

      {/* <Grid item xs={1}>
        <CheckBoxInput
          className="fas fa-tshirt"
          name="topless"
          label=""
          control={control}
        />
      </Grid>
      <Grid item xs={1}>
        <CheckBoxInput
          className="fas fa-tshirt"
          name="noBra"
          label=""
          control={control}
        />
      </Grid>
      <Grid item xs={1}>
        <CheckBoxInput
          className="fas fa-socks"
          name="noPants"
          label=""
          control={control}
        />
      </Grid>
      <Grid item xs={1}>
        <CheckBoxInput
          className="fas fa-socks"
          name="noShoes"
          label=""
          control={control}
        />
      </Grid>
      <Grid item xs={1}>
        <CheckBoxInput
          className="fas fa-ring"
          name="noPiercing"
          label=""
          control={control}
        />
      </Grid>
      <Grid item xs={1}>
        <CheckBoxInput
          className="fas fa-ring"
          name="blouse"
          label=""
          control={control}
        />
      </Grid> */}

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={2}>
            <Button
              onClick={handleCancelPress}
              color="secondary"
              text="Cancel"
              textColor="white"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="Save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
