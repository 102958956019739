export interface OrganismDTO {
  id: number
  type: number
  code: string
  regime: string
  caisse: string
  centre: string
  libelle: string
  codeDestinataire: string
  centreInfo: string
  teletransmissible: boolean
  active: boolean
  phoneNumber: string
  adress: string
  taux_remboursement: number
  num_organisme: string
}

export interface OrganismFilters {
  page: number
  limit: number
  code?: string
  regime?: string
  caisse?: string
  centre?: string
  type?: number
}

export interface OrganismState {
  organisms: {
    datas: OrganismDTO[]
    totalCount: number
  }
  filters: OrganismFilters
  loading: boolean
  error: string | null
}

export type OrganismViewType = 'amo' | 'caisse' | 'mutuelle'

export const ORGANISM_TYPES = {
  AMO: 1,
  CAISSE: 2,
  MUTUELLE: 3,
} as const

export const getTypeNumber = (view: OrganismViewType): number[] => {
  switch (view) {
    case 'amo':
      return [ORGANISM_TYPES.AMO]
    case 'caisse':
      return [ORGANISM_TYPES.CAISSE]
    case 'mutuelle':
      return [ORGANISM_TYPES.MUTUELLE]
    default:
      return [ORGANISM_TYPES.CAISSE]
  }
}
