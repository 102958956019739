import { QuotesFilters } from '@/state/reducers/quoteReducer'
import { axios, getConfigs, IRequestConfig } from './api'
import {
  BillingCompanyDto,
  CreateQuoteDto,
  QuoteDto,
  UpdateQuoteDto,
} from './dtos'
import { PaginatedDto } from './extendedType'

export class QuoteService {
  private static readonly endpoint = 'quote'

  static getAll(query: QuotesFilters): Promise<PaginatedDto<QuoteDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreateQuoteDto): Promise<QuoteDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static update(id: number, dto: UpdateQuoteDto): Promise<QuoteDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
