import React, { useState, useRef, useEffect } from 'react'
import { FormControl, InputLabel } from '@mui/material'

interface DateInputProps {
  value?: string
  onDateChange?: (date: string) => void
  label?: string
  onValidate?: (isValid: boolean) => void
  error?: string
}

export const CustomDateInput: React.FC<DateInputProps> = ({
  value,
  onDateChange,
  label,
  onValidate,
  error,
}) => {
  const initialDate = (() => {
    if (value && value.includes('/')) {
      const parts = value.split('/')
      return {
        day: parts[0] || '',
        month: parts[1] || '',
        year: parts[2] || '',
      }
    }
    return { day: '', month: '', year: '' }
  })()

  const [date, setDate] = useState(initialDate)
  const [isValid, setIsValid] = useState(true)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const validateDate = (newDate: typeof initialDate) => {
    const isInvalid =
      newDate.day === '0' ||
      newDate.day === '00' ||
      newDate.month === '0' ||
      newDate.month === '00' ||
      /^0+$/.test(newDate.year)

    setIsValid(!isInvalid)
    return !isInvalid
  }

  useEffect(() => {
    if (onValidate) {
      onValidate(isValid)
    }
  }, [isValid])

  useEffect(() => {
    if (value && value.includes('/')) {
      const parts = value.split('/')
      const newDate = {
        day: parts[0] || '',
        month: parts[1] || '',
        year: parts[2] || '',
      }
      setDate(newDate)
      validateDate(newDate)
    }
  }, [value])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'day' | 'month' | 'year',
    maxLength: number,
    nextRef?: React.RefObject<HTMLInputElement>,
  ) => {
    const newValue = e.target.value.replace(/\D/g, '')

    if (newValue.length <= maxLength) {
      setDate((prev) => {
        const newDate = { ...prev, [field]: newValue }
        validateDate(newDate)
        if (onDateChange) {
          onDateChange(`${newDate.day}/${newDate.month}/${newDate.year}`)
        }
        return newDate
      })

      if (newValue.length === maxLength && nextRef?.current) {
        nextRef.current.focus()
      }
    }
  }

  const getBorderClass = () => {
    return isValid
      ? 'border-gray-200 focus:border-gray-600'
      : 'border-red-500 focus:border-red-600'
  }

  return (
    <FormControl variant="outlined" fullWidth>
      {label && (
        <InputLabel
          style={{ backgroundColor: 'white' }}
          shrink
          htmlFor="custom-date-input"
        >
          {label}
        </InputLabel>
      )}
      <div
        id="custom-date-input"
        className={`flex border ${getBorderClass()} rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-gray-600 bg-[#F8F9FA] h-[55px]`}
      >
        <input
          type="text"
          value={date.day}
          onChange={(e) => handleChange(e, 'day', 2, monthRef)}
          placeholder="DD"
          className="w-16 p-2 text-center focus:outline-none bg-[#F8F9FA]"
          maxLength={2}
        />
        <span className="flex items-center px-2 text-gray-500">/</span>
        <input
          ref={monthRef}
          type="text"
          value={date.month}
          onChange={(e) => handleChange(e, 'month', 2, yearRef)}
          placeholder="MM"
          className="w-16 p-2 text-center focus:outline-none bg-[#F8F9FA]"
          maxLength={2}
        />
        <span className="flex items-center px-2 text-gray-500">/</span>
        <input
          ref={yearRef}
          type="text"
          value={date.year}
          onChange={(e) => handleChange(e, 'year', 4)}
          placeholder="YYYY"
          className="w-20 p-2 text-center focus:outline-none bg-[#F8F9FA]"
          maxLength={4}
        />
      </div>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </FormControl>
  )
}

export default CustomDateInput
