import { FindBillingCompanies } from '@/state/reducers/billingCompanyReducer'
import { axios, getConfigs, IRequestConfig } from './api'
import {
  CreateBillingCompanyDto,
  BillingCompanyDto,
  UpdateBillingCompanyDto,
} from './dtos'

export class BillingCompanyService {
  private static readonly endpoint = 'billing-company'

  static getAll(params?: FindBillingCompanies): Promise<BillingCompanyDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      if (params) configs.params = params
      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreateBillingCompanyDto): Promise<BillingCompanyDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static update(
    id: number,
    dto: UpdateBillingCompanyDto,
  ): Promise<BillingCompanyDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
