import {
  DataGridPro,
  useGridApiRef,
  GridColumns,
  GridRowsProp,
  GridInitialState,
  GridEventListener,
  GridEvents,
  GridSelectionModel,
} from '@mui/x-data-grid-pro'
import {
  Pagination,
  NoRowsOverlay,
  LoadingOverlay,
  SimpleToolbarProps,
  SimpleToolbar as Toolbar,
} from './components'

type SimpleTableProps = {
  loading: boolean
  rows: GridRowsProp
  columns: GridColumns
  simpleToolBarProps: SimpleToolbarProps
  initialState?: GridInitialState
  onRowClick?: GridEventListener<GridEvents.rowClick>
  checkboxSelection?: boolean
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void
  selections?: number[] | string[]
}

export function SimpleTable({ ...props }: SimpleTableProps) {
  const apiRef = useGridApiRef()
  console.log('selections', props.selections)
  return (
    <DataGridPro
      {...props}
      columns={[...props.columns]}
      apiRef={apiRef}
      getRowId={(row) => row.id}
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableColumnMenu
      disableSelectionOnClick
      selectionModel={props.selections}
      components={{
        Toolbar,
        Pagination,
        NoRowsOverlay,
        LoadingOverlay,
      }}
      componentsProps={{
        toolbar: props.simpleToolBarProps,
      }}
      sx={{
        border: 'none',
        padding: '5px 15px',
      }}
      rowHeight={36}
    />
  )
}
