import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { DateInputField } from '@components/inputs/DateInputField'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { setInvoiceFilters } from '@state/reducers/invoiceReducer'
import { Site } from '@services/api'
import {
  BillingCompanyDto,
  InvoiceEntity,
  InvoicePaymentStatus,
} from '@services/dtos'
import DunningNoticeConfigForm from '@components/forms/DunningNoticeConfigForm'
import { UpTransition } from '@components/animations'
import { getBillingCompanies } from '@state/reducers/billingCompanyReducer'
import PatientSearchComponent from '@components/patient/PatientSearchComponent'

const InvoicesTableToolbar = () => {
  const dispatch = useAppDispatch()
  const [dunningNoticeConfigFormOpen, setDunningNoticeConfigFormOpen] =
    useState(false)
  const { filters, dunningNoticeConfig, billingCompanies } = useAppSelector(
    ({ invoice, billingCompany }) => ({
      filters: invoice.filters,
      dunningNoticeConfig: invoice.dunningNoticeConfig,
      billingCompanies: billingCompany.billingCompanies,
    }),
  )

  const user = useAppSelector(({ auth }) => auth.user)

  const onSiteChange = (ev: SelectChangeEvent<string>) => {
    const siteId = ev.target.value as string
    dispatch(
      setInvoiceFilters({
        ...filters,
        siteId: parseInt(siteId, 10),
      }),
    )
    dispatch(getBillingCompanies({ includeInactive: true, siteId: +siteId }))
  }

  const sites = useMemo(() => {
    const _sites = (user as any & { sites: Site[] })?.sites
    if (_sites?.length === 1) {
      dispatch(
        setInvoiceFilters({
          siteId: _sites[0].id,
        }),
      )
      dispatch(
        getBillingCompanies({ includeInactive: true, siteId: _sites[0].id }),
      )
    }
    return _sites
  }, [user])

  useEffect(() => {
    if (billingCompanies?.length === 1) {
      dispatch(
        setInvoiceFilters({
          billingCompanyId: billingCompanies[0].id,
        }),
      )
    }
  }, [billingCompanies])

  return (
    <>
      <div className="ml-6 mt-4 flex items-center flex-wrap">
        <ToggleButtonGroup
          value={filters.status}
          exclusive
          color="secondary"
          onChange={(_, value) =>
            !!value &&
            dispatch(
              setInvoiceFilters({
                status: value,
              }),
            )
          }
          size="small"
          unselectable="off"
          sx={{
            marginRight: '10px',
            '& .MuiToggleButton-root': {
              textTransform: 'none',
              backgroundColor: '#fff',
              '&.Mui-selected': {
                backgroundColor: '#3A0CA1',
                color: '#fff',
                '&:hover': {
                  // keep the same color when hover
                  backgroundColor: '#3A0CA1',
                },
              },
            },
          }}
        >
          {[
            {
              value: InvoicePaymentStatus.TO_BE_PAID,
              label: 'A régler',
            },
            {
              value: InvoicePaymentStatus.NEED_DUNNING_NOTICE,
              label: 'A relancer',
            },
            ...(filters.entity === InvoiceEntity.PATIENT
              ? [
                  {
                    value: InvoicePaymentStatus.OVERPAID,
                    label: 'Trop perçu',
                  },
                ]
              : []),
            {
              value: InvoicePaymentStatus.FINALIZED,
              label: 'Historique',
            },
          ].map(({ value, label }) => (
            <ToggleButton key={label} value={value}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {filters.status === InvoicePaymentStatus.NEED_DUNNING_NOTICE && (
          <div className="mr-2">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setDunningNoticeConfigFormOpen(true)}
            >
              <i className="fas fa-cog"></i>{' '}
              <span className="ml-2">Configurer</span>
              {dunningNoticeConfig.length === 0 && (
                <i className="fas fa-exclamation-circle ml-2 text-red-500" />
              )}
            </Button>
          </div>
        )}
        {filters.entity === InvoiceEntity.PATIENT && (
          <Box
            sx={{
              width: 230,
              marginRight: 0.75,
              '& div': {
                maxWidth: '100% !important',
              },
              '& .MuiInputBase-root': {
                paddingTop: '1px !important',
                paddingBottom: '1px !important',
              },
            }}
          >
            <PatientSearchComponent
              isPatientExists
              onChange={(selectedPatientId) =>
                dispatch(
                  setInvoiceFilters({
                    patientId: selectedPatientId,
                  }),
                )
              }
            />
          </Box>
        )}
        <FormControl size="small">
          <InputLabel>Site</InputLabel>
          <Select
            value={filters.siteId?.toString()}
            onChange={onSiteChange}
            style={{
              minWidth: '200px',
              marginRight: 6,
            }}
            size="small"
          >
            {sites.length > 1 && (
              <MenuItem value="">
                <em>Tous</em>
              </MenuItem>
            )}
            {sites.map((site: Site) => (
              <MenuItem key={site.id} value={site.id}>
                {site.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {billingCompanies.length > 0 && (
          <FormControl size="small">
            <InputLabel>Société facturation</InputLabel>
            <Select
              value={filters.billingCompanyId?.toString()}
              onChange={(event) =>
                dispatch(
                  setInvoiceFilters({
                    billingCompanyId: +event.target.value,
                  }),
                )
              }
              style={{
                minWidth: '200px',
                marginRight: 6,
              }}
            >
              {billingCompanies.length > 1 && (
                <MenuItem value="">
                  <em>Toutes</em>
                </MenuItem>
              )}
              {billingCompanies.map((billingCompany: BillingCompanyDto) => (
                <MenuItem key={billingCompany.id} value={billingCompany.id}>
                  {billingCompany.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div className="mr-2">
          <TextField
            label="Rechercher N° facture"
            variant="outlined"
            onChange={(e) =>
              dispatch(
                setInvoiceFilters({
                  id: e.target.value || undefined,
                }),
              )
            }
            size="small"
          />
        </div>
        <div className="mr-2">
          <DateInputField
            value={filters.from ? moment(filters.from) : undefined}
            onChange={(date) => {
              dispatch(
                setInvoiceFilters({
                  from: date ? date.startOf('day').toISOString() : undefined,
                }),
              )
            }}
          />
        </div>
        <div className="mr-2">
          <Typography>au</Typography>
        </div>
        <DateInputField
          value={filters.to ? moment(filters.to) : undefined}
          onChange={(date) => {
            dispatch(
              setInvoiceFilters({
                to: date ? date.endOf('day').toISOString() : undefined,
              }),
            )
          }}
        />
      </div>
      {filters.entity &&
        [InvoiceEntity.AMC, InvoiceEntity.AMO].includes(filters.entity) && (
          <ToggleButtonGroup
            value={filters.entity}
            exclusive
            color="secondary"
            onChange={(_, value) =>
              value !== null &&
              dispatch(
                setInvoiceFilters({
                  entity: value,
                }),
              )
            }
            size="small"
            unselectable="off"
            sx={{
              marginLeft: 3,
              '& .MuiToggleButton-root': {
                textTransform: 'none',
                backgroundColor: '#fff',
                marginTop: 1,
                '&.Mui-selected': {
                  backgroundColor: '#3A0CA1',
                  color: '#fff',
                  '&:hover': {
                    // keep the same color when hover
                    backgroundColor: '#3A0CA1',
                  },
                },
              },
            }}
          >
            {[
              {
                value: InvoiceEntity.AMO,
                label: 'AMO',
              },
              {
                value: InvoiceEntity.AMC,
                label: 'AMC',
              },
            ].map(({ value, label }) => (
              <ToggleButton key={label} value={value}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}

      <Dialog
        open={dunningNoticeConfigFormOpen}
        onClose={() => setDunningNoticeConfigFormOpen(false)}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <DunningNoticeConfigForm
            onClose={() => setDunningNoticeConfigFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default InvoicesTableToolbar
