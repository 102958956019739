import { useEffect, useCallback, useState } from 'react'
import StorageService from '../services/storage'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import { logout } from '../state/thunks/authThunk'

type MessageType = 'get-auth-token' | 'app-logout'

interface IframeMessage {
  type: MessageType;
  id: string;
  data?: any;
}

interface IframeResponse {
  id: string;
  data: any;
  error?: string;
}

export const useIframeMessages = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.auth.user)

  const [connectedIframes, setConnectedIframes] = useState<Window[]>([])
  const handleMessage = useCallback(async (event: MessageEvent) => {
    const message = event.data as IframeMessage
    if (!message || !message.type || !message.id) return

    switch (message.type) {
      case 'get-auth-token':
        try {
          const token = StorageService.getAuthToken()
          const response: IframeResponse = {
            id: message.id,
            data: { token, user }
          };
          (event.source as any)?.postMessage(response, {
            targetOrigin: '*'
          })
        } catch (error) {
          const errorResponse: IframeResponse = {
            id: message.id,
            data: null,
            error: error instanceof Error ? error.message : 'Failed to get auth token'
          };
          (event.source as any)?.postMessage(errorResponse, {
            targetOrigin: '*'
          })
        }
        break

      case 'app-logout':
        try {
          dispatch(logout())
          const response: IframeResponse = {
            id: message.id,
            data: { success: true }
          };
          (event.source as any)?.postMessage(response, {
            targetOrigin: '*'
          })
        } catch (error) {
          const errorResponse: IframeResponse = {
            id: message.id,
            data: null,
            error: error instanceof Error ? error.message : 'Failed to logout'
          };
          (event.source as any)?.postMessage(errorResponse, {
            targetOrigin: '*'
          })
        }
        break

      default:
        console.warn('Unhandled iframe message type:', message.type)
    }
  }, [dispatch, user])

  const registerIframe = useCallback((iframeWindow: Window) => {
    setConnectedIframes(prev => [...prev, iframeWindow])
  }, [])

  const sendMessageToIframes = useCallback((message: any) => {
    connectedIframes.forEach(iframe => {
      iframe.postMessage(message, '*')
    })
  }, [connectedIframes])

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  return {
    registerIframe,
    sendMessageToIframes
  }
}
