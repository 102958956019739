import { Container, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { InvoiceDto, PatientPaymentDto, TransactionType } from '@services/dtos'
import moment from 'moment'
import generatePDF, { Margin } from 'react-to-pdf'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { useEffect, useMemo, useRef } from 'react'
import RefundPdfTemplate from '../pdf/RefundPdfTemplate'
import ReportIcon from '@mui/icons-material/Report'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { getDocumentConfig } from '@state/reducers/documentConfigReducer'

interface PatientPaymentsTableProps {
  invoice: InvoiceDto
}

export default function PatientPaymentsTable({
  invoice,
}: PatientPaymentsTableProps) {
  const dispatch = useAppDispatch()
  const { documentConfig } = useAppSelector(({ documentConfig }) => ({
    documentConfig: documentConfig.documentConfig,
  }))

  useEffect(() => {
    dispatch(getDocumentConfig())
  }, [])

  const RefundPdfCellRenderer = ({ row }: { row: PatientPaymentDto }) => {
    const ref = useRef<HTMLDivElement>(null)

    return (
      <div className="ml-0">
        <RefundPdfTemplate
          invoice={invoice}
          patientPayment={row}
          ref={ref}
          documentConfig={documentConfig}
        />
        <Tooltip title="Voir l'avoir">
          <IconButton
            onClick={() =>
              generatePDF(ref, {
                method: 'open',
                page: {
                  margin: Margin.MEDIUM,
                },
              })
            }
            color="primary"
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 70,
        renderCell: ({ row }) =>
          row.transactionType === TransactionType.REFUND ? (
            <RefundPdfCellRenderer row={row} />
          ) : (
            <></>
          ),
      },
      {
        field: 'transactionType',
        headerName: 'Type',
        width: 140,
        valueGetter: ({ row }) =>
          row.transactionType === TransactionType.REFUND
            ? 'Remboursement'
            : 'Encaissement',
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        width: 100,
        valueGetter: ({ row }) => moment(row.createdAt).format('DD/MM/YYYY'),
      },
      {
        field: 'paymentModeLabel',
        headerName: 'Mode de paiement',
        width: 160,
        renderCell: ({ row }) => (
          <>
            {row.cheque?.unpaid && (
              <Tooltip title={'Impayé'}>
                <ReportIcon className="text-red-500 mr-2" />
              </Tooltip>
            )}
            <Typography variant="body2">{row.paymentMode.label}</Typography>
          </>
        ),
      },
      {
        field: 'amount',
        headerName: 'Montant',
        width: 120,
        valueGetter: ({ row }) => `${row.amount}€`,
      },
      {
        field: 'comment',
        headerName: 'Commentaire',
        width: 160,
        valueGetter: ({ row }) => row.comment,
      },
      {
        field: 'updatedAt',
        headerName: 'Dernière action le',
        width: 150,
        valueGetter: ({ row }) => moment(row.updatedAt).format('DD/MM/YYYY'),
      },
      {
        field: 'lastActionBy',
        headerName: 'par',
        width: 120,
        valueGetter: ({ row }) =>
          row.updatedBy
            ? `${row.updatedBy.firstName ?? ''} ${row.updatedBy.lastName ?? ''}`
            : `${row.createdBy.firstName ?? ''} ${
                row.createdBy.lastName ?? ''
              }`,
      },
    ],
    [documentConfig],
  )

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '0px 30px', marginTop: 20 }}
    >
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 300px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                columns={columns}
                rows={invoice.patientPayments}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
